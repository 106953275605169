/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AML {
  channel?: Channel | null;
  comment?: string | AMLComment | null;
  review_status: AMLReviewStatus;
  risk_level: AMLRiskLevel;
  is_identity_checked?: boolean | null;
  is_pep_or_sanctioned?: boolean | null;
}

export enum AMLBlancoPurpose {
  RefinanceLoan = "refinance_loan",
  NewLoan = "new_loan",
  RefinanceAndNewLoan = "refinance_and_new_loan",
}

export interface AMLBlancoResponse {
  channel?: Channel | null;
  comment?: string | AMLComment | null;
  review_status: AMLReviewStatus;
  risk_level: AMLRiskLevel;
  is_identity_checked?: boolean | null;
  is_pep_or_sanctioned?: boolean | null;
  purpose?: string | null;
  purpose_when_new_loan?: string | null;
}

export interface AMLComment {
  user_id: string | string | string;
  comment: string;

  /** @format date-time */
  timestamp: string;
  comment_id: string;
  action?: string;
}

export interface AMLInsertion {
  channel?: Channel | null;
  comment?: string | null;
}

export interface AMLReviewPayload {
  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;
  review_status: AMLReviewStatus;
  comment: string;
}

export enum AMLReviewStatus {
  Approved = "approved",
  Rejected = "rejected",
  NotReviewed = "not_reviewed",
}

export enum AMLRiskLevel {
  Medium = "medium",
  High = "high",
  Unknown = "unknown",
}

export interface AcceptOffer {
  product: Product;
  country: Country;

  /** @format uuid */
  offer_id: string;
  synchronous?: boolean;
}

export interface AcceptOfferPayload {
  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;
  offer_id: string;
  bank_account_number?: string | null;
  bank_clearing_number?: string | null;
}

export interface AcceptOfferPayloadV2 {
  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;

  /** @format uuid */
  offer_id: string;
  bank_account_number?: string | null;
  bank_clearing_number?: string | null;
}

export interface AcceptTermsResponse {
  /** The URL to the Payment Service Provider iframe */
  psp_iframe?: string | null;

  /** The URL to the Payment Service Provider external page */
  psp_external_url?: string | null;
}

export interface AcceptTermsResponseCustomer {
  /** The URL to the Payment Service Provider iframe */
  psp_iframe?: string | null;

  /** The URL to the Payment Service Provider external page */
  psp_external_url?: string | null;
  currency: Currency;

  /** @format date-time */
  date_created: string;
  signup_date?: string | null;
  signup_url?: string | null;
  insurance_id: string;
  encrypted_insurance_id?: string | null;
  insurance_type: InsuranceType;
  insured_amount: number;
  premium_amount: number;
  status: InsuranceStatus;
  substatus: InsuranceSubstatus;
  last_charge?: LastCharge | null;
  mandate?: MandateStatus | null;
  insurance_group: InsuranceGroup;
}

export interface AcceptedLoans {
  amount: number;
  sum: number;
  accepted_per_meeting: number;
  number_of_meetings: number;
  currency: Currency;
}

export interface Account {
  account_number: string;
  clearing_number?: string | null;
  bank_id?: number | null;
  bank_name?: string | null;
}

export enum Action {
  CREATED = "CREATED",
  UPDATE = "UPDATE",
  CUSTOMER_ADDED = "CUSTOMER_ADDED",
  FIRST_TIME_CUSTOMER = "FIRST_TIME_CUSTOMER",
  INTERNAL_COMMENT_ADDED = "INTERNAL_COMMENT_ADDED",
  ACTIVATED = "ACTIVATED",
  HANDLER_ASSIGNED = "HANDLER_ASSIGNED",
  FIRST_OFFER_RECEIVED = "FIRST_OFFER_RECEIVED",
  OFFER_RECEIVED = "OFFER_RECEIVED",
  CANCELED = "CANCELED",
  PROPERTY_ADDED = "PROPERTY_ADDED",
  DOCUMENT_ATTACHED = "DOCUMENT_ATTACHED",
  VERIFIED_BANK_ID = "VERIFIED_BANK_ID",
  AML_REVIEW = "AML_REVIEW",
  DEACTIVATED = "DEACTIVATED",
}

export interface ActionPayload {
  notification_ids: string[];
  action: NotificationEvent;
}

export interface AdditionalCostPerPerson {
  number_of_people: number;
  cost: number;
}

export interface Address {
  BasicAreaName?: string | null;
  BasicAreaNumber?: string | null;
  ConcatenatedAddress?: string | null;
  FullAddress?: string | null;
  Municipality?: string | null;
  Postcode?: string | null;
  PostcodeArea?: string | null;
  Rest?: string | null;
  StreetLetter?: string | null;
  StreetName?: string | null;
  StreetNumber?: string | null;
}

export interface Affiliate {
  name: string;
  website?: string | null;
  affiliate_parent?: string | null;
  partner_url?: string[] | null;
  country?: Country | null;
  webhook_url?: string | null;
  network?: boolean | null;
  publisher_data?: PublisherData | null;
  affiliate_id: string;
  creator: string | string | string;
  status: ModelsAffiliateAffiliateAffiliateStatus;

  /** @format date-time */
  created_at: string;

  /** @format date-time */
  updated_at: string;
  deactivated_at?: string | null;
  campaign?: ModelsAffiliateAffiliateCampaign[] | null;
}

export interface AffiliateApiLeadSource {
  type?: "AFFILIATE_API";
  external_id?: string | null;
  submission_id?: string | null;
  affiliate_id?: string | null;
}

export enum AffiliateApplicantRole {
  MAIN_APPLICANT = "MAIN_APPLICANT",
  CO_APPLICANT = "CO_APPLICANT",
  GUARANTOR = "GUARANTOR",
}

export enum AffiliateApplyingWith {
  PARENT = "PARENT",
  SPOUSE = "SPOUSE",
  ALONE = "ALONE",
  COHABITANT = "COHABITANT",
}

export interface AffiliateBlancoLoan {
  amount?: number | null;
  loan_type?: AffiliateLoanType | null;
  responsibility?:
  | AffiliateResponsibilitySE
  | AffiliateResponsibilityNO
  | null
  | (AffiliateResponsibilitySE & AffiliateResponsibilityNO);
  creditor_id?: number | null;
  refinance?: boolean | null;
  monthly_payment?: number | null;
  rate?: number | null;
}

export interface AffiliateBlancoLoanForMortgage {
  amount?: number | null;
  loan_type?: AffiliateLoanType | null;
  responsibility?:
  | AffiliateResponsibilitySE
  | AffiliateResponsibilityNO
  | null
  | (AffiliateResponsibilitySE & AffiliateResponsibilityNO);
  creditor_id?: number | null;
  [key: string]: any;
}

export enum AffiliateBlancoPurposeForNewLoan {
  CAR_BOAT_OR_MC = "CAR_BOAT_OR_MC",
  CONSUMPTION = "CONSUMPTION",
  PROPERTY_RENOVATION = "PROPERTY_RENOVATION",
  LOAN_FOR_SOMEBODY_ELSE = "LOAN_FOR_SOMEBODY_ELSE",
  INVESTMENT = "INVESTMENT",
  EDUCATION = "EDUCATION",
  HEALTH_CARE = "HEALTH_CARE",
  DEBT_COLLECTION = "DEBT_COLLECTION",
  INVESTMENT_ABROAD = "INVESTMENT_ABROAD",
  DIVORCE = "DIVORCE",
  BUY_PROPERTY = "BUY_PROPERTY",
}

export interface AffiliateBuyingInfo {
  property_price?: number | null;
  buying_date?: string | null;
  new_production?: boolean | null;
}

export interface AffiliateCallback {
  status: ModelsAffiliatePartnerApplicationAffiliateStatus;

  /** @format date-time */
  timestamp: string;
  external_id: string;
  submission_id: string;

  /** Optional message */
  message?: string | null;
  success?: boolean | null;
}

export interface AffiliateCar {
  monthly_cost?: number | null;
}

export interface AffiliateCars {
  owned?: number | null;
  leased?: AffiliateCar[] | null;
}

export interface AffiliateCreateBlancoApplication {
  /** Can be any string. The given value be sent back as part of the webhook request */
  external_id: string;
  country: Country;

  /**
   * Time slot when to book meeting with customer. Has to be a available slot based on the response from the /bookings endpoint
   * @format date-time
   */
  book_meeting_at: string;
  applicants: OptionalAffiliateApplicantBlanco[];

  /** Current loan situation */
  current_loans: AffiliateBlancoLoan[];
  children_in_the_household: number;
  children_in_the_household_full_time: number;

  /** The time customer would like to pay back loan (in months) */
  desired_payback_time?: number | null;

  /** Total desired amount customer wants to borrow. This is refinance and new loan amounts */
  desired_amount: number;

  /** Used when customer wants to borrow money for purpose different than refinancing existing loans */
  new_loan?: AffiliateNewLoan | null;
  [key: string]: any;
}

export interface AffiliateCreditor {
  name: string;
  creditor_id: number;
}

export interface AffiliateData {
  name: string;
  website?: string | null;
  affiliate_parent?: string | null;
  partner_url?: string[] | null;
  country?: Country | null;
  webhook_url?: string | null;
  network?: boolean | null;
  publisher_data?: PublisherData | null;
}

export interface AffiliateEmployer {
  /** Name of the employer. */
  name: string;
  type: "FULL" | "TRIAL" | "HOURLY" | "PROJECT" | "SELF_EMPLOYED" | "COMPANY_OWNER";

  /** @format date */
  start_date: string;

  /** Used in cases when the type is PROJECT */
  end_date?: string | null;

  /** Employer phone number */
  phone?: string | null;
  organization_number?: string | null;
  profession?: string | null;
}

export enum AffiliateHousingType {
  RENTED = "RENTED",
  OWN_APARTMENT = "OWN_APARTMENT",
  OWN_HOUSE = "OWN_HOUSE",
  LIVE_IN = "LIVE_IN",
}

export interface AffiliateIncome {
  amount: number;
  type: AffiliateIncomeType;
  name: string;
}

export enum AffiliateIncomeType {
  RENT = "RENT",
  OTHER = "OTHER",
  EMPLOYMENT = "EMPLOYMENT",
  PARTNER = "PARTNER",
}

export interface AffiliateLeadCreationPayload {
  desired_mortgage_loan?: AffiliateNewMortgageLoan | null;
  current_mortgage_loan?: AffiliateRefinanceInfo | null;
  buying_info?: AffiliateBuyingInfo | null;
  other_loans?: AffiliateBlancoLoanForMortgage[] | null;
  other_properties_to_keep?: AffiliateOtherProperty[] | null;
  co_applicant_relationship_to_main_applicant?: AffiliateMortgageRelationshipStatus | null;
  cars?: AffiliateCars | null;
  external_id: string;
  applicants: OptionalAffiliateApplicant[];
  property?: AffiliateProperty | null;
  children_in_the_household?: number | null;
  comment?: string | null;
  country: Country;

  /** @format date-time */
  meeting_start: string;
}

export enum AffiliateLoanType {
  CREDIT_CARD = "CREDIT_CARD",
  UNSECURED_LOAN = "UNSECURED_LOAN",
  CAR_LOAN = "CAR_LOAN",
  CHECK_CREDIT = "CHECK_CREDIT",
  OTHER = "OTHER",
  STUDENT_LOAN = "STUDENT_LOAN",
  INSTALLMENT = "INSTALLMENT",
  TOP_MORTGAGE_LOAN = "TOP_MORTGAGE_LOAN",
  MEMBER_LOAN = "MEMBER_LOAN",
  MORTGAGE = "MORTGAGE",
}

export enum AffiliateMaritalStatus {
  UNMARRIED = "UNMARRIED",
  MARRIED = "MARRIED",
  PARTNER = "PARTNER",
}

export enum AffiliateMortgageLoanType {
  REFINANCE = "REFINANCE",
  PRE_APPROVAL = "PRE_APPROVAL",
  BUY = "BUY",
}

export enum AffiliateMortgageRelationshipStatus {
  SPOUSE = "SPOUSE",
  CHILD = "CHILD",
  PARENT = "PARENT",
  PARTNER = "PARTNER",
}

export interface AffiliateNetworkCallbackEvent {
  affiliate_network_id: string;
  callback_trigger: PersonStatus;
  callback_url: string;

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;

  /** @format date-time */
  timestamp: string;
  status: AffiliateNetworkCallbackStatus;
  publisher_id: string;
  application_origin?: string | null;
  product?: Product | null;
  zid?: string | null;
  ignored_reason?: string | null;
}

export enum AffiliateNetworkCallbackStatus {
  SENT = "SENT",
  FAILED = "FAILED",
  PENDING = "PENDING",
  DUPLICATE = "DUPLICATE",
  NOT_SENT = "NOT_SENT",
  IGNORED = "IGNORED",
}

export interface AffiliateNewLoan {
  desired_amount: number;
  loan_purpose: AffiliateBlancoPurposeForNewLoan;
  [key: string]: any;
}

export interface AffiliateNewMortgageLoan {
  mortgage_loan_amount?: number | null;
  down_payment?: number | null;
  down_payment_loan_amount?: number | null;
  bridge_loan_amount?: number | null;
  disbursement_date?: string | null;
  applying_with?: AffiliateApplyingWith | null;
  loan_purpose?: AffiliateMortgageLoanType | null;
  buying_situation?: AffiliateSituation | null;
  private_loan_amount?: number | null;
}

export interface AffiliateNotEmployed {
  type: "RETIRED" | "EARLY_RETIRED" | "NO_WORK";

  /** @format date */
  start_date: string;
}

export interface AffiliateOptionsResponse {
  /** List of possible loan responsibilities */
  responsibility: EnumDescription[];

  /** List of possible marital statuses */
  marital_status: EnumDescription[];

  /** List of possible income types */
  income_type?: EnumDescription[] | null;

  /** List of possible employer types */
  employer_type: EnumDescription[];

  /** List of possible housing types */
  housing_type: EnumDescription[];

  /** List of possible loan types */
  loan_type: EnumDescription[];

  /** List of possible property types */
  property_type: EnumDescription[];

  /** List of possible co-applicant relationships */
  co_applicant_relationship_to_main_applicant: EnumDescription[];

  /** List of possible loan purposes */
  loan_purpose: EnumDescription[];

  /** List of possible applying with types */
  applying_with: EnumDescription[];

  /** List of possible situation types */
  situation: EnumDescription[];

  /** List of possible rate types */
  rate_type: EnumDescription[];

  /** List of possible current housing types */
  currently_living_in: EnumDescription[];
  creditors: AffiliateCreditor[];
}

export interface AffiliateOtherProperty {
  monthly_cost?: number | null;
  mortgage_amount?: number | null;
  property_type: any;
  estimated_value?: number | null;
  property_ownership_share?: number | null;
  loan_ownership_share?: number | null;
}

export interface AffiliateProperty {
  property_type: AffiliatePropertyType;
  estimated_value?: number | null;
  housing_monthly_cost?: number | null;
  square_meters?: number | null;
  address?: AffiliatePropertyAddress | null;
}

export interface AffiliatePropertyAddress {
  /** Street address of property  */
  street_address?: string | null;

  /** City of the property */
  city?: string | null;

  /** Postal code of the property */
  postal_code?: string | null;

  /** Municipality of the property (Sweden's municipalities) */
  municipality_code?: string | null;
}

export enum AffiliatePropertyType {
  CONDOMINIUM = "CONDOMINIUM",
  HOUSE = "HOUSE",
  VACATION_HOME = "VACATION_HOME",
}

export interface AffiliateRatePart {
  rate_type: any;
  amount?: number | null;
  rate?: number | null;
  monthly_payment?: number | null;
  until?: string | null;
  loan_number?: string | null;
  condition_rollover_date?: string | null;
  monthly_amortisation?: number | null;
}

export enum AffiliateRateType {
  FIXED = "FIXED",
  FLEXIBLE = "FLEXIBLE",
  MIXED = "MIXED",
}

export interface AffiliateRefinanceInfo {
  current_creditor_id?: number | null;
  amount?: number | null;
  rate?: number | null;
  monthly_payment?: number | null;
  rate_parts?: AffiliateRatePart[] | null;
  rate_type?: any | null;
  ownership_share_of_loan?: number | null;
}

export interface AffiliateResponse {
  submission_id: string;
}

export enum AffiliateResponsibilityNO {
  MAIN_APPLICANT = "MAIN_APPLICANT",
  CO_APPLICANT = "CO_APPLICANT",
  SHARED = "SHARED",
}

export enum AffiliateResponsibilitySE {
  MAIN_APPLICANT = "MAIN_APPLICANT",
  CO_APPLICANT = "CO_APPLICANT",
  SHARED = "SHARED",
  GUARANTOR = "GUARANTOR",
}

export enum AffiliateSituation {
  SOLD = "SOLD",
  WILL_SELL = "WILL_SELL",
  ONLY_BUY = "ONLY_BUY",
}

export interface AllDisbursedLoans {
  amount: number;
  sum: number;
  average_sum: number;
  currency: Currency;
}

export interface AllInsurances {
  seller_id?: (string | string | string)[] | null;
  total_premium_amount: number;
  total_count: number;
  total_insured_amount: number;
  cancelled_insurances_within_period: number;
  currency?: Currency | null;
}

export interface AllOptionalZoordinates {
  Product?: ZProduct | null;
  Country?: ZCountry | null;
  Office?: ZOffice | null;
  EmploymentType?: ZEmploymentType | null;
  Department?: ZDepartment | null;
}

export enum AmortizationPlan {
  FIXED_RATE = "FIXED_RATE",
  STRAIGHT_LINE = "STRAIGHT_LINE",
}

export enum AmortizationRule {
  FI2018 = "FI2018",
  FI2016 = "FI2016",
  SKB70 = "SKB70",
  OTHER = "OTHER",
}

export interface Apartment {
  apartment_number: string;
}

export interface ApiLeadSource {
  type?: "ZRM_API";
}

/**
 * Application model returned by apps-mgr
 */
export interface AppBase {
  loan?: NewMortgageLoan | null;
  refinance_info?: RefinanceInfo | null;
  buying_info?: BuyingInfo | null;
  selling_info?: SellingInfo | null;
  applicants?: BareApplicantWithRole[] | null;
  property_id?: string | null;
  discount?: Discount | null;
  loans?: Loan[] | null;
  other_mortgages?: MortgageLoan[] | null;
  status?: AppsmgrModelsStatusStatus | null;
  substatus?: Substatus | null;
  bank_status?: BankStatus | null;
  children_in_the_household?: number | null;
  children_in_the_household_fulltime?: number | null;
  other_properties_to_keep?: OtherProperty[] | null;
  co_relationship_to_main_applicant?: RelationshipStatus | null;
  guarantor_relationship_to_main_applicant?: RelationshipStatus | null;
  cars?: CarsInput | null;
  brokerage?: Brokerage | null;
  product: Product;
  country: Country;

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;
  offers?: Offer[] | null;
  date_created?: string | null;
  date_updated?: string | null;
  [key: string]: any;
}

export enum AppState {
  DRAFT = "DRAFT",
  COMPLETE = "COMPLETE",
  OFFERED = "OFFERED",
  ACCEPTED = "ACCEPTED",
  DISBURSED = "DISBURSED",
}

export enum AppStateLabel {
  LATE_DENIAL = "LATE_DENIAL",
}

export interface ApplicantDocument {
  document_id: string;
  document_type: string | DocumentType;
}

export interface ApplicantNO {
  country?: "NO";
  pni: string;
  first_name: string;
  last_name: string;
  email?: string | null;
  phone_number?: string | null;
  additional_phone_numbers?: string[] | null;
  monthly_property_cost?: number | null;
  marital_status?: MaritalStatus | null;

  /** Official marital status from ID check; should not be modified; marital_status has higher priority */
  registered_marital_status?: MaritalStatus | null;
  post_code?: string | null;
  city?: string | null;
  incomes?: Income[] | null;
  net_monthly_income?: number | null;
  account?: Account | null;
  street_address?: string | null;

  /** Number of children to pay child support for */
  paying_child_support?: number | null;

  /** Number of children to receive child support for */
  receiving_child_support?: number | null;
  consents?: Consents | null;
  consents_popup_dismissed_timestamp?: string | null;
  partner_pni?: string | null;
  partner_name?: string | null;
  municipality_code?: string | null;
  municipality?: string | null;
  is_pep?: boolean | null;
  age?: number | null;
  other_phones?: AppsmgrModelsPhonePhoneNumber[] | null;
  sverker_id?: number | null;
  children?: number | null;
  citizen?: number | null;
  education?: EducationType | null;
  monthly_partner_income?: number | null;
  immigrated_at?: string | null;
  fullname?: string | null;
  documents?: ApplicantDocument[] | null;
  verified_credits?: boolean | null;
  housing_since?: string | null;
  deceased?: boolean | null;
  deceased_date?: string | null;
  emigrated?: boolean | null;
  is_sanction?: boolean | null;
  is_rca?: boolean | null;
  date_created?: string | null;
  date_updated?: string | null;
  last_credit_check?: string | null;
  last_externally_verified_credits?: string | null;
  last_property_valuation?: string | null;
  pep_quality?: boolean | null;

  /** A cluster is a group of customers displayed with a color */
  cluster?: string | null;

  /** Indicates if the customer is blocked */
  is_blocked?: boolean;
  housing_type?: HousingTypeNO | null;
  employer?: EmployerNO | null;
  ev_estate?: EvEstate[] | null;
  ev_estates?: EvProperty[] | null;
  citizenship_iso_codes?: string[] | null;
  externally_verified_credits?: ExternallyVerifiedCredit[] | null;

  /** @format uuid */
  customer_id: string;
  credit_check?: CreditCheckNO | null;
  external_block_check?: ExternalBlockCheckNO | null;
  block_list?: BlockList | null;
  gender?: Gender | null;
  gr_consent?: boolean;
  last_externally_verified_credits_consent?: string | null;
}

export enum ApplicantRole {
  MAIN_APPLICANT = "MAIN_APPLICANT",
  CO_APPLICANT = "CO_APPLICANT",
  GUARANTOR = "GUARANTOR",
}

export interface ApplicantSE {
  country?: "SE";
  pni: string;
  first_name: string;
  last_name: string;
  email?: string | null;
  phone_number?: string | null;
  additional_phone_numbers?: string[] | null;
  monthly_property_cost?: number | null;
  marital_status?: MaritalStatus | null;

  /** Official marital status from ID check; should not be modified; marital_status has higher priority */
  registered_marital_status?: MaritalStatus | null;
  post_code?: string | null;
  city?: string | null;
  incomes?: Income[] | null;
  net_monthly_income?: number | null;
  account?: Account | null;
  street_address?: string | null;

  /** Number of children to pay child support for */
  paying_child_support?: number | null;

  /** Number of children to receive child support for */
  receiving_child_support?: number | null;
  consents?: Consents | null;
  consents_popup_dismissed_timestamp?: string | null;
  partner_pni?: string | null;
  partner_name?: string | null;
  municipality_code?: string | null;
  municipality?: string | null;
  is_pep?: boolean | null;
  age?: number | null;
  other_phones?: AppsmgrModelsPhonePhoneNumber[] | null;
  sverker_id?: number | null;
  children?: number | null;
  citizen?: number | null;
  education?: EducationType | null;
  monthly_partner_income?: number | null;
  immigrated_at?: string | null;
  fullname?: string | null;
  documents?: ApplicantDocument[] | null;
  verified_credits?: boolean | null;
  housing_since?: string | null;
  deceased?: boolean | null;
  deceased_date?: string | null;
  emigrated?: boolean | null;
  is_sanction?: boolean | null;
  is_rca?: boolean | null;
  date_created?: string | null;
  date_updated?: string | null;
  last_credit_check?: string | null;
  last_externally_verified_credits?: string | null;
  last_property_valuation?: string | null;
  pep_quality?: any;

  /** A cluster is a group of customers displayed with a color */
  cluster?: string | null;

  /** Indicates if the customer is blocked */
  is_blocked?: boolean;
  housing_type?: HousingTypeSE | null;
  employer?: EmployerSE | null;
  census_registration_year?: string | null;
  credit_regulation_information?: CreditRegulationInformation[] | null;
  credit_regulations?: CreditRegulation[] | null;
  case_collection?: CaseCollectionItem[] | null;
  current_section_of_case?: CurrentSectionOfCaseItem[] | null;
  current_frame?: CurrentFrameItem[] | null;
  html?: string | null;
  last_queries?: LastQuery[] | null;
  notes?: Note[] | null;
  tax?: TaxItem[] | null;
  tax_thousands?: TaxThousand[] | null;
  fraudster?: boolean | null;
  investigation_barrier?: boolean | null;
  uc_interall?: string | null;
  date_of_birth?: string | null;
  prenup_text?: string | null;
  uc_internal_2?: any | null;
  parish?: string | null;
  parish_code?: string | null;
  blocked?: boolean | null;
  protected_address?: boolean | null;
  protected_identity?: boolean | null;
  marital_status_date?: string | null;
  minor?: string | null;
  lost_id_documents?: boolean | null;
  county?: string | null;
  county_code?: string | null;

  /** @format uuid */
  customer_id: string;
  credit_check?: CreditCheckSE | null;
  block_list?: BlockList | null;
  gender?: Gender | null;
}

/**
 * Application model in ZRM
 */
export interface Application {
  loan?: NewMortgageLoan | null;
  refinance_info?: RefinanceInfo | null;
  buying_info?: BuyingInfo | null;
  selling_info?: SellingInfo | null;
  applicants?: BareApplicantWithRole[] | null;
  property_id?: string | null;
  discount?: Discount | null;
  loans?: Loan[] | null;
  other_mortgages?: MortgageLoan[] | null;
  status?: AppsmgrModelsStatusStatus | null;
  substatus?: Substatus | null;
  bank_status?: BankStatus | null;
  children_in_the_household?: number | null;
  children_in_the_household_fulltime?: number | null;
  other_properties_to_keep?: OtherProperty[] | null;
  co_relationship_to_main_applicant?: RelationshipStatus | null;
  guarantor_relationship_to_main_applicant?: RelationshipStatus | null;
  cars?: CarsInput | null;
  brokerage?: Brokerage | null;
  product: Product;
  country: Country;

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;
  offers?: Offer[] | null;
  date_created?: string | null;
  date_updated?: string | null;
  meta?: ApplicationMapping | null;
}

export interface ApplicationAssigned {
  type: "APPLICATION_ASSIGNED";

  /** @format date-time */
  timestamp: string;
  status: NotificationEvent;
  data: ApplicationAssignedData;
  notification_id: string;
  user_id: string | string | string | null;
}

export interface ApplicationAssignedData {
  application_id: string;
}

export interface ApplicationCreatedResponse {
  product: Product;
  country: Country;

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;
}

export interface ApplicationCreatedResponseExt {
  product: Product;
  country: Country;

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;
}

export interface ApplicationDisbursed {
  type: "APPLICATION_DISBURSED";

  /** @format date-time */
  timestamp: string;
  status: NotificationEvent;
  data: ApplicationDisbursedData;
  notification_id: string;
  user_id: string | string | string | null;
}

export interface ApplicationDisbursedData {
  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;
}

export interface ApplicationMapping {
  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;
  handler_id?: string | string | string | null;
  creator_id?: string | string | string | null;
  new_app_checklist?: string[] | null;
  new_app_options?: Option[] | null;
  events?: ModelsApplicationMappingApplicationMappingEvent[] | null;
  documents?: DocumentAssignation[] | null;
  source?: ApplicationSource | null;
  lead_id?: string | null;
  aml: AML;
  bank_id_verifications?: BankIDSigned[] | null;
  internal_comments: (
    | ModelsApplicationMappingCommentsInternalComment
    | BankIDSigned
    | (ModelsApplicationMappingCommentsInternalComment & BankIDSigned)
  )[];

  /** @format date-time */
  updated_at: string;
  product: Product;
  country: Country;
}

export interface ApplicationMappingWithNamesInComments {
  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;
  handler_id?: string | string | string | null;
  creator_id?: string | string | string | null;
  new_app_checklist?: string[] | null;
  new_app_options?: Option[] | null;
  events?: ModelsApplicationMappingApplicationMappingEvent[] | null;
  documents?: DocumentAssignation[] | null;
  source?: ApplicationSource | null;
  lead_id?: string | null;
  aml: AML;
  bank_id_verifications?: BankIDSigned[] | null;
  internal_comments: (
    | InternalCommentWithName
    | BankIDSignedWithNames
    | (InternalCommentWithName & BankIDSignedWithNames)
  )[];

  /** @format date-time */
  updated_at: string;
  product: Product;
  country: Country;
}

export interface ApplicationPayload {
  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;
}

export interface ApplicationQuery {
  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;
}

export enum ApplicationReview {
  TryAgainLater = "tryAgainLater",
  EitherOr = "eitherOr",
  LikingZensum = "likingZensum",
  DislikesZensum = "dislikesZensum",
  GoodCase = "goodCase",
  OkayCase = "okayCase",
  BadCase = "badCase",
  CreditNotes = "creditNotes",
  MostHappy = "mostHappy",
  Happy = "happy",
  KindaSortaHappy = "kindaSortaHappy",
  Other = "other",
}

export enum ApplicationSource {
  BLANCO = "BLANCO",
  KH_BLANCO = "KH_BLANCO",
  LOG_BLANCO = "LOG_BLANCO",
  HL_BLANCO = "HL_BLANCO",
  WINBACK = "WINBACK",
  DIRECT = "DIRECT",
  RECOMMENDATION = "RECOMMENDATION",
  AFFILIATE_PARTNER = "AFFILIATE_PARTNER",
  TF_AGENCY = "TF_AGENCY",
  AFFILIATE_API = "AFFILIATE_API",
  ONLINE_LEAD = "ONLINE_LEAD",
  OTHER = "OTHER",
  LIKVIDUM = "LIKVIDUM",
}

export interface ApplicationWithNamesInComments {
  loan?: NewMortgageLoan | null;
  refinance_info?: RefinanceInfo | null;
  buying_info?: BuyingInfo | null;
  selling_info?: SellingInfo | null;
  applicants?: BareApplicantWithRole[] | null;
  property_id?: string | null;
  discount?: Discount | null;
  loans?: Loan[] | null;
  other_mortgages?: MortgageLoan[] | null;
  status?: AppsmgrModelsStatusStatus | null;
  substatus?: Substatus | null;
  bank_status?: BankStatus | null;
  children_in_the_household?: number | null;
  children_in_the_household_fulltime?: number | null;
  other_properties_to_keep?: OtherProperty[] | null;
  co_relationship_to_main_applicant?: RelationshipStatus | null;
  guarantor_relationship_to_main_applicant?: RelationshipStatus | null;
  cars?: CarsInput | null;
  brokerage?: Brokerage | null;
  product: Product;
  country: Country;

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;
  offers?: Offer[] | null;
  date_created?: string | null;
  date_updated?: string | null;
  meta: ApplicationMappingWithNamesInComments;
}

export enum ApplyingWith {
  PARENT = "PARENT",
  SPOUSE = "SPOUSE",
  ALONE = "ALONE",
  COHABITANT = "COHABITANT",
}

export interface AppsSearchResult {
  search_types: Record<string, SearchType[]>;
  apps: AppBase[];
}

export interface AssignApplicationPayload {
  handler_id: string;
}

export interface Assignee {
  handler_id?: string | string | string | null;
  name?: string | null;
}

export interface Available {
  available: ModelsDialMaker2CampaignAggregateCampaign[];
  joined_campaigns: JoinedCampaignExt[];
}

export interface AvailableBookings {
  available_slots: Slots[];
}

export enum BalconyType {
  BALCONY = "BALCONY",
  PATIO = "PATIO",
  NONE = "NONE",
}

export interface Bank {
  bank_id: number;
  country: Country;
  name: string;
}

export interface BankAPIDrivenKALP {
  bank_id: number;
  country: Country;
  name: string;
  api_driven: true;
}

export interface BankAccount {
  clearing_number?: string | null;
  account_number: string;
}

export interface BankApplicant {
  customer: LimitedApplicantSE | LimitedApplicantNO;
  role: ApplicantRole;
}

export interface BankBaseApp {
  product: Product;
  country: Country;
  applicants: BankApplicant[];
  application_id: string;

  /** @format date-time */
  date_created: string;

  /** @format date-time */
  date_updated: string;
  status: AppsmgrModelsStatusStatus;
  loan?: NewMortgageLoan | null;
  refinance_info?: RefinanceInfo | null;
  buying_info?: BuyingInfo | null;
  selling_info?: SellingInfo | null;
  property?: Condominium | House | VacationHome | null | (Condominium & House & VacationHome);
  loans?: Loan[] | null;
  other_mortgages?: MortgageLoan[] | null;
  children_in_the_household?: number | null;
  children_in_the_household_fulltime?: number | null;
  other_properties_to_keep?: OtherProperty[] | null;
  co_relationship_to_main_applicant?: RelationshipStatus | null;
  guarantor_relationship_to_main_applicant?: RelationshipStatus | null;
  cars?: CarsInput | null;
  bank_status: BankStatus;
}

export interface BankComment {
  comment: string;
  application_id: string;
  bank_id: number;
}

export interface BankCommunication {
  events: (
    | BankCommunicationEventOffer
    | BankCommunicationEvent
    | BankCommunicationEventDocumentInfo
    | BankCommunicationEventCommentAdded
    | BankCommunicationEventBankMessage
    | BankCommunicationEventInvalidInvite
    | BankCommunicationEventDocumentSent
    | (BankCommunicationEventOffer &
      BankCommunicationEvent &
      BankCommunicationEventDocumentInfo &
      BankCommunicationEventCommentAdded &
      BankCommunicationEventBankMessage &
      BankCommunicationEventInvalidInvite &
      BankCommunicationEventDocumentSent)
  )[];
  bank_id: number;
  bank_name?: string | null;
  actions: IntegrationAction[];
  encoded_app_id: string;
  last_external_id?: string | null;
}

export type BankCommunicationData = Record<string, any>;

export interface BankCommunicationDataCommentAdded {
  comment: string;
  user_name?: string | null;
  user_name_shown_to_bank?: string | null;
}

export interface BankCommunicationDataDocumentInfo {
  documents: DocRequest[];
}

export interface BankCommunicationDataDocumentSent {
  document_id: string;
  customer_id: string;
}

export interface BankCommunicationDataInvalidInvite {
  messages: string[];
}

export interface BankCommunicationDataOffer {
  offeredAmount: number;
  currency: Currency;
  bindingPeriodMonth: number;
  interestRate: number;
  discount: number;
  amortizationRule: AmortizationRule;
  monthlyAmortization: number;
  invoiceFee?: number | null;
  setupFee?: number | null;
  autogiroFee?: number | null;
  amortizationLength?: number | null;
  firstMonthlyPayment: number;
}

export interface BankCommunicationEvent {
  direction: Direction;
  event_id: string;
  type: BankEventType;

  /** @format date-time */
  timestamp: string;
  data: BankCommunicationData;
  user_id?: string | string | string | null;
}

export interface BankCommunicationEventBankMessage {
  direction: Direction;
  event_id: string;
  type: "MESSAGE";

  /** @format date-time */
  timestamp: string;
  data: BankCommunicationDataCommentAdded;
  user_id?: string | string | string | null;
}

export interface BankCommunicationEventCommentAdded {
  direction: Direction;
  event_id: string;
  type: "COMMENT_TO_BANK_ADDED" | "COMMENT_ADDED" | ("COMMENT_TO_BANK_ADDED" & "COMMENT_ADDED");

  /** @format date-time */
  timestamp: string;
  data: BankCommunicationDataCommentAdded;
  user_id?: string | string | string | null;
}

export interface BankCommunicationEventDocumentInfo {
  direction: Direction;
  event_id: string;
  type: "DOCUMENT_INFO";

  /** @format date-time */
  timestamp: string;
  data: BankCommunicationDataDocumentInfo;
  user_id?: string | string | string | null;
}

export interface BankCommunicationEventDocumentSent {
  direction: Direction;
  event_id: string;
  type: "DOCUMENT_TO_BANK_ADDED";

  /** @format date-time */
  timestamp: string;
  data: BankCommunicationDataDocumentSent;
  user_id?: string | string | string | null;
}

export interface BankCommunicationEventInvalidInvite {
  direction: Direction;
  event_id: string;
  type: "INVITE_APP_INVALID";

  /** @format date-time */
  timestamp: string;
  data: BankCommunicationDataInvalidInvite;
  user_id?: string | string | string | null;
}

export interface BankCommunicationEventOffer {
  direction: Direction;
  event_id: string;
  type: "OFFER";

  /** @format date-time */
  timestamp: string;
  data: BankCommunicationDataOffer;
  user_id?: string | string | string | null;
}

export interface BankDisbursePayload {
  mortgage_amount?: number | null;
  bridge_loan_amount?: number | null;
  disburse_date?: string | null;
  application_id: string;
}

export interface BankEvent {
  direction: Direction;

  /** @format uuid4 */
  event_id: string;
  type: BankEventType;

  /** @format date-time */
  timestamp: string;
  data: BankEventData;
  user_id?: string | string | string | null;
}

export interface BankEventCommentAdded {
  direction: Direction;

  /** @format uuid4 */
  event_id: string;
  type: "COMMENT_TO_BANK_ADDED" | "COMMENT_ADDED" | ("COMMENT_TO_BANK_ADDED" & "COMMENT_ADDED");

  /** @format date-time */
  timestamp: string;
  data: BankEventDataCommentAdded;
  user_id?: string | string | string | null;
}

export type BankEventData = Record<string, any>;

export interface BankEventDataCommentAdded {
  comment: string;
  user_name?: string | null;
  user_name_shown_to_bank?: string | null;
}

export interface BankEventDataDocumentInfo {
  documents: DocRequest[];
}

export interface BankEventDataDocumentSent {
  document_id: string;
  customer_id: string;
}

export interface BankEventDataInvalidInvite {
  messages: string[];
}

export interface BankEventDataOfferCreditCardOffer {
  offer: CreditCardOffer;
}

export interface BankEventDataOfferMortgageOfferExt {
  offer: MortgageOfferExt;
}

export interface BankEventDocumentInfo {
  direction: Direction;

  /** @format uuid4 */
  event_id: string;
  type: "DOCUMENT_INFO";

  /** @format date-time */
  timestamp: string;
  data: BankEventDataDocumentInfo;
  user_id?: string | string | string | null;
}

export interface BankEventDocumentSent {
  direction: Direction;

  /** @format uuid4 */
  event_id: string;
  type: "DOCUMENT_TO_BANK_ADDED";

  /** @format date-time */
  timestamp: string;
  data: BankEventDataDocumentSent;
  user_id?: string | string | string | null;
}

export interface BankEventInvalidInvite {
  direction: Direction;

  /** @format uuid4 */
  event_id: string;
  type: "INVITE_APP_INVALID";

  /** @format date-time */
  timestamp: string;
  data: BankEventDataInvalidInvite;
  user_id?: string | string | string | null;
}

export interface BankEventMessage {
  direction: Direction;

  /** @format uuid4 */
  event_id: string;
  type: "MESSAGE";

  /** @format date-time */
  timestamp: string;
  data: BankEventDataCommentAdded;
  user_id?: string | string | string | null;
}

export interface BankEventOfferCreditCardOffer {
  direction: Direction;

  /** @format uuid4 */
  event_id: string;
  type: "OFFER";

  /** @format date-time */
  timestamp: string;
  data: BankEventDataOfferCreditCardOffer;
  user_id?: string | string | string | null;
}

export interface BankEventOfferMortgageOfferExt {
  direction: Direction;

  /** @format uuid4 */
  event_id: string;
  type: "OFFER";

  /** @format date-time */
  timestamp: string;
  data: BankEventDataOfferMortgageOfferExt;
  user_id?: string | string | string | null;
}

export enum BankEventType {
  INVITE_REQUESTED = "INVITE_REQUESTED",
  INVITE_FILTERS_SUCCESS = "INVITE_FILTERS_SUCCESS",
  INVITE_FILTERED_OUT = "INVITE_FILTERED_OUT",
  INVITE_APP_INVALID = "INVITE_APP_INVALID",
  INVITE_SEND_FAILED = "INVITE_SEND_FAILED",
  INVITE_SEND_FAILED_TRANSIENT = "INVITE_SEND_FAILED_TRANSIENT",
  INVITE_SEND_SUCCESS = "INVITE_SEND_SUCCESS",
  INVITE_POSTPONED = "INVITE_POSTPONED",
  SEND_POSTPONED = "SEND_POSTPONED",
  INVITE_CANCELLED = "INVITE_CANCELLED",
  INVITE_DENIED = "INVITE_DENIED",
  INVITE_SENT = "INVITE_SENT",
  OFFER = "OFFER",
  ACCEPT_REQUESTED = "ACCEPT_REQUESTED",
  ACCEPT_SEND_FAILED = "ACCEPT_SEND_FAILED",
  ACCEPT_SEND_FAILED_TRANSIENT = "ACCEPT_SEND_FAILED_TRANSIENT",
  ACCEPT_SEND_SUCCESS = "ACCEPT_SEND_SUCCESS",
  ACCEPT = "ACCEPT",
  MESSAGE = "MESSAGE",
  STATUS_UPDATE = "STATUS_UPDATE",
  DOCUMENT_INFO = "DOCUMENT_INFO",
  COMMENT_TO_BANK_ADDED = "COMMENT_TO_BANK_ADDED",
  COMMENT_ADDED = "COMMENT_ADDED",
  COMMENT_TO_BANK_SEND_SUCCESS = "COMMENT_TO_BANK_SEND_SUCCESS",
  COMMENT_TO_BANK_SEND_FAILED = "COMMENT_TO_BANK_SEND_FAILED",
  DISBURSEMENT = "DISBURSEMENT",
  DEBENTURESIGNED = "DEBENTURE_SIGNED ",
  CANCEL_REQUESTED = "CANCEL_REQUESTED",
  CANCEL_SEND_FAILED = "CANCEL_SEND_FAILED",
  CANCEL_SEND_FAILED_TRANSIENT = "CANCEL_SEND_FAILED_TRANSIENT",
  CANCEL_SEND_SUCCESS = "CANCEL_SEND_SUCCESS",
  REJECTION = "REJECTION",
  DOCUMENT_TO_BANK_ADDED = "DOCUMENT_TO_BANK_ADDED",
  DOCUMENT_TO_BANK_SEND_SUCCESS = "DOCUMENT_TO_BANK_SEND_SUCCESS",
  DOCUMENT_TO_BANK_SEND_FAILED = "DOCUMENT_TO_BANK_SEND_FAILED",
  DEACTIVATED = "DEACTIVATED",
  REACTIVATED = "REACTIVATED",
  SIGN_STATUS = "SIGN_STATUS",
  SIGN_REQUEST = "SIGN_REQUEST",
  SIGN_SEND_FAILED = "SIGN_SEND_FAILED",
  SIGN_SEND_FAILED_TRANSIENT = "SIGN_SEND_FAILED_TRANSIENT",
  SIGN_SEND_SUCCESS = "SIGN_SEND_SUCCESS",
  LEFT_TO_LIVE = "LEFT_TO_LIVE",
}

export interface BankEventsCreditCardOffer {
  events: (
    | BankEventOfferCreditCardOffer
    | BankEvent
    | BankEventDocumentInfo
    | BankEventCommentAdded
    | BankEventMessage
    | BankEventInvalidInvite
    | BankEventDocumentSent
    | (BankEventOfferCreditCardOffer &
      BankEvent &
      BankEventDocumentInfo &
      BankEventCommentAdded &
      BankEventMessage &
      BankEventInvalidInvite &
      BankEventDocumentSent)
  )[];
  bank_id: number;
  bank_name?: string | null;
  actions: IntegrationAction[];
  encoded_app_id: string;
  last_external_id?: string | null;
}

export interface BankEventsMortgageOfferExt {
  events: (
    | BankEventOfferMortgageOfferExt
    | BankEvent
    | BankEventDocumentInfo
    | BankEventCommentAdded
    | BankEventMessage
    | BankEventInvalidInvite
    | BankEventDocumentSent
    | (BankEventOfferMortgageOfferExt &
      BankEvent &
      BankEventDocumentInfo &
      BankEventCommentAdded &
      BankEventMessage &
      BankEventInvalidInvite &
      BankEventDocumentSent)
  )[];
  bank_id: number;
  bank_name?: string | null;
  actions: IntegrationAction[];
  encoded_app_id: string;
  last_external_id?: string | null;
}

export interface BankIDLoginResponse {
  token: string;
}

export interface BankIDPayload {
  customer_id?: string | null;
  pni?: string | null;

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;
}

export interface BankIDSigned {
  user_id: string | string | string;
  comment?: string;

  /** @format date-time */
  timestamp: string;
  comment_id: string;
  action: "VERIFIED_BANK_ID";
  customer_id: string;
}

export interface BankIDSignedWithNames {
  user_id: string | string | string;
  comment?: string;

  /** @format date-time */
  timestamp: string;
  comment_id: string;
  action: "VERIFIED_BANK_ID";
  customer_id: string;
  user_name: string;
  user_avatar: string;
  customer_name: string;
}

export interface BankKALP {
  /** The current mortgage the customer has */
  current_property_interest: number;
  current_property_amortization: number;
  other_properties_to_keep_interest: number;
  other_properties_to_keep_amortization: number;
  other_properties_to_keep_fixed_monthly_running_cost: Record<string, number | null>;
  other_properties_to_keep_additional_monthly_running_cost: Record<string, number | null>;
  other_loans_interest: number;

  /** 96 months is 8 years */
  other_loans_amortization_terms_in_months: number;
  other_loans_type_exclude: LoanType[];
  other_properties_to_keep_amortization_property_types: PropertyTypeOutput[];
  cost_per_person: CostPerPerson[];
  additional_cost_per_person_in_household: AdditionalCostPerPerson[];
  child_benefit: ChildBenefit[];
  property_tax_per_property: Record<string, number | null>;
  tax_deductions: TaxDeduction;
  cars?: CarsOutput | null;
  bank_id: number;
  country: any;
  name: string;
  api_driven: false;
}

export interface BankMessageReceived {
  type: "BANK_MESSAGE_RECEIVED";

  /** @format date-time */
  timestamp: string;
  status: NotificationEvent;
  data: BankMessageReceivedData;
  notification_id: string;
  user_id: string | string | string | null;
}

export interface BankMessageReceivedData {
  application_id: string;
  bank_id: number;
}

export interface BankOffer {
  offeredAmount: number;
  currency: Currency;
  bindingPeriodMonth: number;
  interestRate: number;
  discount: number;
  amortizationRule: AmortizationRule;
  monthlyAmortization: number;
  invoiceFee?: number | null;
  setupFee?: number | null;
  autogiroFee?: number | null;
  amortizationLength?: number | null;
}

export interface BankOfferPayload {
  bank_id: number;
  bank_reference_id?: string | null;
  offer: BankOffer;
  application_id: string;
}

export interface BankRejectPayload {
  application_id: string;
  reason: string;
}

export enum BankStatus {
  NEW = "NEW",
  ONGOING = "ONGOING",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  DISBURSED = "DISBURSED",
}

export interface BankUserWithBankNames {
  user_id: string;
  first_name: string;
  last_name: string;

  /** @format email */
  username: string;
  deactivated?: boolean;
  banks: Integration[];
}

export interface BanksToExcludeInsuranceOfferingFor {
  SE: number[];
  NO: number[];
}

/**
 * Used to store applicant data in the application object in MongoDB
 */
export interface BareApplicantWithRole {
  /** @format uuid4 */
  customer_id: string;
  role: ApplicantRole;
}

export interface BaseComment {
  comment: string;

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;
  bank_id: number;
}

export interface BaseFormPreferencePayload {
  form_id: string;
  product: Product;
  country: Country;
}

export interface BasicSubmissionReview {
  submission_id: string;
  handled?: boolean | null;
  reviews?: Reviewer[];
  success?: boolean;
  ongoing?: boolean;

  /** @format date-time */
  submission_timestamp: string;
  raw_data: object;
  country: Country;
  product: Product;
  request_id: string;
  checklist?: Checklist;

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id?: string | null;
  version?: number | null;
}

export interface BigQueryCampaignSource {
  type?: "BIG_QUERY";
  query: string;
  mapping?: Record<string, string>;
  imported_at?: string | null;
}

export interface BlancoAMLPatchPayload {
  channel?: Channel | null;
  purpose?: AMLBlancoPurpose | null;
  purpose_when_new_loan?: LoanPurpose | null;
  country?: Country | null;
  comment?: string | null;
}

export interface BlancoAMLReviewPayload {
  comment?: string | null;
  aml_review: AMLReviewStatus;
  country: Country;
}

export interface BlancoGoal {
  type_event: GoalType;
  user_id?: (string | string | string)[] | null;
  country: Country;

  /** What year the goal is set for */
  year: number;

  /** Month from 1-12 */
  month: number;

  /** @format date-time */
  timestamp: string;

  /** Count of sent loans */
  sent_loans: number;

  /** A procent of the return rate of sent loans between 1-100 */
  return_rate: number;

  /** The count of disbursed loans */
  disbursed_loans_count: number;

  /** Working days of the handler for chosen month */
  working_days: number;

  /** Count of sent loans per day */
  sent_loans_per_day: number;

  /** The amount of all disbursed loans in the chosen currency */
  disbursed_amount: number;

  /** The amount of all sent loans in chosen currency */
  sent_loans_amount: number;

  /** The count of signed insurances */
  signed_insurances: number;

  /** The total premium of all signed insurances */
  total_premium: number;

  /** Average loan amount for chosen period, usually set from previous months */
  average_loan_amount: number;

  /** Average premium of the insurances, usually set from previous months */
  average_premium: number;

  /** Amount of leads sent to Mortgage */
  mortgage_leads?: number | null;
}

export interface BlancoGoalMb {
  type_event: GoalType;
  user_id?: (string | string | string)[] | null;
  country: Country;

  /** What year the goal is set for */
  year: number;

  /** Month from 1-12 */
  month: number;

  /** @format date-time */
  timestamp: string;

  /** Number of created applications */
  applications: number;

  /** Working days of the handler for chosen month */
  working_days: number;

  /** Number of disbursed loans */
  disbursed_loans_count: number;

  /** The amount of disbursed loans */
  disbursed_loans_amount: number;

  /** The percentage of applications sent with a co-applicant */
  co_applicant_percentage: number | null;

  /** Average loan amount for chosen period, usually set from previous months */
  average_loan_amount: number;

  /** Count of created applications per day */
  applications_per_day: number;

  /** Percent of applications that have GR */
  gr_percentage: number | null;

  /** Percent of sent applications that get disbursed */
  disbursement_rate: number;
}

export interface BlancoGoalMbPayload {
  /** What year the goal is set for */
  year: number;

  /** Month from 1-12 */
  month: number;
  user_ids?: (string | string | string)[] | null;

  /** Number of created applications */
  applications: number;

  /** Working days of the handler for chosen month, example: 20 */
  working_days: number;

  /** Number of disbursed loans */
  disbursed_loans_count: number;

  /** The amount of disbursed loans */
  disbursed_loans_amount: number;

  /** The percentage of applications sent with a co-applicant */
  co_applicant_percentage: number;

  /** Average loan amount for chosen period, usually set from previous months */
  average_loan_amount: number;

  /** Count of created applications per day */
  applications_per_day: number;

  /** Percent of applications that have GR */
  gr_percentage: number | null;

  /** Percent of sent applications that get disbursed */
  disbursement_rate: number;
}

export interface BlancoGoalPayload {
  /** What year the goal is set for */
  year: number;

  /** Month from 1-12 */
  month: number;
  user_ids?: (string | string | string)[] | null;

  /** The count of sent loans */
  sent_loans: number;

  /** A procent of the return rate of sent loans between 1-100 */
  return_rate: number;

  /** Working days of the handler for chosen month, example: 20 */
  working_days: number;

  /** The count of signed insurances */
  signed_insurances: number;

  /** An amount in the local currency */
  average_premium: number;

  /** Count of sent loans per day */
  sent_loans_per_day: number;

  /** The amount of all disbursed loans in the chosen currency */
  disbursed_amount: number;

  /** Average loan amount for chosen period, usually set from previous months */
  average_loan_amount: number;

  /** The count of disbursed loans */
  disbursed_loans_count: number;

  /** The amount of all sent loans in chosen currency */
  sent_loans_amount: number;

  /** The total premium of all signed insurances */
  total_premium: number;

  /** The amount of leads sent to mortgage */
  mortgage_leads?: number | null;
}

export interface BlancoLoan {
  desired_amount?: number | null;
  loan_purpose?: LoanPurposeNO | LoanPurposeSE | null | (LoanPurposeNO & LoanPurposeSE);
}

export interface BlockCustomerFieldManuallyPayload {
  country: Country;
  reason: string;
  field: BlockField;
  blocked: boolean;
  values: string[];
}

export interface BlockCustomerFieldManuallyResponse {
  result: BlockCustomerFieldManuallyResponseModel[];
}

export interface BlockCustomerFieldManuallyResponseModel {
  value: string;
  blocked?: boolean | null;
}

export enum BlockField {
  Phone = "phone",
  Pni = "pni",
  Email = "email",
}

export interface BlockList {
  pni?: boolean | null;
  phone_number?: boolean | null;
  email?: boolean | null;
}

export interface BodyActivateCampaignDialmakerCampaignActivatePost {
  /** @format uuid */
  campaign_id: string;
}

export interface BodyAdminDialmakerAdminOutcomesPost {
  campaign_ids: string[];
  user_ids?: (string | string | string)[] | null;
}

export interface BodyCallBackDialmakerCardOutcomeCallBackPost {
  /** @format uuid */
  lead_id: string;
  when?: string | null;
  comment?: string | null;
  personal_callback?: boolean;
  get_next?: boolean;
}

export interface BodyCallBackDialmakerCardOutcomeLaterPost {
  /** @format uuid */
  lead_id: string;
  when?: string | null;
  comment?: string | null;
  personal_callback?: boolean;
  get_next?: boolean;
}

export interface BodyCallDiscardsDialmakerCardOutcomeDiscardPost {
  /** @format uuid */
  lead_id: string;
  outcome: string;
  get_next?: boolean;
  comment?: string | null;
}

export interface BodyCallFailureDialmakerCardOutcomeFailurePost {
  /** @format uuid */
  lead_id: string;
  outcome: string;
  get_next?: boolean;
  comment?: string | null;
}

export interface BodyCallNoContactDialmakerCardOutcomeNoContactPost {
  /** @format uuid */
  lead_id: string;
  outcome: string;
  get_next?: boolean;
  comment?: string | null;
}

export interface BodyCallSuccessDialmakerCardOutcomeSuccessPost {
  /** @format uuid */
  lead_id: string;
  comment?: string | null;
  get_next?: boolean;
}

export interface BodyCreateUrlUrlShortenerUrlPost {
  url_name: string;

  /** @format uri */
  target_url: string;
  utm_tags: UtmTags | null;
}

export interface BodyDeactivateCampaignDialmakerCampaignDeactivatePost {
  /** @format uuid */
  campaign_id: string;
}

export interface BodyDeleteExtractionDialmakerCampaignExtractionDelete {
  /** @format uuid */
  campaign_id: string;

  /** @format uuid */
  extraction_id: string;

  /** Force unextract already assigned extractions */
  force?: boolean;
}

export interface BodyElk46CallWebhookMessageElk46CallPost {
  to: string;
  callid: string;
}

export interface BodyExportAndExtractLeadsUrlDialmakerCampaignExportPost {
  /**
   * Campaign ID to export leads from
   * @format uuid
   */
  campaign_id: string;

  /** Number of leads to extract and export */
  leads_count: number;
}

export interface BodyExportAndExtractLeadsUrlDownloadDialmakerCampaignExtractionExportPost {
  /**
   * Extraction ID to export leads from
   * @format uuid
   */
  extraction_id: string;
}

export interface BodyExtractCampaignsDialmakerCampaignExtractPost {
  /** @format uuid */
  campaign_id: string;
  number_of_leads: number;
}

export interface BodyFindCampaignsDialmakerCampaignFindPost {
  filter?: Filter | null;
  order?: Order | null;
}

export interface BodyGetMailStatisticsDialmakerCampaignMailStatisticsPost {
  campaign_ids: string[];
  percentage: boolean;

  /** @format date-time */
  start: string;

  /** @format date-time */
  end: string;
}

export interface BodyGetOutcomesDialmakerCardOutcomesPost {
  /** @format uuid */
  lead_id: string;
}

export interface BodyGetVariablesTemplateVariablesDialmakerMailTemplateVariablesPost {
  template_slug: string;
  campaign_id: string | null;
}

export interface BodyImportLeadsDialmakerCampaignImportPost {
  /** @format uuid */
  campaign_id: string;

  /** Source ID to import from, e.g. sheet or query id for campaign source */
  source_id?: string | null;
}

export interface BodyJoinCampaignDialmakerCampaignsJoinPost {
  /** @format uuid */
  campaign_id: string;
  color: number;
}

export interface BodyLeaveCampaignDialmakerCampaignsLeavePost {
  /** @format uuid */
  campaign_id: string;
}

export interface BodyLockDialmakerCardLockPost {
  /** @format uuid */
  lead_id: string;
  force?: boolean;
}

export interface BodyMessageOpenedMandrillWebhookMessagePost {
  mandrill_events: any;
}

export interface BodyPauseCampaignDialmakerCampaignsPausePost {
  /** @format uuid */
  campaign_id: string;
}

export interface BodyPlayCampaignDialmakerCampaignsPlayPost {
  /** @format uuid */
  campaign_id: string;
}

export interface BodySaveOutcomesDialmakerMiscOutcomesPost {
  outcomes: Outcomes;
}

export interface BodySearchDialmakerCardSearchPost {
  search: string;
}

export interface BodySlackCallbackSlackCallbackPost {
  payload: any;
}

export interface BodyStatsDialmakerAdminOutcomesUsersPost {
  user_ids: (string | string | string)[];

  /** @format date-time */
  start_date: string;

  /** @format date-time */
  end_date: string;
}

export interface BodyStoreEventsFormAnalyticsStoreEventsPost {
  event_list: OnlineFormsEvent[];
}

export interface BodySubmissionJotformWebhookFormIdPost {
  submissionID?: string;
}

export interface BodyUpdateDialmakerCardUpdatePost {
  /** @format uuid */
  lead_id: string;
  data: UpdateLeadData;
}

export interface BodyUploadAttachmentDialmakerCampaignImageUploadPost {
  /** @format binary */
  file: File;
}

export interface BodyUploadAttachmentMessageAttachmentsUploadPost {
  /** @format binary */
  file: File;
  file_name: string;
}

export interface BodyUploadDialmakerSheetCampaignIdUploadWithPreviewPost {
  /** @format binary */
  file: File;
}

export interface BodyViewDialmakerCardViewPost {
  /** @format uuid */
  lead_id: string;
}

export interface BookableSlot {
  /** @format date-time */
  start_date: string;

  /** @format date-time */
  end_date: string;
  available_slots?: number | null;
}

export interface BookingPayload {
  /** @format date-time */
  timeId: string;
  phoneNumber: string;

  /** @format email */
  email: string;
}

export interface BooliPropertySearchResult {
  id: string;
  streetGuid: string;
  streetName: string;
  streetNumber: string;
  postAddress?: string | null;
  postCode: string;
  longitude: number;
  latitude: number;
  housingCoopId?: string;
  hasResidences: boolean;
}

export interface BooliSearchPayload {
  country: Country;
  street_name: string;
  post_code: string;
  street_number?: string | null;
}

export interface BooliValuationPayload {
  property_id: string;
  living_area: number;
}

export interface Brokerage {
  agency?: string | null;
  broker?: string | null;
  email?: string | null;
  phone?: string | null;
}

export interface Budget {
  type_event: GoalType;
  user_id?: (string | string | string)[] | null;
  country: Country;

  /** What year the goal is set for */
  year: number;

  /** Month from 1-12 */
  month: number;

  /** @format date-time */
  timestamp: string;

  /** Budget of applications created */
  applications: number;

  /** Budget of average loan amount */
  average_loan_amount: number;

  /** Budget of number of disbursed loans */
  disbursed_loans_count: number;

  /** Budget of the amount of disbursed loans in countries currency */
  disbursed_loans_amount: number;

  /** Budget of disbursement rate */
  disbursement_rate: number;
}

export interface BudgetPayload {
  /** What year the goal is set for */
  year: number;

  /** Month from 1-12 */
  month: number;
  user_ids?: (string | string | string)[] | null;

  /** Budget of applications created */
  applications: number;

  /** Budget of average loan amount */
  average_loan_amount: number;

  /** Budget of number of disbursed loans */
  disbursed_loans_count: number;

  /** Budget of the amount of disbursed loans in countries currency */
  disbursed_loans_amount: number;

  /** Budget of disbursement rate */
  disbursement_rate: number;
}

export interface BuyingInfo {
  property_price?: number | null;
  buying_date?: string | null;
  new_production?: boolean | null;
  first_owner?: boolean | null;
  down_payment?: number | null;
}

export enum CTATags {
  MISSING2PAYMENTS = "MISSING_2_PAYMENTS",
  MISSING3PAYMENTS = "MISSING_3_PAYMENTS",
  AUTOMATICALLY_CANCELED = "AUTOMATICALLY_CANCELED",
  INACTIVE_MANDATE = "INACTIVE_MANDATE",
}

export interface Cadastre {
  BNr?: number | null;
  FNr?: number | null;
  GNr?: number | null;
  KNr?: number | null;
  SNr?: number | null;
}

export interface CallOutcomeCallBack {
  user_id?: string | string | string | null;

  /** @format date-time */
  timestamp: string;
  type?: "CALL_BACK";

  /** @format uuid */
  campaign_id: string;
  comment?: string | null;
  when?: string | null;
  personal_callback?: boolean;
}

export interface CallOutcomeDiscard {
  user_id?: string | string | string | null;

  /** @format date-time */
  timestamp: string;
  type?: "DISCARD";

  /** @format uuid */
  campaign_id: string;
  comment?: string | null;
  outcome: string;
}

export interface CallOutcomeFailure {
  user_id?: string | string | string | null;

  /** @format date-time */
  timestamp: string;
  type?: "FAILURE";

  /** @format uuid */
  campaign_id: string;
  comment?: string | null;
  outcome: string;
}

export interface CallOutcomeNoContact {
  user_id?: string | string | string | null;

  /** @format date-time */
  timestamp: string;
  type?: "NO_CONTACT";

  /** @format uuid */
  campaign_id: string;
  comment?: string | null;
  outcome: string;
}

export interface CallOutcomeSuccess {
  user_id?: string | string | string | null;

  /** @format date-time */
  timestamp: string;
  type?: "SUCCESS";

  /** @format uuid */
  campaign_id: string;
  comment?: string | null;
}

export interface CallingOrder {
  h00?: number;
  h01?: number;
  h02?: number;
  h03?: number;
  h04?: number;
  h05?: number;
  h06?: number;
  h07?: number;
  h08?: number;
  h09?: number;
  h10?: number;
  h11?: number;
  h12?: number;
  h13?: number;
  h14?: number;
  h15?: number;
  h16?: number;
  h17?: number;
  h18?: number;
  h19?: number;
  h20?: number;
  h21?: number;
  h22?: number;
  h23?: number;
  timezone: string;
}

export interface CampaignData {
  url_target: Product;
  valid_to?: string | null;
  url?: string | null;
}

export interface CampaignExport {
  /** @format uuid */
  export_id: string;

  /** @format uuid */
  extraction_id: string;

  /** @format date-time */
  timestamp: string;
}

export interface CampaignExtraction {
  /** @format uuid */
  extraction_id: string;
  extracted_by?: string | string | string | null;

  /** @min 0 */
  leads_count: number;

  /** @format date-time */
  timestamp: string;
}

export interface CampaignExtractionsStats {
  already_extracted: number;
  without_outcome: number;
  exported_without_outcome: number;
  available_to_call: number;
  left_to_extract?: number | null;
}

export interface CampaignMailOverview {
  currently_in_campaign: number;
}

export interface CampaignMailStats {
  total_opens?: number;
  total_clicks?: number;
  unique_opens?: number;
  unique_clicks?: number;
  sent?: number;
  hard_bounce?: number;
  soft_bounce?: number;
  spam?: number;
  unsubscribed?: number;
  redirects?: number;
  unique_redirects?: number;

  /** @format uuid */
  campaign_id: string;
  mailings: CampaignMailingStats[];
  last_sent?: string | null;
}

export interface CampaignMailingStats {
  total_opens?: number;
  total_clicks?: number;
  unique_opens?: number;
  unique_clicks?: number;
  sent?: number;
  hard_bounce?: number;
  soft_bounce?: number;
  spam?: number;
  unsubscribed?: number;
  redirects?: number;
  unique_redirects?: number;

  /** @format uuid */
  mailing_id: string;
  batches: MailBatchStats[];

  /** @format date-time */
  last_sent: string;
}

export interface CampaignOngoingExtraction {
  /** @format uuid */
  campaign_id: string;

  /** @format uuid */
  job_id: string;
  job_status?: string | null;

  /** @format uuid */
  extraction_id: string;
  leads_count: number;
  user_id: string | string | string;
  unextraction?: boolean;
}

export interface CampaignStats {
  active: number;
}

export enum CampaignType {
  COLD = "COLD",
  POOL = "POOL",
  PROCESS = "PROCESS",
}

export interface CancelOfferPayload {
  reason: CancelReason;

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;
  bank_id: number;
  synchronous?: boolean;
}

export enum CancelReason {
  BETTER_INTEREST_OTHER_BANK = "BETTER_INTEREST_OTHER_BANK",
  OFFERED_AMOUNT_TOO_LOW = "OFFERED_AMOUNT_TOO_LOW",
  NO_DEAL = "NO_DEAL",
  OTHER_REASON = "OTHER_REASON",
}

export enum CancellationTrigger {
  CUSTOMER_REQUEST = "CUSTOMER_REQUEST",
  MANDATE_CANCELLED = "MANDATE_CANCELLED",
  BY_AXA = "BY_AXA",
  END_OF_INSURANCE_LIFETIME = "END_OF_INSURANCE_LIFETIME",
  BECAUSE_OF_CUSTOMER_AGE = "BECAUSE_OF_CUSTOMER_AGE",
  FAILED_PAYMENTS = "FAILED_PAYMENTS",
  DISEASED_CUSTOMER = "DISEASED_CUSTOMER",
  OTHER = "OTHER",
}

export interface Car {
  monthlyCost?: number | null;
}

export interface CarCost {
  actual_cost?: boolean;
  fixed_cost: number;
}

export interface CarsInput {
  owned?: number | null;
  leased?: Car[] | null;
}

export interface CarsOutput {
  per_leased: CarCost;
  per_owned: CarCost;
}

export interface CaseCollectionItem {
  uc_type_template_descition?: string | null;
  year?: string | null;
}

export enum Channel {
  Inbound = "inbound",
  Outbound = "outbound",
  Online = "online",
}

export interface Checklist {
  status?: SubmissionStatus | null;
  form_validation?: ModelsOnlineLoanApplicationStatus | null;
  get_customers?: ModelsOnlineLoanApplicationStatus | null;
  has_recent_app_check?: ModelsOnlineLoanApplicationStatus | null;
  has_recent_app_email?: ModelsOnlineLoanApplicationStatus | null;
  created_application?: ModelsOnlineLoanApplicationStatus | null;
  not_using_test_pni?: ModelsOnlineLoanApplicationStatus | null;
  quick_application?: ModelsOnlineLoanApplicationStatus | null;
  parsed_application?: ModelsOnlineLoanApplicationStatus | null;
  sms_blanco_app_creation?: ModelsOnlineLoanApplicationStatus | null;
  converted_to_blanco_model?: ModelsOnlineLoanApplicationStatus | null;
  created_blanco_application?: ModelsOnlineLoanApplicationStatus | null;
}

export interface ChildBenefit {
  number_of_children: number;
  benefit: number;
}

export interface ClickEvent {
  email_key?: string | null;

  /** @format date-time */
  timestamp: string;
}

export interface CommentPayload {
  comment: string;
}

export interface Condominium {
  property_id: string;
  value?: number | null;
  booli_value?: number | null;
  external_id?: string | null;
  property_type: "CONDOMINIUM";
  country: Country;
  street_address?: string | null;
  post_code?: string | null;
  city?: string | null;
  other_owner?: boolean | null;
  monthly_cost?: number | null;
  square_meter_living_area?: number | null;
  municipality?: string | null;
  municipality_code?: string | null;
  estimated_value?: number | null;
  latitude?: number | null;
  longitude?: number | null;
  ev_partial?: EvPartialCommon | null;
  apartment_number?: string | null;
  elevator?: boolean | null;
  organization_number?: string | null;
  organization_name?: string | null;
  floor?: number | null;
  balcony_type?: BalconyType | null;
  rooms?: number | null;
  [key: string]: any;
}

export interface CondominiumItem {
  /** Housing association organization number */
  organizationNumber?: string | null;

  /** Housing association organization name */
  organizationName?: string | null;

  /** Apartment number in housing association */
  apartmentNumber: string | null;

  /** Floor number. Decimal, could be negative. Example: 1,0, -1,0 or 1,5. */
  floor?: number | null;

  /** If there an elevator in apartment building? */
  elevator?: boolean | null;

  /** Balcony type */
  balconyType?: BalconyType | null;
}

export interface Config {
  opening_hours: ValueListItem[];
  customer_support_phone: ValueListItem;

  /** @format email */
  customer_support_email: string;

  /** @format uri */
  blanco_form_url: string;

  /** @format uri */
  credit_card_form_url: string;

  /** @format uri */
  mortgage_form_url: string;
  office_address: string;
  customer_support_sms: ValueListItem;
}

export interface Consents {
  email_marketing: boolean;
  sms_marketing: boolean;
  customer_club_marketing: boolean;
}

export interface ConsentsPayload {
  consents?: Consents | null;
  dismissed_consents_popup?: boolean;
}

export interface ContactFormPayload {
  givenName: string;
  lastName: string;
  phoneNumber: string;

  /** @format email */
  email: string;
  message: string;
}

export enum ContactType {
  Phone = "phone",
  Mail = "mail",
}

export interface Conversation {
  customer_id: string;

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id_or_lead_id?: string | string | null;
  phone_number?: string | null;
  pni?: string | null;
  open: boolean;
}

export interface ConvertPayload {
  lead_id: string;
}

export interface ConvertResult {
  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;
}

export enum ConvertToBlancoStatus {
  Success = "success",
  Failure = "failure",
  InProgress = "in_progress",
  NotEligible = "not_eligible",
  Enqueued = "enqueued",
}

export interface CostPerPerson {
  cost: number;
  from_and_including_age: number;
  to_and_including_age: number;
}

export enum Country {
  SE = "SE",
  NO = "NO",
}

export interface CreateAndUpdateEmployeeRequest {
  email?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  zoordinates: Zoordinates[];
  phone_number?: string | null;
  telavox_ids?: string[] | null;
  sverker_usernames?: SverkerUsername[] | null;
  roles: RoleType[];
}

export interface CreateApplicantNO {
  country?: "NO";
  pni: string;
  first_name?: string | null;
  last_name?: string | null;
  email?: string | null;
  phone_number?: string | null;
  additional_phone_numbers?: string[] | null;
  monthly_property_cost?: number | null;
  marital_status?: MaritalStatus | null;

  /** Official marital status from ID check; should not be modified; marital_status has higher priority */
  registered_marital_status?: MaritalStatus | null;
  post_code?: string | null;
  city?: string | null;
  incomes?: Income[] | null;
  net_monthly_income?: number | null;
  account?: Account | null;
  street_address?: string | null;

  /** Number of children to pay child support for */
  paying_child_support?: number | null;

  /** Number of children to receive child support for */
  receiving_child_support?: number | null;
  consents?: Consents | null;
  consents_popup_dismissed_timestamp?: string | null;
  partner_pni?: string | null;
  partner_name?: string | null;
  municipality_code?: string | null;
  municipality?: string | null;
  is_pep?: boolean | null;
  age?: number | null;
  other_phones?: AppsmgrModelsPhonePhoneNumber[] | null;
  sverker_id?: number | null;
  children?: number | null;
  citizen?: number | null;
  education?: EducationType | null;
  monthly_partner_income?: number | null;
  immigrated_at?: string | null;
  fullname?: string | null;
  documents?: ApplicantDocument[] | null;
  verified_credits?: boolean | null;
  housing_since?: string | null;
  deceased?: boolean | null;
  deceased_date?: string | null;
  emigrated?: boolean | null;
  is_sanction?: boolean | null;
  is_rca?: boolean | null;
  date_created?: string | null;
  date_updated?: string | null;
  last_credit_check?: string | null;
  last_externally_verified_credits?: string | null;
  last_property_valuation?: string | null;
  pep_quality?: boolean | null;

  /** A cluster is a group of customers displayed with a color */
  cluster?: string | null;

  /** Indicates if the customer is blocked */
  is_blocked?: boolean;
  housing_type?: HousingTypeNO | null;
  employer?: OptionalEmployerNO | null;
  ev_estate?: EvEstate[] | null;
  ev_estates?: EvProperty[] | null;
  citizenship_iso_codes?: string[] | null;
  externally_verified_credits?: ExternallyVerifiedCredit[] | null;
}

/**
 * Create application payload extended with fields for ZRM
 */
export interface CreateApplicationPayloadExt {
  loan?: NewMortgageLoan | null;
  refinance_info?: RefinanceInfo | null;
  buying_info?: BuyingInfo | null;
  selling_info?: SellingInfo | null;
  applicants?: BareApplicantWithRole[] | null;
  property_id?: string | null;
  discount?: Discount | null;
  loans?: Loan[] | null;
  other_mortgages?: MortgageLoan[] | null;
  status?: AppsmgrModelsStatusStatus | null;
  substatus?: Substatus | null;
  bank_status?: BankStatus | null;
  children_in_the_household?: number | null;
  children_in_the_household_fulltime?: number | null;
  other_properties_to_keep?: OtherProperty[] | null;
  co_relationship_to_main_applicant?: RelationshipStatus | null;
  guarantor_relationship_to_main_applicant?: RelationshipStatus | null;
  cars?: CarsInput | null;
  brokerage?: Brokerage | null;
  product: Product;
  country: Country;
  internal_comment?: string | null;
  new_app_options?: Option[] | null;
  new_app_checklist?: string[] | null;
  source?: ApplicationSource | null;
  aml?: AMLInsertion | null;
}

export interface CreateCampaignData {
  name: string;
  description?: string | null;
  source: SheetCampaignSource | BigQueryCampaignSource | ProcessCampaignSource;
  file_id?: string | null;
  exclusion_rules: ExclusionRules;
  daily_extraction_limit?: number | null;
  comment_template?: string | null;
  mail_policy?: MailSendPolicy | null;
  template?: EmailTemplate | null;
  template_vars?: string[] | null;
  retry_policy?: RetryPolicy | null;
  auto_dial?: boolean | null;
  personal_callbacks?: boolean | null;
  no_contact_outcomes?: string[] | null;
  failure_outcomes?: string[] | null;
  discard_outcomes?: string[] | null;
  show_call_history?: boolean | null;
  type: CampaignType;
  country: Country;

  /** Used only for Dial Maker 2.0 for now, but can be used for other non-banking products in the future if needed */
  product: ExtendedProduct;
  department?: ZDepartment | null;
  contact_type: ContactType;
}

/**
 * Create application and applicant payload extended with fields for ZRM
 */
export interface CreateCreditCardApplicationApplicantPayloadExt {
  country: Country;
  data: CreditCardApplicationDataCreate;
  applicant: CreateApplicantNO;
  internal_comment?: string | null;
  new_app_options?: Option[] | null;
  new_app_checklist?: string[] | null;
  source?: ApplicationSource | null;
  aml?: AMLInsertion | null;
}

/**
 * Create application payload extended with fields for ZRM
 */
export interface CreateCreditCardApplicationPayloadExt {
  country: Country;
  data: CreditCardApplicationData;
  internal_comment?: string | null;
  new_app_options?: Option[] | null;
  new_app_checklist?: string[] | null;
  source?: ApplicationSource | null;
  aml?: AMLInsertion | null;
}

/**
 * Payload to send to apps-mgr - create application and applicant
 */
export interface CreateCreditCardApplicationPayloadWithApplicant {
  country: Country;
  data: CreditCardApplicationDataCreate;
  applicant: CreateApplicantNO;
}

export interface CreateCustomerPayload {
  reason: string;
  country: Country;
  pni: string;
  data: CustomerData;
}

export interface CreateInsuranceCustomer {
  insurance_type: InsuranceType;
  insured_amount: number;
  premium_amount: number;

  /** @format email */
  email: string;
  phone_number: string;
  success_url: string;
  fail_url: string;
}

export interface CreateInsuranceCustomerV1 {
  insuranceType: InsuranceType;
  insuredAmount: number;
  premium: number;
  pni: string;
  email: string;
  phoneNumber: string;
}

export interface CreateLeadPayload {
  loan?: NewMortgageLoan | null;
  refinance_info?: RefinanceInfo | null;
  buying_info?: BuyingInfo | null;
  selling_info?: SellingInfo | null;
  applicants?: BareApplicantWithRole[] | null;
  property_id?: string | null;
  discount?: Discount | null;
  loans?: Loan[] | null;
  other_mortgages?: MortgageLoan[] | null;
  status?: AppsmgrModelsStatusStatus | null;
  substatus?: Substatus | null;
  bank_status?: BankStatus | null;
  children_in_the_household?: number | null;
  children_in_the_household_fulltime?: number | null;
  other_properties_to_keep?: OtherProperty[] | null;
  co_relationship_to_main_applicant?: RelationshipStatus | null;
  guarantor_relationship_to_main_applicant?: RelationshipStatus | null;
  cars?: CarsInput | null;
  brokerage?: Brokerage | null;
  applicant: LimitedApplicantSE | LimitedApplicantNO;
  co_applicant?: (LimitedApplicantSE | LimitedApplicantNO) | null;
  property?: ExternalProperty | null;
  comment: string;
  source: ApplicationSource;
  product: Product;
  country: Country;
  [key: string]: any;
}

export interface CreateMeetingPayload {
  /** @format date-time */
  meeting_start: string;

  /** @format date-time */
  meeting_end: string;
  handler_id?: string | null;
  meeting_notes?: string | null;
  meeting_subject: string | null;
  customer_id?: string | null;

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string | string;
}

export interface CreateTMCampaign {
  query: string;
  country: Country;
  product: Product;
  department: ZDepartment;
  description: string;

  /** Number of days since last extraction to ignore customer_id */
  ignore_days_since_last_extraction?: number;
}

export interface CreateUser {
  first_name: string;
  last_name: string;

  /** @format email */
  username: string;
  banks: IntegrationId[];
}

export interface CreateUserResp {
  token: string;
}

export interface CreditCardApplicationData {
  desired_credit_limit: number;
  car: boolean;
  student_loans_amount?: number;
  student_loans_monthly_payment?: number;
  car_boat_mc_loans_amount?: number;
  car_boat_mc_loans_monthly_payment?: number;
  total_consumer_loans_amount?: number;
  total_consumer_loans_monthly_payment?: number;
  mortgage_loans_amount?: number;
  mortgage_loans_monthly_payment?: number;
  credit_card_debt_amount?: number;
  credit_card_debt_monthly_payment?: number;
  children_in_the_household_fulltime?: number;

  /** @format uuid */
  applicant_id: string;
}

export interface CreditCardApplicationDataCreate {
  desired_credit_limit: number;
  car: boolean;
  student_loans_amount?: number;
  student_loans_monthly_payment?: number;
  car_boat_mc_loans_amount?: number;
  car_boat_mc_loans_monthly_payment?: number;
  total_consumer_loans_amount?: number;
  total_consumer_loans_monthly_payment?: number;
  mortgage_loans_amount?: number;
  mortgage_loans_monthly_payment?: number;
  credit_card_debt_amount?: number;
  credit_card_debt_monthly_payment?: number;
  children_in_the_household_fulltime?: number;
}

export interface CreditCardApplicationExtended {
  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;

  /** @format date-time */
  timestamp: string;
  creator?: string | string | string | null;
  data: CreditCardApplicationData;
  active?: boolean;
  state?: AppState;
  created_at?: string | null;
  applicant: ApplicantSE | ApplicantNO;
}

export interface CreditCardApplicationWithNamesInComments {
  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;

  /** @format date-time */
  timestamp: string;
  creator?: string | string | string | null;
  data: CreditCardApplicationData;
  active?: boolean;
  state?: AppState;
  created_at?: string | null;
  applicant: ApplicantSE | ApplicantNO;
  meta: ApplicationMappingWithNamesInComments;
}

export interface CreditCardBankEventsResponse {
  bank_events: BankEventsCreditCardOffer[];
}

export interface CreditCardOffer {
  amortizationRule?: AmortizationPlan;
  offeredAmount?: number;
  minOfferedAmount?: number | null;
  maxOfferedAmount?: number | null;
  mustRefinance?: number | null;
  monthlyPayment?: number | null;
  termMonths?: number | null;
  termFee?: number;
  arrangementFee?: number;
  invoiceFee?: number;
  ratePercent?: number | null;
}

/**
 * General terms for credit card offers.
 */
export interface CreditCardTerms {
  withdrawal_fee: string;
  currency_surcharge: number;
  annual_fee: number;
  apr: number;
  apr_calculation_description?: string;
  grace_period_days: number;
  purchase_fee?: number;
  e_invoice_fee?: number;
}

export interface CreditCheckNO {
  earnedIncome: number;
  mostRecentNoteDate?: string | null;
  creditNotes?: number;
  expired?: boolean;
  expiration_date?: string | null;
  debt_equity_ratio?: number | null;
  debt_equtiy_year?: number | null;
  remarks?: Debt | null;
  cdg2_risk?: number | null;
  cdg2_score?: number | null;
}

export interface CreditCheckSE {
  earnedIncome: number;
  mostRecentNoteDate?: string | null;
  creditNotes?: number;
  expired?: boolean;
  expiration_date?: string | null;
  riskAssessment?: number | null;
  kfmDebt?: number;
  kfmDebtPublic?: number | null;
  kfmDebtPrivate?: number | null;
  numPrevious?: number;
  score?: number | null;
  inquiries?: number | null;
  issuers?: string[] | null;
}

export interface CreditNO {
  sum: number;
  monthly_payment: number;
  bank_id: number;
  liable?: string | null;
  rate: number;
  refinance_amount?: number | null;
  monthly_amortisation?: number | null;
  responsibility?: any | null;
  type: CreditTypeNO;
}

export interface CreditRegulation {
  accounting_controlls_summary?: string | null;
  allow_casuation_storage?: string | null;
  cause_table?: string | null;
  risk?: string | null;
  risk_applicant?: string | null;
  risk_applicant_2?: string | null;
  template_description?: string | null;
  uc_internal_information?: string | null;
  year?: string | null;
}

export interface CreditRegulationInformation {
  account_number?: string | null;
  decision_template?: string | null;
  no_decimals?: string | null;
  order_template?: string | null;
  template_name?: string | null;
  template_response?: string | null;
  year?: string | null;
}

export enum CreditResponsibility {
  MAIN_APPLICANT = "MAIN_APPLICANT",
  CO_APPLICANT = "CO_APPLICANT",
  SHARED = "SHARED",
  GUARANTOR = "GUARANTOR",
}

export interface CreditSE {
  sum: number;
  monthly_payment: number;
  bank_id: number;
  liable?: string | null;
  rate: number;
  refinance_amount?: number | null;
  monthly_amortisation?: number | null;
  responsibility?: any | null;
  type: CreditTypeSE;
}

export enum CreditTypeNO {
  CREDITCARD = "CREDITCARD",
  CARLOAN = "CARLOAN",
  PRIVATELOAN = "PRIVATELOAN",
  CHECKCREDIT = "CHECKCREDIT",
  UNKNOWN = "UNKNOWN",
  STUDENTLOAN = "STUDENTLOAN",
  MORTGAGE = "MORTGAGE",
}

export enum CreditTypeSE {
  CREDITCARD = "CREDITCARD",
  INSTALLMENT = "INSTALLMENT",
  CARLOAN = "CARLOAN",
  TOPLOAN = "TOPLOAN",
  PRIVATELOAN = "PRIVATELOAN",
  MEMBERLOAN = "MEMBERLOAN",
  CHECKCREDIT = "CHECKCREDIT",
  UNKNOWN = "UNKNOWN",
  MORTGAGE = "MORTGAGE",
}

export interface CreditorOfferCreditCardOfferCreditCardTerms {
  country: Country;
  bank_id: number;
  creditor_name: string;

  /** @format uuid4 */
  offer_id: string;
  offer: CreditCardOffer;

  /** General terms for credit card offers. */
  terms: CreditCardTerms;
  acceptable: boolean;
  is_accepted?: boolean;

  /** @format date-time */
  timestamp: string;
  app_identifier: string;
}

export enum Currency {
  EUR = "EUR",
  SEK = "SEK",
  NOK = "NOK",
}

export interface CurrentFrameItem {
  frame_information?: string | null;
  uc_divisions?: string | null;
  uc_post_type_current?: string | null;
  uc_quick_choice?: any | null;
  year?: string | null;
}

export interface CurrentSectionOfCaseItem {
  additional_order?: string | null;
  editor_program?: string | null;
  order_date?: string | null;
  person_name_stored?: string | null;
  report_language?: string | null;
  subscriber_name?: string | null;
  uc_archive?: string | null;
  uc_divisions_1?: string | null;
  uc_divisions_2?: string | null;
  uc_divisions_3?: string | null;
  uc_divisions_4?: string | null;
  uc_divisions_5?: string | null;
  uc_divisions_6?: string | null;
  uc_divisions_7?: string | null;
  uc_divisions_8?: string | null;
  uc_more_info?: string | null;
  uc_role?: string | null;
  uc_type?: string | null;
  uc_type_internal?: string | null;
  uc_type_text?: string | null;
  year?: string | null;
}

export interface CustomerApplication {
  country: Country;
  product: Product;

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;
  is_deactivated: boolean;
  date_created?: string | null;
  offers?: CustomerOffer[] | null;
  credits: CreditSE[] | CreditNO[] | (CreditSE[] & CreditNO[]);
  applicant: CustomerCustomer;
  co_applicant?: CustomerCustomer | null;
}

export interface CustomerApplicationV2 {
  country: Country;
  product: Product;

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;
  is_deactivated: boolean;
  date_created?: string | null;
  offers?: CustomerOfferV2[];
}

export interface CustomerCustomer {
  customer_id?: string | null;
  country: Country;
  pni: string;
  first_name: string;
  last_name: string;
  sverker_id?: number | null;
  email?: string | null;
  phone_number?: string | null;
  bank_account?: Account | null;
  employer?: object | null;
}

export interface CustomerData {
  phone_number?: string | null;
  email?: string | null;
  account?: Account | null;

  /** @min 0 */
  children?: number;
  employer?: EmployerSE | EmployerNO | null | (EmployerSE & EmployerNO);
  incomes?: Income[] | null;
  net_monthly_income?: number | null;
  monthly_property_cost?: number | null;
  housing_type?: HousingTypeSE | HousingTypeNO | null | (HousingTypeSE & HousingTypeNO);
  paying_child_support?: number | null;
  receiving_child_support?: number | null;

  /** Marital status of a person */
  marital_status?: MaritalStatus | null;

  /** Official marital status of a person */
  registered_marital_status?: MaritalStatus | null;
  education?: EducationType | null;
}

export interface CustomerDocument {
  /** Name of the document */
  name: string;

  /** MIME type of the document */
  mime_type: string;

  /** The type of document you're uploading, will be used as key on the customer */
  document_type: string;

  /**
   * ID of the customer to attach the document to
   * @format uuid
   */
  customer_id: string;

  /** Base64 encoded data of the document */
  data: string;

  /**
   * ID of the document
   * @format uuid
   */
  document_id: string;

  /**
   * Document creation time
   * @format date-time
   */
  timestamp: string;
}

export interface CustomerIDGetCustomerPayload {
  reason: string;
  customer_id: string;
}

export interface CustomerIDsGetCustomerPayload {
  reason: string;
  customer_ids: string[];
}

export interface CustomerOffer {
  amortizationRule?: AmortizationPlan | null;
  offeredAmount: number;
  minOfferedAmount?: number | null;
  maxOfferedAmount?: number | null;
  mustRefinance?: number | null;
  monthlyPayment?: number | null;
  termMonths?: number | null;
  termFee: number;
  arrangementFee: number;
  invoiceFee: number;
  ratePercent: number;
  effectiveRatePercent: number;

  /** @format date-time */
  created_at: string;
  bank_id: number;

  /** @format uuid */
  offer_id: string;
  need_more_info: boolean;
  status: OfferStatus;
  invite_id?: number | null;
  sverker_offer_id: number;
  application_id: number;
  external_offer_id?: string | null;
  accepted_at?: string | null;
  disbursed_at?: string | null;
}

export interface CustomerOfferV2 {
  amortizationRule?: AmortizationPlan | null;
  offeredAmount: number;
  minOfferedAmount?: number | null;
  maxOfferedAmount?: number | null;
  mustRefinance?: number | null;
  monthlyPayment?: number;
  termMonths?: number | null;
  termFee: number;
  arrangementFee: number;
  invoiceFee: number;
  ratePercent: number;
  effectiveRatePercent: number;

  /** @format date-time */
  created_at: string;
  bank_id: number;

  /** @format uuid */
  offer_id: string;
  need_more_info: boolean;
  status: OfferStatus;
  invite_id?: number | null;
  sverker_offer_id: number;

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;
  external_offer_id?: string | null;
  accepted_at?: string | null;
  disbursed_at?: string | null;
  acceptable?: boolean;
  currency?: Currency | null;
  total_cost?: number;
  total_fees_amount?: number;
  total_interest_amount?: number;
}

export interface DMLead {
  /** @format uuid */
  id: string;
  version: number;
  data: LeadData;
  campaign_id?: string | null;
  extraction_id?: string | null;

  /** Extraction of this lead */
  extraction: ModelsDialMaker2LeadDataModelsLeadExtraction | null;
  extracted_at?: string | null;

  /** @format date-time */
  updated_at: string;
  export_id?: string | null;
  exported_at?: string | null;
  status: LeadStatus;
  call_outcomes: (
    | CallOutcomeSuccess
    | CallOutcomeCallBack
    | CallOutcomeNoContact
    | CallOutcomeFailure
    | CallOutcomeDiscard
  )[];
  assigned_to?: string | string | string | null;
  assigned_at?: string | null;
  retry_policy?: RetryPolicy | null;
  call_at?: string | null;
  personal_callback?: PersonalCallback | null;
  last_call_outcome?: OutcomeType | null;
  last_call_outcome_at?: string | null;
}

export interface DMLeadCampaignData {
  campaign_name?: string | null;
  image_id?: string | null;
  campaign_color?: number | null;
  auto_dial?: boolean | null;
  show_call_history?: boolean | null;
}

export interface DMLeadExt {
  /** @format uuid */
  id: string;
  version: number;
  data: LeadData;
  campaign_id?: string | null;
  extraction_id?: string | null;

  /** Extraction of this lead */
  extraction: ModelsDialMaker2LeadDataModelsLeadExtraction | null;
  extracted_at?: string | null;

  /** @format date-time */
  updated_at: string;
  export_id?: string | null;
  exported_at?: string | null;
  status: LeadStatus;
  call_outcomes: (
    | CallOutcomeSuccess
    | CallOutcomeCallBack
    | CallOutcomeNoContact
    | CallOutcomeFailure
    | CallOutcomeDiscard
  )[];
  assigned_to?: string | string | string | null;
  assigned_at?: string | null;
  retry_policy?: RetryPolicy | null;
  call_at?: string | null;
  personal_callback?: PersonalCallback | null;
  last_call_outcome?: OutcomeType | null;
  last_call_outcome_at?: string | null;
  campaign_data?: DMLeadCampaignData | null;
}

export interface DashboardPayload {
  user_ids?: (string | string | string | string)[] | null;
  sverker_usernames?: string[] | null;

  /** @format date-time */
  start_date: string;

  /** @format date-time */
  end_date: string;
}

export interface DaySlotDoc {
  /**
   * @min 0
   * @max 6
   */
  day: number;
  product: Product;
  country: Country;

  /** @format date-time */
  starts_at: string;
  ends_at?: string | null;
  slots: ScheduleSlot[];
  id: string;

  /** @format date-time */
  timestamp: string;
  user_id: string | string | string;
  request_id: string;
}

export interface DaySlotDocPayload {
  /**
   * @min 0
   * @max 6
   */
  day: number;
  product: Product;
  country: Country;

  /** @format date-time */
  starts_at: string;
  ends_at?: string | null;
  slots: ScheduleSlot[];
}

export interface DeactivatePayload {
  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;
  review: DeactivationReview;
}

export interface DeactivateUserPayload {
  user_id: string;
}

export interface DeactivationReview {
  reason: AppsmgrBlancoModelsApplicationDeactivationReason;
  review: ApplicationReview;
}

export interface DeactivationReviewsPayload {
  country: Country;
}

export interface DeactivationReviewsResponse {
  reviews: DeactivationReview[];
}

export interface Debt {
  debt?: DebtItem[] | null;
}

export interface DebtItem {
  date?: string | null;
  type?: string | null;
  source?: string | null;
  status?: string | null;
  saksnr?: string | null;
  amount?: number | null;
  oppgjort?: string | null;
  creditor?: string | null;
  art?: string | null;
  amount_percentage?: number | null;
  currency?: string | null;
  code?: string | null;
}

export enum DefaultCalendarView {
  DayGridMonth = "dayGridMonth",
  AppViewWeek = "appViewWeek",
  AppViewDay = "appViewDay",
}

export interface DialCampaignData {
  name: string;
  description?: string | null;
  source: SheetCampaignSource | BigQueryCampaignSource | ProcessCampaignSource;
  file_id?: string | null;
  exclusion_rules: ExclusionRules;
  daily_extraction_limit?: number | null;
  comment_template?: string | null;
  retry_policy: RetryPolicy;
  auto_dial?: boolean | null;
  personal_callbacks?: boolean | null;
  no_contact_outcomes?: string[] | null;
  failure_outcomes?: string[] | null;
  discard_outcomes?: string[] | null;
  show_call_history?: boolean | null;
  type: CampaignType;
  country: Country;

  /** Used only for Dial Maker 2.0 for now, but can be used for other non-banking products in the future if needed */
  product: ExtendedProduct;
  department?: ZDepartment | null;
  contact_type?: "phone";
}

export enum Direction {
  INCOMING = "INCOMING",
  OUTGOING = "OUTGOING",
}

export interface Discount {
  occupational_pension_in_banks?: number[] | null;
}

export interface DocRequest {
  customer_id?: string | null;
  type: DocumentType | string;
  status: "RECEIVED" | "REQUESTED" | "APPROVED" | "DECLINED" | "WITHDRAWN";
  document_id?: string | null;
  comment?: string | null;
}

export interface Document {
  document_id: string;
  customer_id: string;
}

export interface DocumentAssignation {
  customer_id: string;
  document_id: string;
}

export interface DocumentCreate {
  /** Name of the document */
  name: string;

  /** MIME type of the document */
  mime_type: string;

  /** The type of document you're uploading, will be used as key on the customer */
  document_type: string;

  /**
   * ID of the customer to attach the document to
   * @format uuid
   */
  customer_id: string;

  /** Base64 encoded data of the document */
  data: string;
}

export interface DocumentMeta {
  /** Name of the document */
  name: string;

  /** MIME type of the document */
  mime_type: string;

  /** The type of document you're uploading, will be used as key on the customer */
  document_type: string;

  /**
   * ID of the document
   * @format uuid
   */
  document_id: string;

  /**
   * ID of the customer to attach the document to
   * @format uuid
   */
  customer_id: string;

  /**
   * Document creation time
   * @format date-time
   */
  timestamp: string;
}

export interface DocumentToBank {
  documents: Document[];

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;
  bank_id: number;
}

export enum DocumentType {
  AMORTIZATION_PLAN = "AMORTIZATION_PLAN",
  PETITION_FOR_DIVORCE = "PETITION_FOR_DIVORCE",
  CERTIFICATE_OF_EMPLOYMENT = "CERTIFICATE_OF_EMPLOYMENT",
  DIVISION_OF_PROPERTY_AGREEMENT = "DIVISION_OF_PROPERTY_AGREEMENT",
  VERIFY_ESCROW_AGREEMENT = "VERIFY_ESCROW_AGREEMENT",
  FINANCIAL_FORECASTING_FOR_TENANT_OWNERSHIP_ASSOCIATION = "FINANCIAL_FORECASTING_FOR_TENANT_OWNERSHIP_ASSOCIATION",
  POWER_OF_ATTORNEY = "POWER_OF_ATTORNEY",
  PHYSICAL_MORTGAGE_DEEDS = "PHYSICAL_MORTGAGE_DEEDS",
  INTERMEDIATION_AGREEMENT_INCLUDING_STARTING_PRICE = "INTERMEDIATION_AGREEMENT_INCLUDING_STARTING_PRICE",
  DEED_OF_GIFT = "DEED_OF_GIFT",
  PURCHASE_AGREEMENT = "PURCHASE_AGREEMENT",
  CONTRACTORS_AGREEMENT = "CONTRACTORS_AGREEMENT",
  APARTMENT_CERTIFICATE = "APARTMENT_CERTIFICATE",
  COLLATERAL_OBJECT_DESCRIPTION = "COLLATERAL_OBJECT_DESCRIPTION",
  PENSION_PROJECTION = "PENSION_PROJECTION",
  PENSION_SPECIFICATION = "PENSION_SPECIFICATION",
  AUDITORS_CERTIFICATE = "AUDITORS_CERTIFICATE",
  LATESTAPPROVEDINCOMESTATEMENTANDNE3APPENDIX = "LATEST_APPROVED_INCOME_STATEMENT_AND_NE3_APPENDIX",
  LATEST_APPROVED_INCOME_STATEMENT_AND_NE_APPENDIX = "LATEST_APPROVED_INCOME_STATEMENT_AND_NE_APPENDIX",
  FINAL_INVOICE = "FINAL_INVOICE",
  SALES_AGREEMENT = "SALES_AGREEMENT",
  INFORMATION_TO_CONTACT_PERSON = "INFORMATION_TO_CONTACT_PERSON",
  TENURE_AGREEMENT = "TENURE_AGREEMENT",
  VALUATION = "VALUATION",
  VALUATION_OF_COMPLETED_PROPERTY = "VALUATION_OF_COMPLETED_PROPERTY",
  VALUATION_FROM_INDEPENDENT_BROKER = "VALUATION_FROM_INDEPENDENT_BROKER",
  TRANSFER_AGREEMENT = "TRANSFER_AGREEMENT",
  VALUATION_CERTIFICATE = "VALUATION_CERTIFICATE",
  CASH_DEPOSIT_DOCUMENTATION = "CASH_DEPOSIT_DOCUMENTATION",
  PRELIMINARY_SETTLEMENT_NOTE = "PRELIMINARY_SETTLEMENT_NOTE",
  ORIGINAL_BILL_OF_PURCHASE = "ORIGINAL_BILL_OF_PURCHASE",
  TITLE_DEED = "TITLE_DEED",
  FINAL_SERVEYING_PROTOCOL = "FINAL_SERVEYING_PROTOCOL",
  FINAL_CLEARANCE = "FINAL_CLEARANCE",
  INVOICES = "INVOICES",
  STATEMENT_OF_ACCOUNT = "STATEMENT_OF_ACCOUNT",
  PAYSLIP = "PAYSLIP",
  ANNUAL_REPORT_HOUSING_SOCIETY = "ANNUAL_REPORT_HOUSING_SOCIETY",
  ANNUAL_REPORT_SELF_EMPLOYED = "ANNUAL_REPORT_SELF_EMPLOYED",
  SPOUSE_CONSENT = "SPOUSE_CONSENT",
  OTHER_INCOME_CERTIFICATES = "OTHER_INCOME_CERTIFICATES",
  ESTATE_INVENTORY = "ESTATE_INVENTORY",
  LOAN_TERMS_OPTIONS = "LOAN_TERMS_OPTIONS",
  INSTRUMENT_OF_DEBT = "INSTRUMENT_OF_DEBT",
  KNOW_YOUR_CUSTOMER_QUESTIONS = "KNOW_YOUR_CUSTOMER_QUESTIONS",
  COPY_OF_IDENTIFICATION_CARD = "COPY_OF_IDENTIFICATION_CARD",
  INCOME_TAX_RETURN = "INCOME_TAX_RETURN",
  ENERGY_DECLARATION = "ENERGY_DECLARATION",
  OTHER = "OTHER",
}

export interface DuplicateFormPreferencePayload {
  form_id: string;
  product: Product;
  country: Country;
  copy_from_preference_id: string;
}

export interface EditUser {
  first_name: string;
  last_name: string;

  /** @format email */
  username: string;
  banks: IntegrationId[];
}

export enum EducationType {
  ElementarySchool = "elementarySchool",
  HighSchool = "highSchool",
  Type1YearUniversity = "1yearUniversity",
  Type2YearsUniversity = "2yearsUniversity",
  Type3YearsUniversity = "3yearsUniversity",
  MoreThan3YearsUniversity = "moreThan3yearsUniversity",
}

export interface Elk46CallResponse {
  connect: string;
}

export interface Email {
  country?: Country | null;

  /** @format email */
  email: string;
}

export interface EmailGetCustomerPayload {
  reason: string;

  /** @format email */
  email: string;
}

export interface EmailTemplate {
  event: TemplateEvent;

  /** Used only for Dial Maker 2.0 for now, but can be used for other non-banking products in the future if needed */
  product: ExtendedProduct;
  country: Country;
  message_type?: MessageType;

  /** @format date-time */
  timestamp?: string;
  comment?: string | null;
  deleted?: boolean | null;
  name?: string | null;
  mandrill_name: string;
  subject: string;
  use_comment_subject?: boolean | null;
  from_name?: string | null;
  from_email?: string | null;
  reply_to?: string | null;
  attachment_ids?: string[] | null;
}

export interface Employee {
  email?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  zoordinates: Zoordinates[];
  phone_number?: string | null;
  telavox_ids?: string[] | null;
  sverker_usernames?: SverkerUsername[] | null;
  roles: RoleType[];
  user_id: string | string | string;
  avatar: string;
  deactivated?: boolean;
  last_login?: string | null;
  created_at?: string | null;
}

export interface EmployerNO {
  name: string;
  type: EmploymentTypeNO;

  /** @format date */
  start_date: string;
  end_date?: string | null;
  phone?: string | null;
  retirement_age?: number | null;
  organization_number?: string | null;
  profession?: string | null;
}

export interface EmployerSE {
  name: string;
  type: EmploymentTypeSE;

  /** @format date */
  start_date: string;
  end_date?: string | null;
  phone?: string | null;
  retirement_age?: number | null;
  organization_number?: string | null;
  profession?: string | null;
}

export enum EmploymentType {
  Full = "full",
  Trial = "trial",
  Retired = "retired",
  Hourly = "hourly",
  EarlyRetired = "earlyRetired",
  Project = "project",
  SelfEmployed = "selfEmployed",
  CompanyOwner = "companyOwner",
  NoWork = "noWork",
  TemporarilyDischarged = "temporarilyDischarged",
  Student = "student",
  StandIn = "standIn",
  NorwegianSickLeave = "norwegianSickLeave",
  Disabled = "disabled",
  Other = "other",
}

export type EmploymentTypeBase = any;

export enum EmploymentTypeNO {
  Full = "full",
  SelfEmployed = "selfEmployed",
  Retired = "retired",
  TemporarilyDischarged = "temporarilyDischarged",
  Other = "other",
  Disabled = "disabled",
  Trial = "trial",
  Hourly = "hourly",
  Student = "student",
  NoWork = "noWork",
  StandIn = "standIn",
  Project = "project",
  NorwegianSickLeave = "norwegianSickLeave",
}

export enum EmploymentTypeSE {
  Full = "full",
  Trial = "trial",
  Retired = "retired",
  Hourly = "hourly",
  EarlyRetired = "earlyRetired",
  Project = "project",
  SelfEmployed = "selfEmployed",
  CompanyOwner = "companyOwner",
  NoWork = "noWork",
}

export type EmptyModel = object;

export enum EnqueuedJobStatus {
  Success = "success",
  Failure = "failure",
  Pending = "pending",
  InProgress = "in_progress",
}

export interface EnqueuedSubmissionJobResult {
  submission_id: string;
  status: EnqueuedJobStatus;
  checklist?: Checklist | null;
}

export interface EnumDescription {
  /** The value of the enum */
  value: string | number;

  /** The English label of the enum */
  en_label: string;

  /** The Swedish label of the enum */
  sv_label: string;

  /** The Norwegian label of the enum */
  nb_label: string;
}

export interface EstateTypeType {
  Description?: string | null;
  Key?: string | null;
}

export interface EvCheckPayload {
  pnis: string[];
  reason: string;
}

export interface EvEstate {
  municipality?: string | null;
  municipality_number?: number | null;
  cadastralUnitNumber?: number | null;
  unitNumber?: number | null;
  leaseholdUnitNumber?: number | null;
  sectionNumber?: number | null;
  apartmentReference?: string | null;
  organizationNumber?: string | null;
  estimatedValue?: number | null;
  commonDebt?: number | null;
  estateType?: string | null;
  address?: EvEstateAddress | null;
}

export interface EvEstateAddress {
  streetName?: string | null;
  streetNumber?: number | null;
  streetLetter?: string | null;
  postCodeArea?: string | null;
  postCode?: string | null;
}

export interface EvPartialCommon {
  area_market_score?: number | null;
  municipality_score?: number | null;
  net_value_estimate?: number | null;
  last_known_common_debt?: number | null;
  cadastral_unit_number?: number | null;
  unit_number?: number | null;
  leasehold_unit_number?: number | null;
  section_number?: number | null;
}

export interface EvProperty {
  info: Info;
  mortgages?: Mortgage[] | null;
  value?: Value | null;
}

export interface EventData {
  trigger?: CancellationTrigger | null;
  mailId?: string | null;
  rejectReason?: string | null;
  successful?: boolean | null;
  signupUrl?: string | null;
  amount?: number | null;

  /** If the insurance has been winbacked */
  winbacked?: boolean | null;

  /** If the customer responded for a winbackable insurance */
  response?: boolean | null;

  /** The comment for the winbackable insurance */
  comment?: string | null;
  newMandateUrl?: string | null;
  handled?: boolean | null;
  [key: string]: any;
}

export interface EventsResponse {
  integrations: BankCommunication[];
}

export interface ExclusionRules {
  /** days to exclude lead since last extraction */
  global_extraction_policy_days?: number | null;

  /** days to exclude lead for same campaign extraction */
  local_extraction_policy_days?: number | null;

  /** days since customer was contacted in any campaign */
  global_contact_policy_days?: number | null;

  /** days since customer was contacted in same campaign */
  local_contact_policy_days?: number | null;

  /** hours since customer was contacted in same campaign */
  local_contact_policy_hours?: number | null;

  /** hours since customer was contacted in any campaign */
  global_contact_policy_hours?: number | null;
  exclude_blocked_numbers?: boolean | null;
  exclude_blocked_customers?: boolean | null;
  exclude_blocked_pnis?: boolean | null;
  reservation_register_policy?: PolicyPreference | null;
  marketing_consent_policy?: PolicyPreference | null;
}

/**
 * Used only for Dial Maker 2.0 for now, but can be used for other non-banking products in the future if needed
 */
export enum ExtendedProduct {
  Blanco = "blanco",
  Mortgage = "mortgage",
  CreditCard = "credit_card",
  LoanInsurance = "loan_insurance",
}

export interface ExternalBlockCheckNO {
  email?: boolean | null;
  phone?: boolean | null;
  humanitarian?: boolean | null;
}

export interface ExternalProperty {
  estimatedValue?: number | null;
  marketValueUC?: number | null;
  marketValueBooli?: number | null;
  propertyType?: AppsmgrMortgageSeSpecCommonDefinitionsPropertyType | null;

  /** If applicant is the only owner of the property. Required when propertyType is CONDOMINIUM and applicant is applying alone but has marital status MARRIED or REGISTERED_PARTNERSHIP */
  otherOwner?: boolean | null;

  /** Cadastral Designation. Only for propertyType HOUSE and VACATION_HOME, otherwise null */
  houseCadastralDesignation?: string | null;
  housingMonthlyCost?: number | null;
  squareMeters?: number | null;
  rooms?: number | null;

  /** If the property is a new production */
  newProduction?: boolean | null;

  /** If the applicant is the first owner of new production. Required if 'newProduction' is true. */
  firstOwner?: boolean | null;
  address: OptionalPropertyAddress;
  condominium?: CondominiumItem | null;
  external_id?: string | null;
}

export interface ExternallyVerifiedCredit {
  balance?: number | null;
  creditType?: string | null;
  creditLimit?: number | null;
  currency?: Currency | null;
  dataOld?: boolean | null;
  financialInstitutionId?: string | null;
  financialInstitutionName?: string | null;
  gjeldsregisteret_type?: string | null;
  hasCoBorrower?: boolean | null;
  installmentChargePeriod?: string | null;
  installmentCharges?: number | null;
  nonInterestBearingBalance?: number | null;
  interestBearingBalance?: number | null;
  isCoBorrower?: boolean | null;
  nominalInterestRate?: number | null;
  originalBalance?: number | null;
  processedTime?: string | null;
  receivedTime?: string | null;
  terms?: number | null;
}

export interface ExternallyVerifiedCreditWithRole {
  balance?: number | null;
  creditType?: string | null;
  creditLimit?: number | null;
  currency?: Currency | null;
  dataOld?: boolean | null;
  financialInstitutionId?: string | null;
  financialInstitutionName?: string | null;
  gjeldsregisteret_type?: string | null;
  hasCoBorrower?: boolean | null;
  installmentChargePeriod?: string | null;
  installmentCharges?: number | null;
  nonInterestBearingBalance?: number | null;
  interestBearingBalance?: number | null;
  isCoBorrower?: boolean | null;
  nominalInterestRate?: number | null;
  originalBalance?: number | null;
  processedTime?: string | null;
  receivedTime?: string | null;
  terms?: number | null;
  role: ApplicantRole;
}

export interface FailedVariable {
  name: string;
  amount: number;
}

export enum FailureOutcomes {
  IrrelevantLoans = "irrelevant_loans",
  OnlyMortgage = "only_mortgage",
  DebtFree = "debt_free",
  Click = "click",
  DoesNotWantToListen = "does_not_want_to_listen",
  WrongNumber = "wrong_number",
  NotInterested = "not_interested",
  LanguageBarrier = "language_barrier",
  FutureCallback = "future_callback",
  NotQualified = "not_qualified",
  Unemployed = "unemployed",
  CreditCheck = "credit_check",
  DebtRestructuring = "debt_restructuring",
  SwedishEnforcementAuthority = "swedish_enforcement_authority",
  PaymentRemarks = "payment_remarks",
  Deceased = "deceased",
  DoNotCall = "do_not_call",
}

export interface FieldMapping {
  lead_field: string;
  jotform_field: string;
  options_map?: any[][] | null;
}

export interface FieldMappingExt {
  lead_field: string;
  jotform_field: string;
  options_map?: any[][] | null;
  error?: string | null;
}

export interface Filter {
  product?: ExtendedProduct | null;
  country?: Country | null;
  department?: ZDepartment | null;
  active?: boolean | null;
  creator_id?: string | string | string | null;
  campaign_ids?: string[] | null;
  extraction_id?: string | null;
  exported_extraction_id?: string | null;
  reservation_register_policy?: PolicyPreference[] | null;
  campaign_type?: CampaignType | null;
  campaign_types?: CampaignType[] | null;
  contact_type?: ContactType | null;
  process?: ProcessCampaignType[] | null;
}

export enum FilteringReason {
  BLOCK_LIST = "BLOCK_LIST",
  ALREADY_CONSUMED = "ALREADY_CONSUMED",
  EXTRACTION_LIMIT = "EXTRACTION_LIMIT",
}

export interface FilteringResult {
  reason: string;
  number_of_leads_before_filtering: number;
  number_of_leads_after_filtering: number;
  filtering_reason: FilteringReason;
}

export interface Form {
  name: string;
  form_id: string;
  preferences: string[];
}

export interface FormApplicationSubmissionResult {
  ok: boolean;
  submission_id: string;
}

export interface FormCredit {
  amount: number;

  /** Bank ID */
  bankName: number;

  /** Loan type ID */
  loanType: number;

  /** @min 0 */
  monthlyCost: number;
  rate?: number | null;
  owner?: string | null;
}

export interface FormMapping {
  lead_fields: LeadField[];
  jotform_fields: JotformField[];
  mapping: FieldMappingExt[];
}

export interface FormPreference {
  form_preference_id: string;
  jotform_id: string;
  product: Product;
  country: Country;

  /** @format date-time */
  timestamp: string;
  deactivation_date?: string | null;
  mapping?: FieldMapping[];
}

export interface FormPreferenceExt {
  form_preference_id: string;
  jotform_id: string;
  product: Product;
  country: Country;

  /** @format date-time */
  timestamp: string;
  deactivation_date?: string | null;
  mapping?: FieldMappingExt[];
  valid?: boolean;
  unmapped?: string[] | null;
  form_name?: string;
  has_webhook?: boolean;
}

export interface FormPreferencePayload {
  form_id: string;
  product: Product;
  country: Country;
  mapping: FieldMapping[];
}

export interface FormWebhookPayload {
  preference_id: string;
}

export enum Gender {
  Male = "male",
  Female = "female",
  Other = "other",
}

export interface GeneralMessage {
  type: "GENERAL_MESSAGE_RECEIVED";

  /** @format date-time */
  timestamp: string;
  status: NotificationEvent;
  data: GeneralMessageData;
  notification_id: string;
  user_id: string | string | string | null;
}

export interface GeneralMessageData {
  message: string;
}

export interface GetApplicationMessagesPayload {
  limit?: number;

  /** @min 0 */
  page?: number;

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id?: string | null;
}

export interface GetApplicationPayload {
  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;
}

export interface GetApplications {
  /** @min 0 */
  page: number;

  /** @min 0 */
  limit: number;
  product?: Product | null;
  country?: Country | null;
  bank_statuses?: BankStatus[] | null;
  loan_purposes?: MortgageLoanType[] | null;
}

export interface GetApplicationsByApplicantIdentifierPayload {
  text?: string;
}

export interface GetApplicationsByCustomerIdentifierPayload {
  country?: Country | null;
  product?: Product | null;
  pni?: string | null;
  phone_number?: string | null;
  email?: string | null;
  pagination?: Pagination | null;
}

export interface GetApplicationsByCustomerIdentifierPayloadV2 {
  country?: Country | null;
  product?: Product | null;
  customer_id: string;
  phone_number?: string | null;
  email?: string | null;
  pagination?: Pagination | null;
}

export interface GetBanksPayload {
  bank_id?: number | null;
  country?: Country | null;
}

export interface GetLeads {
  page?: number | null;
  limit?: number | null;
  product: Product;
  country: Country;
  include_handled?: boolean | null;
  include_failed?: boolean | null;
  unassigned_only?: boolean | null;
}

export interface GetMeetings {
  gt?: string | null;
  lt?: string | null;
  page?: number | null;
  limit?: number | null;
  product?: Product | null;
  country?: Country | null;

  /** If true, will return apps and leads */
  with_apps?: boolean | null;

  /** If true, will return customer name */
  with_customer_name?: boolean | null;

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id?: string | string | null;
  application_ids?: (string | string)[] | null;
  handler_id?: string | string | string | null;
  customer_id?: string | null;
  with_handler_name?: boolean | null;
}

export interface GetMessages {
  customer_id?: string | null;
  phone_number?: string | null;
  pni?: string | null;
}

export interface GetMessagesPayload {
  limit?: number;

  /** @min 0 */
  page?: number;
}

export interface GetMyApplicationsPayload {
  country?: Country | null;
  product?: Product | null;
  statuses?: AppsmgrModelsStatusStatus[] | null;
  substatuses?: Substatus[] | null;
  loan_purposes?: MortgageLoanType[] | null;
  unassigned_only?: boolean | null;
}

export interface GetMyLeads {
  page?: number | null;
  limit?: number | null;
  product: Product;
  country: Country;
  include_handled?: boolean | null;
}

export interface GetMyMeetings {
  gt?: string | null;
  lt?: string | null;
  page?: number | null;
  limit?: number | null;
  product?: Product | null;
  country?: Country | null;

  /** If true, will return apps and leads */
  with_apps?: boolean | null;

  /** If true, will return customer name */
  with_customer_name?: boolean | null;
}

export interface GetNameByPNIPayload {
  pni: string;
}

export interface GetRecentApplicationsPayload {
  country?: Country | null;
  product?: Product | null;
  statuses?: AppsmgrModelsStatusStatus[] | null;
  substatuses?: Substatus[] | null;
  loan_purposes?: MortgageLoanType[] | null;
  unassigned_only?: boolean | null;
}

export interface GetRecentPayload {
  product: Product;
  country: Country;
  state?: AppState | null;
}

export interface GetTaxPayload {
  monthly_salary: number;
  key: string;
  country: Country;
}

export interface GetTaxPayloadResponse {
  monthly_tax: number;
}

export interface GetTemplatePayload {
  message_type: MessageType;
  template_event: TemplateEvent;
  country: Country;

  /** Used only for Dial Maker 2.0 for now, but can be used for other non-banking products in the future if needed */
  product: ExtendedProduct;
}

export interface GetUserMessagesPayload {
  limit?: number;

  /** @min 0 */
  page?: number;
  customer_id: string;
}

export interface GetUsersPayload {
  limit?: number | null;
  page?: number | null;
  bank_id?: number | null;
  without_deactivated?: boolean | null;
}

export interface GoalPayload {
  /** What year the goal is set for */
  year: number;

  /** Month from 1-12 */
  month: number;
  user_ids?: (string | string | string)[] | null;
}

export enum GoalType {
  BLANCO = "BLANCO",
  MORTGAGE = "MORTGAGE",
  INITIAL_BLANCO = "INITIAL_BLANCO",
  BLANCO_MB = "BLANCO_MB",
  BUDGET_HL = "BUDGET_HL",
  BUDGET_KA = "BUDGET_KA",
  BUDGET_SK = "BUDGET_SK",
  BUDGET_SP = "BUDGET_SP",
}

/**
* HTTP status codes and reason phrases

Status codes from the following RFCs are all observed:

    * RFC 7231: Hypertext Transfer Protocol (HTTP/1.1), obsoletes 2616
    * RFC 6585: Additional HTTP Status Codes
    * RFC 3229: Delta encoding in HTTP
    * RFC 4918: HTTP Extensions for WebDAV, obsoletes 2518
    * RFC 5842: Binding Extensions to WebDAV
    * RFC 7238: Permanent Redirect
    * RFC 2295: Transparent Content Negotiation in HTTP
    * RFC 2774: An HTTP Extension Framework
    * RFC 7725: An HTTP Status Code to Report Legal Obstacles
    * RFC 7540: Hypertext Transfer Protocol Version 2 (HTTP/2)
    * RFC 2324: Hyper Text Coffee Pot Control Protocol (HTCPCP/1.0)
    * RFC 8297: An HTTP Status Code for Indicating Hints
    * RFC 8470: Using Early Data in HTTP
*/
export type HTTPStatus =
  | 100
  | 101
  | 102
  | 103
  | 200
  | 201
  | 202
  | 203
  | 204
  | 205
  | 206
  | 207
  | 208
  | 226
  | 300
  | 301
  | 302
  | 303
  | 304
  | 305
  | 307
  | 308
  | 400
  | 401
  | 402
  | 403
  | 404
  | 405
  | 406
  | 407
  | 408
  | 409
  | 410
  | 411
  | 412
  | 413
  | 414
  | 415
  | 416
  | 417
  | 418
  | 421
  | 422
  | 423
  | 424
  | 425
  | 426
  | 428
  | 429
  | 431
  | 451
  | 500
  | 501
  | 502
  | 503
  | 504
  | 505
  | 506
  | 507
  | 508
  | 510
  | 511;

export interface HTTPValidationError {
  detail?: ValidationError[];
}

export interface HadOfferEverResponse {
  had_offer: boolean;
}

export interface HandledSubmission {
  submission_id: string;
  handled?: boolean | null;
  reviews?: Reviewer[];
}

export interface HeimdallCounterPair {
  allowed_count: number;
  current_count: number;
}

export interface HeimdallReportingPayload {
  tracking_type: HeimdallTrackingType;
  ids: string[];
}

export interface HeimdallReportingResponse {
  rate_limit_exceeded: boolean;
}

export interface HeimdallResponse {
  user_id: string | string | string;
  date: string;
  apps: HeimdallCounterPair;
  leads: HeimdallCounterPair;
  customers: HeimdallCounterPair;
  insurances: HeimdallCounterPair;
}

export enum HeimdallTrackingType {
  Apps = "apps",
  Customers = "customers",
  Insurances = "insurances",
  Leads = "leads",
}

export interface House {
  property_id: string;
  value?: number | null;
  booli_value?: number | null;
  external_id?: string | null;
  property_type: "HOUSE";
  country: Country;
  street_address?: string | null;
  post_code?: string | null;
  city?: string | null;
  other_owner?: boolean | null;
  monthly_cost?: number | null;
  square_meter_living_area?: number | null;
  municipality?: string | null;
  municipality_code?: string | null;
  estimated_value?: number | null;
  latitude?: number | null;
  longitude?: number | null;
  ev_partial?: EvPartialCommon | null;
  cadastral_designation?: string | null;
  [key: string]: any;
}

export interface HousingCooperatativeShareIdentificationType {
  OrganizationNumber?: string | null;
  ShareNumber?: string | null;
}

export enum HousingTypeNO {
  RENTED = "RENTED",
  OWN_HOUSE = "OWN_HOUSE",
  PARENTS = "PARENTS",
  CONDOMINIUM = "CONDOMINIUM",
  OWN_APARTMENT = "OWN_APARTMENT",
}

export enum HousingTypeSE {
  RENTED = "RENTED",
  CONDOMINIUM = "CONDOMINIUM",
  LIVE_IN = "LIVE_IN",
  OWN_HOUSE = "OWN_HOUSE",
}

export interface IDCheckGetCustomerPayload {
  reason: string;
  country?: Country | null;
  pni: string;
}

export interface IDCheckedPerson {
  country: Country;
  pni: string;
  first_name?: string | null;
  last_name?: string | null;
  street_address?: string | null;
  post_code?: string | null;
  city?: string | null;
  marital_status?: MaritalStatus | null;
  is_blocked?: boolean | null;
  age?: number | null;
  block_list?: BlockList | null;
}

export enum IDKollenAuthStatusResponse {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}

export interface IDKollenCollectResponse {
  id?: string | null;
  status: IDKollenAuthStatusResponse;
  refId?: string;
  ssn?: string | null;
  givenName?: string | null;
  surname?: string | null;
  error?: string | null;
  certStartDate?: string | null;
  signResult?: object | null;
}

export interface IdentityTypeType {
  Description?: string | null;
  Key?: string | null;
}

export interface ImpersonateCustomerPayload {
  pni?: string | null;
  customer_id?: string | null;
}

export enum ImportStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}

export interface Income {
  /**
   * Monthly amount
   * @min 0
   */
  amount?: number;

  /**
   * Yearly amount
   * @min 0
   */
  yearly_amount?: number;
  currency?: Currency | null;
  type: IncomeType;
  name: string;
}

export enum IncomeType {
  Rent = "rent",
  Other = "other",
  Employment = "employment",
  Partner = "partner",
}

export enum IncomingInsuranceEvent {
  MANDATE_CANCELLED = "MANDATE_CANCELLED",
}

export interface IncomingSMS {
  message_id: string;

  /** @format date-time */
  timestamp: string;
  sender: string;
  message: string;
  customer_ids: string[];
  application_id?: string | null;
  message_type?: MessageType;
  direction?: Direction;
  phone_number?: string | null;
}

export interface Info {
  Address: Address;
  Cadastre: Cadastre;
  EV_AddressID?: string | null;
  EV_EstateID?: string | null;
  HousingCooperatativeShareIdentification?: HousingCooperatativeShareIdentificationType | null;
}

export interface Insurance {
  insuranceId: string;
  encryptedInsuranceId?: string | null;
  status: InsuranceStatus;
  substatus: InsuranceSubstatus;
  insuranceType: InsuranceType;
  insuranceGroup?: InsuranceGroup | null;
  mandate?: MandateStatus;
  premiumAmount: number;
  insuredAmount: number;

  /** @format uuid */
  customerId: string;
  sellerId?: string | null;
  seller_name?: string | null;

  /** @format date-time */
  dateCreated: string;
  lastCharge?: LastCharge;
  events?: AppsmgrInsuranceInsuranceEvent[];
  signupDate?: string | null;
  totalCharged?: number;
  targetInitalChargeDate?: string | null;
  goCardlessSubscriptionId?: string | null;
  failedConsecutiveMonthlyPayments?: number;
  cancellationDate?: string | null;
  currency: Currency;
  cta_tags?: CTATags[];

  /** If the insurance has been handled uin the winbackable state */
  handled?: boolean;
  last_comment?: string | null;
  customer?: (ApplicantSE | ApplicantNO) | null;
}

/**
* Model for Loan Insurance for what a customer can see
missing things like events, etc
*/
export interface InsuranceCustomer {
  currency: Currency;

  /** @format date-time */
  date_created: string;
  signup_date?: string | null;
  signup_url?: string | null;
  insurance_id: string;
  encrypted_insurance_id?: string | null;
  insurance_type: InsuranceType;
  insured_amount: number;
  premium_amount: number;
  status: InsuranceStatus;
  substatus: InsuranceSubstatus;
  last_charge?: LastCharge | null;
  mandate?: MandateStatus | null;
  insurance_group: InsuranceGroup;
}

export enum InsuranceEventType {
  INSURANCE_CREATED = "INSURANCE_CREATED",
  INSURANCE_SIGNUP_URL = "INSURANCE_SIGNUP_URL",
  INSURANCE_UPDATED = "INSURANCE_UPDATED",
  INSURANCE_CANCELLED = "INSURANCE_CANCELLED",
  INSURANCE_PENDING_CANCELLATION = "INSURANCE_PENDING_CANCELLATION",
  CUSTOMER_ACCEPTED_TERMS = "CUSTOMER_ACCEPTED_TERMS",
  CUSTOMER_REQUESTED_SIGNUP_DATA = "CUSTOMER_REQUESTED_SIGNUP_DATA",
  GO_CARDLESS_MANDATE_CREATED = "GO_CARDLESS_MANDATE_CREATED",
  GO_CARDLESS_MANDATE_SUBMITTED = "GO_CARDLESS_MANDATE_SUBMITTED",
  GO_CARDLESS_ACCOUNT_INFO = "GO_CARDLESS_ACCOUNT_INFO",
  GO_CARDLESS_MANDATE_ACQUIRED = "GO_CARDLESS_MANDATE_ACQUIRED",
  GO_CARDLESS_MANDATE_CANCELLED = "GO_CARDLESS_MANDATE_CANCELLED",
  GO_CARDLESS_SUBSCRIPTION_CREATED = "GO_CARDLESS_SUBSCRIPTION_CREATED",
  GO_CARDLESS_SUBSCRIPTION_SUBMITTED = "GO_CARDLESS_SUBSCRIPTION_SUBMITTED",
  GO_CARDLESS_SUBSCRIPTION_CANCELLED = "GO_CARDLESS_SUBSCRIPTION_CANCELLED",
  GO_CARDLESS_PAYMENT_CREATED = "GO_CARDLESS_PAYMENT_CREATED",
  GO_CARDLESS_PAYMENT_CONFIRMED = "GO_CARDLESS_PAYMENT_CONFIRMED",
  GO_CARDLESS_PAYMENT_FAILED = "GO_CARDLESS_PAYMENT_FAILED",
  GO_CARDLESS_PAYMENT_CANCELLED = "GO_CARDLESS_PAYMENT_CANCELLED",
  GO_CARDLESS_PAYMENT_RESUBMISSION_REQUESTED = "GO_CARDLESS_PAYMENT_RESUBMISSION_REQUESTED",
  GO_CARDLESS_REFUND_CREATED = "GO_CARDLESS_REFUND_CREATED",
  GO_CARDLESS_REFUND_PAID = "GO_CARDLESS_REFUND_PAID",
  TRUSTLY_INITIATED = "TRUSTLY_INITIATED",
  TRUSTLY_ACCOUNT_INFO = "TRUSTLY_ACCOUNT_INFO",
  TRUSTLY_MANDATE_ACQUIRED = "TRUSTLY_MANDATE_ACQUIRED",
  TRUSTLY_MANDATE_CANCELLED = "TRUSTLY_MANDATE_CANCELLED",
  GO_CARDLESS_MANDATE_FAILED = "GO_CARDLESS_MANDATE_FAILED",
  TRUSTLY_MANDATE_SIGNUP_CANCELLED = "TRUSTLY_MANDATE_SIGNUP_CANCELLED",
  TRUSTLY_UNKNOWN_EVENT = "TRUSTLY_UNKNOWN_EVENT",
  SCRIVE_SIGNATURE = "SCRIVE_SIGNATURE",
  TRUSTLY_CHARGE_INITIATED = "TRUSTLY_CHARGE_INITIATED",
  TRUSTLY_PENDING_CHARGE = "TRUSTLY_PENDING_CHARGE",
  TRUSTLY_ACCOUNT_CHARGED = "TRUSTLY_ACCOUNT_CHARGED",
  TRUSTLY_CHARGE_CANCELLED = "TRUSTLY_CHARGE_CANCELLED",
  TRUSTLY_CHARGE_REFUNDED = "TRUSTLY_CHARGE_REFUNDED",
  TRUSTLY_CHARGE_REFUND_FAILED = "TRUSTLY_CHARGE_REFUND_FAILED",
  TRUSTLY_REFUND_INITIATED = "TRUSTLY_REFUND_INITIATED",
  TRUSTLY_REFUND_INITIATION_FAILED = "TRUSTLY_REFUND_INITIATION_FAILED",
  TRUSTLY_CANCEL_ORDER_INITIATED = "TRUSTLY_CANCEL_ORDER_INITIATED",
  TRUSTLY_CANCEL_ORDER_FAILED = "TRUSTLY_CANCEL_ORDER_FAILED",
  INVITATION_EMAIL_SENT = "INVITATION_EMAIL_SENT",
  INVITATION_EMAIL_SENT_AGAIN = "INVITATION_EMAIL_SENT_AGAIN",
  INVITATION_EMAIL_REMINDER_SENT = "INVITATION_EMAIL_REMINDER_SENT",
  SIGNUP_CONFIRMATION_EMAIL_SENT = "SIGNUP_CONFIRMATION_EMAIL_SENT",
  SIGNUP_CONFIRMATION_EMAIL_SENT_AGAIN = "SIGNUP_CONFIRMATION_EMAIL_SENT_AGAIN",
  FAILED_TO_CHARGE_EMAIL_SENT = "FAILED_TO_CHARGE_EMAIL_SENT",
  FAILED_TO_CHARGE_EMAIL_SENT_AGAIN = "FAILED_TO_CHARGE_EMAIL_SENT_AGAIN",
  MANDATE_CANCELLED_EMAIL_SENT = "MANDATE_CANCELLED_EMAIL_SENT",
  MANDATE_CANCELLED_EMAIL_SENT_AGAIN = "MANDATE_CANCELLED_EMAIL_SENT_AGAIN",
  NEW_MANDATE_ACCOUNT_INFO_RECEIVED_EMAIL_SENT = "NEW_MANDATE_ACCOUNT_INFO_RECEIVED_EMAIL_SENT",
  NEW_MANDATE_ACCOUNT_INFO_RECEIVED_EMAIL_SENT_AGAIN = "NEW_MANDATE_ACCOUNT_INFO_RECEIVED_EMAIL_SENT_AGAIN",
  CANCEL_EMAIL_UNPAID_PREMIUM_SENT = "CANCEL_EMAIL_UNPAID_PREMIUM_SENT",
  CANCEL_EMAIL_UNPAID_PREMIUM_SENT_AGAIN = "CANCEL_EMAIL_UNPAID_PREMIUM_SENT_AGAIN",
  CANCEL_EMAIL_CUSTOMER_AGE_SENT = "CANCEL_EMAIL_CUSTOMER_AGE_SENT",
  CANCEL_EMAIL_CUSTOMER_AGE_SENT_AGAIN = "CANCEL_EMAIL_CUSTOMER_AGE_SENT_AGAIN",
  CANCEL_EMAIL_MANDATE_CANCELLED_SENT = "CANCEL_EMAIL_MANDATE_CANCELLED_SENT",
  CANCEL_EMAIL_MANDATE_CANCELLED_SENT_AGAIN = "CANCEL_EMAIL_MANDATE_CANCELLED_SENT_AGAIN",
  CANCEL_EMAIL_GENERIC_SENT = "CANCEL_EMAIL_GENERIC_SENT",
  CANCEL_EMAIL_GENERIC_SENT_AGAIN = "CANCEL_EMAIL_GENERIC_SENT_AGAIN",
  NEW_MANDATE_ACQUIRED_EMAIL_SENT = "NEW_MANDATE_ACQUIRED_EMAIL_SENT",
  NEW_MANDATE_ACQUIRED_EMAIL_SENT_AGAIN = "NEW_MANDATE_ACQUIRED_EMAIL_SENT_AGAIN",
  REQUEST_NEW_MANDATE_EMAIL_SENT = "REQUEST_NEW_MANDATE_EMAIL_SENT",
  REQUEST_NEW_MANDATE_LINK_CREATED = "REQUEST_NEW_MANDATE_LINK_CREATED",
  WINBACK_FEEDBACK = "WINBACK_FEEDBACK",
  VIPPS_INITIATED = "VIPPS_INITIATED",
  VIPPS_MANDATE_ACQUIRED = "VIPPS_MANDATE_ACQUIRED",
  VIPPS_MANDATE_EXPIRED = "VIPPS_MANDATE_EXPIRED",
  VIPPS_MANDATE_FAILED = "VIPPS_MANDATE_FAILED",
  VIPPS_MANDATE_CANCELLED = "VIPPS_MANDATE_CANCELLED",
  VIPPS_CHARGE_INITIATED = "VIPPS_CHARGE_INITIATED",
  VIPPS_CHARGE_PENDING = "VIPPS_CHARGE_PENDING",
  VIPPS_CHARGE_CANCELLED = "VIPPS_CHARGE_CANCELLED",
  VIPPS_ACCOUNT_CHARGED = "VIPPS_ACCOUNT_CHARGED",
  VIPPS_CHARGE_FAILED = "VIPPS_CHARGE_FAILED",
  VIPPS_CHARGE_REFUNDED = "VIPPS_CHARGE_REFUNDED",
}

export enum InsuranceGroup {
  SAFETY_INSURANCE = "SAFETY_INSURANCE",
  ACCIDENT_INSURANCE = "ACCIDENT_INSURANCE",
  LIFE_INSURANCE = "LIFE_INSURANCE",
}

export interface InsuranceNotification {
  type: IncomingInsuranceEvent;
  insurance_id: string;

  /** @format date-time */
  timestamp: string;
  country: Country;
  customer_id: string;
}

export enum InsuranceStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum InsuranceSubstatus {
  WAITING_FOR_CUSTOMER_ACCEPTANCE = "WAITING_FOR_CUSTOMER_ACCEPTANCE",
  WAITING_FOR_BANK = "WAITING_FOR_BANK",
  INACTIVE_MANDATE = "INACTIVE_MANDATE",
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
  PENDING_CANCELLATION = "PENDING_CANCELLATION",
  DISCARDED = "DISCARDED",
}

export interface InsuranceTerms {
  /** The age at which the insurance ceases to be valid */
  active_max_age: number;
  can_create_new: boolean;
  can_request_new_mandate: boolean;
  can_update_insurance: boolean;
  cancellation_withdrawal_period_days: number;
  countries_of_residence: Country[];
  country: Country;
  currency: Currency;
  customer_can_self_sign_up: boolean;
  employment_types: string[];
  insurance_type: InsuranceType;
  insurance_group: InsuranceGroup;
  insurer_code: string;
  max_age: number;

  /** The maximum amount that can be insured */
  max_amount?: number | null;
  max_insured_rate_vs_monthly_gross_income: number;
  min_age: number;

  /** The minimum amount that can be insured */
  min_amount?: number | null;
  min_months_full_time_employment_time?: number | null;
  min_months_employment_time: number;
  min_hours_of_work_per_week: number;
  min_months_employment_time_for_self_employed?: number | null;
  min_hours_of_work_per_week_for_self_employed?: number | null;
  payment_provider: string;

  /** The rate of the premium insured amount * premium rate = premium amount */
  premium_rate?: number | null;
  usps?: string[];
  terms?: string[];

  /** The premium amount for a static premium insurance e.g accident insurance */
  static_premium?: number | null;

  /** The amount that is insured for insurances with a fixed premium */
  insured_amount?: number | null;

  /** The name of the insurance product */
  product_name: string;
}

export enum InsuranceType {
  AXA_ZS_SE = "AXA_ZS_SE",
  AXA_ZS_NO = "AXA_ZS_NO",
  Z8 = "Z8",
  Z6 = "Z6",
  Z1 = "Z1",
  AXAO1SE = "AXA_O1_SE",
}

export interface Integration {
  bank_id: number;
  country: Country;
  product: Product;
  name: string;

  /** Allowed actions to be performed on this integration */
  actions: IntegrationAction[];
}

export enum IntegrationAction {
  INVITE = "INVITE",
  AUTO_INVITE = "AUTO_INVITE",
  COMMENTS = "COMMENTS",
  DOCUMENTS = "DOCUMENTS",
  ACCEPT = "ACCEPT",
  CANCEL = "CANCEL",
}

export interface IntegrationId {
  bank_id: number;
  country: Country;
  product: Product;
}

export interface IntegrationQuery {
  country?: Country | null;
  bank_id?: number | null;
  product?: Product | null;
}

export enum IntentionOfExcessAmount {
  BUYING_PROPERTY = "BUYING_PROPERTY",
  REPAYMENT_OTHER_LOANS = "REPAYMENT_OTHER_LOANS",
  RENOVATION_OF_PROPERTY = "RENOVATION_OF_PROPERTY",
  BUYING_CAPITAL_GOODS = "BUYING_CAPITAL_GOODS",
  OTHER_CONSUMPTION = "OTHER_CONSUMPTION",
}

export interface InterimLead {
  /** @format uuid */
  id?: string;
  data: LeadData;

  /** @format uuid */
  campaign_id: string;
  file_id?: string | null;
  job_id?: string | null;
  lead_type?: "COLD";
  imported_at?: string | null;
  extracted_at?: string | null;
  extraction_id?: string | null;
}

export interface InterimLeadJob {
  /** @format uuid */
  job_id: string;
  is_finished?: boolean;
  is_failed?: boolean;
}

export interface InterimLeadsInsertion {
  not_normalized_count?: number;
  duplicated_count?: number;
  inserted_count?: number;
}

export interface InternalCommentData {
  application_id: string;
  from_user_id?: string | string | string | string | null;
}

export interface InternalCommentWithName {
  user_id: string | string | string;
  comment: string;

  /** @format date-time */
  timestamp: string;
  comment_id: string;
  action?: "INTERNAL_COMMENT_ADDED";
  user_name: string;
  user_avatar: string;
}

export interface InvitePayloadBase {
  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;
  bank_id: number;
  comment?: string | null;
  force?: boolean;
}

export enum JobType {
  Scheduled = "scheduled",
  Failed = "failed",
  Started = "started",
  Finished = "finished",
}

export interface JoinedCampaignExt {
  /** @format uuid */
  campaign_id: string;

  /** @format date-time */
  joined_at: string;
  playing: boolean;

  /** @max 16 */
  color: number;
  stats: CampaignStats;
  campaign_data: MailCampaignData | DialCampaignData | (MailCampaignData & DialCampaignData);
}

export interface JotformCCApplication {
  country: Country;
  data: OptionalCreditCardApplicationDataCreate;

  /** Model used only for mapping jotform data to Applicant model, allowing some more fields to be optional */
  applicant: OptionalCreateApplicantNO;
}

export interface JotformField {
  name: string;
  options?: string[] | null;
}

export interface JotformLeadSource {
  type?: "JOTFORM";
  form_id: string;
  submission_id: string;
  error?: string | null;
}

export interface KDLTCallbackPayload {
  event: KDLTEvent;
  customer_id: string;
  event_id: string;

  /** @format date-time */
  timestamp: string;
}

export enum KDLTEvent {
  CUSTOMER_CREATED = "CUSTOMER_CREATED",
  CUSTOMER_UPDATED = "CUSTOMER_UPDATED",
  UC_ID_CHECK = "UC_ID_CHECK",
  UC_CREDIT_CHECK = "UC_CREDIT_CHECK",
  EXPERIAN_ID_CHECK = "EXPERIAN_ID_CHECK",
  EXPERIAN_CREDIT_CHECK = "EXPERIAN_CREDIT_CHECK",
  CM1PEPCHECK = "CM1_PEP_CHECK",
  GJELDSREGISTERET_CREDITS = "GJELDSREGISTERET_CREDITS",
  CUSTOMER_DELETED = "CUSTOMER_DELETED",
  CUSTOMER_ACCESSED = "CUSTOMER_ACCESSED",
  PEP_SANCTION_WHITELISTING = "PEP_SANCTION_WHITELISTING",
  DOCUMENT_ADDED = "DOCUMENT_ADDED",
  PROPERTY_EV_CHECK = "PROPERTY_EV_CHECK",
  MANUALLY_BLOCKED = "MANUALLY_BLOCKED",
}

export interface LastCharge {
  amount?: number | null;
  date?: string | null;
  orderId?: string | null;
}

export interface LastQuery {
  issuer_business_type?: string | null;
  issuer_name?: string | null;
  query_date?: string | null;
  uc_internal_information?: string | null;
  year?: string | null;
}

export interface Lead {
  product: Product;
  country: Country;
  lead_id: string;
  creator?: string | string | string | null;

  /** @format date-time */
  created_at: string;

  /** @format date-time */
  updated_at: string;
  handled: boolean;
  reprocessable?: boolean | null;
  create_source?:
  | JotformLeadSource
  | ApiLeadSource
  | AffiliateApiLeadSource
  | null
  | (JotformLeadSource & ApiLeadSource & AffiliateApiLeadSource);
  create_sources?: (
    | JotformLeadSource
    | ApiLeadSource
    | AffiliateApiLeadSource
    | (JotformLeadSource & ApiLeadSource & AffiliateApiLeadSource)
  )[];
  converted?: string[] | null;
  data?: LeadCreationData | null;
  source?: ApplicationSource | null;
  deactivation_reason?: AppsmgrMortgageModelsApplicationDeactivationReason | null;
}

export interface LeadAssigned {
  type: "LEAD_ASSIGNED";

  /** @format date-time */
  timestamp: string;
  status: NotificationEvent;
  data: LeadAssignedData;
  notification_id: string;
  user_id: string | string | string | null;
}

export interface LeadAssignedData {
  lead_id: string;
}

export interface LeadComment {
  type: "LEAD_COMMENT_RECEIVED";

  /** @format date-time */
  timestamp: string;
  status: NotificationEvent;
  data: LeadCommentData;
  notification_id: string;
  user_id: string | string | string | null;
}

export interface LeadCommentData {
  lead_id: string;
}

export interface LeadCreationData {
  loan?: NewMortgageLoan | null;
  refinance_info?: RefinanceInfo | null;
  buying_info?: BuyingInfo | null;
  selling_info?: SellingInfo | null;
  applicants?: BareApplicantWithRole[] | null;
  property_id?: string | null;
  discount?: Discount | null;
  loans?: Loan[] | null;
  other_mortgages?: MortgageLoan[] | null;
  status?: AppsmgrModelsStatusStatus | null;
  substatus?: Substatus | null;
  bank_status?: BankStatus | null;
  children_in_the_household?: number | null;
  children_in_the_household_fulltime?: number | null;
  other_properties_to_keep?: OtherProperty[] | null;
  co_relationship_to_main_applicant?: RelationshipStatus | null;
  guarantor_relationship_to_main_applicant?: RelationshipStatus | null;
  cars?: CarsInput | null;
  brokerage?: Brokerage | null;
  applicant?: (LimitedApplicantSE | LimitedApplicantNO) | null;
  co_applicant?: (LimitedApplicantSE | LimitedApplicantNO) | null;
  property?: ExternalProperty | null;
  comment?: string | null;
  ip_address?: string | null;
  [key: string]: any;
}

export interface LeadData {
  phone?: string | null;
  other_phones?: string[] | null;
  pni?: string | null;
  process_type?: ProcessCampaignType | null;
  process_subtype?: InsuranceSubstatus | null;
  priority?: number | null;
  name?: string | null;
  customer_id?: string | null;
  other_properties?: object | null;
  comment_vars?: object | null;
  tags?: object | null;

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id?: string | null;
  comment?: string | null;
  customer_cluster?: string | null;

  /** Number of times this customer/phone number was extracted. */
  extraction_count?: number | null;
  sorting_order?: number | null;
  mail?: string | null;
  contact_type?: ContactType;
  calling_order?: CallingOrder | null;
  raw_calling_order?: CallingOrder | null;
  data_template_vars?: object | null;
}

export interface LeadField {
  name: string;
  options?: string[] | null;
}

export interface LeadHandledPayload {
  lead_id: string;
  deactivation_reason: AppsmgrMortgageModelsApplicationDeactivationReason;
}

export enum LeadStatus {
  SUCCESS = "SUCCESS",
  PENDING = "PENDING",
  FAILED = "FAILED",
  DEACTIVATED = "DEACTIVATED",
  DISCARD = "DISCARD",
}

export interface LeadUpdatePayload {
  loan?: NewMortgageLoan | null;
  refinance_info?: RefinanceInfo | null;
  buying_info?: BuyingInfo | null;
  selling_info?: SellingInfo | null;
  applicants?: BareApplicantWithRole[] | null;
  property_id?: string | null;
  discount?: Discount | null;
  loans?: Loan[] | null;
  other_mortgages?: MortgageLoan[] | null;
  status?: AppsmgrModelsStatusStatus | null;
  substatus?: Substatus | null;
  bank_status?: BankStatus | null;
  children_in_the_household?: number | null;
  children_in_the_household_fulltime?: number | null;
  other_properties_to_keep?: OtherProperty[] | null;
  co_relationship_to_main_applicant?: RelationshipStatus | null;
  guarantor_relationship_to_main_applicant?: RelationshipStatus | null;
  cars?: CarsInput | null;
  brokerage?: Brokerage | null;
  applicant?: (LimitedApplicantSE | LimitedApplicantNO) | null;
  co_applicant?: (LimitedApplicantSE | LimitedApplicantNO) | null;
  property?: ExternalProperty | null;
  comment?: string | null;
}

export enum LeftToLiveFields {
  ExistingMortgage = "existing_mortgage",
  BuyingInfo = "buying_info",
  BooliValuation = "booli_valuation",
  GuarantorEmployer = "guarantor_employer",
  CoApplicantEmployer = "co_applicant_employer",
  MainApplicantEmployer = "main_applicant_employer",
  LoanPurpose = "loan_purpose",
  MortgageLoanAmount = "mortgage_loan_amount",
  NewMortgageLoan = "new_mortgage_loan",
  Property = "property",
  Applicant = "applicant",
  Municipality = "municipality",
  ExistingLoanAmount = "existing_loan_amount",
  ExistingLoanRefinance = "existing_loan_refinance",
  ExistingLoanResponsibility = "existing_loan_responsibility",
  ExistingLoanType = "existing_loan_type",
}

export interface LeftToLiveOutcome {
  /** TODO */
  message?: string | null;

  /** TODO */
  success?: boolean | null;

  /** TODO */
  loans?: LeftToLiveOutcomeLoan[] | null;

  /** TODO */
  leftToLiveOriginalResult?: number | null;

  /** TODO */
  leftToLiveValue?: number | null;

  /** TODO */
  leftToLiveBudget?: number | null;

  /** TODO */
  leftToLiveResult?: number | null;

  /** TODO */
  requestedLoanAmount?: number | null;

  /** TODO */
  loanAmountAdjusted?: boolean | null;

  /** TODO */
  offeredLoanAmount?: number | null;

  /** TODO */
  debtToIncomeRatio?: number | null;
}

export interface LeftToLiveOutcomeLoan {
  /** TODO */
  monthlyPayment?: number | null;

  /** TODO */
  interestRate?: number | null;

  /** TODO */
  loanAmount?: number | null;

  /** TODO */
  loanType?: LoanTypeEnum | null;

  /** TODO */
  monthlyAmortization?: number | null;
}

export interface LeftToLivePayload {
  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;
  bank_id: number;
  force?: boolean | null;
}

export interface LeftToLiveResponse {
  outcome?: LeftToLiveOutcome | null;
  pre_offer?: MortgageRates | null;
  missing_required_fields?: LeftToLiveFields[] | null;
  missing_optional_fields?: LeftToLiveFields[] | null;
}

export interface LegalDocumentTypeType {
  Description?: string | null;
  Key?: string | null;
}

export interface LienTypeType {
  Description?: string | null;
  Key?: string | null;
}

export interface LimitedApplicantNO {
  zid?: string | null;
  maklarpartner?: string | null;
  session_zid?: string | null;
  gid?: string | null;
  country?: "NO";
  pni?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  email?: string | null;
  phone_number?: string | null;
  additional_phone_numbers?: string[] | null;
  monthly_property_cost?: number | null;
  marital_status?: MaritalStatus | null;

  /** Official marital status from ID check; should not be modified; marital_status has higher priority */
  registered_marital_status?: MaritalStatus | null;
  post_code?: string | null;
  city?: string | null;
  incomes?: Income[] | null;
  net_monthly_income?: number | null;
  account?: Account | null;
  street_address?: string | null;

  /** Number of children to pay child support for */
  paying_child_support?: number | null;

  /** Number of children to receive child support for */
  receiving_child_support?: number | null;
  consents?: Consents | null;
  consents_popup_dismissed_timestamp?: string | null;
  partner_pni?: string | null;
  partner_name?: string | null;
  municipality_code?: string | null;
  municipality?: string | null;
  is_pep?: boolean | null;
  age?: number | null;
  other_phones?: AppsmgrModelsPhonePhoneNumber[] | null;
  housing_type?: HousingTypeNO | null;
  employer?: OptionalEmployerNO | null;
  customer_id?: string | null;
}

export interface LimitedApplicantSE {
  zid?: string | null;
  maklarpartner?: string | null;
  session_zid?: string | null;
  gid?: string | null;
  country?: "SE";
  pni?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  email?: string | null;
  phone_number?: string | null;
  additional_phone_numbers?: string[] | null;
  monthly_property_cost?: number | null;
  marital_status?: MaritalStatus | null;

  /** Official marital status from ID check; should not be modified; marital_status has higher priority */
  registered_marital_status?: MaritalStatus | null;
  post_code?: string | null;
  city?: string | null;
  incomes?: Income[] | null;
  net_monthly_income?: number | null;
  account?: Account | null;
  street_address?: string | null;

  /** Number of children to pay child support for */
  paying_child_support?: number | null;

  /** Number of children to receive child support for */
  receiving_child_support?: number | null;
  consents?: Consents | null;
  consents_popup_dismissed_timestamp?: string | null;
  partner_pni?: string | null;
  partner_name?: string | null;
  municipality_code?: string | null;
  municipality?: string | null;
  is_pep?: boolean | null;
  age?: number | null;
  other_phones?: AppsmgrModelsPhonePhoneNumber[] | null;
  housing_type?: HousingTypeSE | null;
  employer?: OptionalEmployerSE | null;
  customer_id?: string | null;
}

export interface Loan {
  currency: Currency;

  /** @format uuid4 */
  loan_id: string;
  amount?: number | null;
  bank_id?: number | null;
  refinance?: boolean | null;
  refinanceAmount?: number | null;
  loan_type?: LoanType | null;
  monthly_payment?: number | null;
  rate?: number | null;
  monthly_amortisation?: number | null;
  responsibility?: any | null;
  credit_limit?: number | null;
  monthly_administrative_fee?: number | null;
}

export interface LoanAndExternallyVerifiedCredit {
  externally_verified_credits: ExternallyVerifiedCreditWithRole[];
  loans: Loan[];
}

export enum LoanPurpose {
  Refinance = "refinance",
  HomeRemodelling = "homeRemodelling",
  HealthcareExpenses = "healthcareExpenses",
  DivorceProceeding = "divorceProceeding",
  HomeDownpayment = "homeDownpayment",
  Investment = "investment",
  Education = "education",
  Travel = "travel",
  Car = "car",
  Other = "other",
  DriversLicense = "driversLicense",
  Wedding = "wedding",
  Relocation = "relocation",
  Interior = "interior",
  Consumption = "consumption",
  DebtCollection = "debtCollection",
  LoanOnBehalfOfOther = "loan-on-behalf-of-other",
  InvestmentAbroad = "investment-abroad",
}

export enum LoanPurposeNO {
  Refinance = "refinance",
  HomeRemodelling = "homeRemodelling",
  HealthcareExpenses = "healthcareExpenses",
  DivorceProceeding = "divorceProceeding",
  HomeDownpayment = "homeDownpayment",
  Investment = "investment",
  Education = "education",
  Travel = "travel",
  Car = "car",
  Other = "other",
  DriversLicense = "driversLicense",
  Wedding = "wedding",
  Relocation = "relocation",
  Interior = "interior",
  Consumption = "consumption",
  DebtCollection = "debtCollection",
}

export enum LoanPurposeSE {
  Refinance = "refinance",
  HomeRemodelling = "homeRemodelling",
  HealthcareExpenses = "healthcareExpenses",
  DivorceProceeding = "divorceProceeding",
  HomeDownpayment = "homeDownpayment",
  Investment = "investment",
  Education = "education",
  Travel = "travel",
  Car = "car",
  Other = "other",
  DriversLicense = "driversLicense",
  Wedding = "wedding",
  Relocation = "relocation",
  Interior = "interior",
  Consumption = "consumption",
  DebtCollection = "debtCollection",
  LoanOnBehalfOfOther = "loan-on-behalf-of-other",
  InvestmentAbroad = "investment-abroad",
}

export enum LoanType {
  CREDIT_CARD = "CREDIT_CARD",
  UNSECURED_LOAN = "UNSECURED_LOAN",
  CAR_LOAN = "CAR_LOAN",
  CHECK_CREDIT = "CHECK_CREDIT",
  OTHER = "OTHER",
  STUDENT_LOAN = "STUDENT_LOAN",
  INSTALLMENT = "INSTALLMENT",
  TOP_MORTGAGE_LOAN = "TOP_MORTGAGE_LOAN",
  MEMBER_LOAN = "MEMBER_LOAN",
  MORTGAGE = "MORTGAGE",
}

export enum LoanTypeEnum {
  UNSECURED_LOAN = "UNSECURED_LOAN",
  MORTGAGE = "MORTGAGE",
}

export interface Login {
  /** @format email */
  username: string;

  /** @format password */
  password: string;
}

export interface LoginPayload {
  pni?: string | null;
  callback_url?: string | null;
  callback_fail_url?: string | null;
  country?: Country;
  product?: Product;
}

export interface LoginResp {
  session_id: string;
}

export interface MailBatchStats {
  total_opens?: number;
  total_clicks?: number;
  unique_opens?: number;
  unique_clicks?: number;
  sent?: number;
  hard_bounce?: number;
  soft_bounce?: number;
  spam?: number;
  unsubscribed?: number;
  redirects?: number;
  unique_redirects?: number;

  /** @format uuid */
  batch_id: string;
  mail_ids: string[];

  /** @format date-time */
  batch_sent: string;
}

export interface MailCampaignData {
  name: string;
  description?: string | null;
  source: SheetCampaignSource | BigQueryCampaignSource | ProcessCampaignSource;
  file_id?: string | null;
  exclusion_rules: ExclusionRules;
  daily_extraction_limit?: number | null;
  comment_template?: string | null;
  mail_policy?: MailSendPolicy | null;
  template?: EmailTemplate | null;
  template_vars?: string[] | null;
  type: CampaignType;
  country: Country;

  /** Used only for Dial Maker 2.0 for now, but can be used for other non-banking products in the future if needed */
  product: ExtendedProduct;
  department?: ZDepartment | null;
  contact_type?: "mail";
  mailings?: MailingData[];
  last_bq_sync?: string | null;
  last_mailing_at?: string | null;
  template_vars_checked_at?: string | null;
  template_links_vars?: string[] | null;
}

export interface MailSendPolicy {
  weekdays_to_send?: number[] | null;
  spread_send_times?: string[] | null;

  /** @min 0 */
  batch_size?: number;
}

export interface MailingData {
  /** @format uuid */
  mailing_id: string;
  template: EmailTemplate;
  template_vars?: string[] | null;

  /** @min 0 */
  leads_count: number;
  mail_times: string[];
  template_vars_checked_at?: string | null;
  template_links_vars?: string[] | null;
}

export interface MandateStatus {
  acquired?: boolean;
  accountId?: string | null;
  canceled?: boolean;
  bank?: string | null;
  lastBankAccountDigits?: string | null;
}

export interface MandrillTemplate {
  slug: string;
  name: string;
  labels: string[];
  code?: string | null;
  subject?: string | null;
  from_email?: string | null;
  from_name?: string | null;
  text?: string | null;
  publish_name: string;
  publish_code?: string | null;
  publish_subject?: string | null;
  publish_from_email?: string | null;
  publish_from_name?: string | null;
  publish_text?: string | null;
  published_at?: string | null;

  /** @format date-time */
  created_at: string;

  /** @format date-time */
  updated_at: string;
  is_broken_template: boolean;
}

export enum MaritalStatus {
  Unmarried = "unmarried",
  Married = "married",
  Divorced = "divorced",
  WidowWidower = "widow/widower",
  Deceased = "deceased",
  ChildUnder18Years = "child under 18 years",
  PreviouslyMarried = "previously married",
  RegisteredPartnership = "registered partnership",
  Partner = "partner",
}

export interface MeetingWithApp {
  /** @format date-time */
  meeting_start: string;

  /** @format date-time */
  meeting_end: string;
  handler_id?: string | null;
  meeting_notes?: string | null;
  meeting_subject?: string | null;
  customer_id?: string | null;

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string | string;
  meeting_id: string;

  /** @format date-time */
  last_updated: string;

  /** @format date-time */
  date_created: string;
  product: Product;
  country: Country;
  customer_name?: string | null;
  handler_name?: string | null;
  application?: AppBase | Lead | null | (AppBase & Lead);
}

export interface MeetingWithHandlerName {
  /** @format date-time */
  meeting_start: string;

  /** @format date-time */
  meeting_end: string;
  handler_id?: string | null;
  meeting_notes?: string | null;
  meeting_subject?: string | null;
  customer_id?: string | null;

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string | string;
  meeting_id: string;

  /** @format date-time */
  last_updated: string;

  /** @format date-time */
  date_created: string;
  product: Product;
  country: Country;
  customer_name?: string | null;
  handler_name?: string | null;
}

export interface MergeVar {
  /** @format email */
  rcpt: string;
  vars: MergeVarContent[];
  lead_id?: string | null;
}

export interface MergeVarContent {
  name: string;
  content?: string | number | boolean | string | null;
}

export enum MessageType {
  SMS = "SMS",
  EMAIL = "EMAIL",
}

export interface MinimalApplicant {
  role: ApplicantRole;
  pni: string;
}

export interface Mortgage {
  Document?: string | null;
  DocumentNumber?: string | null;
  DocumentYear?: string | null;
  IdentityName?: string | null;
  IdentityNumber?: string | null;
  IdentityType?: IdentityTypeType | null;
  IsRegisteredElectronically?: boolean | null;
  IsUncertain?: boolean | null;
  LegalDocumentNumber?: string | null;
  LegalDocumentType?: LegalDocumentTypeType | null;
  LienType?: LienTypeType | null;
  MortgageInfoAsText?: string | null;
  NumEstatesMortgageApplyTo?: string | null;
  OfficeNumber?: string | null;
  Priority?: string | null;
  RegisteredDate?: string | null;
  Share?: number | null;
  UncertainReason?: UncertainReasonType | null;
  Value?: number | null;
}

export interface MortgageBankEventsResponse {
  bank_events: BankEventsMortgageOfferExt[];
}

export interface MortgageDeactivatePayload {
  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;
  reason: AppsmgrMortgageModelsApplicationDeactivationReason;
}

export interface MortgageDeactivationReviewsResponse {
  reasons: AppsmgrMortgageModelsApplicationDeactivationReason[];
}

export interface MortgageFormPayload {
  givenName: string;
  lastName: string;
  phoneNumber: string;

  /** @format email */
  email: string;
  bankName: string;
  loanAmount: number;
}

export interface MortgageGoal {
  type_event: GoalType;
  user_id?: (string | string | string)[] | null;
  country: Country;

  /** What year the goal is set for */
  year: number;

  /** Month from 1-12 */
  month: number;

  /** @format date-time */
  timestamp: string;
}

export interface MortgageLeads {
  amount: number;
}

export interface MortgageLoan {
  bank_id?: number | null;
  monthly_cost?: number | null;
  loan_amount?: number | null;
  refinance?: boolean | null;
  responsibility?: any | ResponsibilityOutput | null;
  rate?: number | null;
}

export enum MortgageLoanType {
  REFINANCE = "REFINANCE",
  PRE_APPROVAL = "PRE_APPROVAL",
  BUY = "BUY",
  BUY_AND_SELL = "BUY_AND_SELL",
}

export interface MortgageOfferExt {
  offeredAmount: number;
  currency: Currency;
  bindingPeriodMonth: number;
  interestRate: number;
  discount: number;
  amortizationRule: AmortizationRule;
  monthlyAmortization: number;
  invoiceFee?: number | null;
  setupFee?: number | null;
  autogiroFee?: number | null;
  amortizationLength?: number | null;
  maxOfferedAmount?: number | null;
  mustRefinance?: number | null;
  effectiveInterestRate?: number | null;
  monthlyPayment?: number | null;
  arrangementFee?: number | null;
  termFee?: number | null;
  termMonths?: number | null;
  downPaymentLoanAmount?: number | null;
  downPaymentLoanInterestRate?: number | null;
  bridgeLoanAmount?: number | null;
  bridgeLoanInterestRate?: number | null;
  firstMonthlyPayment: number;
}

export enum MortgagePropertyType {
  HOUSE = "HOUSE",
  CONDOMINIUM = "CONDOMINIUM",
  VACATION_HOME = "VACATION_HOME",
}

export interface MortgageRates {
  /** TODO */
  loanAmount?: number | null;

  /** TODO */
  marketValue?: number | null;

  /** TODO */
  bindingPeriodMonth?: number | null;

  /** TODO */
  interestRateAfterDiscount?: number | null;

  /** TODO */
  interestRate?: number | null;

  /** TODO */
  monthlyAmortization?: number | null;
}

export interface Municipality {
  id: string;

  /** Used to get the correct table */
  key: string;
  description: string;
  municipality_code: string;
  country: Country;
}

export interface MyDisbursedLoans {
  amount: number;
  sum: number;
  average_sum: number;
  currency: Currency;
  average_rate?: number | null;
  average_year?: number | null;
  average_disbursed_time?: number | null;
}

export interface MyStats {
  accepted?: AcceptedLoans | null;
  disbursed?: MyDisbursedLoans | null;
  insurance?: AllInsurances | null;
  mortgage_leads?: MortgageLeads | null;
}

export interface MyStatsMb {
  applications: number;
  applications_per_day: number;
  sent_loans: number;
  signed: number;
  disbursed: number;
  co_applicants: number;
  disbursed_amount: number;
  signed_amount: number;
  sent_amount: number;
  co_applicant_percentage: number;
  disbursement_rate: number;
  average_team_applications: number;
  average_team_disbursed: number;
  currency: Currency;
}

export interface NOApplicationForm {
  /** @min 0 */
  "example-loan-payback-time"?: number;

  /** @min 0 */
  "loan-amount-text-field"?: number;

  /** @min 0 */
  "total-loan-amount"?: number;

  /** @min 0 */
  slider?: number;
  "refinance-question"?: boolean;
  "refinance-amount"?: number;
  "original-loan-purpose"?: string | null;
  "marital-status"?: MaritalStatus | null;
  "number-of-children"?: number | null;
  "rent-part"?: string | null;
  "employment-type"?: EmploymentType;
  employer?: string | null;
  "employed-year"?: string | null;
  "employed-month"?: string | null;
  "employed-to-year"?: string | null;
  "employed-to-month"?: string | null;
  "company-industry"?: string | null;
  email?: string | null;
  ssn?: string;
  "email-marketing"?: boolean;
  "sms-marketing"?: boolean;
  "customer-club-marketing"?: boolean;
  "applicant-pep"?: boolean;
  "ms-enabled"?: boolean;
  "ms-marital-status"?: MaritalStatus | null;
  "ms-number-of-children"?: string | null;
  "ms-rent-part"?: number | null;
  "ms-employment-type"?: EmploymentType | null;
  "ms-employer"?: string | null;
  "ms-employed-year"?: string | null;
  "ms-employed-month"?: string | null;
  "ms-employed-to-year"?: string | null;
  "ms-employed-to-month"?: string | null;
  "ms-company-industry"?: string | null;
  "ms-email"?: string | null;
  "ms-ssn"?: string | null;
  "ms-applicant-pep"?: boolean | null;
  "is-new-loan-form"?: boolean | null;
  credits?: FormCredit[] | null;
  "loan-purpose"?: LoanPurposeNO;
  "norsk-statsborger"?: boolean | null;
  "lived-in-country-since-year"?: string | null;
  "lived-in-country-since-month"?: string | null;
  "housing-status"?: AppsmgrBlancoNoSpecPrivateUnsecuredLoanApplicationCreatedHousingType | null;
  "housing-type"?: HousingTypeNO | null;
  "phone-number"?: string | null;

  /** @min 0 */
  "salary-yearly"?: number;

  /** @min 0 */
  "other-income"?: number;
  "partners-income-amount"?: number | null;
  "ms-norsk-statsborger"?: boolean | null;
  "ms-lived-in-country-since-year"?: string | null;
  "ms-lived-in-country-since-month"?: string | null;
  "ms-housing-status"?: AppsmgrBlancoNoSpecPrivateUnsecuredLoanApplicationCreatedHousingType | null;
  "ms-housing-type"?: HousingTypeNO | null;
  "ms-phone-number"?: string | null;

  /** @min 0 */
  "ms-salary-yearly"?: number;

  /** @min 0 */
  "ms-other-income"?: number;
}

export interface Name {
  name: string;
}

export interface NewAppOption {
  id: string;
  options: string[];
}

export interface NewMortgageLoan {
  mortgage_loan_amount?: number | null;
  down_payment?: number | null;
  down_payment_loan_amount?: number | null;
  bridge_loan_amount?: number | null;
  disbursement_date?: string | null;
  applying_with?: ApplyingWith | null;
  loan_purpose?: MortgageLoanType | null;
  intention_of_excess_amount?: IntentionOfExcessAmount | null;
  monthly_cost_of_excess_amount?: number | null;
  max_amount?: boolean | null;
  situation?: Situation | null;
  private_loan_amount?: number | null;
}

export interface NewPartialProperty {
  property_type: MortgagePropertyType;
  country?: string | null;
  monthly_cost?: number | null;
  square_meter_living_area?: number | null;
  estimated_value?: number | null;
  municipality?: string | null;
  municipality_code?: string | null;
}

export enum NoContactOutcomes {
  NotConnected = "not_connected",
  Busy = "busy",
  AnsweringMachine = "answering_machine",
  NoAnswer = "no_answer",
}

export interface Note {
  cause_description?: string | null;
  year?: string | null;
}

export interface Notification {
  type: AppsmgrModelsNotificationsNotificationType;
  state_labels?: AppStateLabel[] | null;

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  app_id: string;

  /** @format date-time */
  when: string;
  product: Product;
  country: Country;
  bank_id: number;
  customer_ids?: string[] | null;
  pni?: string | null;
  online?: boolean | null;
}

export enum NotificationEvent {
  CREATED = "CREATED",
  RECEIVED = "RECEIVED",
  READ = "READ",
  DELETED = "DELETED",
}

export interface OTPValidation {
  /** @format password */
  session_id: string;

  /** @format password */
  otp: string;
}

export interface OTPValidationResp {
  token: string;
}

export interface Offer {
  id: string;
  bank_id: number;
  status_id: number;
  rate?: number;
  sum?: number | null;
  status_name: string;
  external_offer_id?: string | null;
  amortization_plan: string;
  sum_to_solve?: number | null;
  monthly_payment: number;
  admin_fee?: number | null;
  setup_fee?: number | null;
  apr?: number | null;

  /** @format date-time */
  created_at: string;
  need_more_info?: boolean | null;

  /** Payback time in months */
  payback_time: number;
  direct_debit_fee?: number;
  is_accepted?: boolean | null;
  [key: string]: any;
}

export interface OfferReceived {
  type: "OFFER_RECEIVED";

  /** @format date-time */
  timestamp: string;
  status: NotificationEvent;
  data: OfferReceivedData;
  notification_id: string;
  user_id: string | string | string | null;
}

export interface OfferReceivedData {
  application_id: string;
}

export enum OfferStatus {
  Approved = "approved",
  Created = "created",
  Edited = "edited",
  Rejected = "rejected",
  Ongoing = "ongoing",
}

export interface OnlineFormEventData {
  event_type: OnlineFormEventType;
  event_subtype?: OnlineFormEventSubType | null;
  event_meta?: object | null;
  application_id?: string | null;
  form_id?: string | null;
  session_id?: string | null;
  device_id?: string | null;
  device_meta?: object | null;
  user_agent?: string | null;
  event_description?: string | null;
}

export enum OnlineFormEventSubType {
  VALIDATION = "VALIDATION",
  BUTTON = "BUTTON",
  START = "START",
  FORWARD = "FORWARD",
  BACKWARD = "BACKWARD",
  SUCCESS = "SUCCESS",
}

export enum OnlineFormEventType {
  ERROR = "ERROR",
  CLICK = "CLICK",
  NAVIGATION = "NAVIGATION",
  SUBMISSION = "SUBMISSION",
  UPDATE = "UPDATE",
}

export interface OnlineFormsEvent {
  /** @format date-time */
  timestamp: string;
  filling_id?: string | null;
  submission_id?: string | null;
  domain?: string | null;
  uri?: string | null;
  ip?: string | null;
  zid?: string | null;
  git_sha?: string | null;
  data?: OnlineFormEventData | null;
}

export interface OnlineFormsTestingPNIResponse {
  se: string[];
  no: string[];
}

export interface Option {
  id: string;
  option: string;
}

export interface OptionalAffiliateApplicant {
  /** Personal national identifier fo the customer */
  pni: string;
  role: AffiliateApplicantRole;
  occupation: AffiliateEmployer | AffiliateNotEmployed;
  incomes: AffiliateIncome[];
  phone_number: string;

  /** @format email */
  email: string;

  /** How does the person live */
  currently_living_in?: AffiliateHousingType | null;

  /** Marital status of a person */
  marital_status: AffiliateMaritalStatus;
}

export interface OptionalAffiliateApplicantBlanco {
  /** Personal national identifier fo the customer */
  pni: string;
  role: AffiliateApplicantRole;
  occupation: AffiliateEmployer | AffiliateNotEmployed;
  incomes: AffiliateIncome[];
  phone_number: string;

  /** @format email */
  email: string;

  /** How does the person live */
  currently_living_in: AffiliateHousingType;

  /** Marital status of a person */
  marital_status: AffiliateMaritalStatus;

  /**
   * How much does the person pay for their housing, full SEK
   * @min 0
   */
  currently_living_in_cost: number;
}

export interface OptionalApplicantNO {
  country?: "NO";
  pni?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  email?: string | null;
  phone_number?: string | null;
  additional_phone_numbers?: string[] | null;
  monthly_property_cost?: number | null;
  marital_status?: MaritalStatus | null;

  /** Official marital status from ID check; should not be modified; marital_status has higher priority */
  registered_marital_status?: MaritalStatus | null;
  post_code?: string | null;
  city?: string | null;
  incomes?: Income[] | null;
  net_monthly_income?: number | null;
  account?: Account | null;
  street_address?: string | null;

  /** Number of children to pay child support for */
  paying_child_support?: number | null;

  /** Number of children to receive child support for */
  receiving_child_support?: number | null;
  consents?: Consents | null;
  consents_popup_dismissed_timestamp?: string | null;
  partner_pni?: string | null;
  partner_name?: string | null;
  municipality_code?: string | null;
  municipality?: string | null;
  is_pep?: boolean | null;
  age?: number | null;
  other_phones?: AppsmgrModelsPhonePhoneNumber[] | null;
  sverker_id?: number | null;
  children?: number | null;
  citizen?: number | null;
  education?: EducationType | null;
  monthly_partner_income?: number | null;
  immigrated_at?: string | null;
  fullname?: string | null;
  documents?: ApplicantDocument[] | null;
  verified_credits?: boolean | null;
  housing_since?: string | null;
  deceased?: boolean | null;
  deceased_date?: string | null;
  emigrated?: boolean | null;
  is_sanction?: boolean | null;
  is_rca?: boolean | null;
  date_created?: string | null;
  date_updated?: string | null;
  last_credit_check?: string | null;
  last_externally_verified_credits?: string | null;
  last_property_valuation?: string | null;
  pep_quality?: boolean | null;

  /** A cluster is a group of customers displayed with a color */
  cluster?: string | null;

  /** Indicates if the customer is blocked */
  is_blocked?: boolean;
  housing_type?: HousingTypeNO | null;
  employer?: OptionalEmployerNO | null;
  ev_estate?: EvEstate[] | null;
  ev_estates?: EvProperty[] | null;
  citizenship_iso_codes?: string[] | null;
  externally_verified_credits?: ExternallyVerifiedCredit[] | null;
  customer_id?: string | null;
  credit_check?: CreditCheckNO | null;
  external_block_check?: ExternalBlockCheckNO | null;
}

export interface OptionalApplicantSE {
  country?: "SE";
  pni?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  email?: string | null;
  phone_number?: string | null;
  additional_phone_numbers?: string[] | null;
  monthly_property_cost?: number | null;
  marital_status?: MaritalStatus | null;

  /** Official marital status from ID check; should not be modified; marital_status has higher priority */
  registered_marital_status?: MaritalStatus | null;
  post_code?: string | null;
  city?: string | null;
  incomes?: Income[] | null;
  net_monthly_income?: number | null;
  account?: Account | null;
  street_address?: string | null;

  /** Number of children to pay child support for */
  paying_child_support?: number | null;

  /** Number of children to receive child support for */
  receiving_child_support?: number | null;
  consents?: Consents | null;
  consents_popup_dismissed_timestamp?: string | null;
  partner_pni?: string | null;
  partner_name?: string | null;
  municipality_code?: string | null;
  municipality?: string | null;
  is_pep?: boolean | null;
  age?: number | null;
  other_phones?: AppsmgrModelsPhonePhoneNumber[] | null;
  sverker_id?: number | null;
  children?: number | null;
  citizen?: number | null;
  education?: EducationType | null;
  monthly_partner_income?: number | null;
  immigrated_at?: string | null;
  fullname?: string | null;
  documents?: ApplicantDocument[] | null;
  verified_credits?: boolean | null;
  housing_since?: string | null;
  deceased?: boolean | null;
  deceased_date?: string | null;
  emigrated?: boolean | null;
  is_sanction?: boolean | null;
  is_rca?: boolean | null;
  date_created?: string | null;
  date_updated?: string | null;
  last_credit_check?: string | null;
  last_externally_verified_credits?: string | null;
  last_property_valuation?: string | null;
  pep_quality?: any;

  /** A cluster is a group of customers displayed with a color */
  cluster?: string | null;

  /** Indicates if the customer is blocked */
  is_blocked?: boolean;
  housing_type?: HousingTypeSE | null;
  employer?: OptionalEmployerSE | null;
  census_registration_year?: string | null;
  credit_regulation_information?: CreditRegulationInformation[] | null;
  credit_regulations?: CreditRegulation[] | null;
  case_collection?: CaseCollectionItem[] | null;
  current_section_of_case?: CurrentSectionOfCaseItem[] | null;
  current_frame?: CurrentFrameItem[] | null;
  html?: string | null;
  last_queries?: LastQuery[] | null;
  notes?: Note[] | null;
  tax?: TaxItem[] | null;
  tax_thousands?: TaxThousand[] | null;
  fraudster?: boolean | null;
  investigation_barrier?: boolean | null;
  uc_interall?: string | null;
  date_of_birth?: string | null;
  prenup_text?: string | null;
  uc_internal_2?: any | null;
  parish?: string | null;
  parish_code?: string | null;
  blocked?: boolean | null;
  protected_address?: boolean | null;
  protected_identity?: boolean | null;
  marital_status_date?: string | null;
  minor?: string | null;
  lost_id_documents?: boolean | null;
  county?: string | null;
  county_code?: string | null;
  customer_id?: string | null;
  credit_check?: CreditCheckSE | null;
}

/**
 * Model used only for mapping jotform data to Applicant model, allowing some more fields to be optional
 */
export interface OptionalCreateApplicantNO {
  country?: "NO";
  pni: string | null;
  first_name?: string | null;
  last_name?: string | null;
  email?: string | null;
  phone_number?: string | null;
  additional_phone_numbers?: string[] | null;
  monthly_property_cost?: number | null;
  marital_status?: MaritalStatus | null;

  /** Official marital status from ID check; should not be modified; marital_status has higher priority */
  registered_marital_status?: MaritalStatus | null;
  post_code?: string | null;
  city?: string | null;
  incomes?: Income[] | null;
  net_monthly_income?: number | null;
  account?: Account | null;
  street_address?: string | null;

  /** Number of children to pay child support for */
  paying_child_support?: number | null;

  /** Number of children to receive child support for */
  receiving_child_support?: number | null;
  consents?: Consents | null;
  consents_popup_dismissed_timestamp?: string | null;
  partner_pni?: string | null;
  partner_name?: string | null;
  municipality_code?: string | null;
  municipality?: string | null;
  is_pep?: boolean | null;
  age?: number | null;
  other_phones?: AppsmgrModelsPhonePhoneNumber[] | null;
  sverker_id?: number | null;
  children?: number | null;
  citizen?: number | null;
  education?: EducationType | null;
  monthly_partner_income?: number | null;
  immigrated_at?: string | null;
  fullname?: string | null;
  documents?: ApplicantDocument[] | null;
  verified_credits?: boolean | null;
  housing_since?: string | null;
  deceased?: boolean | null;
  deceased_date?: string | null;
  emigrated?: boolean | null;
  is_sanction?: boolean | null;
  is_rca?: boolean | null;
  date_created?: string | null;
  date_updated?: string | null;
  last_credit_check?: string | null;
  last_externally_verified_credits?: string | null;
  last_property_valuation?: string | null;
  pep_quality?: boolean | null;

  /** A cluster is a group of customers displayed with a color */
  cluster?: string | null;

  /** Indicates if the customer is blocked */
  is_blocked?: boolean;
  housing_type?: HousingTypeNO | null;
  employer?: OptionalEmployerNO | null;
}

export interface OptionalCreditCardApplicationDataCreate {
  desired_credit_limit?: number | null;
  car?: boolean | null;
  student_loans_amount?: number | null;
  student_loans_monthly_payment?: number | null;
  car_boat_mc_loans_amount?: number | null;
  car_boat_mc_loans_monthly_payment?: number | null;
  total_consumer_loans_amount?: number | null;
  total_consumer_loans_monthly_payment?: number | null;
  mortgage_loans_amount?: number | null;
  mortgage_loans_monthly_payment?: number | null;
  credit_card_debt_amount?: number | null;
  credit_card_debt_monthly_payment?: number | null;
  children_in_the_household_fulltime?: number | null;
}

export interface OptionalEmployerNO {
  name?: string | null;
  type?: EmploymentTypeNO | null;
  start_date?: string | null;
  end_date?: string | null;
  phone?: string | null;
  retirement_age?: number | null;
  organization_number?: string | null;
  profession?: string | null;
}

export interface OptionalEmployerSE {
  name?: string | null;
  type?: EmploymentTypeSE | null;
  start_date?: string | null;
  end_date?: string | null;
  phone?: string | null;
  retirement_age?: number | null;
  organization_number?: string | null;
  profession?: string | null;
}

export interface OptionalPropertyAddress {
  /** Street address of property  */
  streetAddress?: string | null;

  /** City of the property */
  city?: string | null;

  /** Postal code of the property */
  postalCode?: string | null;
  municipality?: string | null;
}

export interface OptionalQuickApplicant {
  /** Personal national identifier fo the customer */
  pni?: string | null;
  role?: ApplicantRole | null;
  employer?: OptionalQuickApplicantEmployer | null;
  incomes?: (Income | null)[] | null;
  phone_number?: string | null;
  email?: string | null;

  /** How does the person live */
  housing_type?: HousingTypeSE | HousingTypeNO | null | (HousingTypeSE & HousingTypeNO);

  /** How much does the person pay for their housing, full SEK */
  currently_living_in_cost?: number | null;

  /** Marital status of a person */
  marital_status?: MaritalStatus | null;
  education?: EducationType | null;

  /** NORWAY only - when immigrated to Norway */
  immigrated_at?: string | null;

  /** NORWAY only - is applicant Norway citizen or not */
  citizenship?: boolean | null;
}

export interface OptionalQuickApplicantEmployer {
  name?: string | null;
  type?: EmploymentType | any | null;
  start_date?: string | null;
  end_date?: string | null;
  phone?: string | null;
  retirement_age?: number | null;
  organization_number?: string | null;
  profession?: string | null;
}

export interface OptionalQuickChildren {
  /** Number of children under the age of 18 */
  childrenBelow18?: number | null;
  childrenBelow18FullTime?: number | null;
  receivingChildSupport?: number | null;
  payingChildSupport?: number | null;
}

export interface OptionalQuickCreateBlancoApplication {
  product: "blanco";
  new_loan?: BlancoLoan | null;

  /** Sverker user when creating a legacy blanco app */
  sverker_user?: SverkerUsername | null;
  aml?: BlancoAMLPatchPayload | null;

  /** Desired loan amount */
  desired_amount?: number | null;
  country: Country;
  meeting_start?: string | null;
  meeting_end?: string | null;
  meeting_subject?: string | null;
  should_remind_about_meeting?: boolean | null;
  applicants: OptionalQuickApplicant[];
  loans?: Loan[] | null;
  children?: OptionalQuickChildren | null;
  internal_comment?: string | null;
  comments?: string[] | null;
  new_app_options?: Option[] | null;
  new_app_checklist?: string[] | null;
  source?: ApplicationSource | null;
  desired_payback_time?: number | null;
  consents?: Consents | null;
}

export interface OptionalQuickCreateMortgageApplication {
  product: "mortgage";
  new_loan?: BlancoLoan | null;

  /** Desired loan amount */
  desired_amount?: number | null;
  country: Country;
  meeting_start?: string | null;
  meeting_end?: string | null;
  meeting_subject?: string | null;
  should_remind_about_meeting?: boolean | null;
  applicants: OptionalQuickApplicant[];
  loans?: Loan[] | null;
  children?: OptionalQuickChildren | null;
  internal_comment?: string | null;
  comments?: string[] | null;
  new_app_options?: Option[] | null;
  new_app_checklist?: string[] | null;
  source?: ApplicationSource | null;
  desired_payback_time?: number | null;
  consents?: Consents | null;
}

export interface Order {
  created_at?: "asc" | "desc" | null;
  updated_at?: "asc" | "desc" | null;
}

export interface OtherProperty {
  monthly_cost: number;
  mortgage_amount?: number | null;
  property_type: PropertyTypeOutput;
  estimated_value?: number | null;
  property_ownership_share?: number | null;
  loan_ownership_share?: number | null;
  interest_rate?: number | null;
  monthly_amortization?: number | null;
  lender?: string | null;
}

export interface OutcomeCount {
  users: UserOutcomeCount[];
  total: UserOutcomeCount;
}

export enum OutcomeType {
  SUCCESS = "SUCCESS",
  CALL_BACK = "CALL_BACK",
  NO_CONTACT = "NO_CONTACT",
  FAILURE = "FAILURE",
  DISCARD = "DISCARD",
}

export interface Outcomes {
  no_contact: string[];
  failure: string[];
  discard: string[];
}

export interface PNI {
  country?: Country | null;
  pni: string;
}

export interface PageCampaignExtraction {
  items: CampaignExtraction[];
  total: number | null;
  page: number | null;
  size: number | null;
  pages?: number | null;
}

export interface PageCampaign {
  items: ModelsDialMaker2CampaignAggregateCampaign[];
  total: number | null;
  page: number | null;
  size: number | null;
  pages?: number | null;
}

export interface PageCreditCardApplicationExtended {
  items: CreditCardApplicationExtended[];
  total: number | null;
  page: number | null;
  size: number | null;
  pages?: number | null;
}

export interface PageDMLead {
  items: DMLead[];
  total: number | null;
  page: number | null;
  size: number | null;
  pages?: number | null;
}

export interface PageSheet {
  items: Sheet[];
  total: number | null;
  page: number | null;
  size: number | null;
  pages?: number | null;
}

export interface Pagination {
  page?: number | null;
  limit?: number | null;
}

export interface PartialCondominium {
  property_type: "CONDOMINIUM";
  country: Country;
  street_address?: string | null;
  post_code?: string | null;
  city?: string | null;
  other_owner?: boolean | null;
  monthly_cost?: number | null;
  square_meter_living_area?: number | null;
  municipality?: string | null;
  municipality_code?: string | null;
  estimated_value?: number | null;
  external_id?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  ev_partial?: EvPartialCommon | null;
  apartment_number?: string | null;
  elevator?: boolean | null;
  organization_number?: string | null;
  organization_name?: string | null;
  floor?: number | null;
  balcony_type?: BalconyType | null;
  rooms?: number | null;
}

export interface PartialHouse {
  property_type: "HOUSE";
  country: Country;
  street_address?: string | null;
  post_code?: string | null;
  city?: string | null;
  other_owner?: boolean | null;
  monthly_cost?: number | null;
  square_meter_living_area?: number | null;
  municipality?: string | null;
  municipality_code?: string | null;
  estimated_value?: number | null;
  external_id?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  ev_partial?: EvPartialCommon | null;
  cadastral_designation?: string | null;
}

export interface PartialProperty {
  property_id: string;
  property_type: MortgagePropertyType;
  country?: string | null;
  monthly_cost?: number | null;
  square_meter_living_area?: number | null;
  estimated_value?: number | null;
  municipality?: string | null;
  municipality_code?: string | null;
}

export interface PartialVacationHome {
  property_type: "VACATION_HOME";
  country: Country;
  street_address?: string | null;
  post_code?: string | null;
  city?: string | null;
  other_owner?: boolean | null;
  monthly_cost?: number | null;
  square_meter_living_area?: number | null;
  municipality?: string | null;
  municipality_code?: string | null;
  estimated_value?: number | null;
  external_id?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  ev_partial?: EvPartialCommon | null;
  cadastral_designation?: string | null;
}

/**
 * These fields only appear in pipeline
 */
export interface Person {
  zid: string;
  session_zid?: string | null;
  pni?: string | File | null;
  customer_id?: string | null;
  gid?: string | null;
  affiliate_id?: string | null;
  product?: Product | null;
  country?: Country | null;
  landing_params?: object | null;
  landing_url?: string | null;
  cookie_consent?: object | null;
  google_session_id?: number | null;
  ip_address?: string | File | null;
  status: PersonStatus;

  /** @format date-time */
  last_activity: string;

  /** @format date-time */
  initialized_at: string;
  last_application_created_at?: string | null;
  last_disbursed_at?: string | null;
}

export enum PersonStatus {
  INITIALIZED = "INITIALIZED",
  WEBSITE_VISIT = "WEBSITE_VISIT",
  LEAD_CREATED = "LEAD_CREATED",
  APPLICATION_CREATED = "APPLICATION_CREATED",
  INVITE = "INVITE",
  OFFER_ACCEPTED = "OFFER_ACCEPTED",
  OFFER = "OFFER",
  DISBURSED = "DISBURSED",
  DISCARDED = "DISCARDED",
  INSURANCE_CREATED = "INSURANCE_CREATED",
}

export interface PersonalCallback {
  /** @format date-time */
  first_at: string;

  /** @format date-time */
  last_at: string;
  count: number;
}

export interface PhoneGetCustomerPayload {
  reason: string;
  phone_number: string;
}

export enum PhoneNumberType {
  Home = "home",
  Day = "day",
  Work = "work",
  Other = "other",
}

export interface PniGetCustomerPayload {
  reason: string;
  country?: Country | null;
  pni: string;
}

export enum PolicyPreference {
  EXCLUDE = "EXCLUDE",
  INCLUDE = "INCLUDE",
  DONTCARE = "DONTCARE",
}

export interface PollResp {
  verified: boolean;
  timestamp?: string | null;
}

export interface PrefsResponse {
  statuses?: AppsmgrModelsStatusStatus[];
  substatuses?: Substatus[];
  countries?: Country[];
  property_types?: MortgagePropertyType[];
  new_app_checklist: Record<string, Record<string, string[]>>;
  new_app_options: Record<string, Record<string, NewAppOption[]>>;
  loan_types?: LoanType[];
}

export interface PreviousApplication {
  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;
  status?: Substatus | AppState | null | (Substatus & AppState);
  role?: ApplicantRole | null;
  created_at?: string | null;
  completed_at?: string | null;
}

export interface PreviousApplicationsList {
  mortgage: PreviousApplication[];
  blanco: PreviousApplication[];
  credit_card: PreviousApplication[];
}

export interface ProcessCampaignSource {
  type?: "PROCESS";
  country: Country;

  /** Used only for Dial Maker 2.0 for now, but can be used for other non-banking products in the future if needed */
  product: ExtendedProduct;
  process: ProcessCampaignType;
  priority?: number;
  substatus?: InsuranceSubstatus | null;
}

export enum ProcessCampaignType {
  QUEUED_FOR_AUTO_INVITE = "QUEUED_FOR_AUTO_INVITE",
  INVITES_SENT = "INVITES_SENT",
  NO_INVITES_SENT = "NO_INVITES_SENT",
  OFFERED = "OFFERED",
  OFFERED_BUT_NO_IMPROVEMENT_TO_CURRENT_LOAN_SITUATION = "OFFERED_BUT_NO_IMPROVEMENT_TO_CURRENT_LOAN_SITUATION",
  NO_OFFERS = "NO_OFFERS",
  NO_OFFERS_BUT_CAN_BE_FIXED = "NO_OFFERS_BUT_CAN_BE_FIXED",
  NOACCEPTAFTER5DAYS = "NO_ACCEPT_AFTER_5_DAYS",
  ACCEPTED_BAD_OFFER = "ACCEPTED_BAD_OFFER",
  ACCEPTED_GOOD_OFFER = "ACCEPTED_GOOD_OFFER",
  REJECTED = "REJECTED",
  REJECTED_HAS_FIXABLE_REMARKS = "REJECTED_HAS_FIXABLE_REMARKS",
  LATE_REJECTED = "LATE_REJECTED",
  DISBURSED = "DISBURSED",
  DISBURSEDNOCONFIRMATIONFROMCREDITORAFTER5DAYS = "DISBURSED_NO_CONFIRMATION_FROM_CREDITOR_AFTER_5_DAYS",
  DEACTIVATED = "DEACTIVATED",
  ABOUT_TO_BE_CANCELED = "ABOUT_TO_BE_CANCELED",
  CANCELED = "CANCELED",
}

export enum Product {
  Blanco = "blanco",
  Mortgage = "mortgage",
  CreditCard = "credit_card",
}

export interface PropertyBeingSold {
  selling_contract_signed?: boolean | null;
  property_price?: number | null;
  existing_mortgage_amount?: number | null;
  real_estate_agent_fee?: number | null;
}

export interface PropertyInfo {
  property_type: MortgagePropertyType;
  id: string;
  apartments?: Apartment[] | null;
  street_address: string;
  post_code: string;
  city: string;
  country: Country;
  municipality_code: string;
  municipality: string;
  organization_name?: string | null;
  organization_number?: string | null;
  external_id?: string | null;
}

export interface PropertySearchResult {
  id: string;
  description: string;
}

export enum PropertyTypeOutput {
  HOUSE = "HOUSE",
  CONDOMINIUM = "CONDOMINIUM",
  VACATION_HOME = "VACATION_HOME",
  RENTING = "RENTING",
}

export interface PropertyValuation {
  property_type: MortgagePropertyType;
  property_id: string;
  living_area?: number | null;
  apartment_number?: string | null;
  external_id?: string | null;
  municipality: string;
  municipality_code: string | null;
  square_meter_living_area: number;
  latitude?: number | null;
  longitude?: number | null;
  value?: number | null;
  booli_value?: number | null;
  [key: string]: any;
}

export interface PublicApplicant {
  first_name?: string | null;
  last_name?: string | null;
  email?: string | null;
  phone_number?: string | null;
  country: Country;
}

export interface PublisherData {
  publisher_id: string;
  callback_trigger: PersonStatus;
  touch: Touch;
  callback_time_limit_in_days: number;
  callback_trigger_blanco?: PersonStatus | null;
  callback_trigger_mortgage?: PersonStatus | null;
  callback_trigger_credit_card?: PersonStatus | null;
}

export interface QRCodeContent {
  qr_code_content: string;
  verified: boolean;
}

export interface QuickApplicant {
  /** Personal national identifier fo the customer */
  pni: string;
  role: ApplicantRole;
  employer: QuickApplicantEmployer;
  incomes: (Income | null)[];
  phone_number: string;

  /** @format email */
  email: string;

  /** How does the person live */
  housing_type: HousingTypeSE | HousingTypeNO | (HousingTypeSE & HousingTypeNO);

  /**
   * How much does the person pay for their housing, full SEK
   * @min 0
   */
  currently_living_in_cost: number;

  /** Marital status of a person */
  marital_status: MaritalStatus;
  education?: EducationType | null;

  /** NORWAY only - when immigrated to Norway */
  immigrated_at?: string | null;

  /** NORWAY only - is applicant Norway citizen or not */
  citizenship?: boolean | null;
  net_monthly_income?: number | null;
}

export interface QuickApplicantEmployer {
  name: string;
  type?: EmploymentType | any | null;
  start_date?: string | null;
  end_date?: string | null;
  phone?: string | null;
  retirement_age?: number | null;
  organization_number?: string | null;
  profession?: string | null;
}

export interface QuickChildren {
  /**
   * @min 0
   * @max 10
   */
  childrenBelow18: number;
  childrenBelow18FullTime?: number | null;
  receivingChildSupport?: number | null;
  payingChildSupport?: number | null;
}

export interface QuickCreateBlancoApplication {
  product: "blanco";
  new_loan?: BlancoLoan | null;

  /** Sverker user when creating a legacy blanco app */
  sverker_user?: SverkerUsername | null;
  aml?: BlancoAMLPatchPayload | null;

  /** Desired loan amount */
  desired_amount?: number | null;
  country: Country;
  meeting_start?: string | null;
  meeting_end?: string | null;
  meeting_subject?: string | null;
  should_remind_about_meeting?: boolean | null;
  applicants: QuickApplicant[];
  loans: Loan[];
  children: QuickChildren;
  internal_comment?: string | null;
  comments?: string[] | null;
  new_app_options: Option[];
  new_app_checklist: string[];
  source: ApplicationSource;
  desired_payback_time?: number | null;
  consents?: Consents | null;
}

export interface QuickCreateMortgageApplication {
  product: "mortgage";
  new_loan?: BlancoLoan | null;

  /** Desired loan amount */
  desired_amount?: number | null;
  country: Country;
  meeting_start?: string | null;
  meeting_end?: string | null;
  meeting_subject?: string | null;
  should_remind_about_meeting?: boolean | null;
  applicants: QuickApplicant[];
  loans: Loan[];
  children: QuickChildren;
  internal_comment?: string | null;
  comments?: string[] | null;
  new_app_options: Option[];
  new_app_checklist: string[];
  source: ApplicationSource;
  desired_payback_time?: number | null;
  consents?: Consents | null;
}

export interface RatePart {
  rate_type: any;
  amount?: number | null;
  rate?: number | null;
  monthly_payment?: number | null;
  until?: string | null;
  loanNumber?: string | null;
  conditionRolloverDate?: string | null;
  monthlyAmortisation?: number | null;
}

export enum RateType {
  Fixed = "fixed",
  Flexible = "flexible",
  Mixed = "mixed",
}

export interface ReactivatePayload {
  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;
  comment: string;
}

/**
* In SE we can only refinance one existing mortgage so this object is the loan to be refinanced,
not used in NO
*/
export interface RefinanceInfo {
  current_bank_id: number;
  amount?: number | null;
  rate?: number | null;
  monthly_payment?: number | null;
  rate_parts?: RatePart[] | null;
  rate_type?: any | null;
  ownershipShareOfLoan?: number | null;
  mortgageBank?: string | null;
  amortisationRuleBeforeMarch2018?: boolean | null;
}

export enum RelationshipStatus {
  Spouse = "spouse",
  Child = "child",
  Parent = "parent",
  Partner = "partner",
}

export interface RenderTemplatePayload {
  mandrill_name: string;
  template_content: MergeVarContent[];
  merge_vars: MergeVarContent[];
}

export interface ReprocessResult {
  lead_id: string;
  status: ReprocessStatus;
  error?: string | null;
  details?: string | null;
}

export enum ReprocessStatus {
  Success = "success",
  Failure = "failure",
  Pending = "pending",
}

export interface ReprocessSubmissionPayload {
  lead_ids: string[];
}

export interface RequestNewOTP {
  token: string;
}

export interface ResetPasswordPayload {
  user_id: string;
}

export enum ResponsibilityOutput {
  MAIN_APPLICANT = "MAIN_APPLICANT",
  CO_APPLICANT = "CO_APPLICANT",
  SHARED = "SHARED",
}

export interface ResubmitPayload {
  submission_id: string;
  country: Country;
  parsed_form:
  | (QuickCreateBlancoApplication | QuickCreateMortgageApplication)
  | CreateCreditCardApplicationPayloadWithApplicant
  | ((QuickCreateBlancoApplication | QuickCreateMortgageApplication) &
    CreateCreditCardApplicationPayloadWithApplicant);
}

export interface RetryPolicy {
  retry_count?: number;
  retry_interval_minutes?: number;
}

export interface ReviewSubmissions {
  /** List of submission ids */
  submission_ids: string[];

  /** Mark submissions as handled or not */
  handled?: boolean;
}

export interface Reviewer {
  user_id: string | string | string;

  /** @format date-time */
  timestamp: string;
}

export enum RoleType {
  EmployeeAdmin = "employee:admin",
  PartnerAdmin = "partner:admin",
  MortgageAdmin = "mortgage:admin",
  LegalAdmin = "legal:admin",
  ZensumSakerReadSalesStats = "zensum-saker:read:sales-stats",
  ZakerInsuranceCreate = "zaker:insurance:create",
  Admin = "admin",
  ImpersonateCustomer = "impersonate-customer",
  REMOTE_ACCESS = "REMOTE_ACCESS",
  READ_BLANCO_SALES_STATS = "READ_BLANCO_SALES_STATS",
  READ_MORTGAGE_SALES_STATS = "READ_MORTGAGE_SALES_STATS",
  InsuranceAdmin = "insurance:admin",
  OnlineAdmin = "online:admin",
  MbAdmin = "mb:admin",
  KdltAdmin = "kdlt:admin",
  DataAdmin = "data:admin",
  TemplateAdmin = "template:admin",
  AffiliateAdmin = "affiliate:admin",
  Developer = "developer",
  TmCampaignAdmin = "tm-campaign:admin",
  TmCampaignRead = "tm-campaign:read",
  DialmakerAdmin = "dialmaker:admin",
  TeamLead = "team-lead",
  MailmakerAdmin = "mailmaker:admin",
}

export type RootModelUnionEmailTemplateSmsTemplate = EmailTemplate | SmsTemplate | (EmailTemplate & SmsTemplate);

export type RootModelUnionMyStatsMyStatsMb = MyStats | MyStatsMb | (MyStats & MyStatsMb);

export type RootModelUnionSummarizedViewMyStatsMb = SummarizedView | MyStatsMb | (SummarizedView & MyStatsMb);

export interface RqJobStatusResponse {
  job_id?: string | null;
  queue?: string | null;
  created_at?: string | null;
  description?: string | null;
  exception_info?: string | null;
  retries_left?: number | null;
  retry_intervals?: number[] | null;
  retry_at?: string[] | null;
  status?: string | null;
  meta?: object | null;
  kwargs?: object | null;
}

export interface SEApplicationForm {
  /** @min 0 */
  "example-loan-payback-time"?: number;

  /** @min 0 */
  "loan-amount-text-field"?: number;

  /** @min 0 */
  "total-loan-amount"?: number;

  /** @min 0 */
  slider?: number;
  "refinance-question"?: boolean;
  "refinance-amount"?: number;
  "original-loan-purpose"?: string | null;
  "marital-status"?: MaritalStatus | null;
  "number-of-children"?: number | null;
  "rent-part"?: string | null;
  "employment-type"?: EmploymentType;
  employer?: string | null;
  "employed-year"?: string | null;
  "employed-month"?: string | null;
  "employed-to-year"?: string | null;
  "employed-to-month"?: string | null;
  "company-industry"?: string | null;
  email?: string | null;
  ssn?: string;
  "email-marketing"?: boolean;
  "sms-marketing"?: boolean;
  "customer-club-marketing"?: boolean;
  "applicant-pep"?: boolean;
  "ms-enabled"?: boolean;
  "ms-marital-status"?: MaritalStatus | null;
  "ms-number-of-children"?: string | null;
  "ms-rent-part"?: number | null;
  "ms-employment-type"?: EmploymentType | null;
  "ms-employer"?: string | null;
  "ms-employed-year"?: string | null;
  "ms-employed-month"?: string | null;
  "ms-employed-to-year"?: string | null;
  "ms-employed-to-month"?: string | null;
  "ms-company-industry"?: string | null;
  "ms-email"?: string | null;
  "ms-ssn"?: string | null;
  "ms-applicant-pep"?: boolean | null;
  "is-new-loan-form"?: boolean | null;
  credits?: FormCredit[] | null;
  "loan-purpose"?: LoanPurpose;
  "housing-status"?: AppsmgrBlancoSeSpecPrivateUnsecuredLoanApplicationCreatedHousingType | null;
  "housing-type"?: HousingTypeSE | null;
  "phone-number"?: string | null;

  /** @min 0 */
  salary?: number;
  "ms-housing-status"?: AppsmgrBlancoSeSpecPrivateUnsecuredLoanApplicationCreatedHousingType | null;
  "ms-housing-type"?: HousingTypeSE | null;
  "ms-phone-number"?: string | null;
  "ms-salary"?: number | null;
}

export interface SMSReceived {
  type: "SMS_RECEIVED";

  /** @format date-time */
  timestamp: string;
  status: NotificationEvent;
  data: SMSReceivedData;
  notification_id: string;
  user_id: string | string | string | null;
}

export interface SMSReceivedData {
  application_id?: string | null;
  customer_ids: string[];
}

export interface SampleVars {
  /** @format uuid */
  customer_id: string;
  template_vars: MergeVarContent[];
}

export interface SavedAttachment {
  file_id: string;
  name?: string | null;
}

export interface SavedMessage {
  message_type: MessageType;
  message_id: string;
  customer_id?: string | string | null;

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id?: string | string | null;
  request_id?: string | null;

  /** @format date-time */
  timestamp: string;
  direction: Direction;
  user_id?: string | string | string | null;
  country?: Country | null;
  product?: ExtendedProduct | null;
  event?: TemplateEvent | null;
  send_at?: string | null;
  message?: string | null;
  sender?: string | null;
  template_name?: string | null;
  template_vars?: object | null;
  customer_ids?: string[] | null;
}

export interface SavedMessageWithName {
  message_type: MessageType;
  message_id: string;
  customer_id?: string | string | null;

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id?: string | string | null;
  request_id?: string | null;

  /** @format date-time */
  timestamp: string;
  direction: Direction;
  user_id?: string | string | string | null;
  country?: Country | null;
  product?: ExtendedProduct | null;
  event?: TemplateEvent | null;
  send_at?: string | null;
  message?: string | null;
  sender?: string | null;
  template_name?: string | null;
  template_vars?: object | null;
  customer_ids?: string[] | null;
  sender_name: string;
}

export interface ScheduleSlot {
  /** @format time */
  start: string;

  /** @format time */
  end: string;

  /** @min 0 */
  available_slots_per_hour: number;
}

export interface SearchLeadsPayload {
  campaign_ids?: string[] | null;
  country?: Country | null;
  product?: ExtendedProduct | null;
  department?: ZDepartment | null;
  user_ids?: (string | string | string)[] | null;
  start?: string | null;
  end?: string | null;
  outcomes?: OutcomeType[] | null;
  failure_sub_outcomes?: FailureOutcomes[] | null;
  no_contact_sub_outcomes?: NoContactOutcomes[] | null;
  status?: LeadStatus | null;
  order?: "asc" | "desc";
  search?: string | null;
  exclude_unextracted?: boolean;
  exclude_null_campaign_id?: boolean;
}

export enum SearchType {
  APPLICATION_ID = "APPLICATION_ID",
  PORTAL_APP_ID = "PORTAL_APP_ID",
  PERSONAL_DATA = "PERSONAL_DATA",
  EXTERNAL_ID = "EXTERNAL_ID",
}

export interface SellingInfo {
  property_being_sold: PropertyBeingSold[];
}

export interface SendRawPayload {
  /** When MessageType.SMS, pass either phone_number or customer_id */
  customer_id?: string | null;

  /** Only for MessageType.SMS, pass either phone_number or customer_id */
  phone_number?: string | null;

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id_or_lead_id?: string | string | null;
  message: string;
  data?: RoutersMessagePayloadData | null;
  subject?: string | null;

  /** Only for MessageType.SMS without customer_id */
  country?: Country | null;
}

export interface SendTemplatePayload {
  /** When MessageType.SMS, pass either phone_number or customer_id */
  customer_id?: string | null;

  /** Only for MessageType.SMS, pass either phone_number or customer_id */
  phone_number?: string | null;

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id?: string | null;
  data?: RoutersMessagePayloadData | null;

  /** Only for MessageType.SMS without customer_id */
  country?: Country | null;

  /** Used only for Dial Maker 2.0 for now, but can be used for other non-banking products in the future if needed */
  product: ExtendedProduct;
}

export interface SentTemplateResponse {
  email: string;
  status: string;
  reject_reason?: string | null;
  queued_reason?: string | null;
  _id: string;
  merge_vars?: MergeVar | null;
}

export interface SetPassword {
  /** @format password */
  otp: string;
  token: string;

  /** @format password */
  password: string;
}

export interface SetPasswordResp {
  success: true;
}

export enum ShareNameConsent {
  NO_CONSENT = "NO_CONSENT",
  INITIALS_ONLY = "INITIALS_ONLY",
  FULL_NAMES = "FULL_NAMES",
}

export interface Sheet {
  /** @format date-time */
  uploaded_at: string;
  type: SheetType;
  name?: string;

  /** @format uuid */
  sheet_id: string;
  separator?: string | null;
  mapping?: Record<string, string>;
  uploaded_sheet_preview?: SheetPreviewBase | null;
  import_status?: ImportStatus;
  import_details?: InterimLeadsInsertion | null;
  import_error_msg?: string | null;
  imported_at?: string | null;
}

export interface SheetCampaignSource {
  type?: "SHEET";
  sheets: Sheet[];
}

export interface SheetPreview {
  headers: string[];
  model_fields: string[];
  preview: Record<string, string | Record<string, string | null> | null>[];

  /**
   * Sheet ID which is needed in extraction endpoint.
   * @format uuid
   */
  sheet_id: string;
  file_name: string;
  file_type: SheetType;

  /**
   * Date when file was uploaded.
   * @format date-time
   */
  uploaded_at: string;
}

export interface SheetPreviewBase {
  headers: string[];
  model_fields: string[];
  preview: Record<string, string | Record<string, string | null> | null>[];
}

export enum SheetType {
  XLSX = "XLSX",
  CSV = "CSV",
}

export enum Situation {
  SOLD = "SOLD",
  WILL_SELL = "WILL_SELL",
  ONLY_BUY = "ONLY_BUY",
}

export interface Slots {
  /** @format date-time */
  start_date: string;
}

export interface SmsTemplate {
  event: TemplateEvent;

  /** Used only for Dial Maker 2.0 for now, but can be used for other non-banking products in the future if needed */
  product: ExtendedProduct;
  country: Country;
  message_type?: MessageType;

  /** @format date-time */
  timestamp?: string;
  comment?: string | null;
  deleted?: boolean | null;
  name?: string | null;
  body: string;
}

export interface StatusType {
  Description?: string | null;
  Key?: string | null;
}

export interface SubmissionReview {
  submission_id: string;
  handled?: boolean | null;
  reviews?: Reviewer[];
  success?: boolean;
  ongoing?: boolean;

  /** @format date-time */
  submission_timestamp: string;
  raw_data: object;
  country: Country;
  product: Product;
  request_id: string;
  checklist?: Checklist;

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id?: string | null;
  version?: number | null;
  parsed_form?: NOApplicationForm | SEApplicationForm | null | (NOApplicationForm & SEApplicationForm);
  quick_application?: (OptionalQuickCreateMortgageApplication | OptionalQuickCreateBlancoApplication) | null;
  parsed_application?: JotformCCApplication | null;
  gid?: string | null;
  zid?: string | null;
  session_zid?: string | null;
  gid_list?: string | null;
  affiliate_id?: string | null;
  http_request_metadata?: object | null;
  cc_converted_blanco_app_id?: string | null;
  cc_conversion_status?: ConvertToBlancoStatus | null;
}

export interface SubmissionReviewResponse {
  submissions: BasicSubmissionReview[];
  count: number;
}

export enum SubmissionStatus {
  Error = "error",
  AppCreated = "app_created",
  AppNotAllowed = "app_not_allowed",
}

export enum Substatus {
  DRAFT = "DRAFT",
  UNKNOWN = "UNKNOWN",
  OFFERED = "OFFERED",
  ACCEPTED = "ACCEPTED",
  DISBURSED = "DISBURSED",
  ACTIVE = "ACTIVE",
  DEACTIVATED = "DEACTIVATED",
}

export interface SummarizedView {
  accepted?: AcceptedLoans | null;
  disbursed?: AllDisbursedLoans | null;
  insurance?: AllInsurances | null;
  mortgage_leads?: MortgageLeads | null;
}

export enum SverkerClearanceID {
  Type1 = "1",
  Type2 = "2",
  Type3 = "3",
  Type5 = "5",
  Type6 = "6",
  Type9 = "9",
  Type10 = "10",
}

export interface SverkerSearchApplicationResponse {
  id: number;
  created_at: string;
}

export interface SverkerSearchResponse {
  applications: SverkerSearchApplicationResponse[];
}

export interface SverkerUsername {
  clearance_id?: SverkerClearanceID | null;
  username: string;
}

export interface TMCampaign {
  query: string;
  country: Country;
  product: Product;
  department: ZDepartment;
  description: string;

  /** Number of days since last extraction to ignore customer_id */
  ignore_days_since_last_extraction?: number;
  campaign_id: string;

  /** @format date-time */
  created_at: string;
  created_by: string | string | string;

  /** @format date-time */
  updated_at: string;
  updated_by: string | string | string;
  active: boolean;
}

export interface TMCampaignList {
  total: number;
  data: TMCampaign[];
}

export interface TMLead {
  customer_id: string;
  tags?: object | null;

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  app_id: string;
}

export interface TMLeadsExtraction {
  leads: TMLead[];
  filtering_reasons: FilteringResult[];
  campaign_id: string;
  job_id: string;

  /** @format date-time */
  extraction_date: string;
  user_id?: string | string | string | null;
  request_id: string;
}

export interface TMLeadsJob {
  job_id: string;
  is_finished?: boolean;
  is_failed?: boolean;
}

export interface TakeCreditCheck {
  customer_id: string;
  reason: string;
}

export interface Tax {
  id: string;

  /** Used to get the correct table */
  key: string;
  description: string;
  municipality_code: string;
  country: Country;
}

export interface TaxDeduction {
  lower: number;
  upper_yearly_limit: number;
  upper: number;
}

export interface TaxItem {
  assessed_income?: string | null;
  assessed_wages?: string | null;
  capital_gains?: string | null;
  final_tax?: string | null;
  income_year?: string | null;
  remaining_tax?: string | null;
  total_income?: string | null;
  year?: string | null;
}

export interface TaxThousand {
  assessed_income_thousand?: string | null;
  assessed_wages_thousand?: string | null;
  capital_gains_thousand?: string | null;
  final_tax_thousand?: string | null;
  income_year?: string | null;
  remaining_tax_thousand?: string | null;
  taxed_income?: string | null;
  year?: string | null;
}

export enum TelavoxDirection {
  In = "in",
  Out = "out",
  Unknown = "unknown",
}

export interface TelavoxExtension {
  /** The extension number */
  extension: string;

  /** The name of the user */
  name: string;

  /** The email of the user */
  email: string;

  /** The mobile number of the user */
  mobile?: string | null;

  /** The profile of the user */
  profile?: TelavoxExtensionProfile | null;

  /** The current call of the user */
  calls?: TelavoxExtensionCall[] | null;

  /** The extension number in E164 format */
  extensionE164?: string | null;

  /** The mobile number in E164 format */
  mobileE164?: string | null;

  /** Keywords for the user */
  keywords?: string[] | null;
}

export interface TelavoxExtensionCall {
  /** The dialed number */
  callerid: string;
  direction: TelavoxDirection;
  linestatus: TelavoxLineStatus;
}

export interface TelavoxExtensionProfile {
  /** Is the user available for calls */
  available: boolean;

  /** Name of current status for telavox user */
  name: string;

  /** Time that the user is available until */
  until?: string | null;

  /** Message for current status set by user */
  message?: string | null;
}

export enum TelavoxLineStatus {
  Up = "up",
  Down = "down",
  Ringing = "ringing",
}

export interface TelavoxMismatch {
  no_id_set: TelavoxNoUserWithId[];
  no_name_match: TelavoxZrmMismatch[];
}

export interface TelavoxNoUserWithId {
  telavox_user: string;
  telavox_id: string;
}

export interface TelavoxZrmMismatch {
  full_name: string;
  telavox_full_name: string;
  user_id: string | string | string;
}

export interface TemplateDryRunResult {
  tested_leads: number;
  populated_leads: number;
  errors: TemplateVarsPopulateErrors;
}

export enum TemplateEvent {
  ApplicationCreated = "application_created",
  ApplicationCreatedReoccurringCustomer = "application_created_reoccurring_customer",
  NoBids = "no_bids",
  FullRejection = "full_rejection",
  FailedAutoInvite = "failed_auto_invite",
  FullLeadCreated = "full_lead_created",
  Missing2Payments = "missing_2_payments",
  MandateCancelled = "mandate_cancelled",
  LateDenial = "late_denial",
  BlancoAppCreation = "blanco_app_creation",
  FirstOffer = "first_offer",
  ReminderToAccept1 = "reminder_to_accept_1",
  ReminderToAccept2 = "reminder_to_accept_2",
  ReminderToAccept3 = "reminder_to_accept_3",
  NoAccept = "no_accept",
  FirstInvite = "first_invite",
  ExternalMortgageInterestConfirmation = "external_mortgage_interest_confirmation",
  MainApplicantConfirmation = "main_applicant_confirmation",
  MainApplicantConfirmationPamind = "main_applicant_confirmation_pamind",
  BookingConfirmation = "booking_confirmation",
  TooManyApplications = "too_many_applications",
  Gr = "gr",
  CoApplicantConfirmation = "co_applicant_confirmation",
  BookingReminder1H = "booking_reminder_1h",
  BookingReminder15Min = "booking_reminder_15_min",
  FirstOfferOnline = "first_offer_online",
  SecondOfferOnline = "second_offer_online",
  Disbursement = "disbursement",
  ExternalSigningLink = "external_signing_link",
  SevenDaysBeforeCreditCheckExpires = "seven_days_before_credit_check_expires",
  Test = "test",
  Marketing = "marketing",
}

export interface TemplateVariables {
  template_vars: string[];
  link_vars: string[];
  template_sample_vars?: SampleVars[];
}

export interface TemplateVarsPopulateErrors {
  no_email?: number;
  failed_vars?: FailedVariable[];
  no_links?: number;
  failed_links?: FailedVariable[];
  no_comment_subject?: number;
}

export interface TestTemplatePayload {
  to: string[];
  mandrill_name: string;
  global_merge_vars: MergeVarContent[];
  merge_vars: MergeVar[];

  /** @format email */
  from_email: string;
  from_name: string;

  /** @format uuid */
  campaign_id: string;
  customer_id?: string | null;
  test_with_real_data?: boolean;
}

export interface TokenResp {
  token: string;
}

export enum Touch {
  FIRST = "FIRST",
  LAST = "LAST",
}

export interface URL {
  url_name: string;
  key: string;
  target_url: string;
  is_active: boolean;
  clicks: number;
  path?: string | null;
  utm_tags?: UtmTags | null;
  click_events?: ClickEvent[];
}

/**
 * Model for Insurance details for what a customer can see
 */
export interface UnauthenticatedInsurance {
  /**
   * Model for Loan Insurance for what a customer can see
   * missing things like events, etc
   */
  insurance: InsuranceCustomer;
  customer: PublicApplicant;
  rules: InsuranceTerms;
}

export interface UncertainReasonType {
  Description?: string | null;
  Key?: string | null;
}

export interface UpdateApplicationPayloadExt {
  loan?: NewMortgageLoan | null;
  refinance_info?: RefinanceInfo | null;
  buying_info?: BuyingInfo | null;
  selling_info?: SellingInfo | null;
  applicants?: BareApplicantWithRole[] | null;
  property_id?: string | null;
  discount?: Discount | null;
  loans?: Loan[] | null;
  other_mortgages?: MortgageLoan[] | null;
  status?: AppsmgrModelsStatusStatus | null;
  substatus?: Substatus | null;
  bank_status?: BankStatus | null;
  children_in_the_household?: number | null;
  children_in_the_household_fulltime?: number | null;
  other_properties_to_keep?: OtherProperty[] | null;
  co_relationship_to_main_applicant?: RelationshipStatus | null;
  guarantor_relationship_to_main_applicant?: RelationshipStatus | null;
  cars?: CarsInput | null;
  brokerage?: Brokerage | null;
  new_app_options?: Option[] | null;
  new_app_checklist?: string[] | null;
  internal_comment?: string | null;
  source?: ApplicationSource | null;
  aml?: AMLInsertion | null;
}

export interface UpdateCampaignData {
  name: string;
  description?: string | null;
  source: SheetCampaignSource | BigQueryCampaignSource | ProcessCampaignSource;
  file_id?: string | null;
  exclusion_rules: ExclusionRules;
  daily_extraction_limit?: number | null;
  comment_template?: string | null;
  mail_policy?: MailSendPolicy | null;
  template?: EmailTemplate | null;
  template_vars?: string[] | null;
  retry_policy?: RetryPolicy | null;
  auto_dial?: boolean | null;
  personal_callbacks?: boolean | null;
  no_contact_outcomes?: string[] | null;
  failure_outcomes?: string[] | null;
  discard_outcomes?: string[] | null;
  show_call_history?: boolean | null;
}

export interface UpdateCreditCardApplicationPayload {
  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;
  data: OptionalCreditCardApplicationDataCreate;
}

export interface UpdateCustomerConsentsPayload {
  reason: string;
  customer_id: string;
  consents: Consents;
}

export interface UpdateCustomerPayload {
  reason: string;
  customer_id: string;
  data: CustomerData;
}

export interface UpdateFormPreferencePayload {
  form_id: string;
  product: Product;
  country: Country;
  mapping: FieldMapping[];
  preference_id: string;
}

export interface UpdateLeadData {
  phone?: string | null;
  other_phones?: string[] | null;
  pni?: string | null;
  process_type?: ProcessCampaignType | null;
  process_subtype?: InsuranceSubstatus | null;
  priority?: number | null;
}

export interface UpdateMeetingPayload {
  meeting_start?: string | null;
  meeting_end?: string | null;
  handler_id?: string | null;
  meeting_notes?: string | null;
  meeting_subject?: string | null;
  customer_id?: string | null;
}

export enum UpsertCampaignStatus {
  Created = "created",
  AlreadyExists = "already exists",
  Error = "error",
}

export interface UserCampaignsExt {
  /** @format uuid */
  id: string;
  version: number;
  user_id: string | string | string;
  joined_campaigns: JoinedCampaignExt[];
}

export interface UserOutcomeCount {
  user_id?: string | string | string | null;
  campaign_id?: string | null;

  /** Number of unique customers */
  unique?: number | null;

  /** Number of all outcomes excluding personal_callbacks */
  all?: number;

  /** Number of outcomes with first outcome set by user */
  first?: number;

  /** Number of successful outcomes */
  success?: number;
}

export interface UserSettings {
  compact?: boolean | null;
  direction?: "ltr" | "rtl" | null | ("ltr" & "rtl");
  responsiveFontSizes?: boolean | null;
  roundedCorners?: boolean | null;
  theme?: string | null;
  zoordinates: string;
  debug?: boolean | null;
  shareNameConsent?: ShareNameConsent;
  sverkerUsername?: SverkerUsername | null;
  default_calender_view?: DefaultCalendarView | null;
  telavox_api_key?: string | null;
}

export interface UtmTags {
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
}

export interface VacationHome {
  property_id: string;
  value?: number | null;
  booli_value?: number | null;
  external_id?: string | null;
  property_type: "VACATION_HOME";
  country: Country;
  street_address?: string | null;
  post_code?: string | null;
  city?: string | null;
  other_owner?: boolean | null;
  monthly_cost?: number | null;
  square_meter_living_area?: number | null;
  municipality?: string | null;
  municipality_code?: string | null;
  estimated_value?: number | null;
  latitude?: number | null;
  longitude?: number | null;
  ev_partial?: EvPartialCommon | null;
  cadastral_designation?: string | null;
  [key: string]: any;
}

export interface ValidatePayload {
  form_id: string;
  product: Product;
  country: Country;
  mapping: FieldMapping[];
  add_defaults?: boolean;
}

export interface ValidationError {
  loc: (string | number)[];
  msg: string;
  type: string;
}

export interface ValidatorServiceResponseModel {
  valid: boolean;
  formatted?: any;
}

export interface ValuationPayload {
  search_id: string;
  apartment_number?: string | null;
  living_area?: number | null;
}

export interface ValuationPayloadV2 {
  external_id: string;
  property_id: string;
  apartment_number?: string | null;
  living_area?: number | null;
}

export interface Value {
  AccuracyLevel?: string | null;
  AreaMarketScore?: number | null;
  AreaVacationHomeMarketScore?: string | null;
  CustomerApprovedValue?: string | null;
  CustomerMaxValue?: string | null;
  EstateType?: EstateTypeType | null;
  Estimate?: number | null;
  EstimateHaircut?: string | null;
  GrossValueEstimate?: string | null;
  HaircutPercentage?: string | null;
  LastKnownCommondebt?: number | null;
  LastKnownCommondebtDate?: string | null;
  MunicipalityScore?: number | null;
  NetValueEstimate?: number | null;
  Status?: StatusType | null;
  StoredEstimateDocumentationId?: string | null;
}

export interface ValueListItem {
  label_sv?: string | null;
  label_nb?: string | null;
  label_en?: string | null;
  value_sv?: string | null;
  value_en?: string | null;
  value_nb?: string | null;
}

export interface WebsiteVisitPayload {
  landing_url?: string | null;
  cookie_consent?: string | null;
}

export interface WorkingDays {
  working_days: number;
  worked_days: number;
}

export enum ZCountry {
  SE = "SE",
  NO = "NO",
  FI = "FI",
}

export enum ZDepartment {
  HL = "HL",
  KA = "KA",
  SK = "SK",
  SP = "SP",
  KH = "KH",
  ON = "ON",
}

export enum ZEmploymentType {
  FU = "FU",
  PA = "PA",
  UN = "UN",
}

export interface ZMSConfig {
  config: Config;
  country: Country;
  user_id: string | string | string | string;

  /** @format date-time */
  timestamp: string;
}

export enum ZOffice {
  ST = "ST",
  UP = "UP",
}

export enum ZProduct {
  MO = "MO",
  UL = "UL",
  CC = "CC",
  LI = "LI",
}

export interface Zoordinates {
  Product: ZProduct;
  Country: ZCountry;
  Office: ZOffice;
  EmploymentType: ZEmploymentType;
  Department: ZDepartment;
}

export interface ZrmAssignmentEvent {
  event_type?: "ASSIGNMENT";

  /** @format date-time */
  timestamp: string;
  user_id?: string | string | string | null;
  zoordinates?: Zoordinates | null;

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;
  handler_id: string | string | string;
  sverker_data?: ZrmSverkerData | null;
}

export interface ZrmBookingEvent {
  event_type?: "BOOKING";

  /** @format date-time */
  timestamp: string;
  user_id?: string | string | string | null;
  zoordinates?: Zoordinates | null;

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;
  handler_id?: string | string | string | null;
  sverker_data?: ZrmSverkerData | null;

  /** @format uuid */
  meeting_id: string;

  /** @format date-time */
  meeting_start: string;

  /** @format date-time */
  meeting_end: string;

  /** @format uuid */
  customer_id: string;
  meeting_subject?: string;
}

export interface ZrmCreationEvent {
  event_type?: "CREATION";

  /** @format date-time */
  timestamp: string;
  user_id?: string | string | string | null;
  zoordinates?: Zoordinates | null;

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;
  handler_id?: string | string | string | null;
  sverker_data?: ZrmSverkerData | null;

  /** @format uuid */
  customer_id: string;
}

export interface ZrmInternalCommentEvent {
  event_type?: "INTERNAL_COMMENT";

  /** @format date-time */
  timestamp: string;
  user_id?: string | string | string | null;
  zoordinates?: Zoordinates | null;

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;
  handler_id?: string | string | string | null;
  sverker_data?: ZrmSverkerData | null;

  /** @format uuid */
  event_id: string;
  comment: string;
  customer_id?: string | null;
}

export interface ZrmMigrationStats {
  creation?: boolean;
  meetings?: number;
  assignments?: number;
  comments?: number;
}

export interface ZrmMigratorEvents {
  creation: ZrmCreationEvent;
  assignments: ZrmAssignmentEvent[];
  bookings: ZrmBookingEvent[];
  comments: ZrmInternalCommentEvent[];
}

export interface ZrmSverkerData {
  version?: number | null;
  row_id?: number | null;
  source?: string | null;
  user_id?: string | null;
  creator_id?: string | null;
  advisor_id?: string | null;
}

export enum AppsmgrBlancoModelsApplicationDeactivationReason {
  Other = "other",
  Moved = "moved",
  FailedCreditCheck = "failedCreditCheck",
  HighRent = "highRent",
  MiddleRent = "middleRent",
  LowRent = "lowRent",
  NoUC = "noUC",
  CallInSix = "callInSix",
  PossibleCreditCard = "possibleCreditCard",
  SentSB = "sentSB",
  Dontcall = "dontcall",
  HighRentBid = "highRentBid",
  HighRentNoBid = "highRentNoBid",
  NoReach = "noReach",
  Duplicate = "duplicate",
  MemberOrCarLoan = "memberOrCarLoan",
  Double = "double",
  UcAlreadyProtected = "ucAlreadyProtected",
  UcNoInterest = "ucNoInterest",
  UcNoTOC = "ucNoTOC",
  UcTooExpensive = "ucTooExpensive",
  UcTookLoanProtection = "ucTookLoanProtection",
  UcTryAgainLater = "ucTryAgainLater",
  PpmNoTime = "ppmNoTime",
  PpmNoInterest = "ppmNoInterest",
  PpmAlreadyHappy = "ppmAlreadyHappy",
}

export enum AppsmgrBlancoNoSpecPrivateUnsecuredLoanApplicationCreatedHousingType {
  COOPERATIVE = "COOPERATIVE",
  LODGER = "LODGER",
  OTHER = "OTHER",
  OWN_APARTMENT = "OWN_APARTMENT",
  OWN_HOUSE = "OWN_HOUSE",
  PARENTS = "PARENTS",
  RENTED = "RENTED",
}

export enum AppsmgrBlancoSeSpecPrivateUnsecuredLoanApplicationCreatedHousingType {
  RENTED = "RENTED",
  OWN_APARTMENT = "OWN_APARTMENT",
  OWN_HOUSE = "OWN_HOUSE",
  LIVE_IN = "LIVE_IN",
}

export interface AppsmgrInsuranceInsuranceEvent {
  eventId: string;
  event: InsuranceEventType;
  data?: EventData;

  /** @format date-time */
  date: string;
  orderId?: string | null;
  userId?: string | null;

  /** The type of the event e.g charges, mandate, etc */
  type: string;

  /** If the customer responded for a winbackable insurance */
  response?: boolean | null;

  /** The comment for the winbackable insurance */
  comment?: string | null;
}

export enum AppsmgrModelsApplicationPropertyType {
  HOUSE = "HOUSE",
  CONDOMINIUM = "CONDOMINIUM",
  VACATION_HOME = "VACATION_HOME",
  RENTING = "RENTING",
}

export enum AppsmgrModelsNotificationsNotificationType {
  OFFER = "OFFER",
  REJECTION = "REJECTION",
  OFFER_ACCEPTED = "OFFER_ACCEPTED",
  INVITE = "INVITE",
  BANK_COMMENT = "BANK_COMMENT",
  BROKER_COMMENT = "BROKER_COMMENT",
  SIGN_STATUS = "SIGN_STATUS",
  FIRST_INVITE = "FIRST_INVITE",
  DISBURSED = "DISBURSED",
  DEACTIVATED = "DEACTIVATED",
  APPLICATION_CREATED = "APPLICATION_CREATED",
  BOOKING_CHANGED = "BOOKING_CHANGED",
}

export interface AppsmgrModelsPhonePhoneNumber {
  number: string;
  type: PhoneNumberType;
}

export enum AppsmgrModelsStatusStatus {
  LEAD = "LEAD",
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  DEACTIVATED = "DEACTIVATED",
}

export enum AppsmgrMortgageModelsApplicationDeactivationReason {
  CUSTOMER_NOT_REACHABLE = "CUSTOMER_NOT_REACHABLE",
  CUSTOMER_NOT_INTERESTED = "CUSTOMER_NOT_INTERESTED",
  NO_IMPROVEMENT = "NO_IMPROVEMENT",
  NEGATIVE_CALCULATION = "NEGATIVE_CALCULATION",
  LOAN_TO_VALUE_TOO_HIGH = "LOAN_TO_VALUE_TOO_HIGH",
  INSECURE_EMPLOYMENT = "INSECURE_EMPLOYMENT",
  CREDIT_RATING_TOO_LOW = "CREDIT_RATING_TOO_LOW",
  TOO_SMALL_BRF = "TOO_SMALL_BRF",
  HOUS_IN_POOR_CONTITION = "HOUS_IN_POOR_CONTITION",
  RED_MUNICIPALITY = "RED_MUNICIPALITY",
  FIXEDINTEREST1YEAR = "FIXED_INTEREST_1_YEAR",
  FIXEDINTEREST3YEAR = "FIXED_INTEREST_3_YEAR",
  FIXEDINTEREST5YEAR = "FIXED_INTEREST_5_YEAR",
  AGRICULTURAL_PROPERTY = "AGRICULTURAL_PROPERTY",
  MIDDLE_OF_SALES = "MIDDLE_OF_SALES",
  TOO_SMALL_CASH_CONTRIBUTION = "TOO_SMALL_CASH_CONTRIBUTION",
  INCOME_REQUIREMENTS_NOT_MET = "INCOME_REQUIREMENTS_NOT_MET",
  NOT_COMMUNICABLE = "NOT_COMMUNICABLE",
  ONGOING_COMPETITOR = "ONGOING_COMPETITOR",
  ONGOING_DIVORCE = "ONGOING_DIVORCE",
  CONSTRUCTION_CREDIT = "CONSTRUCTION_CREDIT",
  BLANCO_APPLICATION = "BLANCO_APPLICATION",
  DUPLICATE = "DUPLICATE",
  OTHER = "OTHER",
}

export enum AppsmgrMortgageNoSpecCommonDefinitionsResponsibility {
  MAIN_APPLICANT = "MAIN_APPLICANT",
  CO_APPLICANT = "CO_APPLICANT",
  SHARED = "SHARED",
}

export enum AppsmgrMortgageSeSpecCommonDefinitionsPropertyType {
  CONDOMINIUM = "CONDOMINIUM",
  HOUSE = "HOUSE",
  VACATION_HOME = "VACATION_HOME",
}

export enum AppsmgrMortgageSeSpecCommonDefinitionsResponsibility {
  MAIN_APPLICANT = "MAIN_APPLICANT",
  CO_APPLICANT = "CO_APPLICANT",
  SHARED = "SHARED",
  GUARANTOR = "GUARANTOR",
}

export enum ModelsAffiliateAffiliateAffiliateStatus {
  ACTIVE = "ACTIVE",
  DEACTIVATED = "DEACTIVATED",
}

export interface ModelsAffiliateAffiliateCampaign {
  url_target: Product;
  valid_to?: string | null;
  url?: string | null;
  campaign_id: string;
}

export enum ModelsAffiliatePartnerApplicationAffiliateStatus {
  APP_CREATED = "APP_CREATED",
  OFFER_ACCEPTED = "OFFER_ACCEPTED",
  OFFER_SIGNED = "OFFER_SIGNED",
  APP_DISBURSED = "APP_DISBURSED",
  APP_DEACTIVATED = "APP_DEACTIVATED",
  LEAD_CREATED = "LEAD_CREATED",
  LEAD_DEACTIVATED = "LEAD_DEACTIVATED",
  APP_NOT_CREATED = "APP_NOT_CREATED",
  SUBMITTED = "SUBMITTED",
}

export interface ModelsApplicationMappingApplicationMappingData {
  handler_id?: string | string | string | null;
  creator_id?: string | string | string | null;
  property_id?: string | null;
  new_app_checklist?: string[] | null;
  new_app_options?: Option[] | null;
  internal_comment?: string | null;
  document?: DocumentAssignation | null;
  customer_id?: string | null;
  external_id?: string | null;
  source?: ApplicationSource | null;

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  duplicate_of?: string | null;
  lead_id?: string | null;
  aml?: AMLInsertion | null;
  aml_review?: AMLReviewPayload | null;
  [key: string]: any;
}

export interface ModelsApplicationMappingApplicationMappingEvent {
  action: Action;

  /** @format date-time */
  timestamp: string;
  user_id?: string | string | string | null;
  data: ModelsApplicationMappingApplicationMappingData;

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string;
  event_id: string;
  request_id: string;
  zoordinates?: Zoordinates | null;
}

export interface ModelsApplicationMappingCommentsInternalComment {
  user_id: string | string | string;
  comment: string;

  /** @format date-time */
  timestamp: string;
  comment_id: string;
  action?: "INTERNAL_COMMENT_ADDED";
}

export interface ModelsDialMaker2LeadDataModelsLeadExtraction {
  /** @format date-time */
  timestamp: string;

  /** @format uuid */
  extraction_id: string;

  /** @format uuid */
  campaign_id: string;

  /** Number of times this customer/phone number was extracted. */
  count?: number;
}

export interface ModelsDialMaker2CampaignAggregateCampaign {
  /** @format uuid */
  id: string;
  version: number;

  /** @format date-time */
  created_at: string;

  /** @format date-time */
  updated_at: string;
  created_by: string | string | string;
  updated_by?: string | string | string | null;
  active: boolean;
  data: DialCampaignData | MailCampaignData | (DialCampaignData & MailCampaignData);
  extractions: CampaignExtraction[];
  exports: CampaignExport[];
}

export interface ModelsMeetingModelsMeeting {
  /** @format date-time */
  meeting_start: string;

  /** @format date-time */
  meeting_end: string;
  handler_id?: string | null;
  meeting_notes?: string | null;
  meeting_subject?: string | null;
  customer_id?: string | null;

  /** Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits. */
  application_id: string | string;
  meeting_id: string;

  /** @format date-time */
  last_updated: string;

  /** @format date-time */
  date_created: string;
  product: Product;
  country: Country;
  customer_name?: string | null;
}

export interface ModelsNotificationsNotificationInternalComment {
  type: "INTERNAL_COMMENT_RECEIVED";

  /** @format date-time */
  timestamp: string;
  status: NotificationEvent;
  data: InternalCommentData;
  notification_id: string;
  user_id: string | string | string | null;
}

export enum ModelsNotificationsNotificationTypesNotificationType {
  SMS_RECEIVED = "SMS_RECEIVED",
  OFFER_RECEIVED = "OFFER_RECEIVED",
  BANK_MESSAGE_RECEIVED = "BANK_MESSAGE_RECEIVED",
  INTERNAL_COMMENT_RECEIVED = "INTERNAL_COMMENT_RECEIVED",
  GENERAL_MESSAGE_RECEIVED = "GENERAL_MESSAGE_RECEIVED",
  APPLICATION_ASSIGNED = "APPLICATION_ASSIGNED",
  LEAD_ASSIGNED = "LEAD_ASSIGNED",
  LEAD_COMMENT_RECEIVED = "LEAD_COMMENT_RECEIVED",
  APPLICATION_DISBURSED = "APPLICATION_DISBURSED",
}

export interface ModelsOnlineLoanApplicationStatus {
  success: boolean;
  error?: string | null;
}

export interface ModelsValidatorServicePayloadPhoneNumber {
  country?: Country | null;
  phone_number: string;
}

export interface RoutersApplicationApplicationPayloadSearchPayload {
  country?: Country | null;
  product?: Product | null;
  string: string;
  search_types?: SearchType[];
}

export interface RoutersBankPayloadsSearchPayload {
  country?: Country | null;
  product?: Product | null;
  string: string;
}

export interface RoutersBankIdModelsBankIDResp {
  ticket_id: string;
}

export interface RoutersBankIdLoginPayloadBankIDResp {
  autostart_token?: string | null;
  ticket_id: string;
  polling_endpoint?: string | null;
  login_url?: string | null;
  qr_code_content?: string | null;
  autostart_uri?: string | null;
  desktop_autostart_uri?: string | null;
}

export interface RoutersDialMaker2PayloadsSignedUrl {
  signed_url: string;
}

export interface RoutersDialMakerPayloadsLeadExtraction {
  leads_to_extract: number;
}

export interface RoutersDialMakerPayloadsSignedUrl {
  signed_url: string;
  file_name: string;
}

export interface RoutersEmployeeModelsSearchPayload {
  string: string;
}

export interface RoutersLeadPayloadSearchPayload {
  string: string;
}

export interface RoutersMessagePayloadData {
  meeting_id?: string | null;
}

export interface RoutersPropertyPayloadSearchPayload {
  country: Country;
  search_string: string;
}

export interface ServicesSverkerResponsesMeeting {
  /** @format date-time */
  id: string;
  time: string;
  booked: boolean;
  format: string;
}

export namespace Application {
  /**
   * No description
   * @tags Application
   * @name QuickCreateApplicationApplicationQuickCreatePost
   * @summary Quick Create Application
   * @request POST:/application/quick-create
   * @secure
   */
  export namespace QuickCreateApplicationApplicationQuickCreatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = QuickCreateMortgageApplication | QuickCreateBlancoApplication;
    export type RequestHeaders = {};
    export type ResponseBody = ApplicationCreatedResponse;
  }
  /**
   * No description
   * @tags Application
   * @name CreateApplicationApplicationCreatePost
   * @summary Create Application
   * @request POST:/application/create
   * @secure
   */
  export namespace CreateApplicationApplicationCreatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateApplicationPayloadExt;
    export type RequestHeaders = {};
    export type ResponseBody = ApplicationCreatedResponseExt;
  }
  /**
   * No description
   * @tags Application
   * @name UpdateApplicationApplicationUpdateApplicationIdPost
   * @summary Update Application
   * @request POST:/application/update/{application_id}
   * @secure
   */
  export namespace UpdateApplicationApplicationUpdateApplicationIdPost {
    export type RequestParams = { applicationId: string };
    export type RequestQuery = {};
    export type RequestBody = UpdateApplicationPayloadExt;
    export type RequestHeaders = {};
    export type ResponseBody = ApplicationWithNamesInComments;
  }
  /**
   * No description
   * @tags Application
   * @name GetApplicationApplicationGetPost
   * @summary Get Application
   * @request POST:/application/get
   * @secure
   */
  export namespace GetApplicationApplicationGetPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetApplicationPayload;
    export type RequestHeaders = {};
    export type ResponseBody = ApplicationWithNamesInComments;
  }
  /**
   * No description
   * @tags Application
   * @name GetApplicationsApplicationGetsPost
   * @summary Get Applications
   * @request POST:/application/gets
   * @deprecated
   * @secure
   */
  export namespace GetApplicationsApplicationGetsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetApplicationsByCustomerIdentifierPayload;
    export type RequestHeaders = {};
    export type ResponseBody = Application[];
  }
  /**
   * No description
   * @tags Application
   * @name GetApplicationsV2ApplicationV2GetsPost
   * @summary Get Applications V2
   * @request POST:/application/v2/gets
   * @secure
   */
  export namespace GetApplicationsV2ApplicationV2GetsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetApplicationsByCustomerIdentifierPayloadV2;
    export type RequestHeaders = {};
    export type ResponseBody = Application[];
  }
  /**
   * No description
   * @tags Application
   * @name GetApplicationsAssignedToMeApplicationGetMinePost
   * @summary Get Applications Assigned To Me
   * @request POST:/application/get/mine
   * @secure
   */
  export namespace GetApplicationsAssignedToMeApplicationGetMinePost {
    export type RequestParams = {};
    export type RequestQuery = { page?: number; limit?: number };
    export type RequestBody = GetMyApplicationsPayload;
    export type RequestHeaders = {};
    export type ResponseBody = Application[];
  }
  /**
   * No description
   * @tags Application
   * @name GetRecentApplicationsApplicationGetRecentPost
   * @summary Get Recent Applications
   * @request POST:/application/get/recent
   * @secure
   */
  export namespace GetRecentApplicationsApplicationGetRecentPost {
    export type RequestParams = {};
    export type RequestQuery = { page?: number; limit?: number };
    export type RequestBody = GetRecentApplicationsPayload;
    export type RequestHeaders = {};
    export type ResponseBody = Application[];
  }
  /**
   * No description
   * @tags Application
   * @name SearchApplicationsApplicationSearchPost
   * @summary Search Applications
   * @request POST:/application/search
   * @secure
   */
  export namespace SearchApplicationsApplicationSearchPost {
    export type RequestParams = {};
    export type RequestQuery = { page?: number; limit?: number };
    export type RequestBody = RoutersApplicationApplicationPayloadSearchPayload;
    export type RequestHeaders = {};
    export type ResponseBody = Application[];
  }
  /**
   * No description
   * @tags Application
   * @name SearchApplications2ApplicationSearch2Post
   * @summary Search Applications2
   * @request POST:/application/search2
   * @secure
   */
  export namespace SearchApplications2ApplicationSearch2Post {
    export type RequestParams = {};
    export type RequestQuery = { page?: number; limit?: number };
    export type RequestBody = RoutersApplicationApplicationPayloadSearchPayload;
    export type RequestHeaders = {};
    export type ResponseBody = AppsSearchResult;
  }
  /**
   * No description
   * @tags Application
   * @name GetLeftToLiveApplicationLeftToLivePost
   * @summary Get Left To Live
   * @request POST:/application/left_to_live
   * @secure
   */
  export namespace GetLeftToLiveApplicationLeftToLivePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = LeftToLivePayload;
    export type RequestHeaders = {};
    export type ResponseBody = LeftToLiveResponse;
  }
  /**
   * No description
   * @tags Application
   * @name DuplicateApplicationApplicationDuplicatePost
   * @summary Duplicate Application
   * @request POST:/application/duplicate
   * @secure
   */
  export namespace DuplicateApplicationApplicationDuplicatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ApplicationPayload;
    export type RequestHeaders = {};
    export type ResponseBody = ApplicationPayload;
  }
  /**
   * No description
   * @tags Application
   * @name DeactivateApplicationApplicationDeactivateProductPost
   * @summary Deactivate Application
   * @request POST:/application/deactivate/{product}
   * @secure
   */
  export namespace DeactivateApplicationApplicationDeactivateProductPost {
    export type RequestParams = { product: Product };
    export type RequestQuery = {};
    export type RequestBody =
      | MortgageDeactivatePayload
      | DeactivatePayload
      | (MortgageDeactivatePayload & DeactivatePayload);
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags Application
   * @name ReactivateApplicationReactivatePost
   * @summary Reactivate
   * @request POST:/application/reactivate
   * @secure
   */
  export namespace ReactivateApplicationReactivatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ReactivatePayload;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags CreditCard Application
   * @name GetApplicationApplicationCcNoGetPost
   * @summary Get Application
   * @request POST:/application/cc/no/get
   * @secure
   */
  export namespace GetApplicationApplicationCcNoGetPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetApplicationPayload;
    export type RequestHeaders = {};
    export type ResponseBody = CreditCardApplicationWithNamesInComments;
  }
  /**
   * No description
   * @tags CreditCard Application
   * @name CreateApplicationApplicationCcNoCreatePost
   * @summary Create Application
   * @request POST:/application/cc/no/create
   * @secure
   */
  export namespace CreateApplicationApplicationCcNoCreatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateCreditCardApplicationPayloadExt;
    export type RequestHeaders = {};
    export type ResponseBody = ApplicationCreatedResponseExt;
  }
  /**
   * No description
   * @tags CreditCard Application
   * @name UpdateApplicationApplicationCcNoUpdatePost
   * @summary Update Application
   * @request POST:/application/cc/no/update
   * @secure
   */
  export namespace UpdateApplicationApplicationCcNoUpdatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UpdateCreditCardApplicationPayload;
    export type RequestHeaders = {};
    export type ResponseBody = EmptyModel;
  }
  /**
   * No description
   * @tags CreditCard Application
   * @name UpdateAndGetApplicationApplicationCcNoUpdateAndGetPost
   * @summary Update And Get Application
   * @request POST:/application/cc/no/update-and-get
   * @secure
   */
  export namespace UpdateAndGetApplicationApplicationCcNoUpdateAndGetPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UpdateCreditCardApplicationPayload;
    export type RequestHeaders = {};
    export type ResponseBody = CreditCardApplicationExtended;
  }
  /**
   * No description
   * @tags CreditCard Application
   * @name CreateApplicationApplicantApplicationCcNoCreateWithApplicantPost
   * @summary Create Application Applicant
   * @request POST:/application/cc/no/create-with-applicant
   * @secure
   */
  export namespace CreateApplicationApplicantApplicationCcNoCreateWithApplicantPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateCreditCardApplicationApplicantPayloadExt;
    export type RequestHeaders = {};
    export type ResponseBody = ApplicationCreatedResponseExt;
  }
  /**
   * No description
   * @tags CreditCard Application
   * @name GetRecentApplicationsApplicationCcNoGetRecentPost
   * @summary Get Recent Applications
   * @request POST:/application/cc/no/get_recent
   * @secure
   */
  export namespace GetRecentApplicationsApplicationCcNoGetRecentPost {
    export type RequestParams = {};
    export type RequestQuery = { page?: number; size?: number };
    export type RequestBody = GetRecentPayload;
    export type RequestHeaders = {};
    export type ResponseBody = PageCreditCardApplicationExtended;
  }
  /**
   * No description
   * @tags CreditCard Application
   * @name GetApplicationBankEventsApplicationCcNoBankEventsPost
   * @summary Get Application Bank Events
   * @request POST:/application/cc/no/bank-events
   * @secure
   */
  export namespace GetApplicationBankEventsApplicationCcNoBankEventsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ApplicationQuery;
    export type RequestHeaders = {};
    export type ResponseBody = CreditCardBankEventsResponse;
  }
  /**
   * No description
   * @tags CreditCard Application
   * @name SearchApplicationsApplicationCcNoSearchApplicationsPost
   * @summary Search Applications
   * @request POST:/application/cc/no/search_applications
   * @secure
   */
  export namespace SearchApplicationsApplicationCcNoSearchApplicationsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetApplicationsByApplicantIdentifierPayload;
    export type RequestHeaders = {};
    export type ResponseBody = CreditCardApplicationExtended[];
  }
  /**
   * No description
   * @tags CreditCard Application
   * @name AcceptOfferApplicationCcNoAcceptOfferPost
   * @summary Accept Offer
   * @request POST:/application/cc/no/accept_offer
   * @secure
   */
  export namespace AcceptOfferApplicationCcNoAcceptOfferPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = AcceptOffer;
    export type RequestHeaders = {};
    export type ResponseBody = CreditorOfferCreditCardOfferCreditCardTerms;
  }
  /**
   * No description
   * @tags Mortgage SE Application
   * @name GetApplicationBankEventsApplicationMoSeBankEventsPost
   * @summary Get Application Bank Events
   * @request POST:/application/mo/se/bank-events
   * @secure
   */
  export namespace GetApplicationBankEventsApplicationMoSeBankEventsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ApplicationQuery;
    export type RequestHeaders = {};
    export type ResponseBody = MortgageBankEventsResponse;
  }
  /**
   * No description
   * @tags Bank Communication
   * @name InviteApplicationApplicationInvitePost
   * @summary Invite Application
   * @request POST:/application/invite
   * @secure
   */
  export namespace InviteApplicationApplicationInvitePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = InvitePayloadBase;
    export type RequestHeaders = {};
    export type ResponseBody = EmptyModel;
  }
  /**
   * No description
   * @tags Bank Communication
   * @name InviteApplicationIdApplicationInviteApplicationIdPost
   * @summary Invite Application Id
   * @request POST:/application/invite/{application_id}
   * @secure
   */
  export namespace InviteApplicationIdApplicationInviteApplicationIdPost {
    export type RequestParams = { applicationId: string };
    export type RequestQuery = {};
    export type RequestBody = InvitePayloadBase;
    export type RequestHeaders = {};
    export type ResponseBody = EmptyModel;
  }
  /**
   * No description
   * @tags Bank Communication
   * @name GetAppEventsApplicationGetAppEventsApplicationIdGet
   * @summary Get App Events
   * @request GET:/application/get_app_events/{application_id}
   * @secure
   */
  export namespace GetAppEventsApplicationGetAppEventsApplicationIdGet {
    export type RequestParams = { applicationId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EventsResponse;
  }
  /**
   * No description
   * @tags Bank Communication
   * @name GetAppEventsOldApplicationGetAppEventsPost
   * @summary Get App Events Old
   * @request POST:/application/get_app_events
   * @secure
   */
  export namespace GetAppEventsOldApplicationGetAppEventsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags Bank Communication
   * @name SendCommentToIntegrationApplicationCommentToBankPost
   * @summary Send Comment To Integration
   * @request POST:/application/comment_to_bank
   * @secure
   */
  export namespace SendCommentToIntegrationApplicationCommentToBankPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BaseComment;
    export type RequestHeaders = {};
    export type ResponseBody = EventsResponse;
  }
  /**
   * No description
   * @tags Bank Communication
   * @name AcceptOfferApplicationAcceptOfferPost
   * @summary Accept Offer
   * @request POST:/application/accept_offer
   * @secure
   */
  export namespace AcceptOfferApplicationAcceptOfferPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = AcceptOffer;
    export type RequestHeaders = {};
    export type ResponseBody = EventsResponse;
  }
  /**
   * No description
   * @tags Bank Communication
   * @name CancelApplicationCancelPost
   * @summary Cancel
   * @request POST:/application/cancel
   * @secure
   */
  export namespace CancelApplicationCancelPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CancelOfferPayload;
    export type RequestHeaders = {};
    export type ResponseBody = EventsResponse;
  }
  /**
   * No description
   * @tags Bank Communication
   * @name SendDocumentToIntegrationApplicationDocumentToBankPost
   * @summary Send Document To Integration
   * @request POST:/application/document_to_bank
   * @secure
   */
  export namespace SendDocumentToIntegrationApplicationDocumentToBankPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = DocumentToBank;
    export type RequestHeaders = {};
    export type ResponseBody = EventsResponse;
  }
  /**
   * No description
   * @tags Application Meta
   * @name AssignApplicationToHandlerApplicationAssignApplicationIdPost
   * @summary Assign Application To Handler
   * @request POST:/application/assign/{application_id}
   * @secure
   */
  export namespace AssignApplicationToHandlerApplicationAssignApplicationIdPost {
    export type RequestParams = { applicationId: string };
    export type RequestQuery = {};
    export type RequestBody = AssignApplicationPayload;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Application Meta
   * @name ListInternalCommentsApplicationCommentApplicationIdGet
   * @summary List Internal Comments
   * @request GET:/application/comment/{application_id}
   * @secure
   */
  export namespace ListInternalCommentsApplicationCommentApplicationIdGet {
    export type RequestParams = { applicationId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = (
      | BankIDSignedWithNames
      | InternalCommentWithName
      | (BankIDSignedWithNames & InternalCommentWithName)
    )[];
  }
  /**
   * No description
   * @tags Application Meta
   * @name AddInternalCommentApplicationCommentApplicationIdPost
   * @summary Add Internal Comment
   * @request POST:/application/comment/{application_id}
   * @secure
   */
  export namespace AddInternalCommentApplicationCommentApplicationIdPost {
    export type RequestParams = { applicationId: string };
    export type RequestQuery = {};
    export type RequestBody = CommentPayload;
    export type RequestHeaders = {};
    export type ResponseBody = (
      | BankIDSignedWithNames
      | InternalCommentWithName
      | (BankIDSignedWithNames & InternalCommentWithName)
    )[];
  }
  /**
   * No description
   * @tags Application Meta
   * @name GetPossibleStatusesApplicationPrefsGet
   * @summary Get Possible Statuses
   * @request GET:/application/prefs
   * @secure
   */
  export namespace GetPossibleStatusesApplicationPrefsGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PrefsResponse;
  }
  /**
   * No description
   * @tags Application Meta
   * @name GetPossibleLoanTypesApplicationLoanTypesCountryGet
   * @summary Get Possible Loan Types
   * @request GET:/application/loan-types/{country}
   * @secure
   */
  export namespace GetPossibleLoanTypesApplicationLoanTypesCountryGet {
    export type RequestParams = { country: Country };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string[];
  }
  /**
   * No description
   * @tags Application Meta
   * @name SearchBlancoSverkerApplicationBlancoSearchCountryGet
   * @summary Search Blanco Sverker
   * @request GET:/application/blanco/search/{country}
   * @secure
   */
  export namespace SearchBlancoSverkerApplicationBlancoSearchCountryGet {
    export type RequestParams = { country: Country };
    export type RequestQuery = { s: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SverkerSearchResponse;
  }
  /**
   * No description
   * @tags AML
   * @name AmlReviewApplicationAmlReviewPost
   * @summary Aml Review
   * @request POST:/application/aml/review
   * @secure
   */
  export namespace AmlReviewApplicationAmlReviewPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = AMLReviewPayload;
    export type RequestHeaders = {};
    export type ResponseBody = ApplicationWithNamesInComments;
  }
  /**
   * No description
   * @tags AML
   * @name GetBlancoAmlApplicationBlancoAmlApplicationIdGet
   * @summary Get Blanco Aml
   * @request GET:/application/blanco/aml/{application_id}
   * @secure
   */
  export namespace GetBlancoAmlApplicationBlancoAmlApplicationIdGet {
    export type RequestParams = { applicationId: number };
    export type RequestQuery = { country: Country };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = AMLBlancoResponse;
  }
  /**
   * No description
   * @tags AML
   * @name PatchBlancoAmlApplicationBlancoAmlApplicationIdPatch
   * @summary Patch Blanco Aml
   * @request PATCH:/application/blanco/aml/{application_id}
   * @secure
   */
  export namespace PatchBlancoAmlApplicationBlancoAmlApplicationIdPatch {
    export type RequestParams = { applicationId: number };
    export type RequestQuery = {};
    export type RequestBody = BlancoAMLPatchPayload;
    export type RequestHeaders = {};
    export type ResponseBody = AMLBlancoResponse;
  }
  /**
   * No description
   * @tags AML
   * @name ReviewBlancoAmlApplicationBlancoAmlReviewApplicationIdPatch
   * @summary Review Blanco Aml
   * @request PATCH:/application/blanco/aml/review/{application_id}
   * @secure
   */
  export namespace ReviewBlancoAmlApplicationBlancoAmlReviewApplicationIdPatch {
    export type RequestParams = { applicationId: number };
    export type RequestQuery = {};
    export type RequestBody = BlancoAMLReviewPayload;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags AML
   * @name MortgageDeactivationReviewsApplicationDeactivationReviewsMortgagePost
   * @summary Mortgage Deactivation Reviews
   * @request POST:/application/deactivation_reviews/mortgage
   * @secure
   */
  export namespace MortgageDeactivationReviewsApplicationDeactivationReviewsMortgagePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = DeactivationReviewsPayload;
    export type RequestHeaders = {};
    export type ResponseBody = MortgageDeactivationReviewsResponse;
  }
  /**
   * No description
   * @tags AML
   * @name BlancoDeactivationReviewsApplicationDeactivationReviewsBlancoPost
   * @summary Blanco Deactivation Reviews
   * @request POST:/application/deactivation_reviews/blanco
   * @secure
   */
  export namespace BlancoDeactivationReviewsApplicationDeactivationReviewsBlancoPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = DeactivationReviewsPayload;
    export type RequestHeaders = {};
    export type ResponseBody = DeactivationReviewsResponse;
  }
  /**
   * No description
   * @tags Migrator
   * @name MigrateSverkerApplicationApplicationMigratorAppLabelPost
   * @summary Migrate Sverker Application
   * @request POST:/application/migrator/{app_label}
   * @secure
   */
  export namespace MigrateSverkerApplicationApplicationMigratorAppLabelPost {
    export type RequestParams = { appLabel: string };
    export type RequestQuery = {};
    export type RequestBody = ZrmMigratorEvents;
    export type RequestHeaders = {};
    export type ResponseBody = ZrmMigrationStats;
  }
}

export namespace Customer {
  /**
   * No description
   * @tags Customer
   * @name GetCustomerCustomerGetPost
   * @summary Get Customer
   * @request POST:/customer/get
   * @secure
   */
  export namespace GetCustomerCustomerGetPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody =
      | PniGetCustomerPayload
      | EmailGetCustomerPayload
      | PhoneGetCustomerPayload
      | CustomerIDGetCustomerPayload
      | CustomerIDsGetCustomerPayload
      | (PniGetCustomerPayload &
        EmailGetCustomerPayload &
        PhoneGetCustomerPayload &
        CustomerIDGetCustomerPayload &
        CustomerIDsGetCustomerPayload);
    export type RequestHeaders = {};
    export type ResponseBody = ApplicantSE | ApplicantNO;
  }
  /**
   * No description
   * @tags Customer
   * @name GetCustomersCustomerGetsPost
   * @summary Get Customers
   * @request POST:/customer/gets
   * @secure
   */
  export namespace GetCustomersCustomerGetsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CustomerIDsGetCustomerPayload;
    export type RequestHeaders = {};
    export type ResponseBody = (ApplicantSE | ApplicantNO)[];
  }
  /**
   * No description
   * @tags Customer
   * @name CreateCustomerCustomerCreatePost
   * @summary Create Customer
   * @request POST:/customer/create
   * @secure
   */
  export namespace CreateCustomerCustomerCreatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateCustomerPayload;
    export type RequestHeaders = {};
    export type ResponseBody = ApplicantSE | ApplicantNO;
  }
  /**
   * No description
   * @tags Customer
   * @name UpdateCustomerCustomerUpdatePost
   * @summary Update Customer
   * @request POST:/customer/update
   * @secure
   */
  export namespace UpdateCustomerCustomerUpdatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UpdateCustomerPayload;
    export type RequestHeaders = {};
    export type ResponseBody = ApplicantSE | ApplicantNO;
  }
  /**
   * No description
   * @tags Customer
   * @name UpdateConsentsCustomerUpdateConsentsPost
   * @summary Update Consents
   * @request POST:/customer/update_consents
   * @secure
   */
  export namespace UpdateConsentsCustomerUpdateConsentsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UpdateCustomerConsentsPayload;
    export type RequestHeaders = {};
    export type ResponseBody = ApplicantSE | ApplicantNO;
  }
  /**
   * No description
   * @tags Customer
   * @name IdCheckOrGetCustomerCustomerIdCheckPost
   * @summary Id Check Or Get Customer
   * @request POST:/customer/id-check
   * @secure
   */
  export namespace IdCheckOrGetCustomerCustomerIdCheckPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = IDCheckGetCustomerPayload;
    export type RequestHeaders = {};
    export type ResponseBody =
      | (ApplicantSE | ApplicantNO)
      | IDCheckedPerson
      | null
      | ((ApplicantSE | ApplicantNO) & IDCheckedPerson);
  }
  /**
   * No description
   * @tags Customer
   * @name TakeCreditCheckCustomerCreditCheckPost
   * @summary Take Credit Check
   * @request POST:/customer/credit-check
   * @secure
   */
  export namespace TakeCreditCheckCustomerCreditCheckPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = TakeCreditCheck;
    export type RequestHeaders = {};
    export type ResponseBody = ApplicantSE | ApplicantNO;
  }
  /**
   * No description
   * @tags Customer
   * @name GetNameByPniCustomerNameByPniPost
   * @summary Get Name By Pni
   * @request POST:/customer/name/by-pni
   * @secure
   */
  export namespace GetNameByPniCustomerNameByPniPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetNameByPNIPayload;
    export type RequestHeaders = {};
    export type ResponseBody = Name | null;
  }
  /**
   * No description
   * @tags Customer
   * @name GetNameCustomerNameCustomerIdGet
   * @summary Get Name
   * @request GET:/customer/name/{customer_id}
   * @secure
   */
  export namespace GetNameCustomerNameCustomerIdGet {
    export type RequestParams = { customerId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Name | null;
  }
  /**
   * No description
   * @tags Customer, Application Meta
   * @name EmploymentTypesCustomerEmploymentTypesCountryGet
   * @summary Employment Types
   * @request GET:/customer/employment-types/{country}
   * @secure
   */
  export namespace EmploymentTypesCustomerEmploymentTypesCountryGet {
    export type RequestParams = { country: Country };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string[];
  }
  /**
   * No description
   * @tags Customer, Application Meta
   * @name HousingTypesCustomerHousingTypesCountryGet
   * @summary Housing Types
   * @request GET:/customer/housing-types/{country}
   * @secure
   */
  export namespace HousingTypesCustomerHousingTypesCountryGet {
    export type RequestParams = { country: Country };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string[];
  }
  /**
   * No description
   * @tags Customer
   * @name GetExternallyVerifiedCreditsCustomerExternallyVerifiedCreditsPost
   * @summary Get Externally Verified Credits
   * @request POST:/customer/externally-verified-credits
   * @secure
   */
  export namespace GetExternallyVerifiedCreditsCustomerExternallyVerifiedCreditsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = MinimalApplicant[];
    export type RequestHeaders = {};
    export type ResponseBody = LoanAndExternallyVerifiedCredit;
  }
}

export namespace Meeting {
  /**
   * No description
   * @tags Meeting
   * @name GetMeetingMeetingGetMeetingIdGet
   * @summary Get Meeting
   * @request GET:/meeting/get/{meeting_id}
   * @secure
   */
  export namespace GetMeetingMeetingGetMeetingIdGet {
    export type RequestParams = { meetingId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ModelsMeetingModelsMeeting;
  }
  /**
   * No description
   * @tags Meeting
   * @name GetMeetingsMeetingGetPost
   * @summary Get Meetings
   * @request POST:/meeting/get
   * @secure
   */
  export namespace GetMeetingsMeetingGetPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetMeetings;
    export type RequestHeaders = {};
    export type ResponseBody =
      | MeetingWithApp[]
      | MeetingWithHandlerName[]
      | ModelsMeetingModelsMeeting[]
      | (MeetingWithApp[] & MeetingWithHandlerName[] & ModelsMeetingModelsMeeting[]);
  }
  /**
   * No description
   * @tags Meeting
   * @name GetMyMeetingsMeetingMinePost
   * @summary Get My Meetings
   * @request POST:/meeting/mine
   * @secure
   */
  export namespace GetMyMeetingsMeetingMinePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetMyMeetings;
    export type RequestHeaders = {};
    export type ResponseBody = (
      | MeetingWithApp
      | MeetingWithHandlerName
      | ModelsMeetingModelsMeeting
      | (MeetingWithApp & MeetingWithHandlerName & ModelsMeetingModelsMeeting)
    )[];
  }
  /**
   * No description
   * @tags Meeting
   * @name CreateMeetingMeetingCreatePost
   * @summary Create Meeting
   * @request POST:/meeting/create
   * @secure
   */
  export namespace CreateMeetingMeetingCreatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateMeetingPayload;
    export type RequestHeaders = {};
    export type ResponseBody = ModelsMeetingModelsMeeting;
  }
  /**
   * No description
   * @tags Meeting
   * @name UpdateMeetingMeetingUpdateMeetingIdPost
   * @summary Update Meeting
   * @request POST:/meeting/update/{meeting_id}
   * @secure
   */
  export namespace UpdateMeetingMeetingUpdateMeetingIdPost {
    export type RequestParams = { meetingId: string };
    export type RequestQuery = {};
    export type RequestBody = UpdateMeetingPayload;
    export type RequestHeaders = {};
    export type ResponseBody = ModelsMeetingModelsMeeting;
  }
  /**
   * No description
   * @tags Meeting
   * @name CancelMeetingsMeetingCancelMeetingIdDelete
   * @summary Cancel Meetings
   * @request DELETE:/meeting/cancel/{meeting_id}
   * @secure
   */
  export namespace CancelMeetingsMeetingCancelMeetingIdDelete {
    export type RequestParams = { meetingId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags Meeting
   * @name GetBookableTimesMeetingAvailableDayGet
   * @summary Get Bookable Times
   * @request GET:/meeting/available/{day}
   * @secure
   */
  export namespace GetBookableTimesMeetingAvailableDayGet {
    export type RequestParams = { day: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = { "x-zoordinates": string };
    export type ResponseBody = BookableSlot[];
  }
}

export namespace Document {
  /**
   * No description
   * @tags Document
   * @name GetDocumentMetaDocumentGetMetaCustomerIdDocumentIdGet
   * @summary Get Document Meta
   * @request GET:/document/get/meta/{customer_id}/{document_id}
   * @secure
   */
  export namespace GetDocumentMetaDocumentGetMetaCustomerIdDocumentIdGet {
    export type RequestParams = { customerId: string; documentId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = DocumentMeta;
  }
  /**
   * No description
   * @tags Document
   * @name GetDocumentDocumentGetCustomerIdDocumentIdGet
   * @summary Get Document
   * @request GET:/document/get/{customer_id}/{document_id}
   * @secure
   */
  export namespace GetDocumentDocumentGetCustomerIdDocumentIdGet {
    export type RequestParams = { customerId: string; documentId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CustomerDocument;
  }
  /**
   * No description
   * @tags Document
   * @name CreateDocumentDocumentSavePost
   * @summary Create Document
   * @request POST:/document/save
   * @secure
   */
  export namespace CreateDocumentDocumentSavePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = DocumentCreate;
    export type RequestHeaders = {};
    export type ResponseBody = CustomerDocument;
  }
  /**
   * No description
   * @tags Document
   * @name DocumentTypesDocumentTypesGet
   * @summary Document Types
   * @request GET:/document/types
   * @secure
   */
  export namespace DocumentTypesDocumentTypesGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = DocumentType[];
  }
  /**
   * No description
   * @tags Document
   * @name GetAttachedDocumentsOfApplicationDocumentAttachedApplicationIdGet
   * @summary Get Attached Documents Of Application
   * @request GET:/document/attached/{application_id}
   * @secure
   */
  export namespace GetAttachedDocumentsOfApplicationDocumentAttachedApplicationIdGet {
    export type RequestParams = { applicationId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = DocumentAssignation[];
  }
  /**
   * No description
   * @tags Document
   * @name AttachDocumentToApplicationDocumentAttachApplicationIdPost
   * @summary Attach Document To Application
   * @request POST:/document/attach/{application_id}
   * @secure
   */
  export namespace AttachDocumentToApplicationDocumentAttachApplicationIdPost {
    export type RequestParams = { applicationId: string };
    export type RequestQuery = {};
    export type RequestBody = DocumentAssignation;
    export type RequestHeaders = {};
    export type ResponseBody = DocumentAssignation[];
  }
}

export namespace Banks {
  /**
   * No description
   * @tags Banks
   * @name GetAllBanksBanksAllGet
   * @summary Get All Banks
   * @request GET:/banks/all
   * @secure
   */
  export namespace GetAllBanksBanksAllGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Bank[];
  }
  /**
   * No description
   * @tags Banks
   * @name GetBanksBanksGetPost
   * @summary Get Banks
   * @request POST:/banks/get
   * @secure
   */
  export namespace GetBanksBanksGetPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetBanksPayload;
    export type RequestHeaders = {};
    export type ResponseBody = Bank[];
  }
  /**
   * No description
   * @tags Banks
   * @name GetAllIntegrationsBanksAllIntegrationsGet
   * @summary Get All Integrations
   * @request GET:/banks/all_integrations
   * @secure
   */
  export namespace GetAllIntegrationsBanksAllIntegrationsGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Integration[];
  }
  /**
   * No description
   * @tags Banks
   * @name GetIntegrationsBanksGetIntegrationsPost
   * @summary Get Integrations
   * @request POST:/banks/get_integrations
   * @secure
   */
  export namespace GetIntegrationsBanksGetIntegrationsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = IntegrationQuery;
    export type RequestHeaders = {};
    export type ResponseBody = Integration[];
  }
}

export namespace Schedule {
  /**
   * No description
   * @tags Advisor Schedule
   * @name ScheduleDaySchedulePost
   * @summary Schedule Day
   * @request POST:/schedule/
   * @secure
   */
  export namespace ScheduleDaySchedulePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = DaySlotDocPayload;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags Advisor Schedule
   * @name GetScheduleOfDayScheduleDayGet
   * @summary Get Schedule Of Day
   * @request GET:/schedule/{day}
   * @secure
   */
  export namespace GetScheduleOfDayScheduleDayGet {
    export type RequestParams = { day: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = { "x-zoordinates": string };
    export type ResponseBody = DaySlotDoc;
  }
  /**
   * No description
   * @tags Advisor Schedule
   * @name GetScheduleOfDayByCountryAndProductScheduleDayProductCountryGet
   * @summary Get Schedule Of Day By Country And Product
   * @request GET:/schedule/{day}/{product}/{country}
   * @secure
   */
  export namespace GetScheduleOfDayByCountryAndProductScheduleDayProductCountryGet {
    export type RequestParams = { day: string; product: Product; country: Country };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = DaySlotDoc;
  }
}

export namespace Employee {
  /**
   * No description
   * @tags Employee
   * @name GetAllEmployeesEmployeeAllGet
   * @summary Get All Employees
   * @request GET:/employee/all
   * @secure
   */
  export namespace GetAllEmployeesEmployeeAllGet {
    export type RequestParams = {};
    export type RequestQuery = { page?: number | null; limit?: number | null; without_deactivated?: boolean | null };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Employee[];
  }
  /**
   * No description
   * @tags Employee
   * @name GetSverkerUsersEmployeeGetSverkerUsersGet
   * @summary Get Sverker Users
   * @request GET:/employee/get_sverker_users
   * @secure
   */
  export namespace GetSverkerUsersEmployeeGetSverkerUsersGet {
    export type RequestParams = {};
    export type RequestQuery = { user_name: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = (string | string | string)[];
  }
  /**
   * No description
   * @tags Employee
   * @name GetEmployeesByZoordinatesEmployeeByZoordinatesPost
   * @summary Get Employees By Zoordinates
   * @request POST:/employee/by_zoordinates
   * @secure
   */
  export namespace GetEmployeesByZoordinatesEmployeeByZoordinatesPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = AllOptionalZoordinates;
    export type RequestHeaders = {};
    export type ResponseBody = Employee[];
  }
  /**
   * No description
   * @tags Employee
   * @name GetMyInfoEmployeeMeGet
   * @summary Get My Info
   * @request GET:/employee/me
   * @secure
   */
  export namespace GetMyInfoEmployeeMeGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Employee;
  }
  /**
   * No description
   * @tags Employee
   * @name DeactivateUserByIdEmployeeActionsBlockOrUnblockUserIdPatch
   * @summary Deactivate User By Id
   * @request PATCH:/employee/actions/block-or-unblock/{user_id}
   * @secure
   */
  export namespace DeactivateUserByIdEmployeeActionsBlockOrUnblockUserIdPatch {
    export type RequestParams = { userId: string };
    export type RequestQuery = { block?: boolean };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Employee
   * @name GetAllZoordinatesEmployeeZoordinatesGet
   * @summary Get All Zoordinates
   * @request GET:/employee/zoordinates
   * @secure
   */
  export namespace GetAllZoordinatesEmployeeZoordinatesGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Zoordinates[];
  }
  /**
   * No description
   * @tags Employee
   * @name GetAllRolesEmployeeRolesGet
   * @summary Get All Roles
   * @request GET:/employee/roles
   * @secure
   */
  export namespace GetAllRolesEmployeeRolesGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = RoleType[];
  }
  /**
   * No description
   * @tags Employee
   * @name GetMySettingsEmployeeMySettingsGet
   * @summary Get My Settings
   * @request GET:/employee/my-settings
   * @secure
   */
  export namespace GetMySettingsEmployeeMySettingsGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UserSettings;
  }
  /**
   * No description
   * @tags Employee
   * @name SaveMySettingsEmployeeMySettingsPost
   * @summary Save My Settings
   * @request POST:/employee/my-settings
   * @secure
   */
  export namespace SaveMySettingsEmployeeMySettingsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UserSettings;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Employee
   * @name CreateEmployeeEmployeePost
   * @summary Create Employee
   * @request POST:/employee/
   * @secure
   */
  export namespace CreateEmployeeEmployeePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateAndUpdateEmployeeRequest;
    export type RequestHeaders = {};
    export type ResponseBody = Employee;
  }
  /**
   * No description
   * @tags Employee
   * @name UpdateEmployeeEmployeeUserIdPut
   * @summary Update Employee
   * @request PUT:/employee/{user_id}
   * @secure
   */
  export namespace UpdateEmployeeEmployeeUserIdPut {
    export type RequestParams = { userId: string };
    export type RequestQuery = {};
    export type RequestBody = CreateAndUpdateEmployeeRequest;
    export type RequestHeaders = {};
    export type ResponseBody = Employee;
  }
  /**
   * No description
   * @tags Employee
   * @name GetByIdEmployeeUserIdGet
   * @summary Get By Id
   * @request GET:/employee/{user_id}
   * @secure
   */
  export namespace GetByIdEmployeeUserIdGet {
    export type RequestParams = { userId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Employee;
  }
  /**
   * No description
   * @tags Employee
   * @name SearchEmployeeSearchPost
   * @summary Search
   * @request POST:/employee/search
   * @secure
   */
  export namespace SearchEmployeeSearchPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = RoutersEmployeeModelsSearchPayload;
    export type RequestHeaders = {};
    export type ResponseBody = Employee[];
  }
}

export namespace Property {
  /**
   * No description
   * @tags Property
   * @name GetPropertyPropertyGetPropertyIdGet
   * @summary Get Property
   * @request GET:/property/get/{property_id}
   * @secure
   */
  export namespace GetPropertyPropertyGetPropertyIdGet {
    export type RequestParams = { propertyId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = House | VacationHome | Condominium | (House & VacationHome & Condominium);
  }
  /**
   * No description
   * @tags Property
   * @name GetPartialPropertyPropertyPartialGetPropertyIdGet
   * @summary Get Partial Property
   * @request GET:/property/partial/get/{property_id}
   * @secure
   */
  export namespace GetPartialPropertyPropertyPartialGetPropertyIdGet {
    export type RequestParams = { propertyId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PartialProperty;
  }
  /**
   * No description
   * @tags Property
   * @name CreatePropertyPropertyCreateApplicationIdPost
   * @summary Create Property
   * @request POST:/property/create/{application_id}
   * @secure
   */
  export namespace CreatePropertyPropertyCreateApplicationIdPost {
    export type RequestParams = { applicationId: string };
    export type RequestQuery = {};
    export type RequestBody =
      | PartialHouse
      | PartialCondominium
      | PartialVacationHome
      | (PartialHouse & PartialCondominium & PartialVacationHome);
    export type RequestHeaders = {};
    export type ResponseBody = House | VacationHome | Condominium | (House & VacationHome & Condominium);
  }
  /**
   * No description
   * @tags Property
   * @name CreatePartialPropertyPropertyPartialCreateApplicationIdPost
   * @summary Create Partial Property
   * @request POST:/property/partial/create/{application_id}
   * @secure
   */
  export namespace CreatePartialPropertyPropertyPartialCreateApplicationIdPost {
    export type RequestParams = { applicationId: string };
    export type RequestQuery = {};
    export type RequestBody = NewPartialProperty;
    export type RequestHeaders = {};
    export type ResponseBody = PartialProperty;
  }
  /**
   * No description
   * @tags Property
   * @name UpdatePropertyPropertyUpdatePropertyIdPut
   * @summary Update Property
   * @request PUT:/property/update/{property_id}
   * @secure
   */
  export namespace UpdatePropertyPropertyUpdatePropertyIdPut {
    export type RequestParams = { propertyId: string };
    export type RequestQuery = {};
    export type RequestBody =
      | PartialHouse
      | PartialCondominium
      | PartialVacationHome
      | (PartialHouse & PartialCondominium & PartialVacationHome);
    export type RequestHeaders = {};
    export type ResponseBody = House | VacationHome | Condominium | (House & VacationHome & Condominium);
  }
  /**
   * No description
   * @tags Property
   * @name UpdatePartialPropertyPropertyPartialUpdatePropertyIdPut
   * @summary Update Partial Property
   * @request PUT:/property/partial/update/{property_id}
   * @secure
   */
  export namespace UpdatePartialPropertyPropertyPartialUpdatePropertyIdPut {
    export type RequestParams = { propertyId: string };
    export type RequestQuery = {};
    export type RequestBody = NewPartialProperty;
    export type RequestHeaders = {};
    export type ResponseBody = PartialProperty;
  }
  /**
   * No description
   * @tags Property Valuation
   * @name SearchPropertyValuationSearchPropertyIdPost
   * @summary Search
   * @request POST:/property/valuation/search/{property_id}
   * @secure
   */
  export namespace SearchPropertyValuationSearchPropertyIdPost {
    export type RequestParams = { propertyId: string };
    export type RequestQuery = {};
    export type RequestBody = RoutersPropertyPayloadSearchPayload;
    export type RequestHeaders = {};
    export type ResponseBody = PropertySearchResult[];
  }
  /**
   * No description
   * @tags Property Creation
   * @name SearchForAddressPropertyV2SearchPropertyTypePost
   * @summary Search For Address
   * @request POST:/property/v2/search/{property_type}
   * @secure
   */
  export namespace SearchForAddressPropertyV2SearchPropertyTypePost {
    export type RequestParams = { propertyType: MortgagePropertyType };
    export type RequestQuery = {};
    export type RequestBody = RoutersPropertyPayloadSearchPayload;
    export type RequestHeaders = {};
    export type ResponseBody = PropertySearchResult[];
  }
  /**
   * No description
   * @tags Property Creation
   * @name GetPropertyInfoForCreationPropertyV2InfoSearchIdGet
   * @summary Get Property Info For Creation
   * @request GET:/property/v2/info/{search_id}
   * @secure
   */
  export namespace GetPropertyInfoForCreationPropertyV2InfoSearchIdGet {
    export type RequestParams = { searchId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PropertyInfo;
  }
  /**
   * No description
   * @tags Property Creation
   * @name GetPropertyInfoByExternalIdPropertyV2InfoByExternalIdExternalIdGet
   * @summary Get Property Info By External Id
   * @request GET:/property/v2/info-by-external-id/{external_id}
   * @secure
   */
  export namespace GetPropertyInfoByExternalIdPropertyV2InfoByExternalIdExternalIdGet {
    export type RequestParams = { externalId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PropertyInfo;
  }
  /**
   * No description
   * @tags Property Valuation
   * @name GetPropertyValuationV2PropertyValuationV2Post
   * @summary Get Property Valuation V2
   * @request POST:/property/valuation/v2
   * @secure
   */
  export namespace GetPropertyValuationV2PropertyValuationV2Post {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ValuationPayloadV2;
    export type RequestHeaders = {};
    export type ResponseBody = PropertyValuation;
  }
  /**
   * No description
   * @tags Property Valuation
   * @name GetPropertyInfoPropertyValuationInfoSearchIdGet
   * @summary Get Property Info
   * @request GET:/property/valuation/info/{search_id}
   * @secure
   */
  export namespace GetPropertyInfoPropertyValuationInfoSearchIdGet {
    export type RequestParams = { searchId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PropertyInfo;
  }
  /**
   * No description
   * @tags Property Valuation
   * @name GetPropertyValuationPropertyValuationValuationPost
   * @summary Get Property Valuation
   * @request POST:/property/valuation/valuation
   * @secure
   */
  export namespace GetPropertyValuationPropertyValuationValuationPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ValuationPayload;
    export type RequestHeaders = {};
    export type ResponseBody = PropertyValuation;
  }
  /**
   * No description
   * @tags Property Creation
   * @name BooliSearchForAddressPropertyV2BooliSearchPost
   * @summary Booli Search For Address
   * @request POST:/property/v2/booli/search
   * @deprecated
   * @secure
   */
  export namespace BooliSearchForAddressPropertyV2BooliSearchPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BooliSearchPayload;
    export type RequestHeaders = {};
    export type ResponseBody = BooliPropertySearchResult[];
  }
  /**
   * No description
   * @tags Property Valuation
   * @name GetPropertyBooliValuationPropertyValuationBooliPost
   * @summary Get Property Booli Valuation
   * @request POST:/property/valuation/booli
   * @deprecated
   * @secure
   */
  export namespace GetPropertyBooliValuationPropertyValuationBooliPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BooliValuationPayload;
    export type RequestHeaders = {};
    export type ResponseBody = PropertyValuation;
  }
  /**
   * No description
   * @name GetPropertyByPniPropertyEvcheckCachePost
   * @summary Get Property By Pni
   * @request POST:/property/evcheck/cache
   * @secure
   */
  export namespace GetPropertyByPniPropertyEvcheckCachePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = EvCheckPayload;
    export type RequestHeaders = {};
    export type ResponseBody = (PartialHouse | PartialCondominium | (PartialHouse & PartialCondominium))[];
  }
}

export namespace Message {
  /**
   * No description
   * @tags Message
   * @name RemoveTemplateMessageTemplateDelete
   * @summary Remove Template
   * @request DELETE:/message/template/
   * @secure
   */
  export namespace RemoveTemplateMessageTemplateDelete {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetTemplatePayload;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags Message
   * @name GetOneTemplateMessageTemplateGetPost
   * @summary Get One Template
   * @request POST:/message/template/get
   * @secure
   */
  export namespace GetOneTemplateMessageTemplateGetPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetTemplatePayload;
    export type RequestHeaders = {};
    export type ResponseBody = RootModelUnionEmailTemplateSmsTemplate;
  }
  /**
   * No description
   * @tags Message
   * @name CreateTemplateMessageTemplateAddPost
   * @summary Create Template
   * @request POST:/message/template/add
   * @secure
   */
  export namespace CreateTemplateMessageTemplateAddPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = RootModelUnionEmailTemplateSmsTemplate;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags Message
   * @name TemplateListMessageTemplateListMessageTypeGet
   * @summary Template List
   * @request GET:/message/template/list/{message_type}
   * @secure
   */
  export namespace TemplateListMessageTemplateListMessageTypeGet {
    export type RequestParams = { messageType: MessageType };
    export type RequestQuery = { cutoff_date?: string | null };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = (EmailTemplate | SmsTemplate | (EmailTemplate & SmsTemplate))[];
  }
  /**
   * No description
   * @tags Message
   * @name SendTemplateMessageMessageSendTemplateMessageTypeTemplateEventPost
   * @summary Send Template Message
   * @request POST:/message/send/template/{message_type}/{template_event}
   * @secure
   */
  export namespace SendTemplateMessageMessageSendTemplateMessageTypeTemplateEventPost {
    export type RequestParams = { messageType: MessageType; templateEvent: TemplateEvent };
    export type RequestQuery = {};
    export type RequestBody = SendTemplatePayload;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags Message
   * @name SendRawMessageMessageSendRawMessageTypePost
   * @summary Send Raw Message
   * @request POST:/message/send/raw/{message_type}
   * @secure
   */
  export namespace SendRawMessageMessageSendRawMessageTypePost {
    export type RequestParams = { messageType: MessageType };
    export type RequestQuery = {};
    export type RequestBody = SendRawPayload;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags Message
   * @name GetUnknownSenderSmsMessageSmsUnknownSenderPost
   * @summary Get Unknown Sender Sms
   * @request POST:/message/sms/unknown-sender
   * @secure
   */
  export namespace GetUnknownSenderSmsMessageSmsUnknownSenderPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetMessagesPayload;
    export type RequestHeaders = {};
    export type ResponseBody = IncomingSMS[];
  }
  /**
   * No description
   * @tags Message
   * @name GetMessagesForUserMessageUserPost
   * @summary Get Messages For User
   * @request POST:/message/user
   * @secure
   */
  export namespace GetMessagesForUserMessageUserPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetUserMessagesPayload;
    export type RequestHeaders = {};
    export type ResponseBody = SavedMessage[];
  }
  /**
   * No description
   * @tags Message
   * @name GetMessagesForApplicationMessageApplicationPost
   * @summary Get Messages For Application
   * @request POST:/message/application
   * @secure
   */
  export namespace GetMessagesForApplicationMessageApplicationPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetApplicationMessagesPayload;
    export type RequestHeaders = {};
    export type ResponseBody = SavedMessage[];
  }
  /**
   * No description
   * @tags Message
   * @name GetMessagesByCustomerIdMessageByCustomerPost
   * @summary Get Messages By Customer Id
   * @request POST:/message/by-customer
   * @secure
   */
  export namespace GetMessagesByCustomerIdMessageByCustomerPost {
    export type RequestParams = {};
    export type RequestQuery = { page?: number; limit?: number };
    export type RequestBody = GetMessages;
    export type RequestHeaders = {};
    export type ResponseBody = SavedMessageWithName[];
  }
  /**
   * No description
   * @tags Message
   * @name GetConversationsMessageConversationsGet
   * @summary Get Conversations
   * @request GET:/message/conversations
   * @secure
   */
  export namespace GetConversationsMessageConversationsGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Conversation[];
  }
  /**
   * No description
   * @tags Message
   * @name SaveConversationsMessageConversationsPost
   * @summary Save Conversations
   * @request POST:/message/conversations
   * @secure
   */
  export namespace SaveConversationsMessageConversationsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = Conversation[];
    export type RequestHeaders = {};
    export type ResponseBody = Conversation[];
  }
  /**
   * No description
   * @tags Message
   * @name StreamConversationsMessageConversationsStreamGet
   * @summary Stream Conversations
   * @request GET:/message/conversations/stream
   * @secure
   */
  export namespace StreamConversationsMessageConversationsStreamGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags Message
   * @name UploadAttachmentMessageAttachmentsUploadPost
   * @summary Upload Attachment
   * @request POST:/message/attachments/upload
   * @secure
   */
  export namespace UploadAttachmentMessageAttachmentsUploadPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyUploadAttachmentMessageAttachmentsUploadPost;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags Message
   * @name GetAttachmentsMessageAttachmentsAllGet
   * @summary Get Attachments
   * @request GET:/message/attachments/all
   * @secure
   */
  export namespace GetAttachmentsMessageAttachmentsAllGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SavedAttachment[];
  }
  /**
   * No description
   * @tags ELK46 Callbacks
   * @name Elk46SmsWebhookMessageElk46ConfirmationPost
   * @summary Elk46 Sms Webhook
   * @request POST:/message/elk46/confirmation
   */
  export namespace Elk46SmsWebhookMessageElk46ConfirmationPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = { "x-forwarded-for": string };
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags ELK46 Callbacks
   * @name Elk46CallWebhookMessageElk46CallPost
   * @summary Elk46 Call Webhook
   * @request POST:/message/elk46/call
   */
  export namespace Elk46CallWebhookMessageElk46CallPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyElk46CallWebhookMessageElk46CallPost;
    export type RequestHeaders = {};
    export type ResponseBody = Elk46CallResponse;
  }
}

export namespace Mortgage {
  /**
   * No description
   * @tags Mortgage
   * @name GetKalpSettingsMortgageKalpCountryGet
   * @summary Get Kalp Settings
   * @request GET:/mortgage/kalp/{country}
   * @secure
   */
  export namespace GetKalpSettingsMortgageKalpCountryGet {
    export type RequestParams = { country: Country };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = (BankKALP | BankAPIDrivenKALP | (BankKALP & BankAPIDrivenKALP))[];
  }
}

export namespace Other {
  /**
   * No description
   * @tags Other
   * @name GetAllTaxesForCountryOtherTaxesCountryGet
   * @summary Get All Taxes For Country
   * @request GET:/other/taxes/{country}
   * @secure
   */
  export namespace GetAllTaxesForCountryOtherTaxesCountryGet {
    export type RequestParams = { country: Country };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Tax[];
  }
  /**
   * No description
   * @tags Other
   * @name TaxesForIdentifierOtherTaxesCalculationPost
   * @summary Taxes For Identifier
   * @request POST:/other/taxes/calculation
   * @secure
   */
  export namespace TaxesForIdentifierOtherTaxesCalculationPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetTaxPayload;
    export type RequestHeaders = {};
    export type ResponseBody = GetTaxPayloadResponse;
  }
  /**
   * No description
   * @tags Other
   * @name GetAllMunicipalitiesForCountryOtherMunicipalityCountryGet
   * @summary Get All Municipalities For Country
   * @request GET:/other/municipality/{country}
   * @secure
   */
  export namespace GetAllMunicipalitiesForCountryOtherMunicipalityCountryGet {
    export type RequestParams = { country: Country };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Municipality[];
  }
  /**
   * No description
   * @tags Other
   * @name DeactivationReasonsOtherDeactivationReasonsGet
   * @summary Deactivation Reasons
   * @request GET:/other/deactivation-reasons
   * @secure
   */
  export namespace DeactivationReasonsOtherDeactivationReasonsGet {
    export type RequestParams = {};
    export type RequestQuery = { product: Product };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string[];
  }
  /**
   * No description
   * @tags Other, websocket
   * @name GetTokenOtherWsTokenForWebsocketGet
   * @summary Get Token
   * @request GET:/other/ws/token-for-websocket
   * @secure
   */
  export namespace GetTokenOtherWsTokenForWebsocketGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TokenResp;
  }
}

export namespace BankId {
  /**
   * No description
   * @tags BankID
   * @name VerifyCustomerBankIdVerifyPost
   * @summary Verify Customer
   * @request POST:/bank-id/verify
   * @secure
   */
  export namespace VerifyCustomerBankIdVerifyPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BankIDPayload;
    export type RequestHeaders = {};
    export type ResponseBody = RoutersBankIdModelsBankIDResp;
  }
  /**
   * No description
   * @tags BankID
   * @name CancelVerifyCustomerBankIdCancelDelete
   * @summary Cancel Verify Customer
   * @request DELETE:/bank-id/cancel
   * @secure
   */
  export namespace CancelVerifyCustomerBankIdCancelDelete {
    export type RequestParams = {};
    export type RequestQuery = { ticket_id: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags BankID
   * @name IsAlreadyVerifiedBankIdVerifiedPost
   * @summary Is Already Verified
   * @request POST:/bank-id/verified
   * @secure
   */
  export namespace IsAlreadyVerifiedBankIdVerifiedPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BankIDPayload;
    export type RequestHeaders = {};
    export type ResponseBody = PollResp;
  }
  /**
   * No description
   * @tags BankID
   * @name GetTicketStatusBankIdVerifyTicketIdGet
   * @summary Get Ticket Status
   * @request GET:/bank-id/verify/{ticket_id}
   * @secure
   */
  export namespace GetTicketStatusBankIdVerifyTicketIdGet {
    export type RequestParams = { ticketId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PollResp;
  }
  /**
   * No description
   * @tags BankID
   * @name IdKollenRedirectNoBankIdIdKollenCallbackGet
   * @summary Id Kollen Redirect No
   * @request GET:/bank-id/id-kollen/callback
   */
  export namespace IdKollenRedirectNoBankIdIdKollenCallbackGet {
    export type RequestParams = {};
    export type RequestQuery = { refId?: string | null; id?: string | null };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = any;
  }
  /**
   * No description
   * @tags BankID
   * @name IdKollenCallbackSeBankIdIdKollenCallbackPost
   * @summary Id Kollen Callback Se
   * @request POST:/bank-id/id-kollen/callback
   */
  export namespace IdKollenCallbackSeBankIdIdKollenCallbackPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = IDKollenCollectResponse;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags BankID
   * @name QrCodeBankIdQrCodeGet
   * @summary Qr Code
   * @request GET:/bank-id/qr-code
   */
  export namespace QrCodeBankIdQrCodeGet {
    export type RequestParams = {};
    export type RequestQuery = { ticket_id: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = QRCodeContent;
  }
  /**
   * No description
   * @tags BankID
   * @name RedirectFromAppBankIdRedirectGet
   * @summary Redirect From App
   * @request GET:/bank-id/redirect
   */
  export namespace RedirectFromAppBankIdRedirectGet {
    export type RequestParams = {};
    export type RequestQuery = { ticket_id: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags BankID
   * @name LoginBankIdAuthPost
   * @summary Login
   * @request POST:/bank-id/auth
   */
  export namespace LoginBankIdAuthPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = LoginPayload;
    export type RequestHeaders = {};
    export type ResponseBody = RoutersBankIdLoginPayloadBankIDResp;
  }
  /**
   * No description
   * @tags BankID
   * @name PollTicketBankIdTicketIdGet
   * @summary Poll Ticket
   * @request GET:/bank-id/{ticket_id}
   */
  export namespace PollTicketBankIdTicketIdGet {
    export type RequestParams = { ticketId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BankIDLoginResponse;
  }
}

export namespace Notification {
  /**
   * No description
   * @tags Notifications
   * @name UserNotificationsNotificationGet
   * @summary User Notifications
   * @request GET:/notification/
   * @secure
   */
  export namespace UserNotificationsNotificationGet {
    export type RequestParams = {};
    export type RequestQuery = {
      status?: NotificationEvent[] | null;
      notification_type?: ModelsNotificationsNotificationTypesNotificationType | null;
      page?: number;
      limit?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = (
      | SMSReceived
      | BankMessageReceived
      | OfferReceived
      | ModelsNotificationsNotificationInternalComment
      | ApplicationAssigned
      | GeneralMessage
      | LeadAssigned
      | LeadComment
      | ApplicationDisbursed
    )[];
  }
  /**
   * No description
   * @tags Notifications
   * @name GetNotificationNotificationByIdNotificationIdGet
   * @summary Get Notification
   * @request GET:/notification/by-id/{notification_id}
   * @secure
   */
  export namespace GetNotificationNotificationByIdNotificationIdGet {
    export type RequestParams = { notificationId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody =
      | SMSReceived
      | BankMessageReceived
      | OfferReceived
      | ModelsNotificationsNotificationInternalComment
      | ApplicationAssigned
      | GeneralMessage
      | LeadAssigned
      | LeadComment
      | ApplicationDisbursed;
  }
  /**
   * No description
   * @tags Notifications
   * @name NotifyNotificationNotifyIncomingPost
   * @summary Notify
   * @request POST:/notification/notify/incoming
   * @secure
   */
  export namespace NotifyNotificationNotifyIncomingPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = Notification;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Notifications
   * @name NotifyInsuranceNotificationNotifyInsurancePost
   * @summary Notify Insurance
   * @request POST:/notification/notify/insurance
   * @secure
   */
  export namespace NotifyInsuranceNotificationNotifyInsurancePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = InsuranceNotification;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Notifications
   * @name AcknowledgeNotificationNotificationActionPost
   * @summary Acknowledge Notification
   * @request POST:/notification/action
   * @secure
   */
  export namespace AcknowledgeNotificationNotificationActionPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ActionPayload;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Notifications
   * @name CustomAuthUserNotificationsNotificationNotificationIdReceivedPatch
   * @summary Custom Auth User Notifications
   * @request PATCH:/notification/{notification_id}/received
   */
  export namespace CustomAuthUserNotificationsNotificationNotificationIdReceivedPatch {
    export type RequestParams = { notificationId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = { token?: string };
    export type ResponseBody = void;
  }
}

export namespace Ponytail {
  /**
   * No description
   * @tags Ponytail
   * @name GetDisbursedStatsPonytailDisbursedAllPost
   * @summary Get Disbursed Stats
   * @request POST:/ponytail/disbursed/all
   * @secure
   */
  export namespace GetDisbursedStatsPonytailDisbursedAllPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = DashboardPayload;
    export type RequestHeaders = {};
    export type ResponseBody = AllDisbursedLoans;
  }
  /**
   * No description
   * @tags Ponytail
   * @name GetMyDisbursedPonytailDisbursedMePost
   * @summary Get My Disbursed
   * @request POST:/ponytail/disbursed/me
   * @secure
   */
  export namespace GetMyDisbursedPonytailDisbursedMePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = DashboardPayload;
    export type RequestHeaders = {};
    export type ResponseBody = MyDisbursedLoans;
  }
  /**
   * No description
   * @tags Ponytail
   * @name GetAcceptedPonytailAcceptedAllPost
   * @summary Get Accepted
   * @request POST:/ponytail/accepted/all
   * @secure
   */
  export namespace GetAcceptedPonytailAcceptedAllPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = DashboardPayload;
    export type RequestHeaders = {};
    export type ResponseBody = AcceptedLoans;
  }
  /**
   * No description
   * @tags Ponytail
   * @name GetMyAcceptedPonytailAcceptedMePost
   * @summary Get My Accepted
   * @request POST:/ponytail/accepted/me
   * @secure
   */
  export namespace GetMyAcceptedPonytailAcceptedMePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = DashboardPayload;
    export type RequestHeaders = {};
    export type ResponseBody = AcceptedLoans;
  }
  /**
   * No description
   * @tags Ponytail
   * @name GetAllInsurancePonytailInsuranceAllPost
   * @summary Get All Insurance
   * @request POST:/ponytail/insurance/all
   * @secure
   */
  export namespace GetAllInsurancePonytailInsuranceAllPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = DashboardPayload;
    export type RequestHeaders = {};
    export type ResponseBody = AllInsurances;
  }
  /**
   * No description
   * @tags Ponytail
   * @name GetMyInsurancePonytailInsuranceMePost
   * @summary Get My Insurance
   * @request POST:/ponytail/insurance/me
   * @secure
   */
  export namespace GetMyInsurancePonytailInsuranceMePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = DashboardPayload;
    export type RequestHeaders = {};
    export type ResponseBody = AllInsurances;
  }
  /**
   * No description
   * @tags Ponytail
   * @name GetAllMyStatsPonytailMyStatsPost
   * @summary Get All My Stats
   * @request POST:/ponytail/my-stats
   * @secure
   */
  export namespace GetAllMyStatsPonytailMyStatsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = DashboardPayload;
    export type RequestHeaders = {};
    export type ResponseBody = RootModelUnionMyStatsMyStatsMb;
  }
  /**
   * No description
   * @tags Ponytail
   * @name SummarizedViewPonytailSummarizedPost
   * @summary Summarized View
   * @request POST:/ponytail/summarized
   * @secure
   */
  export namespace SummarizedViewPonytailSummarizedPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = DashboardPayload;
    export type RequestHeaders = {};
    export type ResponseBody = RootModelUnionSummarizedViewMyStatsMb;
  }
  /**
   * No description
   * @tags Ponytail
   * @name GetWorkingDaysPonytailWorkingDaysPost
   * @summary Get Working Days
   * @request POST:/ponytail/working-days
   * @secure
   */
  export namespace GetWorkingDaysPonytailWorkingDaysPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GoalPayload;
    export type RequestHeaders = {};
    export type ResponseBody = WorkingDays;
  }
  /**
   * No description
   * @tags Ponytail
   * @name PostBlancoGoalPonytailGoalsCreateBlancoPost
   * @summary Post Blanco Goal
   * @request POST:/ponytail/goals/create/blanco
   * @secure
   */
  export namespace PostBlancoGoalPonytailGoalsCreateBlancoPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BlancoGoalPayload;
    export type RequestHeaders = {};
    export type ResponseBody = BlancoGoal;
  }
  /**
   * No description
   * @tags Ponytail
   * @name PostBlancoGoalMbPonytailGoalsCreateBlancoMbPost
   * @summary Post Blanco Goal Mb
   * @request POST:/ponytail/goals/create/blanco-mb
   * @secure
   */
  export namespace PostBlancoGoalMbPonytailGoalsCreateBlancoMbPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BlancoGoalMbPayload;
    export type RequestHeaders = {};
    export type ResponseBody = BlancoGoalMb;
  }
  /**
   * No description
   * @tags Ponytail
   * @name PostInitialBlancoGoalPonytailGoalsCreateInitialBlancoPost
   * @summary Post Initial Blanco Goal
   * @request POST:/ponytail/goals/create/initial-blanco
   * @secure
   */
  export namespace PostInitialBlancoGoalPonytailGoalsCreateInitialBlancoPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BlancoGoalPayload;
    export type RequestHeaders = {};
    export type ResponseBody = BlancoGoal;
  }
  /**
   * No description
   * @tags Ponytail
   * @name PostInitialBudgetPonytailGoalsCreateInitialBlancoMbPost
   * @summary Post Initial Budget
   * @request POST:/ponytail/goals/create/initial-blanco-mb
   * @secure
   */
  export namespace PostInitialBudgetPonytailGoalsCreateInitialBlancoMbPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BudgetPayload;
    export type RequestHeaders = {};
    export type ResponseBody = Budget;
  }
  /**
   * No description
   * @tags Ponytail
   * @name GetMyGoalsPonytailGoalsPost
   * @summary Get My Goals
   * @request POST:/ponytail/goals
   * @secure
   */
  export namespace GetMyGoalsPonytailGoalsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GoalPayload;
    export type RequestHeaders = {};
    export type ResponseBody =
      | BlancoGoalMb
      | BlancoGoal
      | MortgageGoal
      | null
      | (BlancoGoalMb & BlancoGoal & MortgageGoal);
  }
  /**
   * No description
   * @tags Ponytail
   * @name GetInitialGoalPonytailGoalsInitialGoalPost
   * @summary Get Initial Goal
   * @request POST:/ponytail/goals/initial-goal
   * @secure
   */
  export namespace GetInitialGoalPonytailGoalsInitialGoalPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GoalPayload;
    export type RequestHeaders = {};
    export type ResponseBody = Budget | BlancoGoal | MortgageGoal | null | (Budget & BlancoGoal & MortgageGoal);
  }
}

export namespace Lead {
  /**
   * No description
   * @tags Lead
   * @name GetLeadLeadGetLeadIdGet
   * @summary Get Lead
   * @request GET:/lead/get/{lead_id}
   * @secure
   */
  export namespace GetLeadLeadGetLeadIdGet {
    export type RequestParams = { leadId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Lead;
  }
  /**
   * No description
   * @tags Lead
   * @name GetLeadsLeadListPost
   * @summary Get Leads
   * @request POST:/lead/list
   * @secure
   */
  export namespace GetLeadsLeadListPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetLeads;
    export type RequestHeaders = {};
    export type ResponseBody = Lead[];
  }
  /**
   * No description
   * @tags Lead
   * @name CreateLeadLeadCreatePost
   * @summary Create Lead
   * @request POST:/lead/create
   * @secure
   */
  export namespace CreateLeadLeadCreatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateLeadPayload;
    export type RequestHeaders = {};
    export type ResponseBody = Lead;
  }
  /**
   * No description
   * @tags Lead
   * @name SearchLeadSearchPost
   * @summary Search
   * @request POST:/lead/search
   * @secure
   */
  export namespace SearchLeadSearchPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = RoutersLeadPayloadSearchPayload;
    export type RequestHeaders = {};
    export type ResponseBody = Lead[];
  }
  /**
   * No description
   * @tags Lead
   * @name ConvertAndCloseLeadConvertAndMarkHandledPost
   * @summary Convert And Close
   * @request POST:/lead/convert_and_mark_handled
   * @secure
   */
  export namespace ConvertAndCloseLeadConvertAndMarkHandledPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ConvertPayload;
    export type RequestHeaders = {};
    export type ResponseBody = ConvertResult;
  }
  /**
   * No description
   * @tags Lead
   * @name CloseLeadMarkHandledPost
   * @summary Close
   * @request POST:/lead/mark_handled
   * @secure
   */
  export namespace CloseLeadMarkHandledPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = LeadHandledPayload;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Lead
   * @name UnhandleLeadLeadUnhandlePost
   * @summary Unhandle Lead
   * @request POST:/lead/unhandle
   * @secure
   */
  export namespace UnhandleLeadLeadUnhandlePost {
    export type RequestParams = {};
    export type RequestQuery = { lead_id: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags Lead
   * @name UpdateLeadLeadUpdateLeadIdPost
   * @summary Update Lead
   * @request POST:/lead/update/{lead_id}
   * @secure
   */
  export namespace UpdateLeadLeadUpdateLeadIdPost {
    export type RequestParams = { leadId: string };
    export type RequestQuery = {};
    export type RequestBody = LeadUpdatePayload;
    export type RequestHeaders = {};
    export type ResponseBody = Lead;
  }
  /**
   * No description
   * @tags Lead
   * @name ReprocessSubmissionLeadReprocessSubmissionLeadIdPost
   * @summary Reprocess Submission
   * @request POST:/lead/reprocess_submission/{lead_id}
   * @secure
   */
  export namespace ReprocessSubmissionLeadReprocessSubmissionLeadIdPost {
    export type RequestParams = { leadId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags Lead
   * @name ReprocessSubmissionsLeadReprocessSubmissionsPost
   * @summary Reprocess Submissions
   * @request POST:/lead/reprocess_submissions
   * @secure
   */
  export namespace ReprocessSubmissionsLeadReprocessSubmissionsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ReprocessSubmissionPayload;
    export type RequestHeaders = {};
    export type ResponseBody = ReprocessResult[];
  }
  /**
   * No description
   * @tags Lead
   * @name GetCommentsLeadCommentLeadIdGet
   * @summary Get Comments
   * @request GET:/lead/comment/{lead_id}
   * @secure
   */
  export namespace GetCommentsLeadCommentLeadIdGet {
    export type RequestParams = { leadId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = InternalCommentWithName[];
  }
  /**
   * No description
   * @tags Lead
   * @name AddInternalCommentLeadCommentLeadIdPost
   * @summary Add Internal Comment
   * @request POST:/lead/comment/{lead_id}
   * @secure
   */
  export namespace AddInternalCommentLeadCommentLeadIdPost {
    export type RequestParams = { leadId: string };
    export type RequestQuery = {};
    export type RequestBody = CommentPayload;
    export type RequestHeaders = {};
    export type ResponseBody = InternalCommentWithName[];
  }
  /**
   * No description
   * @tags Lead
   * @name AssignLeadToHandlerLeadAssignLeadIdPost
   * @summary Assign Lead To Handler
   * @request POST:/lead/assign/{lead_id}
   * @secure
   */
  export namespace AssignLeadToHandlerLeadAssignLeadIdPost {
    export type RequestParams = { leadId: string };
    export type RequestQuery = {};
    export type RequestBody = AssignApplicationPayload;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Lead
   * @name GetAssignedHandlerLeadAssigneeLeadIdGet
   * @summary Get Assigned Handler
   * @request GET:/lead/assignee/{lead_id}
   * @secure
   */
  export namespace GetAssignedHandlerLeadAssigneeLeadIdGet {
    export type RequestParams = { leadId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Assignee;
  }
  /**
   * No description
   * @tags Lead
   * @name GetLeadsLeadListMinePost
   * @summary Get Leads
   * @request POST:/lead/list/mine
   * @secure
   */
  export namespace GetLeadsLeadListMinePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetMyLeads;
    export type RequestHeaders = {};
    export type ResponseBody = Lead[];
  }
}

export namespace Formprefs {
  /**
   * No description
   * @tags Jotform
   * @name UpdatePreferenceFormprefsPut
   * @summary Update Preference
   * @request PUT:/formprefs/
   * @secure
   */
  export namespace UpdatePreferenceFormprefsPut {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UpdateFormPreferencePayload;
    export type RequestHeaders = {};
    export type ResponseBody = FormPreferenceExt;
  }
  /**
   * No description
   * @tags Jotform
   * @name CreatePreferenceFormprefsPost
   * @summary Create Preference
   * @request POST:/formprefs/
   * @secure
   */
  export namespace CreatePreferenceFormprefsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = FormPreferencePayload;
    export type RequestHeaders = {};
    export type ResponseBody = FormPreferenceExt;
  }
  /**
   * No description
   * @tags Jotform
   * @name DuplicatePreferenceFormprefsDuplicatePost
   * @summary Duplicate Preference
   * @request POST:/formprefs/duplicate
   * @secure
   */
  export namespace DuplicatePreferenceFormprefsDuplicatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = DuplicateFormPreferencePayload;
    export type RequestHeaders = {};
    export type ResponseBody = FormPreferenceExt;
  }
  /**
   * No description
   * @tags Jotform
   * @name ValidatePreferenceFormprefsValidatePost
   * @summary Validate Preference
   * @request POST:/formprefs/validate
   * @secure
   */
  export namespace ValidatePreferenceFormprefsValidatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ValidatePayload;
    export type RequestHeaders = {};
    export type ResponseBody = FormPreferenceExt;
  }
  /**
   * No description
   * @tags Jotform
   * @name GetMappingFormprefsGetMappingPost
   * @summary Get Mapping
   * @request POST:/formprefs/get_mapping
   * @secure
   */
  export namespace GetMappingFormprefsGetMappingPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BaseFormPreferencePayload;
    export type RequestHeaders = {};
    export type ResponseBody = FormMapping;
  }
  /**
   * No description
   * @tags Jotform
   * @name ListPreferencesFormprefsListGet
   * @summary List Preferences
   * @request GET:/formprefs/list
   * @secure
   */
  export namespace ListPreferencesFormprefsListGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = FormPreference[];
  }
  /**
   * No description
   * @tags Jotform
   * @name ListPreferencesExtFormprefsListExtGet
   * @summary List Preferences Ext
   * @request GET:/formprefs/list_ext
   * @secure
   */
  export namespace ListPreferencesExtFormprefsListExtGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = FormPreferenceExt[];
  }
  /**
   * No description
   * @tags Jotform
   * @name ListFormsFormprefsFormsGet
   * @summary List Forms
   * @request GET:/formprefs/forms
   * @secure
   */
  export namespace ListFormsFormprefsFormsGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Form[];
  }
  /**
   * No description
   * @tags Jotform
   * @name CheckAllSubmissionsFormprefsCheckAllGet
   * @summary Check All Submissions
   * @request GET:/formprefs/check-all
   * @secure
   */
  export namespace CheckAllSubmissionsFormprefsCheckAllGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags Jotform
   * @name GetAllSubmissionsFormprefsSubmissionsGet
   * @summary Get All Submissions
   * @request GET:/formprefs/submissions
   * @secure
   */
  export namespace GetAllSubmissionsFormprefsSubmissionsGet {
    export type RequestParams = {};
    export type RequestQuery = { form_id?: string | null };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags Jotform
   * @name GetFormPreferenceFormprefsFormPreferenceIdGet
   * @summary Get Form Preference
   * @request GET:/formprefs/{form_preference_id}
   * @secure
   */
  export namespace GetFormPreferenceFormprefsFormPreferenceIdGet {
    export type RequestParams = { formPreferenceId: string };
    export type RequestQuery = { add_defaults?: boolean };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = FormPreferenceExt;
  }
  /**
   * No description
   * @tags Jotform
   * @name CreateWebhookFormprefsWebhookPost
   * @summary Create Webhook
   * @request POST:/formprefs/webhook
   * @secure
   */
  export namespace CreateWebhookFormprefsWebhookPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = FormWebhookPayload;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags Jotform
   * @name DeleteWebhookFormprefsWebhookDelete
   * @summary Delete Webhook
   * @request DELETE:/formprefs/webhook
   * @secure
   */
  export namespace DeleteWebhookFormprefsWebhookDelete {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = FormWebhookPayload;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
}

export namespace Heimdall {
  /**
   * No description
   * @tags Heimdall
   * @name GetAllHeimdallRecordsForDateHeimdallDateGet
   * @summary Get All Heimdall Records For Date
   * @request GET:/heimdall/{date}
   * @secure
   */
  export namespace GetAllHeimdallRecordsForDateHeimdallDateGet {
    export type RequestParams = { date: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = HeimdallResponse[];
  }
  /**
   * No description
   * @tags Heimdall
   * @name ReportAndVerifyUsageToHeimdallHeimdallUserIdPatch
   * @summary Report And Verify Usage To Heimdall
   * @request PATCH:/heimdall/{user_id}
   * @secure
   */
  export namespace ReportAndVerifyUsageToHeimdallHeimdallUserIdPatch {
    export type RequestParams = { userId: string };
    export type RequestQuery = {};
    export type RequestBody = HeimdallReportingPayload;
    export type RequestHeaders = {};
    export type ResponseBody = HeimdallReportingResponse;
  }
  /**
   * No description
   * @tags Heimdall
   * @name ResetHeimdallRateLimitHeimdallUserIdToResetResetPatch
   * @summary Reset Heimdall Rate Limit
   * @request PATCH:/heimdall/{user_id_to_reset}/reset
   * @secure
   */
  export namespace ResetHeimdallRateLimitHeimdallUserIdToResetResetPatch {
    export type RequestParams = { userIdToReset: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace LarsMagnus {
  /**
   * No description
   * @tags Lars Magnus
   * @name CheckTelavoxIdsLarsMagnusTelavoxCheckGet
   * @summary Check Telavox Ids
   * @request GET:/lars-magnus/telavox-check
   * @secure
   */
  export namespace CheckTelavoxIdsLarsMagnusTelavoxCheckGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TelavoxMismatch;
  }
  /**
   * No description
   * @tags Lars Magnus
   * @name CurrentTelavoxUserLarsMagnusTelavoxUserGet
   * @summary Current Telavox User
   * @request GET:/lars-magnus/telavox-user
   * @secure
   */
  export namespace CurrentTelavoxUserLarsMagnusTelavoxUserGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TelavoxExtension;
  }
}

export namespace MyPages {
  /**
   * No description
   * @tags My Pages
   * @name ImpersonateCustomerMyPagesImpersonatePost
   * @summary Impersonate Customer
   * @request POST:/my-pages/impersonate
   * @secure
   */
  export namespace ImpersonateCustomerMyPagesImpersonatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ImpersonateCustomerPayload;
    export type RequestHeaders = {};
    export type ResponseBody = BankIDLoginResponse;
  }
  /**
   * No description
   * @tags My Pages
   * @name ValidateClearingMyPagesOffersClearingValidateClearingGet
   * @summary Validate Clearing
   * @request GET:/my-pages/offers/clearing/validate/{clearing}
   */
  export namespace ValidateClearingMyPagesOffersClearingValidateClearingGet {
    export type RequestParams = { clearing: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmptyModel;
  }
  /**
   * @description Gather request md and proceed with accepting terms for insurance used for customers that are not authenticated and cannot change the amount
   * @tags My Pages
   * @name ConfirmInsuranceTermsMyPagesInsurancesConfirmPost
   * @summary Confirm Insurance Terms
   * @request POST:/my-pages/insurances/confirm
   */
  export namespace ConfirmInsuranceTermsMyPagesInsurancesConfirmPost {
    export type RequestParams = {};
    export type RequestQuery = {
      encrypted_insurance_id: string;
      email: string;
      phone_number: string;
      success_url: string;
      fail_url: string;
      insured_amount?: number | null;
      premium?: number | null;
    };
    export type RequestBody = never;
    export type RequestHeaders = { "user-agent"?: string | null; origin?: string | null };
    export type ResponseBody = AcceptTermsResponse;
  }
  /**
   * No description
   * @tags My Pages
   * @name GetInsuranceMyPagesInsuranceGet
   * @summary Get Insurance
   * @request GET:/my-pages/insurance
   */
  export namespace GetInsuranceMyPagesInsuranceGet {
    export type RequestParams = {};
    export type RequestQuery = { id: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UnauthenticatedInsurance;
  }
  /**
   * No description
   * @tags My Pages
   * @name GetLatestActiveApplicationsV2MyPagesV2ApplicationsLatestGet
   * @summary Get Latest Active Applications V2
   * @request GET:/my-pages/v2/applications/latest
   * @secure
   */
  export namespace GetLatestActiveApplicationsV2MyPagesV2ApplicationsLatestGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CustomerApplicationV2[];
  }
  /**
   * No description
   * @tags My Pages
   * @name GetLatestActiveApplicationMyPagesApplicationLatestGet
   * @summary Get Latest Active Application
   * @request GET:/my-pages/application/latest
   * @secure
   */
  export namespace GetLatestActiveApplicationMyPagesApplicationLatestGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CustomerApplication | null;
  }
  /**
   * No description
   * @tags My Pages
   * @name GetCreditCardOffersMyPagesCreditCardOffersGet
   * @summary Get Credit Card Offers
   * @request GET:/my-pages/credit_card/offers
   * @secure
   */
  export namespace GetCreditCardOffersMyPagesCreditCardOffersGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CreditorOfferCreditCardOfferCreditCardTerms[];
  }
  /**
   * No description
   * @tags My Pages
   * @name GetApplicantMyPagesApplicantMeGet
   * @summary Get Applicant
   * @request GET:/my-pages/applicant/me
   * @secure
   */
  export namespace GetApplicantMyPagesApplicantMeGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ApplicantSE | ApplicantNO;
  }
  /**
   * @description Legacy blanco endpoint (Sverker)
   * @tags My Pages
   * @name AcceptOfferLegacyMyPagesOfferAcceptPost
   * @summary Accept Offer Legacy
   * @request POST:/my-pages/offer/accept
   * @secure
   */
  export namespace AcceptOfferLegacyMyPagesOfferAcceptPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = AcceptOfferPayload;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * @description Legacy blanco endpoint (Sverker)
   * @tags My Pages
   * @name AcceptOfferLegacyV2MyPagesV2OfferAcceptPost
   * @summary Accept Offer Legacy V2
   * @request POST:/my-pages/v2/offer/accept
   * @secure
   */
  export namespace AcceptOfferLegacyV2MyPagesV2OfferAcceptPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = AcceptOfferPayloadV2;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags My Pages
   * @name AcceptOfferMyPagesOfferAccept2Post
   * @summary Accept Offer
   * @request POST:/my-pages/offer/accept2
   * @secure
   */
  export namespace AcceptOfferMyPagesOfferAccept2Post {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = AcceptOffer;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags My Pages
   * @name BanksToExcludeFromInsuranceOfferingForMyPagesInsurancesBanksExcludedGet
   * @summary Banks To Exclude From Insurance Offering For
   * @request GET:/my-pages/insurances/banks/excluded
   * @deprecated
   * @secure
   */
  export namespace BanksToExcludeFromInsuranceOfferingForMyPagesInsurancesBanksExcludedGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BanksToExcludeInsuranceOfferingFor;
  }
  /**
   * No description
   * @tags My Pages
   * @name InsurancesRulesMyPagesInsurancesRulesGet
   * @summary Insurances Rules
   * @request GET:/my-pages/insurances/rules
   * @secure
   */
  export namespace InsurancesRulesMyPagesInsurancesRulesGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = InsuranceTerms[];
  }
  /**
   * No description
   * @tags My Pages
   * @name LoanInsurancesRulesMyPagesInsurancesLoanInsuranceRulesGet
   * @summary Loan Insurances Rules
   * @request GET:/my-pages/insurances/loan-insurance-rules
   * @secure
   */
  export namespace LoanInsurancesRulesMyPagesInsurancesLoanInsuranceRulesGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = InsuranceTerms;
  }
  /**
   * No description
   * @tags My Pages
   * @name IdCheckMyPagesIdCheckGet
   * @summary Id Check
   * @request GET:/my-pages/id-check
   * @secure
   */
  export namespace IdCheckMyPagesIdCheckGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody =
      | (ApplicantSE | ApplicantNO)
      | (OptionalApplicantSE | OptionalApplicantNO)
      | IDCheckedPerson
      | ((ApplicantSE | ApplicantNO) & (OptionalApplicantSE | OptionalApplicantNO) & IDCheckedPerson);
  }
  /**
   * No description
   * @tags My Pages
   * @name ActiveInsuranceByPniMyPagesInsurancesGet
   * @summary Active Insurance By Pni
   * @request GET:/my-pages/insurances
   * @secure
   */
  export namespace ActiveInsuranceByPniMyPagesInsurancesGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = InsuranceCustomer[];
  }
  /**
   * No description
   * @tags My Pages
   * @name CreateInsuranceV2MyPagesInsurancesV2Post
   * @summary Create Insurance V2
   * @request POST:/my-pages/insurances/v2
   * @secure
   */
  export namespace CreateInsuranceV2MyPagesInsurancesV2Post {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateInsuranceCustomer;
    export type RequestHeaders = { "user-agent"?: string | null; origin?: string | null };
    export type ResponseBody = AcceptTermsResponseCustomer;
  }
  /**
   * No description
   * @tags My Pages
   * @name DebtRegisterConsentMyPagesDebtRegisterConsentPost
   * @summary Debt Register Consent
   * @request POST:/my-pages/debt-register/consent
   * @secure
   */
  export namespace DebtRegisterConsentMyPagesDebtRegisterConsentPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = { "user-agent"?: string | null; origin?: string | null };
    export type ResponseBody =
      | (ApplicantSE | ApplicantNO)
      | (OptionalApplicantSE | OptionalApplicantNO)
      | ((ApplicantSE | ApplicantNO) & (OptionalApplicantSE | OptionalApplicantNO));
  }
  /**
   * No description
   * @tags My Pages
   * @name DebtRegisterCheckConsentMyPagesDebtRegisterCheckConsentPost
   * @summary Debt Register Check Consent
   * @request POST:/my-pages/debt-register/check-consent
   * @secure
   */
  export namespace DebtRegisterCheckConsentMyPagesDebtRegisterCheckConsentPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody =
      | (ApplicantSE | ApplicantNO)
      | (OptionalApplicantSE | OptionalApplicantNO)
      | ((ApplicantSE | ApplicantNO) & (OptionalApplicantSE | OptionalApplicantNO));
  }
  /**
   * No description
   * @tags My Pages
   * @name DebtRegisterRenewMyPagesDebtRegisterRenewPost
   * @summary Debt Register Renew
   * @request POST:/my-pages/debt-register/renew
   * @secure
   */
  export namespace DebtRegisterRenewMyPagesDebtRegisterRenewPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody =
      | (ApplicantSE | ApplicantNO)
      | (OptionalApplicantSE | OptionalApplicantNO)
      | ((ApplicantSE | ApplicantNO) & (OptionalApplicantSE | OptionalApplicantNO));
  }
  /**
   * No description
   * @tags My Pages
   * @name UpsertMarketingConsentMyPagesMarketingConsentsPost
   * @summary Upsert Marketing Consent
   * @request POST:/my-pages/marketing-consents
   * @secure
   */
  export namespace UpsertMarketingConsentMyPagesMarketingConsentsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ConsentsPayload;
    export type RequestHeaders = { "user-agent"?: string | null; origin?: string | null };
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags My Pages
   * @name GetTrustlyUrlForNewMandateMyPagesInsurancesTrustlyUrlGet
   * @summary Get Trustly Url For New Mandate
   * @request GET:/my-pages/insurances/trustly-url
   * @secure
   */
  export namespace GetTrustlyUrlForNewMandateMyPagesInsurancesTrustlyUrlGet {
    export type RequestParams = {};
    export type RequestQuery = { id: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags My Pages
   * @name GetLifeInsuranceEligiabilityMyPagesLifeInsuranceEligiabilityCustomerIdGet
   * @summary Get Life Insurance Eligiability
   * @request GET:/my-pages/life-insurance-eligiability/{customer_id}
   * @secure
   */
  export namespace GetLifeInsuranceEligiabilityMyPagesLifeInsuranceEligiabilityCustomerIdGet {
    export type RequestParams = { customerId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = HadOfferEverResponse;
  }
}

export namespace Zms {
  /**
   * No description
   * @tags ZMS
   * @name SaveZmsConfigZmsCountryPost
   * @summary Save Zms Config
   * @request POST:/zms/{country}
   * @secure
   */
  export namespace SaveZmsConfigZmsCountryPost {
    export type RequestParams = { country: Country };
    export type RequestQuery = {};
    export type RequestBody = Config;
    export type RequestHeaders = {};
    export type ResponseBody = ZMSConfig;
  }
  /**
   * No description
   * @tags ZMS
   * @name GetZmsConfigZmsCountryGet
   * @summary Get Zms Config
   * @request GET:/zms/{country}
   */
  export namespace GetZmsConfigZmsCountryGet {
    export type RequestParams = { country: Country };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ZMSConfig;
  }
  /**
   * No description
   * @tags ZMS
   * @name GetAllConfigsZmsGet
   * @summary Get All Configs
   * @request GET:/zms
   */
  export namespace GetAllConfigsZmsGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ZMSConfig[];
  }
}

export namespace Affiliates {
  /**
   * No description
   * @tags Affiliate
   * @name CreateAffiliateAffiliatesPost
   * @summary Create Affiliate
   * @request POST:/affiliates/
   * @secure
   */
  export namespace CreateAffiliateAffiliatesPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = AffiliateData;
    export type RequestHeaders = {};
    export type ResponseBody = Affiliate;
  }
  /**
   * No description
   * @tags Affiliate
   * @name GetAffiliatesAffiliatesGet
   * @summary Get Affiliates
   * @request GET:/affiliates/
   * @secure
   */
  export namespace GetAffiliatesAffiliatesGet {
    export type RequestParams = {};
    export type RequestQuery = {
      page?: number;
      limit?: number;
      status?: string | null;
      network?: boolean | null;
      publisher?: boolean | null;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Affiliate[];
  }
  /**
   * No description
   * @tags Affiliate
   * @name SearchAffiliatesAffiliatesSearchGet
   * @summary Search Affiliates
   * @request GET:/affiliates/search
   * @secure
   */
  export namespace SearchAffiliatesAffiliatesSearchGet {
    export type RequestParams = {};
    export type RequestQuery = { keyword: string; page?: number; limit?: number };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Affiliate[];
  }
  /**
   * No description
   * @tags Affiliate
   * @name GetAffiliateAffiliatesAffiliateIdGet
   * @summary Get Affiliate
   * @request GET:/affiliates/{affiliate_id}
   * @secure
   */
  export namespace GetAffiliateAffiliatesAffiliateIdGet {
    export type RequestParams = { affiliateId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Affiliate;
  }
  /**
   * No description
   * @tags Affiliate
   * @name UpdateAffiliateAffiliatesAffiliateIdPut
   * @summary Update Affiliate
   * @request PUT:/affiliates/{affiliate_id}
   * @secure
   */
  export namespace UpdateAffiliateAffiliatesAffiliateIdPut {
    export type RequestParams = { affiliateId: string };
    export type RequestQuery = {};
    export type RequestBody = AffiliateData;
    export type RequestHeaders = {};
    export type ResponseBody = Affiliate;
  }
  /**
   * No description
   * @tags Affiliate
   * @name DeactivateAffiliateAffiliatesAffiliateIdDelete
   * @summary Deactivate Affiliate
   * @request DELETE:/affiliates/{affiliate_id}
   * @secure
   */
  export namespace DeactivateAffiliateAffiliatesAffiliateIdDelete {
    export type RequestParams = { affiliateId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags Affiliate
   * @name CreateCampaignAffiliatesAffiliateIdCampaignsPost
   * @summary Create Campaign
   * @request POST:/affiliates/{affiliate_id}/campaigns
   * @secure
   */
  export namespace CreateCampaignAffiliatesAffiliateIdCampaignsPost {
    export type RequestParams = { affiliateId: string };
    export type RequestQuery = {};
    export type RequestBody = CampaignData;
    export type RequestHeaders = {};
    export type ResponseBody = Affiliate;
  }
  /**
   * No description
   * @tags Affiliate
   * @name GetCampaignsAffiliatesAffiliateIdCampaignsGet
   * @summary Get Campaigns
   * @request GET:/affiliates/{affiliate_id}/campaigns
   * @secure
   */
  export namespace GetCampaignsAffiliatesAffiliateIdCampaignsGet {
    export type RequestParams = { affiliateId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ModelsAffiliateAffiliateCampaign[];
  }
  /**
   * No description
   * @tags Affiliate
   * @name UpdateCampaignAffiliatesAffiliateIdCampaignsCampaignIdPut
   * @summary Update Campaign
   * @request PUT:/affiliates/{affiliate_id}/campaigns/{campaign_id}
   * @secure
   */
  export namespace UpdateCampaignAffiliatesAffiliateIdCampaignsCampaignIdPut {
    export type RequestParams = { campaignId: string; affiliateId: string };
    export type RequestQuery = {};
    export type RequestBody = CampaignData;
    export type RequestHeaders = {};
    export type ResponseBody = Affiliate;
  }
  /**
   * No description
   * @tags Affiliate
   * @name RemoveCampaignAffiliatesAffiliateIdCampaignsCampaignIdDelete
   * @summary Remove Campaign
   * @request DELETE:/affiliates/{affiliate_id}/campaigns/{campaign_id}
   * @secure
   */
  export namespace RemoveCampaignAffiliatesAffiliateIdCampaignsCampaignIdDelete {
    export type RequestParams = { campaignId: string; affiliateId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags Affiliate
   * @name GetPersonEventAffiliatesPersonsZidGet
   * @summary Get Person Event
   * @request GET:/affiliates/persons/{zid}
   * @secure
   */
  export namespace GetPersonEventAffiliatesPersonsZidGet {
    export type RequestParams = { zid: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Person;
  }
  /**
   * No description
   * @tags Affiliate
   * @name GetAffiliatePersonEventsAffiliatesAffiliateIdPersonsGet
   * @summary Get Affiliate Person Events
   * @request GET:/affiliates/{affiliate_id}/persons
   * @secure
   */
  export namespace GetAffiliatePersonEventsAffiliatesAffiliateIdPersonsGet {
    export type RequestParams = { affiliateId: string };
    export type RequestQuery = { page?: number; limit?: number };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Person[];
  }
  /**
   * No description
   * @tags Affiliate
   * @name GetAffiliateCallbacksAffiliatesAffiliateNetworkIdCallbacksPost
   * @summary Get Affiliate Callbacks
   * @request POST:/affiliates/{affiliate_network_id}/callbacks
   * @secure
   */
  export namespace GetAffiliateCallbacksAffiliatesAffiliateNetworkIdCallbacksPost {
    export type RequestParams = { affiliateNetworkId: string };
    export type RequestQuery = {
      page?: number;
      limit?: number;
      publisher?: string | null;
      start_date?: string | null;
      end_date?: string | null;
    };
    export type RequestBody = AffiliateNetworkCallbackStatus[] | null;
    export type RequestHeaders = {};
    export type ResponseBody = AffiliateNetworkCallbackEvent[];
  }
  /**
   * No description
   * @tags Affiliate
   * @name GetAffiliatePublishersAffiliatesAffiliateIdPublishersGet
   * @summary Get Affiliate Publishers
   * @request GET:/affiliates/{affiliate_id}/publishers
   * @secure
   */
  export namespace GetAffiliatePublishersAffiliatesAffiliateIdPublishersGet {
    export type RequestParams = { affiliateId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Affiliate[];
  }
  /**
   * No description
   * @tags Affiliate
   * @name AdtractionNetworkCallbackAffiliatesDevAdtractionCallbackSiteTtGet
   * @summary Adtraction Network Callback
   * @request GET:/affiliates/dev/adtraction/callback/{site}/t/t
   * @secure
   */
  export namespace AdtractionNetworkCallbackAffiliatesDevAdtractionCallbackSiteTtGet {
    export type RequestParams = { site: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = HTTPStatus;
  }
  /**
   * No description
   * @tags Affiliate
   * @name AdserviceNetworkCallbackAffiliatesDevAdserviceCallbackCgiBinApiConversionServiceCallbackGet
   * @summary Adservice Network Callback
   * @request GET:/affiliates/dev/adservice/callback/cgi-bin/API/ConversionService/Callback
   * @secure
   */
  export namespace AdserviceNetworkCallbackAffiliatesDevAdserviceCallbackCgiBinApiConversionServiceCallbackGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = HTTPStatus;
  }
  /**
   * No description
   * @tags Affiliate
   * @name SmartresponseNetworkCallbackAffiliatesDevSmartresponseCallbackPAshxGet
   * @summary Smartresponse Network Callback
   * @request GET:/affiliates/dev/smartresponse/callback/p.ashx
   * @secure
   */
  export namespace SmartresponseNetworkCallbackAffiliatesDevSmartresponseCallbackPAshxGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = HTTPStatus;
  }
}

export namespace Online {
  /**
   * @description Get all submissions for a country
   * @tags Online
   * @name GetAllSubmissionsOnlineSubmissionsGet
   * @summary Get All Submissions
   * @request GET:/online/submissions
   * @secure
   */
  export namespace GetAllSubmissionsOnlineSubmissionsGet {
    export type RequestParams = {};
    export type RequestQuery = {
      failed?: boolean | null;
      handled?: boolean | null;
      form_validation?: boolean | null;
      quick_application?: boolean | null;
      get_customers?: boolean | null;
      has_recent_app_check?: boolean | null;
      created_application?: boolean | null;
      app_created_hours_ago?: number | null;
      limit?: number | null;
      page?: number | null;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SubmissionReviewResponse;
  }
  /**
   * @description Get submission by id
   * @tags Online
   * @name GetASubmissionOnlineSubmissionsSubmissionIdGet
   * @summary Get A Submission
   * @request GET:/online/submissions/{submission_id}
   * @secure
   */
  export namespace GetASubmissionOnlineSubmissionsSubmissionIdGet {
    export type RequestParams = { submissionId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SubmissionReview;
  }
  /**
   * @description Retry processing of submissions in case of temporary error
   * @tags Online
   * @name PostReprocessSubmissionsOnlineSubmissionsReprocessPost
   * @summary Post Reprocess Submissions
   * @request POST:/online/submissions/reprocess
   * @secure
   */
  export namespace PostReprocessSubmissionsOnlineSubmissionsReprocessPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = string[];
    export type RequestHeaders = {};
    export type ResponseBody = EnqueuedSubmissionJobResult[];
  }
  /**
   * @description Retry processing of submission in case of temporary error
   * @tags Online
   * @name PostReprocessSubmissionOnlineSubmissionsSubmissionIdReprocessPost
   * @summary Post Reprocess Submission
   * @request POST:/online/submissions/{submission_id}/reprocess
   * @secure
   */
  export namespace PostReprocessSubmissionOnlineSubmissionsSubmissionIdReprocessPost {
    export type RequestParams = { submissionId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EnqueuedSubmissionJobResult;
  }
  /**
   * No description
   * @tags Online
   * @name PostResubmitSubmissionOnlineSubmissionsSubmissionIdResubmitPost
   * @summary Post Resubmit Submission
   * @request POST:/online/submissions/{submission_id}/resubmit
   * @secure
   */
  export namespace PostResubmitSubmissionOnlineSubmissionsSubmissionIdResubmitPost {
    export type RequestParams = { submissionId: string };
    export type RequestQuery = {};
    export type RequestBody = ResubmitPayload;
    export type RequestHeaders = {};
    export type ResponseBody = EnqueuedSubmissionJobResult;
  }
  /**
   * No description
   * @tags Online
   * @name PostReviewSubmissionOnlineSubmissionsSubmissionIdReviewPost
   * @summary Post Review Submission
   * @request POST:/online/submissions/{submission_id}/review
   * @secure
   */
  export namespace PostReviewSubmissionOnlineSubmissionsSubmissionIdReviewPost {
    export type RequestParams = { submissionId: string };
    export type RequestQuery = { handled?: boolean };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = HandledSubmission;
  }
  /**
   * No description
   * @tags Online
   * @name PostReviewSubmissionsOnlineSubmissionsReviewPost
   * @summary Post Review Submissions
   * @request POST:/online/submissions/review
   * @secure
   */
  export namespace PostReviewSubmissionsOnlineSubmissionsReviewPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ReviewSubmissions;
    export type RequestHeaders = {};
    export type ResponseBody = HandledSubmission[];
  }
  /**
   * @description Converts a credit card submission to a blanco submission
   * @tags Online
   * @name PostConvertToBlancoOnlineSubmissionsSubmissionIdConvertToBlancoPost
   * @summary Post Convert To Blanco
   * @request POST:/online/submissions/{submission_id}/convert_to_blanco
   * @secure
   */
  export namespace PostConvertToBlancoOnlineSubmissionsSubmissionIdConvertToBlancoPost {
    export type RequestParams = { submissionId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EnqueuedSubmissionJobResult;
  }
  /**
   * No description
   * @tags Online
   * @name OnlineAppsMessagesOnlineSendBlancoSmsMessagesPost
   * @summary Online Apps Messages
   * @request POST:/online/send_blanco_sms_messages
   * @secure
   */
  export namespace OnlineAppsMessagesOnlineSendBlancoSmsMessagesPost {
    export type RequestParams = {};
    export type RequestQuery = { country: Country; product: Product };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags Online
   * @name WhitelistedPniOnlineTestingPnisGet
   * @summary Whitelisted Pni
   * @request GET:/online/testing-pnis
   * @secure
   */
  export namespace WhitelistedPniOnlineTestingPnisGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = OnlineFormsTestingPNIResponse;
  }
  /**
   * No description
   * @tags Online
   * @name GetBookingsOnlineBookingsCountryGet
   * @summary Get Bookings
   * @request GET:/online/bookings/{country}
   */
  export namespace GetBookingsOnlineBookingsCountryGet {
    export type RequestParams = { country: Country };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmptyModel;
  }
  /**
   * No description
   * @tags Online
   * @name BookMeetingOnlineBookingsCountryPost
   * @summary Book Meeting
   * @request POST:/online/bookings/{country}
   */
  export namespace BookMeetingOnlineBookingsCountryPost {
    export type RequestParams = { country: Country };
    export type RequestQuery = {};
    export type RequestBody = BookingPayload;
    export type RequestHeaders = {};
    export type ResponseBody = ServicesSverkerResponsesMeeting;
  }
  /**
   * No description
   * @tags Online
   * @name ContactFormOnlineContactCountryPost
   * @summary Contact Form
   * @request POST:/online/contact/{country}
   */
  export namespace ContactFormOnlineContactCountryPost {
    export type RequestParams = { country: Country };
    export type RequestQuery = {};
    export type RequestBody = ContactFormPayload;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags Online
   * @name MortgageFormOnlineMortgageCountryPost
   * @summary Mortgage Form
   * @request POST:/online/mortgage/{country}
   */
  export namespace MortgageFormOnlineMortgageCountryPost {
    export type RequestParams = { country: Country };
    export type RequestQuery = {};
    export type RequestBody = MortgageFormPayload;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags Online
   * @name BlancoApplicationOnlineBlancoApplicationCountryPost
   * @summary Blanco Application
   * @request POST:/online/blanco-application/{country}
   */
  export namespace BlancoApplicationOnlineBlancoApplicationCountryPost {
    export type RequestParams = { country: Country };
    export type RequestQuery = {};
    export type RequestBody = EmptyModel;
    export type RequestHeaders = {
      "x-forwarded-for"?: string | null;
      "user-agent"?: string | null;
      origin?: string | null;
      "x-zid"?: string | null;
      "x-s-zid"?: string | null;
      "x-gid"?: string | null;
      "x-google-ids"?: string | null;
    };
    export type ResponseBody = FormApplicationSubmissionResult;
  }
  /**
   * No description
   * @tags Online
   * @name OnlineQuickCreateOnlineQuickCreatePost
   * @summary Online Quick Create
   * @request POST:/online/quick-create
   */
  export namespace OnlineQuickCreateOnlineQuickCreatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody =
      | (QuickCreateBlancoApplication | QuickCreateMortgageApplication)
      | object
      | ((QuickCreateBlancoApplication | QuickCreateMortgageApplication) & object);
    export type RequestHeaders = {};
    export type ResponseBody = FormApplicationSubmissionResult;
  }
  /**
   * @description Norway only
   * @tags Online
   * @name NorwegianMortgageApplicationOnlineNorwegianMortgageApplicationPost
   * @summary Norwegian Mortgage Application
   * @request POST:/online/norwegian-mortgage-application
   */
  export namespace NorwegianMortgageApplicationOnlineNorwegianMortgageApplicationPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = EmptyModel;
    export type RequestHeaders = {
      "x-forwarded-for"?: string | null;
      "user-agent"?: string | null;
      origin?: string | null;
      "x-zid"?: string | null;
      "x-gid"?: string | null;
      "x-s-zid"?: string | null;
      "x-google-ids"?: string | null;
    };
    export type ResponseBody = FormApplicationSubmissionResult;
  }
}

export namespace Rq {
  /**
   * No description
   * @tags Monitor
   * @name GetListOfJobsRqJobsGet
   * @summary Get List Of Jobs
   * @request GET:/rq/jobs
   * @secure
   */
  export namespace GetListOfJobsRqJobsGet {
    export type RequestParams = {};
    export type RequestQuery = { job_type: JobType };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = RqJobStatusResponse[];
  }
  /**
   * No description
   * @tags Monitor
   * @name GetFailedJobsRqManualFailGet
   * @summary Get Failed Jobs
   * @request GET:/rq/manual-fail
   * @secure
   */
  export namespace GetFailedJobsRqManualFailGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags Monitor
   * @name ClearFailedJobRqDeleteJobJobIdGet
   * @summary Clear Failed Job
   * @request GET:/rq/delete-job/{job_id}
   * @secure
   */
  export namespace ClearFailedJobRqDeleteJobJobIdGet {
    export type RequestParams = { jobId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags Monitor
   * @name ReScheduleRqReScheduleGet
   * @summary Re Schedule
   * @request GET:/rq/re-schedule
   * @secure
   */
  export namespace ReScheduleRqReScheduleGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string[];
  }
  /**
   * No description
   * @tags Monitor
   * @name GetSingleJobRqJobIdGet
   * @summary Get Single Job
   * @request GET:/rq/{job_id}
   * @secure
   */
  export namespace GetSingleJobRqJobIdGet {
    export type RequestParams = { jobId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = RqJobStatusResponse | null;
  }
}

export namespace Kdlt {
  /**
   * No description
   * @tags kdlt
   * @name CallbackKdltCallbackPost
   * @summary Callback
   * @request POST:/kdlt/callback
   * @secure
   */
  export namespace CallbackKdltCallbackPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = KDLTCallbackPayload;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace DialMaker {
  /**
   * No description
   * @tags Dial Maker
   * @name GetLeadsForCampaignDialMakerTmLeadsCampaignIdPost
   * @summary Get Leads For Campaign
   * @request POST:/dial-maker/tm-leads/{campaign_id}
   * @secure
   */
  export namespace GetLeadsForCampaignDialMakerTmLeadsCampaignIdPost {
    export type RequestParams = { campaignId: string };
    export type RequestQuery = {};
    export type RequestBody = RoutersDialMakerPayloadsLeadExtraction;
    export type RequestHeaders = {};
    export type ResponseBody = TMLeadsJob;
  }
  /**
   * No description
   * @tags Dial Maker
   * @name GetJobStatusOrResultDialMakerJobStatusJobIdGet
   * @summary Get Job Status Or Result
   * @request GET:/dial-maker/job-status/{job_id}
   * @secure
   */
  export namespace GetJobStatusOrResultDialMakerJobStatusJobIdGet {
    export type RequestParams = { jobId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TMLeadsExtraction | TMLeadsJob | (TMLeadsExtraction & TMLeadsJob);
  }
  /**
   * No description
   * @tags Dial Maker
   * @name GetDownloadUrlDialMakerJobResultJobIdDownloadUrlGet
   * @summary Get Download Url
   * @request GET:/dial-maker/job-result/{job_id}/download-url
   * @secure
   */
  export namespace GetDownloadUrlDialMakerJobResultJobIdDownloadUrlGet {
    export type RequestParams = { jobId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = RoutersDialMakerPayloadsSignedUrl;
  }
  /**
   * No description
   * @tags Dial Maker
   * @name GetCampaignsDialMakerCampaignsGet
   * @summary Get Campaigns
   * @request GET:/dial-maker/campaigns
   * @secure
   */
  export namespace GetCampaignsDialMakerCampaignsGet {
    export type RequestParams = {};
    export type RequestQuery = {
      page: number;
      limit: number;
      department?: ZDepartment | null;
      country?: Country | null;
      product?: Product | null;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TMCampaignList;
  }
  /**
   * No description
   * @tags Dial Maker
   * @name CreateCampaignDialMakerCampaignsPost
   * @summary Create Campaign
   * @request POST:/dial-maker/campaigns
   * @secure
   */
  export namespace CreateCampaignDialMakerCampaignsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateTMCampaign;
    export type RequestHeaders = {};
    export type ResponseBody = TMCampaign;
  }
  /**
   * No description
   * @tags Dial Maker
   * @name UpdateCampaignDialMakerCampaignsCampaignIdPut
   * @summary Update Campaign
   * @request PUT:/dial-maker/campaigns/{campaign_id}
   * @secure
   */
  export namespace UpdateCampaignDialMakerCampaignsCampaignIdPut {
    export type RequestParams = { campaignId: string };
    export type RequestQuery = {};
    export type RequestBody = CreateTMCampaign;
    export type RequestHeaders = {};
    export type ResponseBody = TMCampaign;
  }
  /**
   * No description
   * @tags Dial Maker
   * @name GetCampaignDialMakerCampaignsCampaignIdGet
   * @summary Get Campaign
   * @request GET:/dial-maker/campaigns/{campaign_id}
   * @secure
   */
  export namespace GetCampaignDialMakerCampaignsCampaignIdGet {
    export type RequestParams = { campaignId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TMCampaign;
  }
  /**
   * No description
   * @tags Dial Maker
   * @name DeleteCampaignDialMakerCampaignsCampaignIdDelete
   * @summary Delete Campaign
   * @request DELETE:/dial-maker/campaigns/{campaign_id}
   * @secure
   */
  export namespace DeleteCampaignDialMakerCampaignsCampaignIdDelete {
    export type RequestParams = { campaignId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = boolean;
  }
  /**
   * No description
   * @tags Dial Maker
   * @name GetCampaignExtractionsDialMakerCampaignsCampaignIdExtractionsGet
   * @summary Get Campaign Extractions
   * @request GET:/dial-maker/campaigns/{campaign_id}/extractions
   * @secure
   */
  export namespace GetCampaignExtractionsDialMakerCampaignsCampaignIdExtractionsGet {
    export type RequestParams = { campaignId: string };
    export type RequestQuery = { page: number; limit: number };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TMLeadsExtraction[];
  }
  /**
   * No description
   * @tags Dial Maker
   * @name DownloadXlsxDialMakerDownloadSignatureGet
   * @summary Download Xlsx
   * @request GET:/dial-maker/download/{signature}
   */
  export namespace DownloadXlsxDialMakerDownloadSignatureGet {
    export type RequestParams = { signature: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace Dialmaker {
  /**
   * No description
   * @tags Dial Maker 2
   * @name CreateCampaignDialmakerCampaignPost
   * @summary Create Campaign
   * @request POST:/dialmaker/campaign
   * @secure
   */
  export namespace CreateCampaignDialmakerCampaignPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateCampaignData;
    export type RequestHeaders = {};
    export type ResponseBody = ModelsDialMaker2CampaignAggregateCampaign;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name UpdateCampaignDialmakerCampaignCampaignIdPut
   * @summary Update Campaign
   * @request PUT:/dialmaker/campaign/{campaign_id}
   * @secure
   */
  export namespace UpdateCampaignDialmakerCampaignCampaignIdPut {
    export type RequestParams = { campaignId: string };
    export type RequestQuery = {};
    export type RequestBody = UpdateCampaignData;
    export type RequestHeaders = {};
    export type ResponseBody = ModelsDialMaker2CampaignAggregateCampaign;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name GetCampaignDialmakerCampaignCampaignIdGet
   * @summary Get Campaign
   * @request GET:/dialmaker/campaign/{campaign_id}
   * @secure
   */
  export namespace GetCampaignDialmakerCampaignCampaignIdGet {
    export type RequestParams = { campaignId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ModelsDialMaker2CampaignAggregateCampaign;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name UpdateCampaignAutoDialDialmakerCampaignCampaignIdAutoDialPut
   * @summary Update Campaign Auto Dial
   * @request PUT:/dialmaker/campaign/{campaign_id}/auto-dial
   * @secure
   */
  export namespace UpdateCampaignAutoDialDialmakerCampaignCampaignIdAutoDialPut {
    export type RequestParams = { campaignId: string };
    export type RequestQuery = { auto_dial: boolean };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ModelsDialMaker2CampaignAggregateCampaign;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name GetCampaignSourceDialmakerCampaignCampaignIdDataSourceGet
   * @summary Get Campaign Source
   * @request GET:/dialmaker/campaign/{campaign_id}/data/source
   * @secure
   */
  export namespace GetCampaignSourceDialmakerCampaignCampaignIdDataSourceGet {
    export type RequestParams = { campaignId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody =
      | SheetCampaignSource
      | BigQueryCampaignSource
      | ProcessCampaignSource
      | (SheetCampaignSource & BigQueryCampaignSource & ProcessCampaignSource);
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name GetCampaignExtractionsDialmakerCampaignCampaignIdExtractionsGet
   * @summary Get Campaign Extractions
   * @request GET:/dialmaker/campaign/{campaign_id}/extractions
   * @secure
   */
  export namespace GetCampaignExtractionsDialmakerCampaignCampaignIdExtractionsGet {
    export type RequestParams = { campaignId: string };
    export type RequestQuery = { page?: number; size?: number };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PageCampaignExtraction;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name GetCampaignSheetsDialmakerCampaignCampaignIdDataSheetsGet
   * @summary Get Campaign Sheets
   * @request GET:/dialmaker/campaign/{campaign_id}/data/sheets
   * @secure
   */
  export namespace GetCampaignSheetsDialmakerCampaignCampaignIdDataSheetsGet {
    export type RequestParams = { campaignId: string };
    export type RequestQuery = { page?: number; size?: number };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PageSheet;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name DeactivateCampaignDialmakerCampaignDeactivatePost
   * @summary Deactivate Campaign
   * @request POST:/dialmaker/campaign/deactivate
   * @secure
   */
  export namespace DeactivateCampaignDialmakerCampaignDeactivatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyDeactivateCampaignDialmakerCampaignDeactivatePost;
    export type RequestHeaders = {};
    export type ResponseBody = ModelsDialMaker2CampaignAggregateCampaign;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name ActivateCampaignDialmakerCampaignActivatePost
   * @summary Activate Campaign
   * @request POST:/dialmaker/campaign/activate
   * @secure
   */
  export namespace ActivateCampaignDialmakerCampaignActivatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyActivateCampaignDialmakerCampaignActivatePost;
    export type RequestHeaders = {};
    export type ResponseBody = ModelsDialMaker2CampaignAggregateCampaign;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name ExtractCampaignsDialmakerCampaignExtractPost
   * @summary Extract Campaigns
   * @request POST:/dialmaker/campaign/extract
   * @secure
   */
  export namespace ExtractCampaignsDialmakerCampaignExtractPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyExtractCampaignsDialmakerCampaignExtractPost;
    export type RequestHeaders = {};
    export type ResponseBody = ModelsDialMaker2CampaignAggregateCampaign;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name DeleteExtractionDialmakerCampaignExtractionDelete
   * @summary Delete Extraction
   * @request DELETE:/dialmaker/campaign/extraction
   * @secure
   */
  export namespace DeleteExtractionDialmakerCampaignExtractionDelete {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyDeleteExtractionDialmakerCampaignExtractionDelete;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name FindCampaignsDialmakerCampaignFindPost
   * @summary Find Campaigns
   * @request POST:/dialmaker/campaign/find
   * @secure
   */
  export namespace FindCampaignsDialmakerCampaignFindPost {
    export type RequestParams = {};
    export type RequestQuery = { page?: number; size?: number };
    export type RequestBody = BodyFindCampaignsDialmakerCampaignFindPost;
    export type RequestHeaders = {};
    export type ResponseBody = PageCampaign;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name GetLeadsDialmakerCampaignLeadsCampaignIdGet
   * @summary Get Leads
   * @request GET:/dialmaker/campaign/leads/{campaign_id}
   * @secure
   */
  export namespace GetLeadsDialmakerCampaignLeadsCampaignIdGet {
    export type RequestParams = { campaignId: string };
    export type RequestQuery = { page?: number; size?: number };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PageDMLead;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name ImportLeadsDialmakerCampaignImportPost
   * @summary Import Leads
   * @request POST:/dialmaker/campaign/import
   * @secure
   */
  export namespace ImportLeadsDialmakerCampaignImportPost {
    export type RequestParams = {};
    export type RequestQuery = { synchronous?: boolean };
    export type RequestBody = BodyImportLeadsDialmakerCampaignImportPost;
    export type RequestHeaders = {};
    export type ResponseBody = ModelsDialMaker2CampaignAggregateCampaign | string;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name GetJobStatusOrResultDialmakerCampaignJobStatusJobIdGet
   * @summary Get Job Status Or Result
   * @request GET:/dialmaker/campaign/job-status/{job_id}
   * @secure
   */
  export namespace GetJobStatusOrResultDialmakerCampaignJobStatusJobIdGet {
    export type RequestParams = { jobId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = InterimLeadJob | InterimLead[] | (InterimLeadJob & InterimLead[]);
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name UploadAttachmentDialmakerCampaignImageUploadPost
   * @summary Upload Attachment
   * @request POST:/dialmaker/campaign/image/upload
   * @secure
   */
  export namespace UploadAttachmentDialmakerCampaignImageUploadPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyUploadAttachmentDialmakerCampaignImageUploadPost;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name ExportAndExtractLeadsUrlDialmakerCampaignExportPost
   * @summary Export And Extract Leads Url
   * @request POST:/dialmaker/campaign/export
   * @secure
   */
  export namespace ExportAndExtractLeadsUrlDialmakerCampaignExportPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyExportAndExtractLeadsUrlDialmakerCampaignExportPost;
    export type RequestHeaders = {};
    export type ResponseBody = RoutersDialMaker2PayloadsSignedUrl;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name ExportAndExtractLeadsUrlDownloadDialmakerCampaignExtractionExportPost
   * @summary Export And Extract Leads Url Download
   * @request POST:/dialmaker/campaign/extraction/export
   * @secure
   */
  export namespace ExportAndExtractLeadsUrlDownloadDialmakerCampaignExtractionExportPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyExportAndExtractLeadsUrlDownloadDialmakerCampaignExtractionExportPost;
    export type RequestHeaders = {};
    export type ResponseBody = RoutersDialMaker2PayloadsSignedUrl;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name GetOngoingExtractionsDialmakerCampaignCampaignIdOngoingExtractionsPost
   * @summary Get Ongoing Extractions
   * @request POST:/dialmaker/campaign/{campaign_id}/ongoing_extractions
   * @secure
   */
  export namespace GetOngoingExtractionsDialmakerCampaignCampaignIdOngoingExtractionsPost {
    export type RequestParams = { campaignId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CampaignOngoingExtraction[];
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name GetOutcomesReportDialmakerCampaignSearchLeadsPost
   * @summary Get Outcomes Report
   * @request POST:/dialmaker/campaign/search-leads
   * @secure
   */
  export namespace GetOutcomesReportDialmakerCampaignSearchLeadsPost {
    export type RequestParams = {};
    export type RequestQuery = { page?: number; size?: number };
    export type RequestBody = SearchLeadsPayload;
    export type RequestHeaders = {};
    export type ResponseBody = PageDMLead | null;
  }
  /**
   * @description This endpoint is used to get statistics: - Leads with 0 outcomes - Available leads to call - Already extracted count - Leads left to extract (cold campaigns only)
   * @tags Dial Maker 2
   * @name GetExtractionsStatsDialmakerCampaignCampaignIdExtractionsStatsGet
   * @summary Get Extractions Stats
   * @request GET:/dialmaker/campaign/{campaign_id}/extractions_stats
   * @secure
   */
  export namespace GetExtractionsStatsDialmakerCampaignCampaignIdExtractionsStatsGet {
    export type RequestParams = { campaignId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CampaignExtractionsStats;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name GetCampaignsByIdsDialmakerCampaignGetCampaignsByIdsPost
   * @summary Get Campaigns By Ids
   * @request POST:/dialmaker/campaign/get_campaigns_by_ids
   * @secure
   */
  export namespace GetCampaignsByIdsDialmakerCampaignGetCampaignsByIdsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = string[];
    export type RequestHeaders = {};
    export type ResponseBody = ModelsDialMaker2CampaignAggregateCampaign[];
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name SeedCampaignsDialmakerCampaignProcessSeedPost
   * @summary Seed Campaigns
   * @request POST:/dialmaker/campaign/process/seed
   * @secure
   */
  export namespace SeedCampaignsDialmakerCampaignProcessSeedPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Record<string, UpsertCampaignStatus>;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name FetchAndHandleInsurancesDialmakerCampaignProcessFetchAndHandlePost
   * @summary Fetch And Handle Insurances
   * @request POST:/dialmaker/campaign/process/fetch_and_handle
   * @secure
   */
  export namespace FetchAndHandleInsurancesDialmakerCampaignProcessFetchAndHandlePost {
    export type RequestParams = {};
    export type RequestQuery = { product: ExtendedProduct; country: Country };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name SendMailToCampaignManuallyDialmakerCampaignCampaignIdMailSendManuallyPost
   * @summary Send Mail To Campaign Manually
   * @request POST:/dialmaker/campaign/{campaign_id}/mail/send_manually
   * @secure
   */
  export namespace SendMailToCampaignManuallyDialmakerCampaignCampaignIdMailSendManuallyPost {
    export type RequestParams = { campaignId: string };
    export type RequestQuery = { limit_amount_of_recipients?: number | null };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name GetMailStatisticsDialmakerCampaignMailStatisticsPost
   * @summary Get Mail Statistics
   * @request POST:/dialmaker/campaign/mail/statistics
   * @secure
   */
  export namespace GetMailStatisticsDialmakerCampaignMailStatisticsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyGetMailStatisticsDialmakerCampaignMailStatisticsPost;
    export type RequestHeaders = {};
    export type ResponseBody = CampaignMailStats[];
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name DryRunMailTemplateDialmakerCampaignCampaignIdMailTemplateSlugDryRunPost
   * @summary Dry Run Mail Template
   * @request POST:/dialmaker/campaign/{campaign_id}/mail/template/{slug}/dry-run
   * @secure
   */
  export namespace DryRunMailTemplateDialmakerCampaignCampaignIdMailTemplateSlugDryRunPost {
    export type RequestParams = { campaignId: string; slug: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TemplateDryRunResult;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name SendTestMailDialmakerCampaignMailSendTestPost
   * @summary Send Test Mail
   * @request POST:/dialmaker/campaign/mail/send_test
   * @secure
   */
  export namespace SendTestMailDialmakerCampaignMailSendTestPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = TestTemplatePayload;
    export type RequestHeaders = {};
    export type ResponseBody = SentTemplateResponse[];
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name GetMailLeadsOverviewDialmakerCampaignCampaignIdMailLeadsGet
   * @summary Get Mail Leads Overview
   * @request GET:/dialmaker/campaign/{campaign_id}/mail/leads
   * @secure
   */
  export namespace GetMailLeadsOverviewDialmakerCampaignCampaignIdMailLeadsGet {
    export type RequestParams = { campaignId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CampaignMailOverview;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name GetUserCampaignsDialmakerCampaignsGet
   * @summary Get User Campaigns
   * @request GET:/dialmaker/campaigns
   * @deprecated
   * @secure
   */
  export namespace GetUserCampaignsDialmakerCampaignsGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UserCampaignsExt | null;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name AvailableCampaignsDialmakerCampaignsAvailablePost
   * @summary Available Campaigns
   * @request POST:/dialmaker/campaigns/available
   * @secure
   */
  export namespace AvailableCampaignsDialmakerCampaignsAvailablePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Available;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name JoinCampaignDialmakerCampaignsJoinPost
   * @summary Join Campaign
   * @request POST:/dialmaker/campaigns/join
   * @secure
   */
  export namespace JoinCampaignDialmakerCampaignsJoinPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyJoinCampaignDialmakerCampaignsJoinPost;
    export type RequestHeaders = {};
    export type ResponseBody = Available;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name LeaveCampaignDialmakerCampaignsLeavePost
   * @summary Leave Campaign
   * @request POST:/dialmaker/campaigns/leave
   * @secure
   */
  export namespace LeaveCampaignDialmakerCampaignsLeavePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyLeaveCampaignDialmakerCampaignsLeavePost;
    export type RequestHeaders = {};
    export type ResponseBody = Available;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name PlayCampaignDialmakerCampaignsPlayPost
   * @summary Play Campaign
   * @request POST:/dialmaker/campaigns/play
   * @secure
   */
  export namespace PlayCampaignDialmakerCampaignsPlayPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyPlayCampaignDialmakerCampaignsPlayPost;
    export type RequestHeaders = {};
    export type ResponseBody = Available;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name PauseCampaignDialmakerCampaignsPausePost
   * @summary Pause Campaign
   * @request POST:/dialmaker/campaigns/pause
   * @secure
   */
  export namespace PauseCampaignDialmakerCampaignsPausePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyPauseCampaignDialmakerCampaignsPausePost;
    export type RequestHeaders = {};
    export type ResponseBody = Available;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name GetCardDialmakerCardNextPost
   * @summary Get Card
   * @request POST:/dialmaker/card/next
   * @secure
   */
  export namespace GetCardDialmakerCardNextPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = DMLeadExt | null;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name ViewDialmakerCardViewPost
   * @summary View
   * @request POST:/dialmaker/card/view
   * @secure
   */
  export namespace ViewDialmakerCardViewPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyViewDialmakerCardViewPost;
    export type RequestHeaders = {};
    export type ResponseBody = DMLeadExt;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name UpdateDialmakerCardUpdatePost
   * @summary Update
   * @request POST:/dialmaker/card/update
   * @secure
   */
  export namespace UpdateDialmakerCardUpdatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyUpdateDialmakerCardUpdatePost;
    export type RequestHeaders = {};
    export type ResponseBody = DMLead;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name HangupDialmakerCardHangupPost
   * @summary Hangup
   * @request POST:/dialmaker/card/hangup
   * @secure
   */
  export namespace HangupDialmakerCardHangupPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name CallSuccessDialmakerCardOutcomeSuccessPost
   * @summary Call Success
   * @request POST:/dialmaker/card/outcome/success
   * @secure
   */
  export namespace CallSuccessDialmakerCardOutcomeSuccessPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyCallSuccessDialmakerCardOutcomeSuccessPost;
    export type RequestHeaders = {};
    export type ResponseBody = DMLeadExt | null;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name CallBackDialmakerCardOutcomeCallBackPost
   * @summary Call Back
   * @request POST:/dialmaker/card/outcome/call_back
   * @secure
   */
  export namespace CallBackDialmakerCardOutcomeCallBackPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyCallBackDialmakerCardOutcomeCallBackPost;
    export type RequestHeaders = {};
    export type ResponseBody = DMLeadExt | null;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name CallBackDialmakerCardOutcomeLaterPost
   * @summary Call Back
   * @request POST:/dialmaker/card/outcome/later
   * @secure
   */
  export namespace CallBackDialmakerCardOutcomeLaterPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyCallBackDialmakerCardOutcomeLaterPost;
    export type RequestHeaders = {};
    export type ResponseBody = DMLeadExt | null;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name CallNoContactDialmakerCardOutcomeNoContactPost
   * @summary Call No Contact
   * @request POST:/dialmaker/card/outcome/no_contact
   * @secure
   */
  export namespace CallNoContactDialmakerCardOutcomeNoContactPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyCallNoContactDialmakerCardOutcomeNoContactPost;
    export type RequestHeaders = {};
    export type ResponseBody = DMLeadExt | null;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name CallDiscardsDialmakerCardOutcomeDiscardPost
   * @summary Call Discards
   * @request POST:/dialmaker/card/outcome/discard
   * @secure
   */
  export namespace CallDiscardsDialmakerCardOutcomeDiscardPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyCallDiscardsDialmakerCardOutcomeDiscardPost;
    export type RequestHeaders = {};
    export type ResponseBody = DMLeadExt | null;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name CallFailureDialmakerCardOutcomeFailurePost
   * @summary Call Failure
   * @request POST:/dialmaker/card/outcome/failure
   * @secure
   */
  export namespace CallFailureDialmakerCardOutcomeFailurePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyCallFailureDialmakerCardOutcomeFailurePost;
    export type RequestHeaders = {};
    export type ResponseBody = DMLeadExt | null;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name GetOutcomesDialmakerCardOutcomesPost
   * @summary Get Outcomes
   * @request POST:/dialmaker/card/outcomes
   * @secure
   */
  export namespace GetOutcomesDialmakerCardOutcomesPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyGetOutcomesDialmakerCardOutcomesPost;
    export type RequestHeaders = {};
    export type ResponseBody = Outcomes;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name SearchDialmakerCardSearchPost
   * @summary Search
   * @request POST:/dialmaker/card/search
   * @secure
   */
  export namespace SearchDialmakerCardSearchPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodySearchDialmakerCardSearchPost;
    export type RequestHeaders = {};
    export type ResponseBody = DMLeadExt[];
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name LockDialmakerCardLockPost
   * @summary Lock
   * @request POST:/dialmaker/card/lock
   * @secure
   */
  export namespace LockDialmakerCardLockPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyLockDialmakerCardLockPost;
    export type RequestHeaders = {};
    export type ResponseBody = DMLead;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name PersonalCallbacksDialmakerCardPersonalCallbacksPost
   * @summary Personal Callbacks
   * @request POST:/dialmaker/card/personal_callbacks
   * @secure
   */
  export namespace PersonalCallbacksDialmakerCardPersonalCallbacksPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = DMLeadExt[];
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name GetSlotsDialmakerCardSlotsGet
   * @summary Get Slots
   * @request GET:/dialmaker/card/slots
   * @secure
   */
  export namespace GetSlotsDialmakerCardSlotsGet {
    export type RequestParams = {};
    export type RequestQuery = { date: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BookableSlot[];
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name GetPreviousAppsDialmakerCardPreviousAppsPost
   * @summary Get Previous Apps
   * @request POST:/dialmaker/card/previous_apps
   * @secure
   */
  export namespace GetPreviousAppsDialmakerCardPreviousAppsPost {
    export type RequestParams = {};
    export type RequestQuery = { customer_id: string; country: Country; pni?: string | null };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PreviousApplicationsList;
  }
  /**
   * @description Get all insurances for a customer, sorted by creation date.
   * @tags Dial Maker 2
   * @name GetCustomerInsurancesDialmakerCardCustomerInsurancesPost
   * @summary Get Customer Insurances
   * @request POST:/dialmaker/card/customer_insurances
   * @secure
   */
  export namespace GetCustomerInsurancesDialmakerCardCustomerInsurancesPost {
    export type RequestParams = {};
    export type RequestQuery = { customer_id: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Insurance[];
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name MineDialmakerCardOutcomesMineTodayPost
   * @summary Mine
   * @request POST:/dialmaker/card/outcomes/mine_today
   * @secure
   */
  export namespace MineDialmakerCardOutcomesMineTodayPost {
    export type RequestParams = {};
    export type RequestQuery = { start_date: string; end_date: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = OutcomeCount;
  }
  /**
   * @description Upload file and return a preview of sheet, together with the file id needed to import leads. Uploaded file whether it's csv or xlsx should have headers in the first row to be able to map the columns to the lead data model.
   * @tags Dial Maker 2
   * @name UploadDialmakerSheetCampaignIdUploadWithPreviewPost
   * @summary Upload
   * @request POST:/dialmaker/sheet/{campaign_id}/upload_with_preview
   * @secure
   */
  export namespace UploadDialmakerSheetCampaignIdUploadWithPreviewPost {
    export type RequestParams = { campaignId: string };
    export type RequestQuery = {};
    export type RequestBody = BodyUploadDialmakerSheetCampaignIdUploadWithPreviewPost;
    export type RequestHeaders = {};
    export type ResponseBody = SheetPreview;
  }
  /**
   * @description This endpoint is used to import leads from a file to a global leads collection with campaign_id and file_id metadata
   * @tags Dial Maker 2
   * @name ImportFromFileDialmakerSheetCampaignIdImportFromFileIdPost
   * @summary Import From File
   * @request POST:/dialmaker/sheet/{campaign_id}/import_from/{file_id}
   * @secure
   */
  export namespace ImportFromFileDialmakerSheetCampaignIdImportFromFileIdPost {
    export type RequestParams = { campaignId: string; fileId: string };
    export type RequestQuery = { synchronous?: boolean };
    export type RequestBody = Record<string, string>;
    export type RequestHeaders = {};
    export type ResponseBody = ModelsDialMaker2CampaignAggregateCampaign | string;
  }
  /**
   * @description This endpoint is used to delete a file from the database if it was not imported yet
   * @tags Dial Maker 2
   * @name DeleteCampaignSheetDialmakerSheetCampaignIdDeleteFileFileIdDelete
   * @summary Delete Campaign Sheet
   * @request DELETE:/dialmaker/sheet/{campaign_id}/delete_file/{file_id}
   * @secure
   */
  export namespace DeleteCampaignSheetDialmakerSheetCampaignIdDeleteFileFileIdDelete {
    export type RequestParams = { campaignId: string; fileId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ModelsDialMaker2CampaignAggregateCampaign | null;
  }
  /**
   * @description This endpoint is used to delete import of a given sheet_id with all interim leads
   * @tags Dial Maker 2
   * @name DeleteInterimLeadsDialmakerSheetCampaignIdImportSheetIdDelete
   * @summary Delete Interim Leads
   * @request DELETE:/dialmaker/sheet/{campaign_id}/import/{sheet_id}
   * @secure
   */
  export namespace DeleteInterimLeadsDialmakerSheetCampaignIdImportSheetIdDelete {
    export type RequestParams = { campaignId: string; sheetId: string };
    export type RequestQuery = { force?: boolean };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ModelsDialMaker2CampaignAggregateCampaign | null;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name GetOutcomesDialmakerMiscOutcomesGet
   * @summary Get Outcomes
   * @request GET:/dialmaker/misc/outcomes
   * @secure
   */
  export namespace GetOutcomesDialmakerMiscOutcomesGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Outcomes;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name SaveOutcomesDialmakerMiscOutcomesPost
   * @summary Save Outcomes
   * @request POST:/dialmaker/misc/outcomes
   * @secure
   */
  export namespace SaveOutcomesDialmakerMiscOutcomesPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodySaveOutcomesDialmakerMiscOutcomesPost;
    export type RequestHeaders = {};
    export type ResponseBody = Outcomes;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name AdminDialmakerAdminOutcomesPost
   * @summary Admin
   * @request POST:/dialmaker/admin/outcomes
   * @secure
   */
  export namespace AdminDialmakerAdminOutcomesPost {
    export type RequestParams = {};
    export type RequestQuery = { start_date: string; end_date: string };
    export type RequestBody = BodyAdminDialmakerAdminOutcomesPost;
    export type RequestHeaders = {};
    export type ResponseBody = OutcomeCount;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name BlockFieldsDialmakerAdminBlockPost
   * @summary Block Fields
   * @request POST:/dialmaker/admin/block
   * @secure
   */
  export namespace BlockFieldsDialmakerAdminBlockPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BlockCustomerFieldManuallyPayload;
    export type RequestHeaders = {};
    export type ResponseBody = BlockCustomerFieldManuallyResponse;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name StatsDialmakerAdminOutcomesUsersPost
   * @summary Stats
   * @request POST:/dialmaker/admin/outcomes/users
   * @secure
   */
  export namespace StatsDialmakerAdminOutcomesUsersPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyStatsDialmakerAdminOutcomesUsersPost;
    export type RequestHeaders = {};
    export type ResponseBody = OutcomeCount;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name GetMailTemplatesDialmakerMailTemplatesAvailableGet
   * @summary Get Mail Templates
   * @request GET:/dialmaker/mail/templates/available
   * @secure
   */
  export namespace GetMailTemplatesDialmakerMailTemplatesAvailableGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = MandrillTemplate[];
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name GetVariablesTemplateVariablesDialmakerMailTemplateVariablesPost
   * @summary Get Variables Template Variables
   * @request POST:/dialmaker/mail/template/variables
   * @secure
   */
  export namespace GetVariablesTemplateVariablesDialmakerMailTemplateVariablesPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyGetVariablesTemplateVariablesDialmakerMailTemplateVariablesPost;
    export type RequestHeaders = {};
    export type ResponseBody = TemplateVariables;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name GetAvailableTemplateVariablesDialmakerMailTemplateAvailableVariablesGet
   * @summary Get Available Template Variables
   * @request GET:/dialmaker/mail/template/available/variables
   * @secure
   */
  export namespace GetAvailableTemplateVariablesDialmakerMailTemplateAvailableVariablesGet {
    export type RequestParams = {};
    export type RequestQuery = { country: Country };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string[];
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name TemplateRenderDialmakerMailTemplateRenderPost
   * @summary Template Render
   * @request POST:/dialmaker/mail/template/render
   * @secure
   */
  export namespace TemplateRenderDialmakerMailTemplateRenderPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = RenderTemplatePayload;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
}

export namespace UrlShortener {
  /**
   * No description
   * @tags URL Shortener
   * @name CreateUrlUrlShortenerUrlPost
   * @summary Create Url
   * @request POST:/url-shortener/url
   * @secure
   */
  export namespace CreateUrlUrlShortenerUrlPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyCreateUrlUrlShortenerUrlPost;
    export type RequestHeaders = {};
    export type ResponseBody = URL;
  }
  /**
   * No description
   * @tags URL Shortener
   * @name GetAllUrlsUrlShortenerUrlsGet
   * @summary Get All Urls
   * @request GET:/url-shortener/urls
   * @secure
   */
  export namespace GetAllUrlsUrlShortenerUrlsGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = URL[];
  }
  /**
   * No description
   * @tags URL Shortener
   * @name GetUrlInfoUrlShortenerUrlKeyGet
   * @summary Get Url Info
   * @request GET:/url-shortener/{url_key}
   * @secure
   */
  export namespace GetUrlInfoUrlShortenerUrlKeyGet {
    export type RequestParams = { urlKey: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = URL;
  }
  /**
   * No description
   * @tags URL Shortener
   * @name GetShortUrlUrlShortenerUrlKeyShortenedCountryGet
   * @summary Get Short Url
   * @request GET:/url-shortener/{url_key}/shortened/{country}
   * @secure
   */
  export namespace GetShortUrlUrlShortenerUrlKeyShortenedCountryGet {
    export type RequestParams = { urlKey: string; country: Country };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
}

export namespace Insurance {
  /**
   * No description
   * @tags Insurance
   * @name CreateInsuranceInsuranceInsurancePost
   * @summary Create Insurance
   * @request POST:/insurance/insurance
   * @secure
   */
  export namespace CreateInsuranceInsuranceInsurancePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateInsuranceCustomerV1;
    export type RequestHeaders = {};
    export type ResponseBody = Insurance;
  }
}

export namespace Bank {
  /**
   * No description
   * @tags Bank Login
   * @name LoginBankLoginPost
   * @summary Login
   * @request POST:/bank/login
   */
  export namespace LoginBankLoginPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = Login;
    export type RequestHeaders = {};
    export type ResponseBody = LoginResp;
  }
  /**
   * No description
   * @tags Bank Login
   * @name ValidateOtpCodeBankLoginValidatePost
   * @summary Validate Otp Code
   * @request POST:/bank/login/validate
   */
  export namespace ValidateOtpCodeBankLoginValidatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = OTPValidation;
    export type RequestHeaders = {};
    export type ResponseBody = OTPValidationResp;
  }
  /**
   * No description
   * @tags Bank Login
   * @name SetNewPasswordBankSetPasswordPost
   * @summary Set New Password
   * @request POST:/bank/set-password
   */
  export namespace SetNewPasswordBankSetPasswordPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = SetPassword;
    export type RequestHeaders = {};
    export type ResponseBody = SetPasswordResp;
  }
  /**
   * No description
   * @tags Bank Login
   * @name RequestNewOtpBankNewOtpPost
   * @summary Request New Otp
   * @request POST:/bank/new-otp
   */
  export namespace RequestNewOtpBankNewOtpPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = RequestNewOTP;
    export type RequestHeaders = {};
    export type ResponseBody = SetPasswordResp;
  }
  /**
   * No description
   * @tags Bank User Administration
   * @name CreateNewBankUserBankCreateUserPost
   * @summary Create New Bank User
   * @request POST:/bank/create-user
   * @secure
   */
  export namespace CreateNewBankUserBankCreateUserPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateUser;
    export type RequestHeaders = {};
    export type ResponseBody = CreateUserResp;
  }
  /**
   * No description
   * @tags Bank User Administration
   * @name GetBankUsersBankGetPost
   * @summary Get Bank Users
   * @request POST:/bank/get
   * @secure
   */
  export namespace GetBankUsersBankGetPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetUsersPayload;
    export type RequestHeaders = {};
    export type ResponseBody = BankUserWithBankNames[];
  }
  /**
   * No description
   * @tags Bank User Administration
   * @name GetBankUserBankUserIdGet
   * @summary Get Bank User
   * @request GET:/bank/{user_id}
   * @secure
   */
  export namespace GetBankUserBankUserIdGet {
    export type RequestParams = { userId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BankUserWithBankNames;
  }
  /**
   * No description
   * @tags Bank User Administration
   * @name EditBankUserBankUserIdPut
   * @summary Edit Bank User
   * @request PUT:/bank/{user_id}
   * @secure
   */
  export namespace EditBankUserBankUserIdPut {
    export type RequestParams = { userId: string };
    export type RequestQuery = {};
    export type RequestBody = EditUser;
    export type RequestHeaders = {};
    export type ResponseBody = BankUserWithBankNames;
  }
  /**
   * No description
   * @tags Bank User Administration
   * @name ResetBankUserPasswordBankResetPasswordPost
   * @summary Reset Bank User Password
   * @request POST:/bank/reset-password
   * @secure
   */
  export namespace ResetBankUserPasswordBankResetPasswordPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ResetPasswordPayload;
    export type RequestHeaders = {};
    export type ResponseBody = CreateUserResp;
  }
  /**
   * No description
   * @tags Bank User Administration
   * @name DeactivateBankUserBankDeactivatePut
   * @summary Deactivate Bank User
   * @request PUT:/bank/deactivate
   * @secure
   */
  export namespace DeactivateBankUserBankDeactivatePut {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = DeactivateUserPayload;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Bank
   * @name GetApplicationsBankApplicationsPost
   * @summary Get Applications
   * @request POST:/bank/applications
   * @secure
   */
  export namespace GetApplicationsBankApplicationsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetApplications;
    export type RequestHeaders = {};
    export type ResponseBody = BankBaseApp[];
  }
  /**
   * No description
   * @tags Bank
   * @name GetApplicationBankApplicationApplicationIdGet
   * @summary Get Application
   * @request GET:/bank/application/{application_id}
   * @secure
   */
  export namespace GetApplicationBankApplicationApplicationIdGet {
    export type RequestParams = { applicationId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BankBaseApp;
  }
  /**
   * No description
   * @tags Bank
   * @name GetDocumentMetaBankDocumentMetaCustomerIdDocumentIdGet
   * @summary Get Document Meta
   * @request GET:/bank/document/meta/{customer_id}/{document_id}
   * @secure
   */
  export namespace GetDocumentMetaBankDocumentMetaCustomerIdDocumentIdGet {
    export type RequestParams = { customerId: string; documentId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = DocumentMeta;
  }
  /**
   * No description
   * @tags Bank
   * @name GetDocumentBankGetCustomerIdDocumentIdGet
   * @summary Get Document
   * @request GET:/bank/get/{customer_id}/{document_id}
   * @secure
   */
  export namespace GetDocumentBankGetCustomerIdDocumentIdGet {
    export type RequestParams = { customerId: string; documentId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CustomerDocument;
  }
  /**
   * No description
   * @tags Bank
   * @name GetAllBanksBankGetBanksPost
   * @summary Get All Banks
   * @request POST:/bank/get-banks
   * @secure
   */
  export namespace GetAllBanksBankGetBanksPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetBanksPayload;
    export type RequestHeaders = {};
    export type ResponseBody = Bank[];
  }
  /**
   * No description
   * @tags Bank
   * @name GetAppEventsBankGetAppEventsApplicationIdGet
   * @summary Get App Events
   * @request GET:/bank/get_app_events/{application_id}
   * @secure
   */
  export namespace GetAppEventsBankGetAppEventsApplicationIdGet {
    export type RequestParams = { applicationId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EventsResponse;
  }
  /**
   * No description
   * @tags Bank
   * @name AddCommentBankCommentPost
   * @summary Add Comment
   * @request POST:/bank/comment
   * @secure
   */
  export namespace AddCommentBankCommentPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BankComment;
    export type RequestHeaders = {};
    export type ResponseBody = EventsResponse;
  }
  /**
   * No description
   * @tags Bank
   * @name PostOfferBankOfferPost
   * @summary Post Offer
   * @request POST:/bank/offer
   * @secure
   */
  export namespace PostOfferBankOfferPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BankOfferPayload;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Bank, Application
   * @name RejectBankRejectPost
   * @summary Reject
   * @request POST:/bank/reject
   * @secure
   */
  export namespace RejectBankRejectPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BankRejectPayload;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags Bank, Application
   * @name DisburseBankDisbursePost
   * @summary Disburse
   * @request POST:/bank/disburse
   * @secure
   */
  export namespace DisburseBankDisbursePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BankDisbursePayload;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags Bank
   * @name SearchApplicationsBankSearchPost
   * @summary Search Applications
   * @request POST:/bank/search
   * @secure
   */
  export namespace SearchApplicationsBankSearchPost {
    export type RequestParams = {};
    export type RequestQuery = { page?: number; limit?: number };
    export type RequestBody = RoutersBankPayloadsSearchPayload;
    export type RequestHeaders = {};
    export type ResponseBody = BankBaseApp[];
  }
}

export namespace Jotform {
  /**
   * No description
   * @tags Jotform
   * @name SubmissionJotformWebhookFormIdPost
   * @summary Submission
   * @request POST:/jotform/webhook/{form_id}
   */
  export namespace SubmissionJotformWebhookFormIdPost {
    export type RequestParams = { formId: string };
    export type RequestQuery = {};
    export type RequestBody = BodySubmissionJotformWebhookFormIdPost;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
}

export namespace Campaign {
  /**
   * No description
   * @tags Affiliate
   * @name CreateCampaignInitializedEventCampaignCampaignIdGet
   * @summary Create Campaign Initialized Event
   * @request GET:/campaign/{campaign_id}
   */
  export namespace CreateCampaignInitializedEventCampaignCampaignIdGet {
    export type RequestParams = { campaignId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
}

export namespace Visit {
  /**
   * No description
   * @tags Affiliate
   * @name CreateVisitEventVisitCountryZidPost
   * @summary Create Visit Event
   * @request POST:/visit/{country}/{zid}
   */
  export namespace CreateVisitEventVisitCountryZidPost {
    export type RequestParams = { country: Country; zid: string };
    export type RequestQuery = {};
    export type RequestBody = WebsiteVisitPayload;
    export type RequestHeaders = {
      "x-s-zid"?: string | null;
      "x-gid"?: string | null;
      "X-Forwarded-For"?: string | null;
    };
    export type ResponseBody = EmploymentTypeBase;
  }
}

export namespace V2 {
  /**
   * No description
   * @tags Affiliate
   * @name CreateWebsiteVisitEventV2VisitCountryPost
   * @summary Create Website Visit Event
   * @request POST:/v2/visit/{country}
   */
  export namespace CreateWebsiteVisitEventV2VisitCountryPost {
    export type RequestParams = { country: Country };
    export type RequestQuery = {};
    export type RequestBody = WebsiteVisitPayload;
    export type RequestHeaders = { "X-Forwarded-For"?: string | null };
    export type ResponseBody = EmploymentTypeBase;
  }
}

export namespace Slack {
  /**
   * No description
   * @tags Slack
   * @name SlackCallbackSlackCallbackPost
   * @summary Slack Callback
   * @request POST:/slack/callback
   */
  export namespace SlackCallbackSlackCallbackPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodySlackCallbackSlackCallbackPost;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
}

export namespace DialMaker2 {
  /**
   * No description
   * @tags Dial Maker 2
   * @name GetImageDialMaker2ImageGet
   * @summary Get Image
   * @request GET:/dial-maker2/image
   */
  export namespace GetImageDialMaker2ImageGet {
    export type RequestParams = {};
    export type RequestQuery = { file_id: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
  /**
   * No description
   * @tags Dial Maker 2
   * @name DownloadLeadsXlsxDialMaker2ExportDownloadSignatureGet
   * @summary Download Leads Xlsx
   * @request GET:/dial-maker2/export/download/{signature}
   */
  export namespace DownloadLeadsXlsxDialMaker2ExportDownloadSignatureGet {
    export type RequestParams = { signature: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
}

export namespace WellKnown {
  /**
   * No description
   * @tags Well known
   * @name GetJwksWellKnownJwksJsonGet
   * @summary Get Jwks
   * @request GET:/.well-known/jwks.json
   */
  export namespace GetJwksWellKnownJwksJsonGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmptyModel;
  }
}

export namespace Health {
  /**
   * No description
   * @tags Health
   * @name GetHealthHealthGet
   * @summary Get Health
   * @request GET:/health
   */
  export namespace GetHealthHealthGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
}

export namespace Validator {
  /**
   * No description
   * @tags Validator
   * @name ValidateValidatorPost
   * @summary Validate
   * @request POST:/validator/
   */
  export namespace ValidateValidatorPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody =
      | Email
      | PNI
      | ModelsValidatorServicePayloadPhoneNumber
      | BankAccount
      | (Email & PNI & ModelsValidatorServicePayloadPhoneNumber & BankAccount);
    export type RequestHeaders = {};
    export type ResponseBody = ValidatorServiceResponseModel;
  }
  /**
   * No description
   * @tags Validator
   * @name ValidateValidatorPost2
   * @summary Validate
   * @request POST:/validator
   * @originalName validateValidatorPost
   * @duplicate
   */
  export namespace ValidateValidatorPost2 {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody =
      | Email
      | PNI
      | ModelsValidatorServicePayloadPhoneNumber
      | BankAccount
      | (Email & PNI & ModelsValidatorServicePayloadPhoneNumber & BankAccount);
    export type RequestHeaders = {};
    export type ResponseBody = ValidatorServiceResponseModel;
  }
  /**
   * No description
   * @tags Validator
   * @name ValidateSafelyValidatorSafePost
   * @summary Validate Safely
   * @request POST:/validator/safe/
   */
  export namespace ValidateSafelyValidatorSafePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = EmptyModel;
    export type RequestHeaders = {};
    export type ResponseBody = ValidatorServiceResponseModel;
  }
  /**
   * No description
   * @tags Validator
   * @name ValidateSafelyValidatorSafePost2
   * @summary Validate Safely
   * @request POST:/validator/safe
   * @originalName validateSafelyValidatorSafePost
   * @duplicate
   */
  export namespace ValidateSafelyValidatorSafePost2 {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = EmptyModel;
    export type RequestHeaders = {};
    export type ResponseBody = ValidatorServiceResponseModel;
  }
}

export namespace Mandrill {
  /**
   * No description
   * @tags Mail Webhook
   * @name MessageOpenedMandrillWebhookMessagePost
   * @summary Message Opened
   * @request POST:/mandrill/webhook/message
   */
  export namespace MessageOpenedMandrillWebhookMessagePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyMessageOpenedMandrillWebhookMessagePost;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
}

export namespace Mail {
  /**
   * No description
   * @tags MailMaker
   * @name UnsubscribeFromMailMarketingMailPublicUnsubscribePost
   * @summary Unsubscribe From Mail Marketing
   * @request POST:/mail/public/unsubscribe
   */
  export namespace UnsubscribeFromMailMarketingMailPublicUnsubscribePost {
    export type RequestParams = {};
    export type RequestQuery = { mail_key: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
}

export namespace P {
  /**
   * No description
   * @tags URL Shortener
   * @name ForwardToTargetUrlPUrlKeyPathGet
   * @summary Forward To Target Url
   * @request GET:/p/{url_key}/{path}
   */
  export namespace ForwardToTargetUrlPUrlKeyPathGet {
    export type RequestParams = { urlKey: string; path: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
}

export namespace FormAnalytics {
  /**
   * No description
   * @tags Online
   * @name StoreEventsFormAnalyticsStoreEventsPost
   * @summary Store Events
   * @request POST:/form-analytics/store-events
   */
  export namespace StoreEventsFormAnalyticsStoreEventsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyStoreEventsFormAnalyticsStoreEventsPost;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
}

export namespace Infra {
  /**
   * No description
   * @tags Other
   * @name Auth0EventsProxyInfraAuth0EventsPost
   * @summary Auth0 Events Proxy
   * @request POST:/infra/auth0/events
   * @secure
   */
  export namespace Auth0EventsProxyInfraAuth0EventsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = object[];
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace Callmaker {
  /**
   * No description
   * @tags Callmaker Callbacks
   * @name GetCallmakerDataCallmakerCallbackCallPost
   * @summary Get Callmaker Data
   * @request POST:/callmaker/callback/call
   * @secure
   */
  export namespace GetCallmakerDataCallmakerCallbackCallPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = EmptyModel;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Callmaker Callbacks
   * @name GetProcessedDataCallmakerCallbackProcessPost
   * @summary Get Processed Data
   * @request POST:/callmaker/callback/process
   * @secure
   */
  export namespace GetProcessedDataCallmakerCallbackProcessPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = EmptyModel;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace V1 {
  /**
   * No description
   * @tags V1 Affiliate API
   * @name CreateBlancoApplicationV1AffiliateApplicationsBlancoPost
   * @summary Create Blanco Application
   * @request POST:/v1/affiliate/applications/blanco
   * @secure
   */
  export namespace CreateBlancoApplicationV1AffiliateApplicationsBlancoPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = AffiliateCreateBlancoApplication;
    export type RequestHeaders = {};
    export type ResponseBody = AffiliateResponse;
  }
  /**
   * No description
   * @tags V1 Affiliate API
   * @name GetBookingsBlancoV1AffiliateBookingsBlancoGet
   * @summary Get Bookings Blanco
   * @request GET:/v1/affiliate/bookings/blanco
   * @secure
   */
  export namespace GetBookingsBlancoV1AffiliateBookingsBlancoGet {
    export type RequestParams = {};
    export type RequestQuery = { start_date: string; end_date: string; country: Country };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = AvailableBookings;
  }
  /**
   * No description
   * @tags V1 Affiliate API
   * @name CreateMortgageLeadV1AffiliateLeadsMortgagePost
   * @summary Create Mortgage Lead
   * @request POST:/v1/affiliate/leads/mortgage
   * @secure
   */
  export namespace CreateMortgageLeadV1AffiliateLeadsMortgagePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = AffiliateLeadCreationPayload;
    export type RequestHeaders = {};
    export type ResponseBody = AffiliateResponse;
  }
  /**
   * No description
   * @tags V1 Affiliate API
   * @name GetBookingsMortgageV1AffiliateBookingsMortgageGet
   * @summary Get Bookings Mortgage
   * @request GET:/v1/affiliate/bookings/mortgage
   * @secure
   */
  export namespace GetBookingsMortgageV1AffiliateBookingsMortgageGet {
    export type RequestParams = {};
    export type RequestQuery = { start_date: string; end_date: string; country: Country };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = AvailableBookings;
  }
  /**
   * @description Returns all allowed enum values
   * @tags V1 Affiliate API
   * @name GetOptionsV1AffiliateOptionsCountryProductGet
   * @summary Get Options
   * @request GET:/v1/affiliate/options/{country}/{product}
   * @secure
   */
  export namespace GetOptionsV1AffiliateOptionsCountryProductGet {
    export type RequestParams = { country: Country; product: Product };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = AffiliateOptionsResponse;
  }
  /**
   * No description
   * @tags V1 Affiliate API
   * @name GetBlancoStatusV1AffiliateApplicationsBlancoSubmissionIdGet
   * @summary Get Blanco Status
   * @request GET:/v1/affiliate/applications/blanco/{submission_id}
   * @secure
   */
  export namespace GetBlancoStatusV1AffiliateApplicationsBlancoSubmissionIdGet {
    export type RequestParams = { submissionId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = AffiliateCallback;
  }
  /**
   * No description
   * @tags V1 Affiliate API
   * @name GetMortgageStatusV1AffiliateLeadsMortgageSubmissionIdGet
   * @summary Get Mortgage Status
   * @request GET:/v1/affiliate/leads/mortgage/{submission_id}
   * @secure
   */
  export namespace GetMortgageStatusV1AffiliateLeadsMortgageSubmissionIdGet {
    export type RequestParams = { submissionId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = AffiliateCallback;
  }
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends globalThis.Response {
  data: D;
  error: E;
  requestId: string;
}

export interface HttpErrorResponse<E> extends globalThis.Response {
  error: E;
  requestId: string;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "include",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  private generateUUID() {
    // Public Domain/MIT
    var d = new Date().getTime(); //Timestamp
    var d2 = (typeof performance !== "undefined" && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = Math.random() * 16; //random number between 0 and 16
      if (d > 0) {
        //Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        //Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
  }

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    // We need to generate new RequestID for every request
    let requestId = requestParams.headers["X-Request-ID"];
    if (!requestId) {
      requestId = this.generateUUID();
      requestParams.headers["X-Request-ID"] = requestId;
    }

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;
      r.requestId = requestId;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
          .then((data) => {
            if (r.ok) {
              r.data = data;
            } else {
              r.error = data;
            }
            return r;
          })
          .catch((e) => {
            r.error = e;
            return r;
          });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data as HttpErrorResponse<E>;
      return data;
    });
  };
}

/**
 * @title The ZRM
 * @version 0.1.0
 *
 *
 * A __ZRM__ _(please say it out loud and you shall understand)_ for the products at Zensum.
 *
 * ---
 * ### Code
 *
 * You can find this code [here on Github](https://github.com/zensum/zrm) if you're a part of the Zensum organization.
 *
 * ---
 *
 * ### Features
 *  - handling/booking meetings
 *  - sending messages to customer
 *  - proxying requests for customer data to kunddatalagringstjänsten
 *  - proxying requests for applications data to applications manager
 *
 * ---
 *
 * ### Authorization
 *
 * In order to authorize you need a token with the audience `API_TEST_WEB` from the issuer `zensum-test.eu.auth0.com`.
 * Easiest way of getting a token is to look in Auth0 and get a token with a curl similar to this
 *
 * ```sh
 * curl --request POST \
 *      --url https://zensum-test.eu.auth0.com/oauth/token \
 *      --header 'content-type: application/json' \
 *      --data '{"client_id":"{CLIENT_ID}","client_secret":"{CLIENT_SECRET}", \
 *         "audience":"API_TEST_WEB","grant_type":"client_credentials"}'
 * ```
 * _Remember to replace {CLIENT_ID} and {CLIENT_SECRET}!_
 *
 * ---
 *
 * ### Zoordinates
 * Please add one of your allowed Zoordinates to the header `X-Zoordinates`.
 *
 * ---
 *
 * ### Request ID
 * Please add a value to the header `X-Request-ID` with a UUID4 value to be able to track
 * the request between services. 🙏🏼
 *
 * _Otherwise Heroku will take care of it and we'd loose control._
 *
 * ---
 *
 * ### Support
 * Look below 😅
 *
 * ---
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  application = {
    /**
     * No description
     *
     * @tags Application
     * @name QuickCreateApplicationApplicationQuickCreatePost
     * @summary Quick Create Application
     * @request POST:/application/quick-create
     * @secure
     */
    quickCreateApplicationApplicationQuickCreatePost: (
      data: QuickCreateMortgageApplication | QuickCreateBlancoApplication,
      params: RequestParams = {},
    ) =>
      this.request<ApplicationCreatedResponse, HTTPValidationError>({
        path: `/application/quick-create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application
     * @name CreateApplicationApplicationCreatePost
     * @summary Create Application
     * @request POST:/application/create
     * @secure
     */
    createApplicationApplicationCreatePost: (data: CreateApplicationPayloadExt, params: RequestParams = {}) =>
      this.request<ApplicationCreatedResponseExt, HTTPValidationError>({
        path: `/application/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application
     * @name UpdateApplicationApplicationUpdateApplicationIdPost
     * @summary Update Application
     * @request POST:/application/update/{application_id}
     * @secure
     */
    updateApplicationApplicationUpdateApplicationIdPost: (
      applicationId: string,
      data: UpdateApplicationPayloadExt,
      params: RequestParams = {},
    ) =>
      this.request<ApplicationWithNamesInComments, HTTPValidationError>({
        path: `/application/update/${applicationId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application
     * @name GetApplicationApplicationGetPost
     * @summary Get Application
     * @request POST:/application/get
     * @secure
     */
    getApplicationApplicationGetPost: (data: GetApplicationPayload, params: RequestParams = {}) =>
      this.request<ApplicationWithNamesInComments, HTTPValidationError>({
        path: `/application/get`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application
     * @name GetApplicationsApplicationGetsPost
     * @summary Get Applications
     * @request POST:/application/gets
     * @deprecated
     * @secure
     */
    getApplicationsApplicationGetsPost: (
      data: GetApplicationsByCustomerIdentifierPayload,
      params: RequestParams = {},
    ) =>
      this.request<Application[], HTTPValidationError>({
        path: `/application/gets`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application
     * @name GetApplicationsV2ApplicationV2GetsPost
     * @summary Get Applications V2
     * @request POST:/application/v2/gets
     * @secure
     */
    getApplicationsV2ApplicationV2GetsPost: (
      data: GetApplicationsByCustomerIdentifierPayloadV2,
      params: RequestParams = {},
    ) =>
      this.request<Application[], HTTPValidationError>({
        path: `/application/v2/gets`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application
     * @name GetApplicationsAssignedToMeApplicationGetMinePost
     * @summary Get Applications Assigned To Me
     * @request POST:/application/get/mine
     * @secure
     */
    getApplicationsAssignedToMeApplicationGetMinePost: (
      data: GetMyApplicationsPayload,
      query?: { page?: number; limit?: number },
      params: RequestParams = {},
    ) =>
      this.request<Application[], HTTPValidationError>({
        path: `/application/get/mine`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application
     * @name GetRecentApplicationsApplicationGetRecentPost
     * @summary Get Recent Applications
     * @request POST:/application/get/recent
     * @secure
     */
    getRecentApplicationsApplicationGetRecentPost: (
      data: GetRecentApplicationsPayload,
      query?: { page?: number; limit?: number },
      params: RequestParams = {},
    ) =>
      this.request<Application[], HTTPValidationError>({
        path: `/application/get/recent`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application
     * @name SearchApplicationsApplicationSearchPost
     * @summary Search Applications
     * @request POST:/application/search
     * @secure
     */
    searchApplicationsApplicationSearchPost: (
      data: RoutersApplicationApplicationPayloadSearchPayload,
      query?: { page?: number; limit?: number },
      params: RequestParams = {},
    ) =>
      this.request<Application[], HTTPValidationError>({
        path: `/application/search`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application
     * @name SearchApplications2ApplicationSearch2Post
     * @summary Search Applications2
     * @request POST:/application/search2
     * @secure
     */
    searchApplications2ApplicationSearch2Post: (
      data: RoutersApplicationApplicationPayloadSearchPayload,
      query?: { page?: number; limit?: number },
      params: RequestParams = {},
    ) =>
      this.request<AppsSearchResult, HTTPValidationError>({
        path: `/application/search2`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application
     * @name GetLeftToLiveApplicationLeftToLivePost
     * @summary Get Left To Live
     * @request POST:/application/left_to_live
     * @secure
     */
    getLeftToLiveApplicationLeftToLivePost: (data: LeftToLivePayload, params: RequestParams = {}) =>
      this.request<LeftToLiveResponse, HTTPValidationError>({
        path: `/application/left_to_live`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application
     * @name DuplicateApplicationApplicationDuplicatePost
     * @summary Duplicate Application
     * @request POST:/application/duplicate
     * @secure
     */
    duplicateApplicationApplicationDuplicatePost: (data: ApplicationPayload, params: RequestParams = {}) =>
      this.request<ApplicationPayload, HTTPValidationError>({
        path: `/application/duplicate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application
     * @name DeactivateApplicationApplicationDeactivateProductPost
     * @summary Deactivate Application
     * @request POST:/application/deactivate/{product}
     * @secure
     */
    deactivateApplicationApplicationDeactivateProductPost: (
      product: Product,
      data: MortgageDeactivatePayload | DeactivatePayload | (MortgageDeactivatePayload & DeactivatePayload),
      params: RequestParams = {},
    ) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/application/deactivate/${product}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application
     * @name ReactivateApplicationReactivatePost
     * @summary Reactivate
     * @request POST:/application/reactivate
     * @secure
     */
    reactivateApplicationReactivatePost: (data: ReactivatePayload, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/application/reactivate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditCard Application
     * @name GetApplicationApplicationCcNoGetPost
     * @summary Get Application
     * @request POST:/application/cc/no/get
     * @secure
     */
    getApplicationApplicationCcNoGetPost: (data: GetApplicationPayload, params: RequestParams = {}) =>
      this.request<CreditCardApplicationWithNamesInComments, HTTPValidationError>({
        path: `/application/cc/no/get`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditCard Application
     * @name CreateApplicationApplicationCcNoCreatePost
     * @summary Create Application
     * @request POST:/application/cc/no/create
     * @secure
     */
    createApplicationApplicationCcNoCreatePost: (
      data: CreateCreditCardApplicationPayloadExt,
      params: RequestParams = {},
    ) =>
      this.request<ApplicationCreatedResponseExt, HTTPValidationError>({
        path: `/application/cc/no/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditCard Application
     * @name UpdateApplicationApplicationCcNoUpdatePost
     * @summary Update Application
     * @request POST:/application/cc/no/update
     * @secure
     */
    updateApplicationApplicationCcNoUpdatePost: (
      data: UpdateCreditCardApplicationPayload,
      params: RequestParams = {},
    ) =>
      this.request<EmptyModel, HTTPValidationError>({
        path: `/application/cc/no/update`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditCard Application
     * @name UpdateAndGetApplicationApplicationCcNoUpdateAndGetPost
     * @summary Update And Get Application
     * @request POST:/application/cc/no/update-and-get
     * @secure
     */
    updateAndGetApplicationApplicationCcNoUpdateAndGetPost: (
      data: UpdateCreditCardApplicationPayload,
      params: RequestParams = {},
    ) =>
      this.request<CreditCardApplicationExtended, HTTPValidationError>({
        path: `/application/cc/no/update-and-get`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditCard Application
     * @name CreateApplicationApplicantApplicationCcNoCreateWithApplicantPost
     * @summary Create Application Applicant
     * @request POST:/application/cc/no/create-with-applicant
     * @secure
     */
    createApplicationApplicantApplicationCcNoCreateWithApplicantPost: (
      data: CreateCreditCardApplicationApplicantPayloadExt,
      params: RequestParams = {},
    ) =>
      this.request<ApplicationCreatedResponseExt, HTTPValidationError>({
        path: `/application/cc/no/create-with-applicant`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditCard Application
     * @name GetRecentApplicationsApplicationCcNoGetRecentPost
     * @summary Get Recent Applications
     * @request POST:/application/cc/no/get_recent
     * @secure
     */
    getRecentApplicationsApplicationCcNoGetRecentPost: (
      data: GetRecentPayload,
      query?: { page?: number; size?: number },
      params: RequestParams = {},
    ) =>
      this.request<PageCreditCardApplicationExtended, HTTPValidationError>({
        path: `/application/cc/no/get_recent`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditCard Application
     * @name GetApplicationBankEventsApplicationCcNoBankEventsPost
     * @summary Get Application Bank Events
     * @request POST:/application/cc/no/bank-events
     * @secure
     */
    getApplicationBankEventsApplicationCcNoBankEventsPost: (data: ApplicationQuery, params: RequestParams = {}) =>
      this.request<CreditCardBankEventsResponse, HTTPValidationError>({
        path: `/application/cc/no/bank-events`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditCard Application
     * @name SearchApplicationsApplicationCcNoSearchApplicationsPost
     * @summary Search Applications
     * @request POST:/application/cc/no/search_applications
     * @secure
     */
    searchApplicationsApplicationCcNoSearchApplicationsPost: (
      data: GetApplicationsByApplicantIdentifierPayload,
      params: RequestParams = {},
    ) =>
      this.request<CreditCardApplicationExtended[], HTTPValidationError>({
        path: `/application/cc/no/search_applications`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditCard Application
     * @name AcceptOfferApplicationCcNoAcceptOfferPost
     * @summary Accept Offer
     * @request POST:/application/cc/no/accept_offer
     * @secure
     */
    acceptOfferApplicationCcNoAcceptOfferPost: (data: AcceptOffer, params: RequestParams = {}) =>
      this.request<CreditorOfferCreditCardOfferCreditCardTerms, HTTPValidationError>({
        path: `/application/cc/no/accept_offer`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Mortgage SE Application
     * @name GetApplicationBankEventsApplicationMoSeBankEventsPost
     * @summary Get Application Bank Events
     * @request POST:/application/mo/se/bank-events
     * @secure
     */
    getApplicationBankEventsApplicationMoSeBankEventsPost: (data: ApplicationQuery, params: RequestParams = {}) =>
      this.request<MortgageBankEventsResponse, HTTPValidationError>({
        path: `/application/mo/se/bank-events`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank Communication
     * @name InviteApplicationApplicationInvitePost
     * @summary Invite Application
     * @request POST:/application/invite
     * @secure
     */
    inviteApplicationApplicationInvitePost: (data: InvitePayloadBase, params: RequestParams = {}) =>
      this.request<EmptyModel, HTTPValidationError>({
        path: `/application/invite`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank Communication
     * @name InviteApplicationIdApplicationInviteApplicationIdPost
     * @summary Invite Application Id
     * @request POST:/application/invite/{application_id}
     * @secure
     */
    inviteApplicationIdApplicationInviteApplicationIdPost: (
      applicationId: string,
      data: InvitePayloadBase,
      params: RequestParams = {},
    ) =>
      this.request<EmptyModel, HTTPValidationError>({
        path: `/application/invite/${applicationId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank Communication
     * @name GetAppEventsApplicationGetAppEventsApplicationIdGet
     * @summary Get App Events
     * @request GET:/application/get_app_events/{application_id}
     * @secure
     */
    getAppEventsApplicationGetAppEventsApplicationIdGet: (applicationId: string, params: RequestParams = {}) =>
      this.request<EventsResponse, HTTPValidationError>({
        path: `/application/get_app_events/${applicationId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank Communication
     * @name GetAppEventsOldApplicationGetAppEventsPost
     * @summary Get App Events Old
     * @request POST:/application/get_app_events
     * @secure
     */
    getAppEventsOldApplicationGetAppEventsPost: (params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, any>({
        path: `/application/get_app_events`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank Communication
     * @name SendCommentToIntegrationApplicationCommentToBankPost
     * @summary Send Comment To Integration
     * @request POST:/application/comment_to_bank
     * @secure
     */
    sendCommentToIntegrationApplicationCommentToBankPost: (data: BaseComment, params: RequestParams = {}) =>
      this.request<EventsResponse, HTTPValidationError>({
        path: `/application/comment_to_bank`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank Communication
     * @name AcceptOfferApplicationAcceptOfferPost
     * @summary Accept Offer
     * @request POST:/application/accept_offer
     * @secure
     */
    acceptOfferApplicationAcceptOfferPost: (data: AcceptOffer, params: RequestParams = {}) =>
      this.request<EventsResponse, HTTPValidationError>({
        path: `/application/accept_offer`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank Communication
     * @name CancelApplicationCancelPost
     * @summary Cancel
     * @request POST:/application/cancel
     * @secure
     */
    cancelApplicationCancelPost: (data: CancelOfferPayload, params: RequestParams = {}) =>
      this.request<EventsResponse, HTTPValidationError>({
        path: `/application/cancel`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank Communication
     * @name SendDocumentToIntegrationApplicationDocumentToBankPost
     * @summary Send Document To Integration
     * @request POST:/application/document_to_bank
     * @secure
     */
    sendDocumentToIntegrationApplicationDocumentToBankPost: (data: DocumentToBank, params: RequestParams = {}) =>
      this.request<EventsResponse, HTTPValidationError>({
        path: `/application/document_to_bank`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application Meta
     * @name AssignApplicationToHandlerApplicationAssignApplicationIdPost
     * @summary Assign Application To Handler
     * @request POST:/application/assign/{application_id}
     * @secure
     */
    assignApplicationToHandlerApplicationAssignApplicationIdPost: (
      applicationId: string,
      data: AssignApplicationPayload,
      params: RequestParams = {},
    ) =>
      this.request<void, HTTPValidationError>({
        path: `/application/assign/${applicationId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application Meta
     * @name ListInternalCommentsApplicationCommentApplicationIdGet
     * @summary List Internal Comments
     * @request GET:/application/comment/{application_id}
     * @secure
     */
    listInternalCommentsApplicationCommentApplicationIdGet: (applicationId: string, params: RequestParams = {}) =>
      this.request<
        (BankIDSignedWithNames | InternalCommentWithName | (BankIDSignedWithNames & InternalCommentWithName))[],
        HTTPValidationError
      >({
        path: `/application/comment/${applicationId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application Meta
     * @name AddInternalCommentApplicationCommentApplicationIdPost
     * @summary Add Internal Comment
     * @request POST:/application/comment/{application_id}
     * @secure
     */
    addInternalCommentApplicationCommentApplicationIdPost: (
      applicationId: string,
      data: CommentPayload,
      params: RequestParams = {},
    ) =>
      this.request<
        (BankIDSignedWithNames | InternalCommentWithName | (BankIDSignedWithNames & InternalCommentWithName))[],
        HTTPValidationError
      >({
        path: `/application/comment/${applicationId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application Meta
     * @name GetPossibleStatusesApplicationPrefsGet
     * @summary Get Possible Statuses
     * @request GET:/application/prefs
     * @secure
     */
    getPossibleStatusesApplicationPrefsGet: (params: RequestParams = {}) =>
      this.request<PrefsResponse, any>({
        path: `/application/prefs`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application Meta
     * @name GetPossibleLoanTypesApplicationLoanTypesCountryGet
     * @summary Get Possible Loan Types
     * @request GET:/application/loan-types/{country}
     * @secure
     */
    getPossibleLoanTypesApplicationLoanTypesCountryGet: (country: Country, params: RequestParams = {}) =>
      this.request<string[], HTTPValidationError>({
        path: `/application/loan-types/${country}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application Meta
     * @name SearchBlancoSverkerApplicationBlancoSearchCountryGet
     * @summary Search Blanco Sverker
     * @request GET:/application/blanco/search/{country}
     * @secure
     */
    searchBlancoSverkerApplicationBlancoSearchCountryGet: (
      country: Country,
      query: { s: string },
      params: RequestParams = {},
    ) =>
      this.request<SverkerSearchResponse, HTTPValidationError>({
        path: `/application/blanco/search/${country}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AML
     * @name AmlReviewApplicationAmlReviewPost
     * @summary Aml Review
     * @request POST:/application/aml/review
     * @secure
     */
    amlReviewApplicationAmlReviewPost: (data: AMLReviewPayload, params: RequestParams = {}) =>
      this.request<ApplicationWithNamesInComments, HTTPValidationError>({
        path: `/application/aml/review`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AML
     * @name GetBlancoAmlApplicationBlancoAmlApplicationIdGet
     * @summary Get Blanco Aml
     * @request GET:/application/blanco/aml/{application_id}
     * @secure
     */
    getBlancoAmlApplicationBlancoAmlApplicationIdGet: (
      applicationId: number,
      query: { country: Country },
      params: RequestParams = {},
    ) =>
      this.request<AMLBlancoResponse, HTTPValidationError>({
        path: `/application/blanco/aml/${applicationId}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AML
     * @name PatchBlancoAmlApplicationBlancoAmlApplicationIdPatch
     * @summary Patch Blanco Aml
     * @request PATCH:/application/blanco/aml/{application_id}
     * @secure
     */
    patchBlancoAmlApplicationBlancoAmlApplicationIdPatch: (
      applicationId: number,
      data: BlancoAMLPatchPayload,
      params: RequestParams = {},
    ) =>
      this.request<AMLBlancoResponse, HTTPValidationError>({
        path: `/application/blanco/aml/${applicationId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AML
     * @name ReviewBlancoAmlApplicationBlancoAmlReviewApplicationIdPatch
     * @summary Review Blanco Aml
     * @request PATCH:/application/blanco/aml/review/{application_id}
     * @secure
     */
    reviewBlancoAmlApplicationBlancoAmlReviewApplicationIdPatch: (
      applicationId: number,
      data: BlancoAMLReviewPayload,
      params: RequestParams = {},
    ) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/application/blanco/aml/review/${applicationId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AML
     * @name MortgageDeactivationReviewsApplicationDeactivationReviewsMortgagePost
     * @summary Mortgage Deactivation Reviews
     * @request POST:/application/deactivation_reviews/mortgage
     * @secure
     */
    mortgageDeactivationReviewsApplicationDeactivationReviewsMortgagePost: (
      data: DeactivationReviewsPayload,
      params: RequestParams = {},
    ) =>
      this.request<MortgageDeactivationReviewsResponse, HTTPValidationError>({
        path: `/application/deactivation_reviews/mortgage`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AML
     * @name BlancoDeactivationReviewsApplicationDeactivationReviewsBlancoPost
     * @summary Blanco Deactivation Reviews
     * @request POST:/application/deactivation_reviews/blanco
     * @secure
     */
    blancoDeactivationReviewsApplicationDeactivationReviewsBlancoPost: (
      data: DeactivationReviewsPayload,
      params: RequestParams = {},
    ) =>
      this.request<DeactivationReviewsResponse, HTTPValidationError>({
        path: `/application/deactivation_reviews/blanco`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Migrator
     * @name MigrateSverkerApplicationApplicationMigratorAppLabelPost
     * @summary Migrate Sverker Application
     * @request POST:/application/migrator/{app_label}
     * @secure
     */
    migrateSverkerApplicationApplicationMigratorAppLabelPost: (
      appLabel: string,
      data: ZrmMigratorEvents,
      params: RequestParams = {},
    ) =>
      this.request<ZrmMigrationStats, HTTPValidationError>({
        path: `/application/migrator/${appLabel}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  customer = {
    /**
     * No description
     *
     * @tags Customer
     * @name GetCustomerCustomerGetPost
     * @summary Get Customer
     * @request POST:/customer/get
     * @secure
     */
    getCustomerCustomerGetPost: (
      data:
        | PniGetCustomerPayload
        | EmailGetCustomerPayload
        | PhoneGetCustomerPayload
        | CustomerIDGetCustomerPayload
        | CustomerIDsGetCustomerPayload
        | (PniGetCustomerPayload &
          EmailGetCustomerPayload &
          PhoneGetCustomerPayload &
          CustomerIDGetCustomerPayload &
          CustomerIDsGetCustomerPayload),
      params: RequestParams = {},
    ) =>
      this.request<ApplicantSE | ApplicantNO, HTTPValidationError>({
        path: `/customer/get`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name GetCustomersCustomerGetsPost
     * @summary Get Customers
     * @request POST:/customer/gets
     * @secure
     */
    getCustomersCustomerGetsPost: (data: CustomerIDsGetCustomerPayload, params: RequestParams = {}) =>
      this.request<(ApplicantSE | ApplicantNO)[], HTTPValidationError>({
        path: `/customer/gets`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CreateCustomerCustomerCreatePost
     * @summary Create Customer
     * @request POST:/customer/create
     * @secure
     */
    createCustomerCustomerCreatePost: (data: CreateCustomerPayload, params: RequestParams = {}) =>
      this.request<ApplicantSE | ApplicantNO, HTTPValidationError>({
        path: `/customer/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name UpdateCustomerCustomerUpdatePost
     * @summary Update Customer
     * @request POST:/customer/update
     * @secure
     */
    updateCustomerCustomerUpdatePost: (data: UpdateCustomerPayload, params: RequestParams = {}) =>
      this.request<ApplicantSE | ApplicantNO, HTTPValidationError>({
        path: `/customer/update`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name UpdateConsentsCustomerUpdateConsentsPost
     * @summary Update Consents
     * @request POST:/customer/update_consents
     * @secure
     */
    updateConsentsCustomerUpdateConsentsPost: (data: UpdateCustomerConsentsPayload, params: RequestParams = {}) =>
      this.request<ApplicantSE | ApplicantNO, HTTPValidationError>({
        path: `/customer/update_consents`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name IdCheckOrGetCustomerCustomerIdCheckPost
     * @summary Id Check Or Get Customer
     * @request POST:/customer/id-check
     * @secure
     */
    idCheckOrGetCustomerCustomerIdCheckPost: (data: IDCheckGetCustomerPayload, params: RequestParams = {}) =>
      this.request<
        (ApplicantSE | ApplicantNO) | IDCheckedPerson | null | ((ApplicantSE | ApplicantNO) & IDCheckedPerson),
        HTTPValidationError
      >({
        path: `/customer/id-check`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name TakeCreditCheckCustomerCreditCheckPost
     * @summary Take Credit Check
     * @request POST:/customer/credit-check
     * @secure
     */
    takeCreditCheckCustomerCreditCheckPost: (data: TakeCreditCheck, params: RequestParams = {}) =>
      this.request<ApplicantSE | ApplicantNO, HTTPValidationError>({
        path: `/customer/credit-check`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name GetNameByPniCustomerNameByPniPost
     * @summary Get Name By Pni
     * @request POST:/customer/name/by-pni
     * @secure
     */
    getNameByPniCustomerNameByPniPost: (data: GetNameByPNIPayload, params: RequestParams = {}) =>
      this.request<Name | null, HTTPValidationError>({
        path: `/customer/name/by-pni`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name GetNameCustomerNameCustomerIdGet
     * @summary Get Name
     * @request GET:/customer/name/{customer_id}
     * @secure
     */
    getNameCustomerNameCustomerIdGet: (customerId: string, params: RequestParams = {}) =>
      this.request<Name | null, HTTPValidationError>({
        path: `/customer/name/${customerId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer, Application Meta
     * @name EmploymentTypesCustomerEmploymentTypesCountryGet
     * @summary Employment Types
     * @request GET:/customer/employment-types/{country}
     * @secure
     */
    employmentTypesCustomerEmploymentTypesCountryGet: (country: Country, params: RequestParams = {}) =>
      this.request<string[], HTTPValidationError>({
        path: `/customer/employment-types/${country}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer, Application Meta
     * @name HousingTypesCustomerHousingTypesCountryGet
     * @summary Housing Types
     * @request GET:/customer/housing-types/{country}
     * @secure
     */
    housingTypesCustomerHousingTypesCountryGet: (country: Country, params: RequestParams = {}) =>
      this.request<string[], HTTPValidationError>({
        path: `/customer/housing-types/${country}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name GetExternallyVerifiedCreditsCustomerExternallyVerifiedCreditsPost
     * @summary Get Externally Verified Credits
     * @request POST:/customer/externally-verified-credits
     * @secure
     */
    getExternallyVerifiedCreditsCustomerExternallyVerifiedCreditsPost: (
      data: MinimalApplicant[],
      params: RequestParams = {},
    ) =>
      this.request<LoanAndExternallyVerifiedCredit, HTTPValidationError>({
        path: `/customer/externally-verified-credits`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  meeting = {
    /**
     * No description
     *
     * @tags Meeting
     * @name GetMeetingMeetingGetMeetingIdGet
     * @summary Get Meeting
     * @request GET:/meeting/get/{meeting_id}
     * @secure
     */
    getMeetingMeetingGetMeetingIdGet: (meetingId: string, params: RequestParams = {}) =>
      this.request<ModelsMeetingModelsMeeting, HTTPValidationError>({
        path: `/meeting/get/${meetingId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Meeting
     * @name GetMeetingsMeetingGetPost
     * @summary Get Meetings
     * @request POST:/meeting/get
     * @secure
     */
    getMeetingsMeetingGetPost: (data: GetMeetings, params: RequestParams = {}) =>
      this.request<
        | MeetingWithApp[]
        | MeetingWithHandlerName[]
        | ModelsMeetingModelsMeeting[]
        | (MeetingWithApp[] & MeetingWithHandlerName[] & ModelsMeetingModelsMeeting[]),
        HTTPValidationError
      >({
        path: `/meeting/get`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Meeting
     * @name GetMyMeetingsMeetingMinePost
     * @summary Get My Meetings
     * @request POST:/meeting/mine
     * @secure
     */
    getMyMeetingsMeetingMinePost: (data: GetMyMeetings, params: RequestParams = {}) =>
      this.request<
        (
          | MeetingWithApp
          | MeetingWithHandlerName
          | ModelsMeetingModelsMeeting
          | (MeetingWithApp & MeetingWithHandlerName & ModelsMeetingModelsMeeting)
        )[],
        HTTPValidationError
      >({
        path: `/meeting/mine`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Meeting
     * @name CreateMeetingMeetingCreatePost
     * @summary Create Meeting
     * @request POST:/meeting/create
     * @secure
     */
    createMeetingMeetingCreatePost: (data: CreateMeetingPayload, params: RequestParams = {}) =>
      this.request<ModelsMeetingModelsMeeting, HTTPValidationError>({
        path: `/meeting/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Meeting
     * @name UpdateMeetingMeetingUpdateMeetingIdPost
     * @summary Update Meeting
     * @request POST:/meeting/update/{meeting_id}
     * @secure
     */
    updateMeetingMeetingUpdateMeetingIdPost: (
      meetingId: string,
      data: UpdateMeetingPayload,
      params: RequestParams = {},
    ) =>
      this.request<ModelsMeetingModelsMeeting, HTTPValidationError>({
        path: `/meeting/update/${meetingId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Meeting
     * @name CancelMeetingsMeetingCancelMeetingIdDelete
     * @summary Cancel Meetings
     * @request DELETE:/meeting/cancel/{meeting_id}
     * @secure
     */
    cancelMeetingsMeetingCancelMeetingIdDelete: (meetingId: string, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/meeting/cancel/${meetingId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Meeting
     * @name GetBookableTimesMeetingAvailableDayGet
     * @summary Get Bookable Times
     * @request GET:/meeting/available/{day}
     * @secure
     */
    getBookableTimesMeetingAvailableDayGet: (day: string, params: RequestParams = {}) =>
      this.request<BookableSlot[], HTTPValidationError>({
        path: `/meeting/available/${day}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  document = {
    /**
     * No description
     *
     * @tags Document
     * @name GetDocumentMetaDocumentGetMetaCustomerIdDocumentIdGet
     * @summary Get Document Meta
     * @request GET:/document/get/meta/{customer_id}/{document_id}
     * @secure
     */
    getDocumentMetaDocumentGetMetaCustomerIdDocumentIdGet: (
      customerId: string,
      documentId: string,
      params: RequestParams = {},
    ) =>
      this.request<DocumentMeta, HTTPValidationError>({
        path: `/document/get/meta/${customerId}/${documentId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name GetDocumentDocumentGetCustomerIdDocumentIdGet
     * @summary Get Document
     * @request GET:/document/get/{customer_id}/{document_id}
     * @secure
     */
    getDocumentDocumentGetCustomerIdDocumentIdGet: (
      customerId: string,
      documentId: string,
      params: RequestParams = {},
    ) =>
      this.request<CustomerDocument, HTTPValidationError>({
        path: `/document/get/${customerId}/${documentId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name CreateDocumentDocumentSavePost
     * @summary Create Document
     * @request POST:/document/save
     * @secure
     */
    createDocumentDocumentSavePost: (data: DocumentCreate, params: RequestParams = {}) =>
      this.request<CustomerDocument, HTTPValidationError>({
        path: `/document/save`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name DocumentTypesDocumentTypesGet
     * @summary Document Types
     * @request GET:/document/types
     * @secure
     */
    documentTypesDocumentTypesGet: (params: RequestParams = {}) =>
      this.request<DocumentType[], any>({
        path: `/document/types`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name GetAttachedDocumentsOfApplicationDocumentAttachedApplicationIdGet
     * @summary Get Attached Documents Of Application
     * @request GET:/document/attached/{application_id}
     * @secure
     */
    getAttachedDocumentsOfApplicationDocumentAttachedApplicationIdGet: (
      applicationId: string,
      params: RequestParams = {},
    ) =>
      this.request<DocumentAssignation[], HTTPValidationError>({
        path: `/document/attached/${applicationId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name AttachDocumentToApplicationDocumentAttachApplicationIdPost
     * @summary Attach Document To Application
     * @request POST:/document/attach/{application_id}
     * @secure
     */
    attachDocumentToApplicationDocumentAttachApplicationIdPost: (
      applicationId: string,
      data: DocumentAssignation,
      params: RequestParams = {},
    ) =>
      this.request<DocumentAssignation[], HTTPValidationError>({
        path: `/document/attach/${applicationId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  banks = {
    /**
     * No description
     *
     * @tags Banks
     * @name GetAllBanksBanksAllGet
     * @summary Get All Banks
     * @request GET:/banks/all
     * @secure
     */
    getAllBanksBanksAllGet: (params: RequestParams = {}) =>
      this.request<Bank[], any>({
        path: `/banks/all`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Banks
     * @name GetBanksBanksGetPost
     * @summary Get Banks
     * @request POST:/banks/get
     * @secure
     */
    getBanksBanksGetPost: (data: GetBanksPayload, params: RequestParams = {}) =>
      this.request<Bank[], HTTPValidationError>({
        path: `/banks/get`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Banks
     * @name GetAllIntegrationsBanksAllIntegrationsGet
     * @summary Get All Integrations
     * @request GET:/banks/all_integrations
     * @secure
     */
    getAllIntegrationsBanksAllIntegrationsGet: (params: RequestParams = {}) =>
      this.request<Integration[], any>({
        path: `/banks/all_integrations`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Banks
     * @name GetIntegrationsBanksGetIntegrationsPost
     * @summary Get Integrations
     * @request POST:/banks/get_integrations
     * @secure
     */
    getIntegrationsBanksGetIntegrationsPost: (data: IntegrationQuery, params: RequestParams = {}) =>
      this.request<Integration[], HTTPValidationError>({
        path: `/banks/get_integrations`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  schedule = {
    /**
     * No description
     *
     * @tags Advisor Schedule
     * @name ScheduleDaySchedulePost
     * @summary Schedule Day
     * @request POST:/schedule/
     * @secure
     */
    scheduleDaySchedulePost: (data: DaySlotDocPayload, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/schedule/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Advisor Schedule
     * @name GetScheduleOfDayScheduleDayGet
     * @summary Get Schedule Of Day
     * @request GET:/schedule/{day}
     * @secure
     */
    getScheduleOfDayScheduleDayGet: (day: string, params: RequestParams = {}) =>
      this.request<DaySlotDoc, HTTPValidationError>({
        path: `/schedule/${day}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Advisor Schedule
     * @name GetScheduleOfDayByCountryAndProductScheduleDayProductCountryGet
     * @summary Get Schedule Of Day By Country And Product
     * @request GET:/schedule/{day}/{product}/{country}
     * @secure
     */
    getScheduleOfDayByCountryAndProductScheduleDayProductCountryGet: (
      day: string,
      product: Product,
      country: Country,
      params: RequestParams = {},
    ) =>
      this.request<DaySlotDoc, HTTPValidationError>({
        path: `/schedule/${day}/${product}/${country}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  employee = {
    /**
     * No description
     *
     * @tags Employee
     * @name GetAllEmployeesEmployeeAllGet
     * @summary Get All Employees
     * @request GET:/employee/all
     * @secure
     */
    getAllEmployeesEmployeeAllGet: (
      query?: { page?: number | null; limit?: number | null; without_deactivated?: boolean | null },
      params: RequestParams = {},
    ) =>
      this.request<Employee[], HTTPValidationError>({
        path: `/employee/all`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name GetSverkerUsersEmployeeGetSverkerUsersGet
     * @summary Get Sverker Users
     * @request GET:/employee/get_sverker_users
     * @secure
     */
    getSverkerUsersEmployeeGetSverkerUsersGet: (query: { user_name: string }, params: RequestParams = {}) =>
      this.request<(string | string | string)[], HTTPValidationError>({
        path: `/employee/get_sverker_users`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name GetEmployeesByZoordinatesEmployeeByZoordinatesPost
     * @summary Get Employees By Zoordinates
     * @request POST:/employee/by_zoordinates
     * @secure
     */
    getEmployeesByZoordinatesEmployeeByZoordinatesPost: (data: AllOptionalZoordinates, params: RequestParams = {}) =>
      this.request<Employee[], HTTPValidationError>({
        path: `/employee/by_zoordinates`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name GetMyInfoEmployeeMeGet
     * @summary Get My Info
     * @request GET:/employee/me
     * @secure
     */
    getMyInfoEmployeeMeGet: (params: RequestParams = {}) =>
      this.request<Employee, any>({
        path: `/employee/me`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name DeactivateUserByIdEmployeeActionsBlockOrUnblockUserIdPatch
     * @summary Deactivate User By Id
     * @request PATCH:/employee/actions/block-or-unblock/{user_id}
     * @secure
     */
    deactivateUserByIdEmployeeActionsBlockOrUnblockUserIdPatch: (
      userId: string,
      query?: { block?: boolean },
      params: RequestParams = {},
    ) =>
      this.request<void, HTTPValidationError>({
        path: `/employee/actions/block-or-unblock/${userId}`,
        method: "PATCH",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name GetAllZoordinatesEmployeeZoordinatesGet
     * @summary Get All Zoordinates
     * @request GET:/employee/zoordinates
     * @secure
     */
    getAllZoordinatesEmployeeZoordinatesGet: (params: RequestParams = {}) =>
      this.request<Zoordinates[], any>({
        path: `/employee/zoordinates`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name GetAllRolesEmployeeRolesGet
     * @summary Get All Roles
     * @request GET:/employee/roles
     * @secure
     */
    getAllRolesEmployeeRolesGet: (params: RequestParams = {}) =>
      this.request<RoleType[], any>({
        path: `/employee/roles`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name GetMySettingsEmployeeMySettingsGet
     * @summary Get My Settings
     * @request GET:/employee/my-settings
     * @secure
     */
    getMySettingsEmployeeMySettingsGet: (params: RequestParams = {}) =>
      this.request<UserSettings, any>({
        path: `/employee/my-settings`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name SaveMySettingsEmployeeMySettingsPost
     * @summary Save My Settings
     * @request POST:/employee/my-settings
     * @secure
     */
    saveMySettingsEmployeeMySettingsPost: (data: UserSettings, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/employee/my-settings`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name CreateEmployeeEmployeePost
     * @summary Create Employee
     * @request POST:/employee/
     * @secure
     */
    createEmployeeEmployeePost: (data: CreateAndUpdateEmployeeRequest, params: RequestParams = {}) =>
      this.request<Employee, HTTPValidationError>({
        path: `/employee/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name UpdateEmployeeEmployeeUserIdPut
     * @summary Update Employee
     * @request PUT:/employee/{user_id}
     * @secure
     */
    updateEmployeeEmployeeUserIdPut: (
      userId: string,
      data: CreateAndUpdateEmployeeRequest,
      params: RequestParams = {},
    ) =>
      this.request<Employee, HTTPValidationError>({
        path: `/employee/${userId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name GetByIdEmployeeUserIdGet
     * @summary Get By Id
     * @request GET:/employee/{user_id}
     * @secure
     */
    getByIdEmployeeUserIdGet: (userId: string, params: RequestParams = {}) =>
      this.request<Employee, HTTPValidationError>({
        path: `/employee/${userId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name SearchEmployeeSearchPost
     * @summary Search
     * @request POST:/employee/search
     * @secure
     */
    searchEmployeeSearchPost: (data: RoutersEmployeeModelsSearchPayload, params: RequestParams = {}) =>
      this.request<Employee[], HTTPValidationError>({
        path: `/employee/search`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  property = {
    /**
     * No description
     *
     * @tags Property
     * @name GetPropertyPropertyGetPropertyIdGet
     * @summary Get Property
     * @request GET:/property/get/{property_id}
     * @secure
     */
    getPropertyPropertyGetPropertyIdGet: (propertyId: string, params: RequestParams = {}) =>
      this.request<House | VacationHome | Condominium | (House & VacationHome & Condominium), HTTPValidationError>({
        path: `/property/get/${propertyId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property
     * @name GetPartialPropertyPropertyPartialGetPropertyIdGet
     * @summary Get Partial Property
     * @request GET:/property/partial/get/{property_id}
     * @secure
     */
    getPartialPropertyPropertyPartialGetPropertyIdGet: (propertyId: string, params: RequestParams = {}) =>
      this.request<PartialProperty, HTTPValidationError>({
        path: `/property/partial/get/${propertyId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property
     * @name CreatePropertyPropertyCreateApplicationIdPost
     * @summary Create Property
     * @request POST:/property/create/{application_id}
     * @secure
     */
    createPropertyPropertyCreateApplicationIdPost: (
      applicationId: string,
      data:
        | PartialHouse
        | PartialCondominium
        | PartialVacationHome
        | (PartialHouse & PartialCondominium & PartialVacationHome),
      params: RequestParams = {},
    ) =>
      this.request<House | VacationHome | Condominium | (House & VacationHome & Condominium), HTTPValidationError>({
        path: `/property/create/${applicationId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property
     * @name CreatePartialPropertyPropertyPartialCreateApplicationIdPost
     * @summary Create Partial Property
     * @request POST:/property/partial/create/{application_id}
     * @secure
     */
    createPartialPropertyPropertyPartialCreateApplicationIdPost: (
      applicationId: string,
      data: NewPartialProperty,
      params: RequestParams = {},
    ) =>
      this.request<PartialProperty, HTTPValidationError>({
        path: `/property/partial/create/${applicationId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property
     * @name UpdatePropertyPropertyUpdatePropertyIdPut
     * @summary Update Property
     * @request PUT:/property/update/{property_id}
     * @secure
     */
    updatePropertyPropertyUpdatePropertyIdPut: (
      propertyId: string,
      data:
        | PartialHouse
        | PartialCondominium
        | PartialVacationHome
        | (PartialHouse & PartialCondominium & PartialVacationHome),
      params: RequestParams = {},
    ) =>
      this.request<House | VacationHome | Condominium | (House & VacationHome & Condominium), HTTPValidationError>({
        path: `/property/update/${propertyId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property
     * @name UpdatePartialPropertyPropertyPartialUpdatePropertyIdPut
     * @summary Update Partial Property
     * @request PUT:/property/partial/update/{property_id}
     * @secure
     */
    updatePartialPropertyPropertyPartialUpdatePropertyIdPut: (
      propertyId: string,
      data: NewPartialProperty,
      params: RequestParams = {},
    ) =>
      this.request<PartialProperty, HTTPValidationError>({
        path: `/property/partial/update/${propertyId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property Valuation
     * @name SearchPropertyValuationSearchPropertyIdPost
     * @summary Search
     * @request POST:/property/valuation/search/{property_id}
     * @secure
     */
    searchPropertyValuationSearchPropertyIdPost: (
      propertyId: string,
      data: RoutersPropertyPayloadSearchPayload,
      params: RequestParams = {},
    ) =>
      this.request<PropertySearchResult[], HTTPValidationError>({
        path: `/property/valuation/search/${propertyId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property Creation
     * @name SearchForAddressPropertyV2SearchPropertyTypePost
     * @summary Search For Address
     * @request POST:/property/v2/search/{property_type}
     * @secure
     */
    searchForAddressPropertyV2SearchPropertyTypePost: (
      propertyType: MortgagePropertyType,
      data: RoutersPropertyPayloadSearchPayload,
      params: RequestParams = {},
    ) =>
      this.request<PropertySearchResult[], HTTPValidationError>({
        path: `/property/v2/search/${propertyType}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property Creation
     * @name GetPropertyInfoForCreationPropertyV2InfoSearchIdGet
     * @summary Get Property Info For Creation
     * @request GET:/property/v2/info/{search_id}
     * @secure
     */
    getPropertyInfoForCreationPropertyV2InfoSearchIdGet: (searchId: string, params: RequestParams = {}) =>
      this.request<PropertyInfo, HTTPValidationError>({
        path: `/property/v2/info/${searchId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property Creation
     * @name GetPropertyInfoByExternalIdPropertyV2InfoByExternalIdExternalIdGet
     * @summary Get Property Info By External Id
     * @request GET:/property/v2/info-by-external-id/{external_id}
     * @secure
     */
    getPropertyInfoByExternalIdPropertyV2InfoByExternalIdExternalIdGet: (
      externalId: string,
      params: RequestParams = {},
    ) =>
      this.request<PropertyInfo, HTTPValidationError>({
        path: `/property/v2/info-by-external-id/${externalId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property Valuation
     * @name GetPropertyValuationV2PropertyValuationV2Post
     * @summary Get Property Valuation V2
     * @request POST:/property/valuation/v2
     * @secure
     */
    getPropertyValuationV2PropertyValuationV2Post: (data: ValuationPayloadV2, params: RequestParams = {}) =>
      this.request<PropertyValuation, HTTPValidationError>({
        path: `/property/valuation/v2`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property Valuation
     * @name GetPropertyInfoPropertyValuationInfoSearchIdGet
     * @summary Get Property Info
     * @request GET:/property/valuation/info/{search_id}
     * @secure
     */
    getPropertyInfoPropertyValuationInfoSearchIdGet: (searchId: string, params: RequestParams = {}) =>
      this.request<PropertyInfo, HTTPValidationError>({
        path: `/property/valuation/info/${searchId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property Valuation
     * @name GetPropertyValuationPropertyValuationValuationPost
     * @summary Get Property Valuation
     * @request POST:/property/valuation/valuation
     * @secure
     */
    getPropertyValuationPropertyValuationValuationPost: (data: ValuationPayload, params: RequestParams = {}) =>
      this.request<PropertyValuation, HTTPValidationError>({
        path: `/property/valuation/valuation`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property Creation
     * @name BooliSearchForAddressPropertyV2BooliSearchPost
     * @summary Booli Search For Address
     * @request POST:/property/v2/booli/search
     * @deprecated
     * @secure
     */
    booliSearchForAddressPropertyV2BooliSearchPost: (data: BooliSearchPayload, params: RequestParams = {}) =>
      this.request<BooliPropertySearchResult[], HTTPValidationError>({
        path: `/property/v2/booli/search`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property Valuation
     * @name GetPropertyBooliValuationPropertyValuationBooliPost
     * @summary Get Property Booli Valuation
     * @request POST:/property/valuation/booli
     * @deprecated
     * @secure
     */
    getPropertyBooliValuationPropertyValuationBooliPost: (data: BooliValuationPayload, params: RequestParams = {}) =>
      this.request<PropertyValuation, HTTPValidationError>({
        path: `/property/valuation/booli`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetPropertyByPniPropertyEvcheckCachePost
     * @summary Get Property By Pni
     * @request POST:/property/evcheck/cache
     * @secure
     */
    getPropertyByPniPropertyEvcheckCachePost: (data: EvCheckPayload, params: RequestParams = {}) =>
      this.request<(PartialHouse | PartialCondominium | (PartialHouse & PartialCondominium))[], HTTPValidationError>({
        path: `/property/evcheck/cache`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  message = {
    /**
     * No description
     *
     * @tags Message
     * @name RemoveTemplateMessageTemplateDelete
     * @summary Remove Template
     * @request DELETE:/message/template/
     * @secure
     */
    removeTemplateMessageTemplateDelete: (data: GetTemplatePayload, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/message/template/`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name GetOneTemplateMessageTemplateGetPost
     * @summary Get One Template
     * @request POST:/message/template/get
     * @secure
     */
    getOneTemplateMessageTemplateGetPost: (data: GetTemplatePayload, params: RequestParams = {}) =>
      this.request<RootModelUnionEmailTemplateSmsTemplate, HTTPValidationError>({
        path: `/message/template/get`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name CreateTemplateMessageTemplateAddPost
     * @summary Create Template
     * @request POST:/message/template/add
     * @secure
     */
    createTemplateMessageTemplateAddPost: (data: RootModelUnionEmailTemplateSmsTemplate, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/message/template/add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name TemplateListMessageTemplateListMessageTypeGet
     * @summary Template List
     * @request GET:/message/template/list/{message_type}
     * @secure
     */
    templateListMessageTemplateListMessageTypeGet: (
      messageType: MessageType,
      query?: { cutoff_date?: string | null },
      params: RequestParams = {},
    ) =>
      this.request<(EmailTemplate | SmsTemplate | (EmailTemplate & SmsTemplate))[], HTTPValidationError>({
        path: `/message/template/list/${messageType}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name SendTemplateMessageMessageSendTemplateMessageTypeTemplateEventPost
     * @summary Send Template Message
     * @request POST:/message/send/template/{message_type}/{template_event}
     * @secure
     */
    sendTemplateMessageMessageSendTemplateMessageTypeTemplateEventPost: (
      messageType: MessageType,
      templateEvent: TemplateEvent,
      data: SendTemplatePayload,
      params: RequestParams = {},
    ) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/message/send/template/${messageType}/${templateEvent}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name SendRawMessageMessageSendRawMessageTypePost
     * @summary Send Raw Message
     * @request POST:/message/send/raw/{message_type}
     * @secure
     */
    sendRawMessageMessageSendRawMessageTypePost: (
      messageType: MessageType,
      data: SendRawPayload,
      params: RequestParams = {},
    ) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/message/send/raw/${messageType}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name GetUnknownSenderSmsMessageSmsUnknownSenderPost
     * @summary Get Unknown Sender Sms
     * @request POST:/message/sms/unknown-sender
     * @secure
     */
    getUnknownSenderSmsMessageSmsUnknownSenderPost: (data: GetMessagesPayload, params: RequestParams = {}) =>
      this.request<IncomingSMS[], HTTPValidationError>({
        path: `/message/sms/unknown-sender`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name GetMessagesForUserMessageUserPost
     * @summary Get Messages For User
     * @request POST:/message/user
     * @secure
     */
    getMessagesForUserMessageUserPost: (data: GetUserMessagesPayload, params: RequestParams = {}) =>
      this.request<SavedMessage[], HTTPValidationError>({
        path: `/message/user`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name GetMessagesForApplicationMessageApplicationPost
     * @summary Get Messages For Application
     * @request POST:/message/application
     * @secure
     */
    getMessagesForApplicationMessageApplicationPost: (
      data: GetApplicationMessagesPayload,
      params: RequestParams = {},
    ) =>
      this.request<SavedMessage[], HTTPValidationError>({
        path: `/message/application`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name GetMessagesByCustomerIdMessageByCustomerPost
     * @summary Get Messages By Customer Id
     * @request POST:/message/by-customer
     * @secure
     */
    getMessagesByCustomerIdMessageByCustomerPost: (
      data: GetMessages,
      query?: { page?: number; limit?: number },
      params: RequestParams = {},
    ) =>
      this.request<SavedMessageWithName[], HTTPValidationError>({
        path: `/message/by-customer`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name GetConversationsMessageConversationsGet
     * @summary Get Conversations
     * @request GET:/message/conversations
     * @secure
     */
    getConversationsMessageConversationsGet: (params: RequestParams = {}) =>
      this.request<Conversation[], any>({
        path: `/message/conversations`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name SaveConversationsMessageConversationsPost
     * @summary Save Conversations
     * @request POST:/message/conversations
     * @secure
     */
    saveConversationsMessageConversationsPost: (data: Conversation[], params: RequestParams = {}) =>
      this.request<Conversation[], HTTPValidationError>({
        path: `/message/conversations`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name StreamConversationsMessageConversationsStreamGet
     * @summary Stream Conversations
     * @request GET:/message/conversations/stream
     * @secure
     */
    streamConversationsMessageConversationsStreamGet: (params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, any>({
        path: `/message/conversations/stream`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name UploadAttachmentMessageAttachmentsUploadPost
     * @summary Upload Attachment
     * @request POST:/message/attachments/upload
     * @secure
     */
    uploadAttachmentMessageAttachmentsUploadPost: (
      data: BodyUploadAttachmentMessageAttachmentsUploadPost,
      params: RequestParams = {},
    ) =>
      this.request<string, HTTPValidationError>({
        path: `/message/attachments/upload`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.UrlEncoded,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name GetAttachmentsMessageAttachmentsAllGet
     * @summary Get Attachments
     * @request GET:/message/attachments/all
     * @secure
     */
    getAttachmentsMessageAttachmentsAllGet: (params: RequestParams = {}) =>
      this.request<SavedAttachment[], any>({
        path: `/message/attachments/all`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ELK46 Callbacks
     * @name Elk46SmsWebhookMessageElk46ConfirmationPost
     * @summary Elk46 Sms Webhook
     * @request POST:/message/elk46/confirmation
     */
    elk46SmsWebhookMessageElk46ConfirmationPost: (params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/message/elk46/confirmation`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ELK46 Callbacks
     * @name Elk46CallWebhookMessageElk46CallPost
     * @summary Elk46 Call Webhook
     * @request POST:/message/elk46/call
     */
    elk46CallWebhookMessageElk46CallPost: (
      data: BodyElk46CallWebhookMessageElk46CallPost,
      params: RequestParams = {},
    ) =>
      this.request<Elk46CallResponse, HTTPValidationError>({
        path: `/message/elk46/call`,
        method: "POST",
        body: data,
        type: ContentType.UrlEncoded,
        format: "json",
        ...params,
      }),
  };
  mortgage = {
    /**
     * No description
     *
     * @tags Mortgage
     * @name GetKalpSettingsMortgageKalpCountryGet
     * @summary Get Kalp Settings
     * @request GET:/mortgage/kalp/{country}
     * @secure
     */
    getKalpSettingsMortgageKalpCountryGet: (country: Country, params: RequestParams = {}) =>
      this.request<(BankKALP | BankAPIDrivenKALP | (BankKALP & BankAPIDrivenKALP))[], HTTPValidationError>({
        path: `/mortgage/kalp/${country}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  other = {
    /**
     * No description
     *
     * @tags Other
     * @name GetAllTaxesForCountryOtherTaxesCountryGet
     * @summary Get All Taxes For Country
     * @request GET:/other/taxes/{country}
     * @secure
     */
    getAllTaxesForCountryOtherTaxesCountryGet: (country: Country, params: RequestParams = {}) =>
      this.request<Tax[], HTTPValidationError>({
        path: `/other/taxes/${country}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Other
     * @name TaxesForIdentifierOtherTaxesCalculationPost
     * @summary Taxes For Identifier
     * @request POST:/other/taxes/calculation
     * @secure
     */
    taxesForIdentifierOtherTaxesCalculationPost: (data: GetTaxPayload, params: RequestParams = {}) =>
      this.request<GetTaxPayloadResponse, HTTPValidationError>({
        path: `/other/taxes/calculation`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Other
     * @name GetAllMunicipalitiesForCountryOtherMunicipalityCountryGet
     * @summary Get All Municipalities For Country
     * @request GET:/other/municipality/{country}
     * @secure
     */
    getAllMunicipalitiesForCountryOtherMunicipalityCountryGet: (country: Country, params: RequestParams = {}) =>
      this.request<Municipality[], HTTPValidationError>({
        path: `/other/municipality/${country}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Other
     * @name DeactivationReasonsOtherDeactivationReasonsGet
     * @summary Deactivation Reasons
     * @request GET:/other/deactivation-reasons
     * @secure
     */
    deactivationReasonsOtherDeactivationReasonsGet: (query: { product: Product }, params: RequestParams = {}) =>
      this.request<string[], HTTPValidationError>({
        path: `/other/deactivation-reasons`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Other, websocket
     * @name GetTokenOtherWsTokenForWebsocketGet
     * @summary Get Token
     * @request GET:/other/ws/token-for-websocket
     * @secure
     */
    getTokenOtherWsTokenForWebsocketGet: (params: RequestParams = {}) =>
      this.request<TokenResp, any>({
        path: `/other/ws/token-for-websocket`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  bankId = {
    /**
     * No description
     *
     * @tags BankID
     * @name VerifyCustomerBankIdVerifyPost
     * @summary Verify Customer
     * @request POST:/bank-id/verify
     * @secure
     */
    verifyCustomerBankIdVerifyPost: (data: BankIDPayload, params: RequestParams = {}) =>
      this.request<RoutersBankIdModelsBankIDResp, HTTPValidationError>({
        path: `/bank-id/verify`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BankID
     * @name CancelVerifyCustomerBankIdCancelDelete
     * @summary Cancel Verify Customer
     * @request DELETE:/bank-id/cancel
     * @secure
     */
    cancelVerifyCustomerBankIdCancelDelete: (query: { ticket_id: string }, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/bank-id/cancel`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BankID
     * @name IsAlreadyVerifiedBankIdVerifiedPost
     * @summary Is Already Verified
     * @request POST:/bank-id/verified
     * @secure
     */
    isAlreadyVerifiedBankIdVerifiedPost: (data: BankIDPayload, params: RequestParams = {}) =>
      this.request<PollResp, HTTPValidationError>({
        path: `/bank-id/verified`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BankID
     * @name GetTicketStatusBankIdVerifyTicketIdGet
     * @summary Get Ticket Status
     * @request GET:/bank-id/verify/{ticket_id}
     * @secure
     */
    getTicketStatusBankIdVerifyTicketIdGet: (ticketId: string, params: RequestParams = {}) =>
      this.request<PollResp, HTTPValidationError>({
        path: `/bank-id/verify/${ticketId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BankID
     * @name IdKollenRedirectNoBankIdIdKollenCallbackGet
     * @summary Id Kollen Redirect No
     * @request GET:/bank-id/id-kollen/callback
     */
    idKollenRedirectNoBankIdIdKollenCallbackGet: (
      query?: { refId?: string | null; id?: string | null },
      params: RequestParams = {},
    ) =>
      this.request<any, EmploymentTypeBase | HTTPValidationError>({
        path: `/bank-id/id-kollen/callback`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BankID
     * @name IdKollenCallbackSeBankIdIdKollenCallbackPost
     * @summary Id Kollen Callback Se
     * @request POST:/bank-id/id-kollen/callback
     */
    idKollenCallbackSeBankIdIdKollenCallbackPost: (data: IDKollenCollectResponse, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/bank-id/id-kollen/callback`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BankID
     * @name QrCodeBankIdQrCodeGet
     * @summary Qr Code
     * @request GET:/bank-id/qr-code
     */
    qrCodeBankIdQrCodeGet: (query: { ticket_id: string }, params: RequestParams = {}) =>
      this.request<QRCodeContent, HTTPValidationError>({
        path: `/bank-id/qr-code`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BankID
     * @name RedirectFromAppBankIdRedirectGet
     * @summary Redirect From App
     * @request GET:/bank-id/redirect
     */
    redirectFromAppBankIdRedirectGet: (query: { ticket_id: string }, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/bank-id/redirect`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BankID
     * @name LoginBankIdAuthPost
     * @summary Login
     * @request POST:/bank-id/auth
     */
    loginBankIdAuthPost: (data: LoginPayload, params: RequestParams = {}) =>
      this.request<RoutersBankIdLoginPayloadBankIDResp, HTTPValidationError>({
        path: `/bank-id/auth`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BankID
     * @name PollTicketBankIdTicketIdGet
     * @summary Poll Ticket
     * @request GET:/bank-id/{ticket_id}
     */
    pollTicketBankIdTicketIdGet: (ticketId: string, params: RequestParams = {}) =>
      this.request<BankIDLoginResponse, HTTPValidationError>({
        path: `/bank-id/${ticketId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  notification = {
    /**
     * No description
     *
     * @tags Notifications
     * @name UserNotificationsNotificationGet
     * @summary User Notifications
     * @request GET:/notification/
     * @secure
     */
    userNotificationsNotificationGet: (
      query?: {
        status?: NotificationEvent[] | null;
        notification_type?: ModelsNotificationsNotificationTypesNotificationType | null;
        page?: number;
        limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        (
          | SMSReceived
          | BankMessageReceived
          | OfferReceived
          | ModelsNotificationsNotificationInternalComment
          | ApplicationAssigned
          | GeneralMessage
          | LeadAssigned
          | LeadComment
          | ApplicationDisbursed
        )[],
        HTTPValidationError
      >({
        path: `/notification/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name GetNotificationNotificationByIdNotificationIdGet
     * @summary Get Notification
     * @request GET:/notification/by-id/{notification_id}
     * @secure
     */
    getNotificationNotificationByIdNotificationIdGet: (notificationId: string, params: RequestParams = {}) =>
      this.request<
        | SMSReceived
        | BankMessageReceived
        | OfferReceived
        | ModelsNotificationsNotificationInternalComment
        | ApplicationAssigned
        | GeneralMessage
        | LeadAssigned
        | LeadComment
        | ApplicationDisbursed,
        HTTPValidationError
      >({
        path: `/notification/by-id/${notificationId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name NotifyNotificationNotifyIncomingPost
     * @summary Notify
     * @request POST:/notification/notify/incoming
     * @secure
     */
    notifyNotificationNotifyIncomingPost: (data: Notification, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/notification/notify/incoming`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name NotifyInsuranceNotificationNotifyInsurancePost
     * @summary Notify Insurance
     * @request POST:/notification/notify/insurance
     * @secure
     */
    notifyInsuranceNotificationNotifyInsurancePost: (data: InsuranceNotification, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/notification/notify/insurance`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name AcknowledgeNotificationNotificationActionPost
     * @summary Acknowledge Notification
     * @request POST:/notification/action
     * @secure
     */
    acknowledgeNotificationNotificationActionPost: (data: ActionPayload, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/notification/action`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name CustomAuthUserNotificationsNotificationNotificationIdReceivedPatch
     * @summary Custom Auth User Notifications
     * @request PATCH:/notification/{notification_id}/received
     */
    customAuthUserNotificationsNotificationNotificationIdReceivedPatch: (
      notificationId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, HTTPValidationError>({
        path: `/notification/${notificationId}/received`,
        method: "PATCH",
        ...params,
      }),
  };
  ponytail = {
    /**
     * No description
     *
     * @tags Ponytail
     * @name GetDisbursedStatsPonytailDisbursedAllPost
     * @summary Get Disbursed Stats
     * @request POST:/ponytail/disbursed/all
     * @secure
     */
    getDisbursedStatsPonytailDisbursedAllPost: (data: DashboardPayload, params: RequestParams = {}) =>
      this.request<AllDisbursedLoans, HTTPValidationError>({
        path: `/ponytail/disbursed/all`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ponytail
     * @name GetMyDisbursedPonytailDisbursedMePost
     * @summary Get My Disbursed
     * @request POST:/ponytail/disbursed/me
     * @secure
     */
    getMyDisbursedPonytailDisbursedMePost: (data: DashboardPayload, params: RequestParams = {}) =>
      this.request<MyDisbursedLoans, HTTPValidationError>({
        path: `/ponytail/disbursed/me`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ponytail
     * @name GetAcceptedPonytailAcceptedAllPost
     * @summary Get Accepted
     * @request POST:/ponytail/accepted/all
     * @secure
     */
    getAcceptedPonytailAcceptedAllPost: (data: DashboardPayload, params: RequestParams = {}) =>
      this.request<AcceptedLoans, HTTPValidationError>({
        path: `/ponytail/accepted/all`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ponytail
     * @name GetMyAcceptedPonytailAcceptedMePost
     * @summary Get My Accepted
     * @request POST:/ponytail/accepted/me
     * @secure
     */
    getMyAcceptedPonytailAcceptedMePost: (data: DashboardPayload, params: RequestParams = {}) =>
      this.request<AcceptedLoans, HTTPValidationError>({
        path: `/ponytail/accepted/me`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ponytail
     * @name GetAllInsurancePonytailInsuranceAllPost
     * @summary Get All Insurance
     * @request POST:/ponytail/insurance/all
     * @secure
     */
    getAllInsurancePonytailInsuranceAllPost: (data: DashboardPayload, params: RequestParams = {}) =>
      this.request<AllInsurances, HTTPValidationError>({
        path: `/ponytail/insurance/all`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ponytail
     * @name GetMyInsurancePonytailInsuranceMePost
     * @summary Get My Insurance
     * @request POST:/ponytail/insurance/me
     * @secure
     */
    getMyInsurancePonytailInsuranceMePost: (data: DashboardPayload, params: RequestParams = {}) =>
      this.request<AllInsurances, HTTPValidationError>({
        path: `/ponytail/insurance/me`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ponytail
     * @name GetAllMyStatsPonytailMyStatsPost
     * @summary Get All My Stats
     * @request POST:/ponytail/my-stats
     * @secure
     */
    getAllMyStatsPonytailMyStatsPost: (data: DashboardPayload, params: RequestParams = {}) =>
      this.request<RootModelUnionMyStatsMyStatsMb, HTTPValidationError>({
        path: `/ponytail/my-stats`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ponytail
     * @name SummarizedViewPonytailSummarizedPost
     * @summary Summarized View
     * @request POST:/ponytail/summarized
     * @secure
     */
    summarizedViewPonytailSummarizedPost: (data: DashboardPayload, params: RequestParams = {}) =>
      this.request<RootModelUnionSummarizedViewMyStatsMb, HTTPValidationError>({
        path: `/ponytail/summarized`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ponytail
     * @name GetWorkingDaysPonytailWorkingDaysPost
     * @summary Get Working Days
     * @request POST:/ponytail/working-days
     * @secure
     */
    getWorkingDaysPonytailWorkingDaysPost: (data: GoalPayload, params: RequestParams = {}) =>
      this.request<WorkingDays, HTTPValidationError>({
        path: `/ponytail/working-days`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ponytail
     * @name PostBlancoGoalPonytailGoalsCreateBlancoPost
     * @summary Post Blanco Goal
     * @request POST:/ponytail/goals/create/blanco
     * @secure
     */
    postBlancoGoalPonytailGoalsCreateBlancoPost: (data: BlancoGoalPayload, params: RequestParams = {}) =>
      this.request<BlancoGoal, HTTPValidationError>({
        path: `/ponytail/goals/create/blanco`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ponytail
     * @name PostBlancoGoalMbPonytailGoalsCreateBlancoMbPost
     * @summary Post Blanco Goal Mb
     * @request POST:/ponytail/goals/create/blanco-mb
     * @secure
     */
    postBlancoGoalMbPonytailGoalsCreateBlancoMbPost: (data: BlancoGoalMbPayload, params: RequestParams = {}) =>
      this.request<BlancoGoalMb, HTTPValidationError>({
        path: `/ponytail/goals/create/blanco-mb`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ponytail
     * @name PostInitialBlancoGoalPonytailGoalsCreateInitialBlancoPost
     * @summary Post Initial Blanco Goal
     * @request POST:/ponytail/goals/create/initial-blanco
     * @secure
     */
    postInitialBlancoGoalPonytailGoalsCreateInitialBlancoPost: (data: BlancoGoalPayload, params: RequestParams = {}) =>
      this.request<BlancoGoal, HTTPValidationError>({
        path: `/ponytail/goals/create/initial-blanco`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ponytail
     * @name PostInitialBudgetPonytailGoalsCreateInitialBlancoMbPost
     * @summary Post Initial Budget
     * @request POST:/ponytail/goals/create/initial-blanco-mb
     * @secure
     */
    postInitialBudgetPonytailGoalsCreateInitialBlancoMbPost: (data: BudgetPayload, params: RequestParams = {}) =>
      this.request<Budget, HTTPValidationError>({
        path: `/ponytail/goals/create/initial-blanco-mb`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ponytail
     * @name GetMyGoalsPonytailGoalsPost
     * @summary Get My Goals
     * @request POST:/ponytail/goals
     * @secure
     */
    getMyGoalsPonytailGoalsPost: (data: GoalPayload, params: RequestParams = {}) =>
      this.request<
        BlancoGoalMb | BlancoGoal | MortgageGoal | null | (BlancoGoalMb & BlancoGoal & MortgageGoal),
        HTTPValidationError
      >({
        path: `/ponytail/goals`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ponytail
     * @name GetInitialGoalPonytailGoalsInitialGoalPost
     * @summary Get Initial Goal
     * @request POST:/ponytail/goals/initial-goal
     * @secure
     */
    getInitialGoalPonytailGoalsInitialGoalPost: (data: GoalPayload, params: RequestParams = {}) =>
      this.request<
        Budget | BlancoGoal | MortgageGoal | null | (Budget & BlancoGoal & MortgageGoal),
        HTTPValidationError
      >({
        path: `/ponytail/goals/initial-goal`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  lead = {
    /**
     * No description
     *
     * @tags Lead
     * @name GetLeadLeadGetLeadIdGet
     * @summary Get Lead
     * @request GET:/lead/get/{lead_id}
     * @secure
     */
    getLeadLeadGetLeadIdGet: (leadId: string, params: RequestParams = {}) =>
      this.request<Lead, HTTPValidationError>({
        path: `/lead/get/${leadId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lead
     * @name GetLeadsLeadListPost
     * @summary Get Leads
     * @request POST:/lead/list
     * @secure
     */
    getLeadsLeadListPost: (data: GetLeads, params: RequestParams = {}) =>
      this.request<Lead[], HTTPValidationError>({
        path: `/lead/list`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lead
     * @name CreateLeadLeadCreatePost
     * @summary Create Lead
     * @request POST:/lead/create
     * @secure
     */
    createLeadLeadCreatePost: (data: CreateLeadPayload, params: RequestParams = {}) =>
      this.request<Lead, HTTPValidationError>({
        path: `/lead/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lead
     * @name SearchLeadSearchPost
     * @summary Search
     * @request POST:/lead/search
     * @secure
     */
    searchLeadSearchPost: (data: RoutersLeadPayloadSearchPayload, params: RequestParams = {}) =>
      this.request<Lead[], HTTPValidationError>({
        path: `/lead/search`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lead
     * @name ConvertAndCloseLeadConvertAndMarkHandledPost
     * @summary Convert And Close
     * @request POST:/lead/convert_and_mark_handled
     * @secure
     */
    convertAndCloseLeadConvertAndMarkHandledPost: (data: ConvertPayload, params: RequestParams = {}) =>
      this.request<ConvertResult, HTTPValidationError>({
        path: `/lead/convert_and_mark_handled`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lead
     * @name CloseLeadMarkHandledPost
     * @summary Close
     * @request POST:/lead/mark_handled
     * @secure
     */
    closeLeadMarkHandledPost: (data: LeadHandledPayload, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/lead/mark_handled`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lead
     * @name UnhandleLeadLeadUnhandlePost
     * @summary Unhandle Lead
     * @request POST:/lead/unhandle
     * @secure
     */
    unhandleLeadLeadUnhandlePost: (query: { lead_id: string }, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/lead/unhandle`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lead
     * @name UpdateLeadLeadUpdateLeadIdPost
     * @summary Update Lead
     * @request POST:/lead/update/{lead_id}
     * @secure
     */
    updateLeadLeadUpdateLeadIdPost: (leadId: string, data: LeadUpdatePayload, params: RequestParams = {}) =>
      this.request<Lead, HTTPValidationError>({
        path: `/lead/update/${leadId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lead
     * @name ReprocessSubmissionLeadReprocessSubmissionLeadIdPost
     * @summary Reprocess Submission
     * @request POST:/lead/reprocess_submission/{lead_id}
     * @secure
     */
    reprocessSubmissionLeadReprocessSubmissionLeadIdPost: (leadId: string, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/lead/reprocess_submission/${leadId}`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lead
     * @name ReprocessSubmissionsLeadReprocessSubmissionsPost
     * @summary Reprocess Submissions
     * @request POST:/lead/reprocess_submissions
     * @secure
     */
    reprocessSubmissionsLeadReprocessSubmissionsPost: (data: ReprocessSubmissionPayload, params: RequestParams = {}) =>
      this.request<ReprocessResult[], HTTPValidationError>({
        path: `/lead/reprocess_submissions`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lead
     * @name GetCommentsLeadCommentLeadIdGet
     * @summary Get Comments
     * @request GET:/lead/comment/{lead_id}
     * @secure
     */
    getCommentsLeadCommentLeadIdGet: (leadId: string, params: RequestParams = {}) =>
      this.request<InternalCommentWithName[], HTTPValidationError>({
        path: `/lead/comment/${leadId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lead
     * @name AddInternalCommentLeadCommentLeadIdPost
     * @summary Add Internal Comment
     * @request POST:/lead/comment/{lead_id}
     * @secure
     */
    addInternalCommentLeadCommentLeadIdPost: (leadId: string, data: CommentPayload, params: RequestParams = {}) =>
      this.request<InternalCommentWithName[], HTTPValidationError>({
        path: `/lead/comment/${leadId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lead
     * @name AssignLeadToHandlerLeadAssignLeadIdPost
     * @summary Assign Lead To Handler
     * @request POST:/lead/assign/{lead_id}
     * @secure
     */
    assignLeadToHandlerLeadAssignLeadIdPost: (
      leadId: string,
      data: AssignApplicationPayload,
      params: RequestParams = {},
    ) =>
      this.request<void, HTTPValidationError>({
        path: `/lead/assign/${leadId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lead
     * @name GetAssignedHandlerLeadAssigneeLeadIdGet
     * @summary Get Assigned Handler
     * @request GET:/lead/assignee/{lead_id}
     * @secure
     */
    getAssignedHandlerLeadAssigneeLeadIdGet: (leadId: string, params: RequestParams = {}) =>
      this.request<Assignee, HTTPValidationError>({
        path: `/lead/assignee/${leadId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lead
     * @name GetLeadsLeadListMinePost
     * @summary Get Leads
     * @request POST:/lead/list/mine
     * @secure
     */
    getLeadsLeadListMinePost: (data: GetMyLeads, params: RequestParams = {}) =>
      this.request<Lead[], HTTPValidationError>({
        path: `/lead/list/mine`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  formprefs = {
    /**
     * No description
     *
     * @tags Jotform
     * @name UpdatePreferenceFormprefsPut
     * @summary Update Preference
     * @request PUT:/formprefs/
     * @secure
     */
    updatePreferenceFormprefsPut: (data: UpdateFormPreferencePayload, params: RequestParams = {}) =>
      this.request<FormPreferenceExt, HTTPValidationError>({
        path: `/formprefs/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jotform
     * @name CreatePreferenceFormprefsPost
     * @summary Create Preference
     * @request POST:/formprefs/
     * @secure
     */
    createPreferenceFormprefsPost: (data: FormPreferencePayload, params: RequestParams = {}) =>
      this.request<FormPreferenceExt, HTTPValidationError>({
        path: `/formprefs/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jotform
     * @name DuplicatePreferenceFormprefsDuplicatePost
     * @summary Duplicate Preference
     * @request POST:/formprefs/duplicate
     * @secure
     */
    duplicatePreferenceFormprefsDuplicatePost: (data: DuplicateFormPreferencePayload, params: RequestParams = {}) =>
      this.request<FormPreferenceExt, HTTPValidationError>({
        path: `/formprefs/duplicate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jotform
     * @name ValidatePreferenceFormprefsValidatePost
     * @summary Validate Preference
     * @request POST:/formprefs/validate
     * @secure
     */
    validatePreferenceFormprefsValidatePost: (data: ValidatePayload, params: RequestParams = {}) =>
      this.request<FormPreferenceExt, HTTPValidationError>({
        path: `/formprefs/validate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jotform
     * @name GetMappingFormprefsGetMappingPost
     * @summary Get Mapping
     * @request POST:/formprefs/get_mapping
     * @secure
     */
    getMappingFormprefsGetMappingPost: (data: BaseFormPreferencePayload, params: RequestParams = {}) =>
      this.request<FormMapping, HTTPValidationError>({
        path: `/formprefs/get_mapping`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jotform
     * @name ListPreferencesFormprefsListGet
     * @summary List Preferences
     * @request GET:/formprefs/list
     * @secure
     */
    listPreferencesFormprefsListGet: (params: RequestParams = {}) =>
      this.request<FormPreference[], any>({
        path: `/formprefs/list`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jotform
     * @name ListPreferencesExtFormprefsListExtGet
     * @summary List Preferences Ext
     * @request GET:/formprefs/list_ext
     * @secure
     */
    listPreferencesExtFormprefsListExtGet: (params: RequestParams = {}) =>
      this.request<FormPreferenceExt[], any>({
        path: `/formprefs/list_ext`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jotform
     * @name ListFormsFormprefsFormsGet
     * @summary List Forms
     * @request GET:/formprefs/forms
     * @secure
     */
    listFormsFormprefsFormsGet: (params: RequestParams = {}) =>
      this.request<Form[], any>({
        path: `/formprefs/forms`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jotform
     * @name CheckAllSubmissionsFormprefsCheckAllGet
     * @summary Check All Submissions
     * @request GET:/formprefs/check-all
     * @secure
     */
    checkAllSubmissionsFormprefsCheckAllGet: (params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, any>({
        path: `/formprefs/check-all`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jotform
     * @name GetAllSubmissionsFormprefsSubmissionsGet
     * @summary Get All Submissions
     * @request GET:/formprefs/submissions
     * @secure
     */
    getAllSubmissionsFormprefsSubmissionsGet: (query?: { form_id?: string | null }, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/formprefs/submissions`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jotform
     * @name GetFormPreferenceFormprefsFormPreferenceIdGet
     * @summary Get Form Preference
     * @request GET:/formprefs/{form_preference_id}
     * @secure
     */
    getFormPreferenceFormprefsFormPreferenceIdGet: (
      formPreferenceId: string,
      query?: { add_defaults?: boolean },
      params: RequestParams = {},
    ) =>
      this.request<FormPreferenceExt, HTTPValidationError>({
        path: `/formprefs/${formPreferenceId}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jotform
     * @name CreateWebhookFormprefsWebhookPost
     * @summary Create Webhook
     * @request POST:/formprefs/webhook
     * @secure
     */
    createWebhookFormprefsWebhookPost: (data: FormWebhookPayload, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/formprefs/webhook`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jotform
     * @name DeleteWebhookFormprefsWebhookDelete
     * @summary Delete Webhook
     * @request DELETE:/formprefs/webhook
     * @secure
     */
    deleteWebhookFormprefsWebhookDelete: (data: FormWebhookPayload, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/formprefs/webhook`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  heimdall = {
    /**
     * No description
     *
     * @tags Heimdall
     * @name GetAllHeimdallRecordsForDateHeimdallDateGet
     * @summary Get All Heimdall Records For Date
     * @request GET:/heimdall/{date}
     * @secure
     */
    getAllHeimdallRecordsForDateHeimdallDateGet: (date: string, params: RequestParams = {}) =>
      this.request<HeimdallResponse[], HTTPValidationError>({
        path: `/heimdall/${date}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Heimdall
     * @name ReportAndVerifyUsageToHeimdallHeimdallUserIdPatch
     * @summary Report And Verify Usage To Heimdall
     * @request PATCH:/heimdall/{user_id}
     * @secure
     */
    reportAndVerifyUsageToHeimdallHeimdallUserIdPatch: (
      userId: string,
      data: HeimdallReportingPayload,
      params: RequestParams = {},
    ) =>
      this.request<HeimdallReportingResponse, HTTPValidationError>({
        path: `/heimdall/${userId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Heimdall
     * @name ResetHeimdallRateLimitHeimdallUserIdToResetResetPatch
     * @summary Reset Heimdall Rate Limit
     * @request PATCH:/heimdall/{user_id_to_reset}/reset
     * @secure
     */
    resetHeimdallRateLimitHeimdallUserIdToResetResetPatch: (userIdToReset: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/heimdall/${userIdToReset}/reset`,
        method: "PATCH",
        secure: true,
        ...params,
      }),
  };
  larsMagnus = {
    /**
     * No description
     *
     * @tags Lars Magnus
     * @name CheckTelavoxIdsLarsMagnusTelavoxCheckGet
     * @summary Check Telavox Ids
     * @request GET:/lars-magnus/telavox-check
     * @secure
     */
    checkTelavoxIdsLarsMagnusTelavoxCheckGet: (params: RequestParams = {}) =>
      this.request<TelavoxMismatch, any>({
        path: `/lars-magnus/telavox-check`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lars Magnus
     * @name CurrentTelavoxUserLarsMagnusTelavoxUserGet
     * @summary Current Telavox User
     * @request GET:/lars-magnus/telavox-user
     * @secure
     */
    currentTelavoxUserLarsMagnusTelavoxUserGet: (params: RequestParams = {}) =>
      this.request<TelavoxExtension, any>({
        path: `/lars-magnus/telavox-user`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  myPages = {
    /**
     * No description
     *
     * @tags My Pages
     * @name ImpersonateCustomerMyPagesImpersonatePost
     * @summary Impersonate Customer
     * @request POST:/my-pages/impersonate
     * @secure
     */
    impersonateCustomerMyPagesImpersonatePost: (data: ImpersonateCustomerPayload, params: RequestParams = {}) =>
      this.request<BankIDLoginResponse, HTTPValidationError>({
        path: `/my-pages/impersonate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name ValidateClearingMyPagesOffersClearingValidateClearingGet
     * @summary Validate Clearing
     * @request GET:/my-pages/offers/clearing/validate/{clearing}
     */
    validateClearingMyPagesOffersClearingValidateClearingGet: (clearing: string, params: RequestParams = {}) =>
      this.request<EmptyModel, HTTPValidationError>({
        path: `/my-pages/offers/clearing/validate/${clearing}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Gather request md and proceed with accepting terms for insurance used for customers that are not authenticated and cannot change the amount
     *
     * @tags My Pages
     * @name ConfirmInsuranceTermsMyPagesInsurancesConfirmPost
     * @summary Confirm Insurance Terms
     * @request POST:/my-pages/insurances/confirm
     */
    confirmInsuranceTermsMyPagesInsurancesConfirmPost: (
      query: {
        encrypted_insurance_id: string;
        email: string;
        phone_number: string;
        success_url: string;
        fail_url: string;
        insured_amount?: number | null;
        premium?: number | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<AcceptTermsResponse, HTTPValidationError>({
        path: `/my-pages/insurances/confirm`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name GetInsuranceMyPagesInsuranceGet
     * @summary Get Insurance
     * @request GET:/my-pages/insurance
     */
    getInsuranceMyPagesInsuranceGet: (query: { id: string }, params: RequestParams = {}) =>
      this.request<UnauthenticatedInsurance, HTTPValidationError>({
        path: `/my-pages/insurance`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name GetLatestActiveApplicationsV2MyPagesV2ApplicationsLatestGet
     * @summary Get Latest Active Applications V2
     * @request GET:/my-pages/v2/applications/latest
     * @secure
     */
    getLatestActiveApplicationsV2MyPagesV2ApplicationsLatestGet: (params: RequestParams = {}) =>
      this.request<CustomerApplicationV2[], any>({
        path: `/my-pages/v2/applications/latest`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name GetLatestActiveApplicationMyPagesApplicationLatestGet
     * @summary Get Latest Active Application
     * @request GET:/my-pages/application/latest
     * @secure
     */
    getLatestActiveApplicationMyPagesApplicationLatestGet: (params: RequestParams = {}) =>
      this.request<CustomerApplication | null, any>({
        path: `/my-pages/application/latest`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name GetCreditCardOffersMyPagesCreditCardOffersGet
     * @summary Get Credit Card Offers
     * @request GET:/my-pages/credit_card/offers
     * @secure
     */
    getCreditCardOffersMyPagesCreditCardOffersGet: (params: RequestParams = {}) =>
      this.request<CreditorOfferCreditCardOfferCreditCardTerms[], any>({
        path: `/my-pages/credit_card/offers`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name GetApplicantMyPagesApplicantMeGet
     * @summary Get Applicant
     * @request GET:/my-pages/applicant/me
     * @secure
     */
    getApplicantMyPagesApplicantMeGet: (params: RequestParams = {}) =>
      this.request<ApplicantSE | ApplicantNO, any>({
        path: `/my-pages/applicant/me`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Legacy blanco endpoint (Sverker)
     *
     * @tags My Pages
     * @name AcceptOfferLegacyMyPagesOfferAcceptPost
     * @summary Accept Offer Legacy
     * @request POST:/my-pages/offer/accept
     * @secure
     */
    acceptOfferLegacyMyPagesOfferAcceptPost: (data: AcceptOfferPayload, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/my-pages/offer/accept`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Legacy blanco endpoint (Sverker)
     *
     * @tags My Pages
     * @name AcceptOfferLegacyV2MyPagesV2OfferAcceptPost
     * @summary Accept Offer Legacy V2
     * @request POST:/my-pages/v2/offer/accept
     * @secure
     */
    acceptOfferLegacyV2MyPagesV2OfferAcceptPost: (data: AcceptOfferPayloadV2, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/my-pages/v2/offer/accept`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name AcceptOfferMyPagesOfferAccept2Post
     * @summary Accept Offer
     * @request POST:/my-pages/offer/accept2
     * @secure
     */
    acceptOfferMyPagesOfferAccept2Post: (data: AcceptOffer, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/my-pages/offer/accept2`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name BanksToExcludeFromInsuranceOfferingForMyPagesInsurancesBanksExcludedGet
     * @summary Banks To Exclude From Insurance Offering For
     * @request GET:/my-pages/insurances/banks/excluded
     * @deprecated
     * @secure
     */
    banksToExcludeFromInsuranceOfferingForMyPagesInsurancesBanksExcludedGet: (params: RequestParams = {}) =>
      this.request<BanksToExcludeInsuranceOfferingFor, any>({
        path: `/my-pages/insurances/banks/excluded`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name InsurancesRulesMyPagesInsurancesRulesGet
     * @summary Insurances Rules
     * @request GET:/my-pages/insurances/rules
     * @secure
     */
    insurancesRulesMyPagesInsurancesRulesGet: (params: RequestParams = {}) =>
      this.request<InsuranceTerms[], any>({
        path: `/my-pages/insurances/rules`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name LoanInsurancesRulesMyPagesInsurancesLoanInsuranceRulesGet
     * @summary Loan Insurances Rules
     * @request GET:/my-pages/insurances/loan-insurance-rules
     * @secure
     */
    loanInsurancesRulesMyPagesInsurancesLoanInsuranceRulesGet: (params: RequestParams = {}) =>
      this.request<InsuranceTerms, any>({
        path: `/my-pages/insurances/loan-insurance-rules`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name IdCheckMyPagesIdCheckGet
     * @summary Id Check
     * @request GET:/my-pages/id-check
     * @secure
     */
    idCheckMyPagesIdCheckGet: (params: RequestParams = {}) =>
      this.request<
        | (ApplicantSE | ApplicantNO)
        | (OptionalApplicantSE | OptionalApplicantNO)
        | IDCheckedPerson
        | ((ApplicantSE | ApplicantNO) & (OptionalApplicantSE | OptionalApplicantNO) & IDCheckedPerson),
        any
      >({
        path: `/my-pages/id-check`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name ActiveInsuranceByPniMyPagesInsurancesGet
     * @summary Active Insurance By Pni
     * @request GET:/my-pages/insurances
     * @secure
     */
    activeInsuranceByPniMyPagesInsurancesGet: (params: RequestParams = {}) =>
      this.request<InsuranceCustomer[], any>({
        path: `/my-pages/insurances`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name CreateInsuranceV2MyPagesInsurancesV2Post
     * @summary Create Insurance V2
     * @request POST:/my-pages/insurances/v2
     * @secure
     */
    createInsuranceV2MyPagesInsurancesV2Post: (data: CreateInsuranceCustomer, params: RequestParams = {}) =>
      this.request<AcceptTermsResponseCustomer, HTTPValidationError>({
        path: `/my-pages/insurances/v2`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name DebtRegisterConsentMyPagesDebtRegisterConsentPost
     * @summary Debt Register Consent
     * @request POST:/my-pages/debt-register/consent
     * @secure
     */
    debtRegisterConsentMyPagesDebtRegisterConsentPost: (params: RequestParams = {}) =>
      this.request<
        | (ApplicantSE | ApplicantNO)
        | (OptionalApplicantSE | OptionalApplicantNO)
        | ((ApplicantSE | ApplicantNO) & (OptionalApplicantSE | OptionalApplicantNO)),
        HTTPValidationError
      >({
        path: `/my-pages/debt-register/consent`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name DebtRegisterCheckConsentMyPagesDebtRegisterCheckConsentPost
     * @summary Debt Register Check Consent
     * @request POST:/my-pages/debt-register/check-consent
     * @secure
     */
    debtRegisterCheckConsentMyPagesDebtRegisterCheckConsentPost: (params: RequestParams = {}) =>
      this.request<
        | (ApplicantSE | ApplicantNO)
        | (OptionalApplicantSE | OptionalApplicantNO)
        | ((ApplicantSE | ApplicantNO) & (OptionalApplicantSE | OptionalApplicantNO)),
        any
      >({
        path: `/my-pages/debt-register/check-consent`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name DebtRegisterRenewMyPagesDebtRegisterRenewPost
     * @summary Debt Register Renew
     * @request POST:/my-pages/debt-register/renew
     * @secure
     */
    debtRegisterRenewMyPagesDebtRegisterRenewPost: (params: RequestParams = {}) =>
      this.request<
        | (ApplicantSE | ApplicantNO)
        | (OptionalApplicantSE | OptionalApplicantNO)
        | ((ApplicantSE | ApplicantNO) & (OptionalApplicantSE | OptionalApplicantNO)),
        any
      >({
        path: `/my-pages/debt-register/renew`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name UpsertMarketingConsentMyPagesMarketingConsentsPost
     * @summary Upsert Marketing Consent
     * @request POST:/my-pages/marketing-consents
     * @secure
     */
    upsertMarketingConsentMyPagesMarketingConsentsPost: (data: ConsentsPayload, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/my-pages/marketing-consents`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name GetTrustlyUrlForNewMandateMyPagesInsurancesTrustlyUrlGet
     * @summary Get Trustly Url For New Mandate
     * @request GET:/my-pages/insurances/trustly-url
     * @secure
     */
    getTrustlyUrlForNewMandateMyPagesInsurancesTrustlyUrlGet: (query: { id: string }, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/my-pages/insurances/trustly-url`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name GetLifeInsuranceEligiabilityMyPagesLifeInsuranceEligiabilityCustomerIdGet
     * @summary Get Life Insurance Eligiability
     * @request GET:/my-pages/life-insurance-eligiability/{customer_id}
     * @secure
     */
    getLifeInsuranceEligiabilityMyPagesLifeInsuranceEligiabilityCustomerIdGet: (
      customerId: string,
      params: RequestParams = {},
    ) =>
      this.request<HadOfferEverResponse, HTTPValidationError>({
        path: `/my-pages/life-insurance-eligiability/${customerId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  zms = {
    /**
     * No description
     *
     * @tags ZMS
     * @name SaveZmsConfigZmsCountryPost
     * @summary Save Zms Config
     * @request POST:/zms/{country}
     * @secure
     */
    saveZmsConfigZmsCountryPost: (country: Country, data: Config, params: RequestParams = {}) =>
      this.request<ZMSConfig, HTTPValidationError>({
        path: `/zms/${country}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ZMS
     * @name GetZmsConfigZmsCountryGet
     * @summary Get Zms Config
     * @request GET:/zms/{country}
     */
    getZmsConfigZmsCountryGet: (country: Country, params: RequestParams = {}) =>
      this.request<ZMSConfig, HTTPValidationError>({
        path: `/zms/${country}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ZMS
     * @name GetAllConfigsZmsGet
     * @summary Get All Configs
     * @request GET:/zms
     */
    getAllConfigsZmsGet: (params: RequestParams = {}) =>
      this.request<ZMSConfig[], any>({
        path: `/zms`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  affiliates = {
    /**
     * No description
     *
     * @tags Affiliate
     * @name CreateAffiliateAffiliatesPost
     * @summary Create Affiliate
     * @request POST:/affiliates/
     * @secure
     */
    createAffiliateAffiliatesPost: (data: AffiliateData, params: RequestParams = {}) =>
      this.request<Affiliate, HTTPValidationError>({
        path: `/affiliates/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affiliate
     * @name GetAffiliatesAffiliatesGet
     * @summary Get Affiliates
     * @request GET:/affiliates/
     * @secure
     */
    getAffiliatesAffiliatesGet: (
      query?: {
        page?: number;
        limit?: number;
        status?: string | null;
        network?: boolean | null;
        publisher?: boolean | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<Affiliate[], HTTPValidationError>({
        path: `/affiliates/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affiliate
     * @name SearchAffiliatesAffiliatesSearchGet
     * @summary Search Affiliates
     * @request GET:/affiliates/search
     * @secure
     */
    searchAffiliatesAffiliatesSearchGet: (
      query: { keyword: string; page?: number; limit?: number },
      params: RequestParams = {},
    ) =>
      this.request<Affiliate[], HTTPValidationError>({
        path: `/affiliates/search`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affiliate
     * @name GetAffiliateAffiliatesAffiliateIdGet
     * @summary Get Affiliate
     * @request GET:/affiliates/{affiliate_id}
     * @secure
     */
    getAffiliateAffiliatesAffiliateIdGet: (affiliateId: string, params: RequestParams = {}) =>
      this.request<Affiliate, HTTPValidationError>({
        path: `/affiliates/${affiliateId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affiliate
     * @name UpdateAffiliateAffiliatesAffiliateIdPut
     * @summary Update Affiliate
     * @request PUT:/affiliates/{affiliate_id}
     * @secure
     */
    updateAffiliateAffiliatesAffiliateIdPut: (affiliateId: string, data: AffiliateData, params: RequestParams = {}) =>
      this.request<Affiliate, HTTPValidationError>({
        path: `/affiliates/${affiliateId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affiliate
     * @name DeactivateAffiliateAffiliatesAffiliateIdDelete
     * @summary Deactivate Affiliate
     * @request DELETE:/affiliates/{affiliate_id}
     * @secure
     */
    deactivateAffiliateAffiliatesAffiliateIdDelete: (affiliateId: string, params: RequestParams = {}) =>
      this.request<string, HTTPValidationError>({
        path: `/affiliates/${affiliateId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affiliate
     * @name CreateCampaignAffiliatesAffiliateIdCampaignsPost
     * @summary Create Campaign
     * @request POST:/affiliates/{affiliate_id}/campaigns
     * @secure
     */
    createCampaignAffiliatesAffiliateIdCampaignsPost: (
      affiliateId: string,
      data: CampaignData,
      params: RequestParams = {},
    ) =>
      this.request<Affiliate, HTTPValidationError>({
        path: `/affiliates/${affiliateId}/campaigns`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affiliate
     * @name GetCampaignsAffiliatesAffiliateIdCampaignsGet
     * @summary Get Campaigns
     * @request GET:/affiliates/{affiliate_id}/campaigns
     * @secure
     */
    getCampaignsAffiliatesAffiliateIdCampaignsGet: (affiliateId: string, params: RequestParams = {}) =>
      this.request<ModelsAffiliateAffiliateCampaign[], HTTPValidationError>({
        path: `/affiliates/${affiliateId}/campaigns`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affiliate
     * @name UpdateCampaignAffiliatesAffiliateIdCampaignsCampaignIdPut
     * @summary Update Campaign
     * @request PUT:/affiliates/{affiliate_id}/campaigns/{campaign_id}
     * @secure
     */
    updateCampaignAffiliatesAffiliateIdCampaignsCampaignIdPut: (
      campaignId: string,
      affiliateId: string,
      data: CampaignData,
      params: RequestParams = {},
    ) =>
      this.request<Affiliate, HTTPValidationError>({
        path: `/affiliates/${affiliateId}/campaigns/${campaignId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affiliate
     * @name RemoveCampaignAffiliatesAffiliateIdCampaignsCampaignIdDelete
     * @summary Remove Campaign
     * @request DELETE:/affiliates/{affiliate_id}/campaigns/{campaign_id}
     * @secure
     */
    removeCampaignAffiliatesAffiliateIdCampaignsCampaignIdDelete: (
      campaignId: string,
      affiliateId: string,
      params: RequestParams = {},
    ) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/affiliates/${affiliateId}/campaigns/${campaignId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affiliate
     * @name GetPersonEventAffiliatesPersonsZidGet
     * @summary Get Person Event
     * @request GET:/affiliates/persons/{zid}
     * @secure
     */
    getPersonEventAffiliatesPersonsZidGet: (zid: string, params: RequestParams = {}) =>
      this.request<Person, HTTPValidationError>({
        path: `/affiliates/persons/${zid}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affiliate
     * @name GetAffiliatePersonEventsAffiliatesAffiliateIdPersonsGet
     * @summary Get Affiliate Person Events
     * @request GET:/affiliates/{affiliate_id}/persons
     * @secure
     */
    getAffiliatePersonEventsAffiliatesAffiliateIdPersonsGet: (
      affiliateId: string,
      query?: { page?: number; limit?: number },
      params: RequestParams = {},
    ) =>
      this.request<Person[], HTTPValidationError>({
        path: `/affiliates/${affiliateId}/persons`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affiliate
     * @name GetAffiliateCallbacksAffiliatesAffiliateNetworkIdCallbacksPost
     * @summary Get Affiliate Callbacks
     * @request POST:/affiliates/{affiliate_network_id}/callbacks
     * @secure
     */
    getAffiliateCallbacksAffiliatesAffiliateNetworkIdCallbacksPost: (
      affiliateNetworkId: string,
      data: AffiliateNetworkCallbackStatus[] | null,
      query?: {
        page?: number;
        limit?: number;
        publisher?: string | null;
        start_date?: string | null;
        end_date?: string | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<AffiliateNetworkCallbackEvent[], HTTPValidationError>({
        path: `/affiliates/${affiliateNetworkId}/callbacks`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affiliate
     * @name GetAffiliatePublishersAffiliatesAffiliateIdPublishersGet
     * @summary Get Affiliate Publishers
     * @request GET:/affiliates/{affiliate_id}/publishers
     * @secure
     */
    getAffiliatePublishersAffiliatesAffiliateIdPublishersGet: (affiliateId: string, params: RequestParams = {}) =>
      this.request<Affiliate[], HTTPValidationError>({
        path: `/affiliates/${affiliateId}/publishers`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affiliate
     * @name AdtractionNetworkCallbackAffiliatesDevAdtractionCallbackSiteTtGet
     * @summary Adtraction Network Callback
     * @request GET:/affiliates/dev/adtraction/callback/{site}/t/t
     * @secure
     */
    adtractionNetworkCallbackAffiliatesDevAdtractionCallbackSiteTTGet: (site: string, params: RequestParams = {}) =>
      this.request<HTTPStatus, HTTPValidationError>({
        path: `/affiliates/dev/adtraction/callback/${site}/t/t`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affiliate
     * @name AdserviceNetworkCallbackAffiliatesDevAdserviceCallbackCgiBinApiConversionServiceCallbackGet
     * @summary Adservice Network Callback
     * @request GET:/affiliates/dev/adservice/callback/cgi-bin/API/ConversionService/Callback
     * @secure
     */
    adserviceNetworkCallbackAffiliatesDevAdserviceCallbackCgiBinApiConversionServiceCallbackGet: (
      params: RequestParams = {},
    ) =>
      this.request<HTTPStatus, any>({
        path: `/affiliates/dev/adservice/callback/cgi-bin/API/ConversionService/Callback`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affiliate
     * @name SmartresponseNetworkCallbackAffiliatesDevSmartresponseCallbackPAshxGet
     * @summary Smartresponse Network Callback
     * @request GET:/affiliates/dev/smartresponse/callback/p.ashx
     * @secure
     */
    smartresponseNetworkCallbackAffiliatesDevSmartresponseCallbackPAshxGet: (params: RequestParams = {}) =>
      this.request<HTTPStatus, any>({
        path: `/affiliates/dev/smartresponse/callback/p.ashx`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  online = {
    /**
     * @description Get all submissions for a country
     *
     * @tags Online
     * @name GetAllSubmissionsOnlineSubmissionsGet
     * @summary Get All Submissions
     * @request GET:/online/submissions
     * @secure
     */
    getAllSubmissionsOnlineSubmissionsGet: (
      query?: {
        failed?: boolean | null;
        handled?: boolean | null;
        form_validation?: boolean | null;
        quick_application?: boolean | null;
        get_customers?: boolean | null;
        has_recent_app_check?: boolean | null;
        created_application?: boolean | null;
        app_created_hours_ago?: number | null;
        limit?: number | null;
        page?: number | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<SubmissionReviewResponse, HTTPValidationError>({
        path: `/online/submissions`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get submission by id
     *
     * @tags Online
     * @name GetASubmissionOnlineSubmissionsSubmissionIdGet
     * @summary Get A Submission
     * @request GET:/online/submissions/{submission_id}
     * @secure
     */
    getASubmissionOnlineSubmissionsSubmissionIdGet: (submissionId: string, params: RequestParams = {}) =>
      this.request<SubmissionReview, HTTPValidationError>({
        path: `/online/submissions/${submissionId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Retry processing of submissions in case of temporary error
     *
     * @tags Online
     * @name PostReprocessSubmissionsOnlineSubmissionsReprocessPost
     * @summary Post Reprocess Submissions
     * @request POST:/online/submissions/reprocess
     * @secure
     */
    postReprocessSubmissionsOnlineSubmissionsReprocessPost: (data: string[], params: RequestParams = {}) =>
      this.request<EnqueuedSubmissionJobResult[], HTTPValidationError>({
        path: `/online/submissions/reprocess`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Retry processing of submission in case of temporary error
     *
     * @tags Online
     * @name PostReprocessSubmissionOnlineSubmissionsSubmissionIdReprocessPost
     * @summary Post Reprocess Submission
     * @request POST:/online/submissions/{submission_id}/reprocess
     * @secure
     */
    postReprocessSubmissionOnlineSubmissionsSubmissionIdReprocessPost: (
      submissionId: string,
      params: RequestParams = {},
    ) =>
      this.request<EnqueuedSubmissionJobResult, HTTPValidationError>({
        path: `/online/submissions/${submissionId}/reprocess`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Online
     * @name PostResubmitSubmissionOnlineSubmissionsSubmissionIdResubmitPost
     * @summary Post Resubmit Submission
     * @request POST:/online/submissions/{submission_id}/resubmit
     * @secure
     */
    postResubmitSubmissionOnlineSubmissionsSubmissionIdResubmitPost: (
      submissionId: string,
      data: ResubmitPayload,
      params: RequestParams = {},
    ) =>
      this.request<EnqueuedSubmissionJobResult, HTTPValidationError>({
        path: `/online/submissions/${submissionId}/resubmit`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Online
     * @name PostReviewSubmissionOnlineSubmissionsSubmissionIdReviewPost
     * @summary Post Review Submission
     * @request POST:/online/submissions/{submission_id}/review
     * @secure
     */
    postReviewSubmissionOnlineSubmissionsSubmissionIdReviewPost: (
      submissionId: string,
      query?: { handled?: boolean },
      params: RequestParams = {},
    ) =>
      this.request<HandledSubmission, HTTPValidationError>({
        path: `/online/submissions/${submissionId}/review`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Online
     * @name PostReviewSubmissionsOnlineSubmissionsReviewPost
     * @summary Post Review Submissions
     * @request POST:/online/submissions/review
     * @secure
     */
    postReviewSubmissionsOnlineSubmissionsReviewPost: (data: ReviewSubmissions, params: RequestParams = {}) =>
      this.request<HandledSubmission[], HTTPValidationError>({
        path: `/online/submissions/review`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Converts a credit card submission to a blanco submission
     *
     * @tags Online
     * @name PostConvertToBlancoOnlineSubmissionsSubmissionIdConvertToBlancoPost
     * @summary Post Convert To Blanco
     * @request POST:/online/submissions/{submission_id}/convert_to_blanco
     * @secure
     */
    postConvertToBlancoOnlineSubmissionsSubmissionIdConvertToBlancoPost: (
      submissionId: string,
      params: RequestParams = {},
    ) =>
      this.request<EnqueuedSubmissionJobResult, HTTPValidationError>({
        path: `/online/submissions/${submissionId}/convert_to_blanco`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Online
     * @name OnlineAppsMessagesOnlineSendBlancoSmsMessagesPost
     * @summary Online Apps Messages
     * @request POST:/online/send_blanco_sms_messages
     * @secure
     */
    onlineAppsMessagesOnlineSendBlancoSmsMessagesPost: (
      query: { country: Country; product: Product },
      params: RequestParams = {},
    ) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/online/send_blanco_sms_messages`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Online
     * @name WhitelistedPniOnlineTestingPnisGet
     * @summary Whitelisted Pni
     * @request GET:/online/testing-pnis
     * @secure
     */
    whitelistedPniOnlineTestingPnisGet: (params: RequestParams = {}) =>
      this.request<OnlineFormsTestingPNIResponse, any>({
        path: `/online/testing-pnis`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Online
     * @name GetBookingsOnlineBookingsCountryGet
     * @summary Get Bookings
     * @request GET:/online/bookings/{country}
     */
    getBookingsOnlineBookingsCountryGet: (country: Country, params: RequestParams = {}) =>
      this.request<EmptyModel, HTTPValidationError>({
        path: `/online/bookings/${country}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Online
     * @name BookMeetingOnlineBookingsCountryPost
     * @summary Book Meeting
     * @request POST:/online/bookings/{country}
     */
    bookMeetingOnlineBookingsCountryPost: (country: Country, data: BookingPayload, params: RequestParams = {}) =>
      this.request<ServicesSverkerResponsesMeeting, HTTPValidationError>({
        path: `/online/bookings/${country}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Online
     * @name ContactFormOnlineContactCountryPost
     * @summary Contact Form
     * @request POST:/online/contact/{country}
     */
    contactFormOnlineContactCountryPost: (country: Country, data: ContactFormPayload, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/online/contact/${country}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Online
     * @name MortgageFormOnlineMortgageCountryPost
     * @summary Mortgage Form
     * @request POST:/online/mortgage/{country}
     */
    mortgageFormOnlineMortgageCountryPost: (country: Country, data: MortgageFormPayload, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/online/mortgage/${country}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Online
     * @name BlancoApplicationOnlineBlancoApplicationCountryPost
     * @summary Blanco Application
     * @request POST:/online/blanco-application/{country}
     */
    blancoApplicationOnlineBlancoApplicationCountryPost: (
      country: Country,
      data: EmptyModel,
      params: RequestParams = {},
    ) =>
      this.request<FormApplicationSubmissionResult, HTTPValidationError>({
        path: `/online/blanco-application/${country}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Online
     * @name OnlineQuickCreateOnlineQuickCreatePost
     * @summary Online Quick Create
     * @request POST:/online/quick-create
     */
    onlineQuickCreateOnlineQuickCreatePost: (
      data:
        | (QuickCreateBlancoApplication | QuickCreateMortgageApplication)
        | object
        | ((QuickCreateBlancoApplication | QuickCreateMortgageApplication) & object),
      params: RequestParams = {},
    ) =>
      this.request<FormApplicationSubmissionResult, HTTPValidationError>({
        path: `/online/quick-create`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Norway only
     *
     * @tags Online
     * @name NorwegianMortgageApplicationOnlineNorwegianMortgageApplicationPost
     * @summary Norwegian Mortgage Application
     * @request POST:/online/norwegian-mortgage-application
     */
    norwegianMortgageApplicationOnlineNorwegianMortgageApplicationPost: (
      data: EmptyModel,
      params: RequestParams = {},
    ) =>
      this.request<FormApplicationSubmissionResult, HTTPValidationError>({
        path: `/online/norwegian-mortgage-application`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  rq = {
    /**
     * No description
     *
     * @tags Monitor
     * @name GetListOfJobsRqJobsGet
     * @summary Get List Of Jobs
     * @request GET:/rq/jobs
     * @secure
     */
    getListOfJobsRqJobsGet: (query: { job_type: JobType }, params: RequestParams = {}) =>
      this.request<RqJobStatusResponse[], HTTPValidationError>({
        path: `/rq/jobs`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Monitor
     * @name GetFailedJobsRqManualFailGet
     * @summary Get Failed Jobs
     * @request GET:/rq/manual-fail
     * @secure
     */
    getFailedJobsRqManualFailGet: (params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, any>({
        path: `/rq/manual-fail`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Monitor
     * @name ClearFailedJobRqDeleteJobJobIdGet
     * @summary Clear Failed Job
     * @request GET:/rq/delete-job/{job_id}
     * @secure
     */
    clearFailedJobRqDeleteJobJobIdGet: (jobId: string, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/rq/delete-job/${jobId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Monitor
     * @name ReScheduleRqReScheduleGet
     * @summary Re Schedule
     * @request GET:/rq/re-schedule
     * @secure
     */
    reScheduleRqReScheduleGet: (params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/rq/re-schedule`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Monitor
     * @name GetSingleJobRqJobIdGet
     * @summary Get Single Job
     * @request GET:/rq/{job_id}
     * @secure
     */
    getSingleJobRqJobIdGet: (jobId: string, params: RequestParams = {}) =>
      this.request<RqJobStatusResponse | null, HTTPValidationError>({
        path: `/rq/${jobId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  kdlt = {
    /**
     * No description
     *
     * @tags kdlt
     * @name CallbackKdltCallbackPost
     * @summary Callback
     * @request POST:/kdlt/callback
     * @secure
     */
    callbackKdltCallbackPost: (data: KDLTCallbackPayload, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/kdlt/callback`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  dialMaker = {
    /**
     * No description
     *
     * @tags Dial Maker
     * @name GetLeadsForCampaignDialMakerTmLeadsCampaignIdPost
     * @summary Get Leads For Campaign
     * @request POST:/dial-maker/tm-leads/{campaign_id}
     * @secure
     */
    getLeadsForCampaignDialMakerTmLeadsCampaignIdPost: (
      campaignId: string,
      data: RoutersDialMakerPayloadsLeadExtraction,
      params: RequestParams = {},
    ) =>
      this.request<TMLeadsJob, HTTPValidationError>({
        path: `/dial-maker/tm-leads/${campaignId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker
     * @name GetJobStatusOrResultDialMakerJobStatusJobIdGet
     * @summary Get Job Status Or Result
     * @request GET:/dial-maker/job-status/{job_id}
     * @secure
     */
    getJobStatusOrResultDialMakerJobStatusJobIdGet: (jobId: string, params: RequestParams = {}) =>
      this.request<TMLeadsExtraction | TMLeadsJob | (TMLeadsExtraction & TMLeadsJob), HTTPValidationError>({
        path: `/dial-maker/job-status/${jobId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker
     * @name GetDownloadUrlDialMakerJobResultJobIdDownloadUrlGet
     * @summary Get Download Url
     * @request GET:/dial-maker/job-result/{job_id}/download-url
     * @secure
     */
    getDownloadUrlDialMakerJobResultJobIdDownloadUrlGet: (jobId: string, params: RequestParams = {}) =>
      this.request<RoutersDialMakerPayloadsSignedUrl, HTTPValidationError>({
        path: `/dial-maker/job-result/${jobId}/download-url`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker
     * @name GetCampaignsDialMakerCampaignsGet
     * @summary Get Campaigns
     * @request GET:/dial-maker/campaigns
     * @secure
     */
    getCampaignsDialMakerCampaignsGet: (
      query: {
        page: number;
        limit: number;
        department?: ZDepartment | null;
        country?: Country | null;
        product?: Product | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<TMCampaignList, HTTPValidationError>({
        path: `/dial-maker/campaigns`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker
     * @name CreateCampaignDialMakerCampaignsPost
     * @summary Create Campaign
     * @request POST:/dial-maker/campaigns
     * @secure
     */
    createCampaignDialMakerCampaignsPost: (data: CreateTMCampaign, params: RequestParams = {}) =>
      this.request<TMCampaign, HTTPValidationError>({
        path: `/dial-maker/campaigns`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker
     * @name UpdateCampaignDialMakerCampaignsCampaignIdPut
     * @summary Update Campaign
     * @request PUT:/dial-maker/campaigns/{campaign_id}
     * @secure
     */
    updateCampaignDialMakerCampaignsCampaignIdPut: (
      campaignId: string,
      data: CreateTMCampaign,
      params: RequestParams = {},
    ) =>
      this.request<TMCampaign, HTTPValidationError>({
        path: `/dial-maker/campaigns/${campaignId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker
     * @name GetCampaignDialMakerCampaignsCampaignIdGet
     * @summary Get Campaign
     * @request GET:/dial-maker/campaigns/{campaign_id}
     * @secure
     */
    getCampaignDialMakerCampaignsCampaignIdGet: (campaignId: string, params: RequestParams = {}) =>
      this.request<TMCampaign, HTTPValidationError>({
        path: `/dial-maker/campaigns/${campaignId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker
     * @name DeleteCampaignDialMakerCampaignsCampaignIdDelete
     * @summary Delete Campaign
     * @request DELETE:/dial-maker/campaigns/{campaign_id}
     * @secure
     */
    deleteCampaignDialMakerCampaignsCampaignIdDelete: (campaignId: string, params: RequestParams = {}) =>
      this.request<boolean, HTTPValidationError>({
        path: `/dial-maker/campaigns/${campaignId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker
     * @name GetCampaignExtractionsDialMakerCampaignsCampaignIdExtractionsGet
     * @summary Get Campaign Extractions
     * @request GET:/dial-maker/campaigns/{campaign_id}/extractions
     * @secure
     */
    getCampaignExtractionsDialMakerCampaignsCampaignIdExtractionsGet: (
      campaignId: string,
      query: { page: number; limit: number },
      params: RequestParams = {},
    ) =>
      this.request<TMLeadsExtraction[], HTTPValidationError>({
        path: `/dial-maker/campaigns/${campaignId}/extractions`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker
     * @name DownloadXlsxDialMakerDownloadSignatureGet
     * @summary Download Xlsx
     * @request GET:/dial-maker/download/{signature}
     */
    downloadXlsxDialMakerDownloadSignatureGet: (signature: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/dial-maker/download/${signature}`,
        method: "GET",
        ...params,
      }),
  };
  dialmaker = {
    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name CreateCampaignDialmakerCampaignPost
     * @summary Create Campaign
     * @request POST:/dialmaker/campaign
     * @secure
     */
    createCampaignDialmakerCampaignPost: (data: CreateCampaignData, params: RequestParams = {}) =>
      this.request<ModelsDialMaker2CampaignAggregateCampaign, HTTPValidationError>({
        path: `/dialmaker/campaign`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name UpdateCampaignDialmakerCampaignCampaignIdPut
     * @summary Update Campaign
     * @request PUT:/dialmaker/campaign/{campaign_id}
     * @secure
     */
    updateCampaignDialmakerCampaignCampaignIdPut: (
      campaignId: string,
      data: UpdateCampaignData,
      params: RequestParams = {},
    ) =>
      this.request<ModelsDialMaker2CampaignAggregateCampaign, HTTPValidationError>({
        path: `/dialmaker/campaign/${campaignId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetCampaignDialmakerCampaignCampaignIdGet
     * @summary Get Campaign
     * @request GET:/dialmaker/campaign/{campaign_id}
     * @secure
     */
    getCampaignDialmakerCampaignCampaignIdGet: (campaignId: string, params: RequestParams = {}) =>
      this.request<ModelsDialMaker2CampaignAggregateCampaign, HTTPValidationError>({
        path: `/dialmaker/campaign/${campaignId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name UpdateCampaignAutoDialDialmakerCampaignCampaignIdAutoDialPut
     * @summary Update Campaign Auto Dial
     * @request PUT:/dialmaker/campaign/{campaign_id}/auto-dial
     * @secure
     */
    updateCampaignAutoDialDialmakerCampaignCampaignIdAutoDialPut: (
      campaignId: string,
      query: { auto_dial: boolean },
      params: RequestParams = {},
    ) =>
      this.request<ModelsDialMaker2CampaignAggregateCampaign, HTTPValidationError>({
        path: `/dialmaker/campaign/${campaignId}/auto-dial`,
        method: "PUT",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetCampaignSourceDialmakerCampaignCampaignIdDataSourceGet
     * @summary Get Campaign Source
     * @request GET:/dialmaker/campaign/{campaign_id}/data/source
     * @secure
     */
    getCampaignSourceDialmakerCampaignCampaignIdDataSourceGet: (campaignId: string, params: RequestParams = {}) =>
      this.request<
        | SheetCampaignSource
        | BigQueryCampaignSource
        | ProcessCampaignSource
        | (SheetCampaignSource & BigQueryCampaignSource & ProcessCampaignSource),
        HTTPValidationError
      >({
        path: `/dialmaker/campaign/${campaignId}/data/source`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetCampaignExtractionsDialmakerCampaignCampaignIdExtractionsGet
     * @summary Get Campaign Extractions
     * @request GET:/dialmaker/campaign/{campaign_id}/extractions
     * @secure
     */
    getCampaignExtractionsDialmakerCampaignCampaignIdExtractionsGet: (
      campaignId: string,
      query?: { page?: number; size?: number },
      params: RequestParams = {},
    ) =>
      this.request<PageCampaignExtraction, HTTPValidationError>({
        path: `/dialmaker/campaign/${campaignId}/extractions`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetCampaignSheetsDialmakerCampaignCampaignIdDataSheetsGet
     * @summary Get Campaign Sheets
     * @request GET:/dialmaker/campaign/{campaign_id}/data/sheets
     * @secure
     */
    getCampaignSheetsDialmakerCampaignCampaignIdDataSheetsGet: (
      campaignId: string,
      query?: { page?: number; size?: number },
      params: RequestParams = {},
    ) =>
      this.request<PageSheet, HTTPValidationError>({
        path: `/dialmaker/campaign/${campaignId}/data/sheets`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name DeactivateCampaignDialmakerCampaignDeactivatePost
     * @summary Deactivate Campaign
     * @request POST:/dialmaker/campaign/deactivate
     * @secure
     */
    deactivateCampaignDialmakerCampaignDeactivatePost: (
      data: BodyDeactivateCampaignDialmakerCampaignDeactivatePost,
      params: RequestParams = {},
    ) =>
      this.request<ModelsDialMaker2CampaignAggregateCampaign, HTTPValidationError>({
        path: `/dialmaker/campaign/deactivate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name ActivateCampaignDialmakerCampaignActivatePost
     * @summary Activate Campaign
     * @request POST:/dialmaker/campaign/activate
     * @secure
     */
    activateCampaignDialmakerCampaignActivatePost: (
      data: BodyActivateCampaignDialmakerCampaignActivatePost,
      params: RequestParams = {},
    ) =>
      this.request<ModelsDialMaker2CampaignAggregateCampaign, HTTPValidationError>({
        path: `/dialmaker/campaign/activate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name ExtractCampaignsDialmakerCampaignExtractPost
     * @summary Extract Campaigns
     * @request POST:/dialmaker/campaign/extract
     * @secure
     */
    extractCampaignsDialmakerCampaignExtractPost: (
      data: BodyExtractCampaignsDialmakerCampaignExtractPost,
      params: RequestParams = {},
    ) =>
      this.request<ModelsDialMaker2CampaignAggregateCampaign, HTTPValidationError>({
        path: `/dialmaker/campaign/extract`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name DeleteExtractionDialmakerCampaignExtractionDelete
     * @summary Delete Extraction
     * @request DELETE:/dialmaker/campaign/extraction
     * @secure
     */
    deleteExtractionDialmakerCampaignExtractionDelete: (
      data: BodyDeleteExtractionDialmakerCampaignExtractionDelete,
      params: RequestParams = {},
    ) =>
      this.request<string, HTTPValidationError>({
        path: `/dialmaker/campaign/extraction`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name FindCampaignsDialmakerCampaignFindPost
     * @summary Find Campaigns
     * @request POST:/dialmaker/campaign/find
     * @secure
     */
    findCampaignsDialmakerCampaignFindPost: (
      data: BodyFindCampaignsDialmakerCampaignFindPost,
      query?: { page?: number; size?: number },
      params: RequestParams = {},
    ) =>
      this.request<PageCampaign, HTTPValidationError>({
        path: `/dialmaker/campaign/find`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetLeadsDialmakerCampaignLeadsCampaignIdGet
     * @summary Get Leads
     * @request GET:/dialmaker/campaign/leads/{campaign_id}
     * @secure
     */
    getLeadsDialmakerCampaignLeadsCampaignIdGet: (
      campaignId: string,
      query?: { page?: number; size?: number },
      params: RequestParams = {},
    ) =>
      this.request<PageDMLead, HTTPValidationError>({
        path: `/dialmaker/campaign/leads/${campaignId}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name ImportLeadsDialmakerCampaignImportPost
     * @summary Import Leads
     * @request POST:/dialmaker/campaign/import
     * @secure
     */
    importLeadsDialmakerCampaignImportPost: (
      data: BodyImportLeadsDialmakerCampaignImportPost,
      query?: { synchronous?: boolean },
      params: RequestParams = {},
    ) =>
      this.request<ModelsDialMaker2CampaignAggregateCampaign | string, HTTPValidationError>({
        path: `/dialmaker/campaign/import`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetJobStatusOrResultDialmakerCampaignJobStatusJobIdGet
     * @summary Get Job Status Or Result
     * @request GET:/dialmaker/campaign/job-status/{job_id}
     * @secure
     */
    getJobStatusOrResultDialmakerCampaignJobStatusJobIdGet: (jobId: string, params: RequestParams = {}) =>
      this.request<InterimLeadJob | InterimLead[] | (InterimLeadJob & InterimLead[]), HTTPValidationError>({
        path: `/dialmaker/campaign/job-status/${jobId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name UploadAttachmentDialmakerCampaignImageUploadPost
     * @summary Upload Attachment
     * @request POST:/dialmaker/campaign/image/upload
     * @secure
     */
    uploadAttachmentDialmakerCampaignImageUploadPost: (
      data: BodyUploadAttachmentDialmakerCampaignImageUploadPost,
      params: RequestParams = {},
    ) =>
      this.request<string, HTTPValidationError>({
        path: `/dialmaker/campaign/image/upload`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name ExportAndExtractLeadsUrlDialmakerCampaignExportPost
     * @summary Export And Extract Leads Url
     * @request POST:/dialmaker/campaign/export
     * @secure
     */
    exportAndExtractLeadsUrlDialmakerCampaignExportPost: (
      data: BodyExportAndExtractLeadsUrlDialmakerCampaignExportPost,
      params: RequestParams = {},
    ) =>
      this.request<RoutersDialMaker2PayloadsSignedUrl, HTTPValidationError>({
        path: `/dialmaker/campaign/export`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name ExportAndExtractLeadsUrlDownloadDialmakerCampaignExtractionExportPost
     * @summary Export And Extract Leads Url Download
     * @request POST:/dialmaker/campaign/extraction/export
     * @secure
     */
    exportAndExtractLeadsUrlDownloadDialmakerCampaignExtractionExportPost: (
      data: BodyExportAndExtractLeadsUrlDownloadDialmakerCampaignExtractionExportPost,
      params: RequestParams = {},
    ) =>
      this.request<RoutersDialMaker2PayloadsSignedUrl, HTTPValidationError>({
        path: `/dialmaker/campaign/extraction/export`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetOngoingExtractionsDialmakerCampaignCampaignIdOngoingExtractionsPost
     * @summary Get Ongoing Extractions
     * @request POST:/dialmaker/campaign/{campaign_id}/ongoing_extractions
     * @secure
     */
    getOngoingExtractionsDialmakerCampaignCampaignIdOngoingExtractionsPost: (
      campaignId: string,
      params: RequestParams = {},
    ) =>
      this.request<CampaignOngoingExtraction[], HTTPValidationError>({
        path: `/dialmaker/campaign/${campaignId}/ongoing_extractions`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetOutcomesReportDialmakerCampaignSearchLeadsPost
     * @summary Get Outcomes Report
     * @request POST:/dialmaker/campaign/search-leads
     * @secure
     */
    getOutcomesReportDialmakerCampaignSearchLeadsPost: (
      data: SearchLeadsPayload,
      query?: { page?: number; size?: number },
      params: RequestParams = {},
    ) =>
      this.request<PageDMLead | null, HTTPValidationError>({
        path: `/dialmaker/campaign/search-leads`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description This endpoint is used to get statistics: - Leads with 0 outcomes - Available leads to call - Already extracted count - Leads left to extract (cold campaigns only)
     *
     * @tags Dial Maker 2
     * @name GetExtractionsStatsDialmakerCampaignCampaignIdExtractionsStatsGet
     * @summary Get Extractions Stats
     * @request GET:/dialmaker/campaign/{campaign_id}/extractions_stats
     * @secure
     */
    getExtractionsStatsDialmakerCampaignCampaignIdExtractionsStatsGet: (
      campaignId: string,
      params: RequestParams = {},
    ) =>
      this.request<CampaignExtractionsStats, HTTPValidationError>({
        path: `/dialmaker/campaign/${campaignId}/extractions_stats`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetCampaignsByIdsDialmakerCampaignGetCampaignsByIdsPost
     * @summary Get Campaigns By Ids
     * @request POST:/dialmaker/campaign/get_campaigns_by_ids
     * @secure
     */
    getCampaignsByIdsDialmakerCampaignGetCampaignsByIdsPost: (data: string[], params: RequestParams = {}) =>
      this.request<ModelsDialMaker2CampaignAggregateCampaign[], HTTPValidationError>({
        path: `/dialmaker/campaign/get_campaigns_by_ids`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name SeedCampaignsDialmakerCampaignProcessSeedPost
     * @summary Seed Campaigns
     * @request POST:/dialmaker/campaign/process/seed
     * @secure
     */
    seedCampaignsDialmakerCampaignProcessSeedPost: (params: RequestParams = {}) =>
      this.request<Record<string, UpsertCampaignStatus>, any>({
        path: `/dialmaker/campaign/process/seed`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name FetchAndHandleInsurancesDialmakerCampaignProcessFetchAndHandlePost
     * @summary Fetch And Handle Insurances
     * @request POST:/dialmaker/campaign/process/fetch_and_handle
     * @secure
     */
    fetchAndHandleInsurancesDialmakerCampaignProcessFetchAndHandlePost: (
      query: { product: ExtendedProduct; country: Country },
      params: RequestParams = {},
    ) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/dialmaker/campaign/process/fetch_and_handle`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name SendMailToCampaignManuallyDialmakerCampaignCampaignIdMailSendManuallyPost
     * @summary Send Mail To Campaign Manually
     * @request POST:/dialmaker/campaign/{campaign_id}/mail/send_manually
     * @secure
     */
    sendMailToCampaignManuallyDialmakerCampaignCampaignIdMailSendManuallyPost: (
      campaignId: string,
      query?: { limit_amount_of_recipients?: number | null },
      params: RequestParams = {},
    ) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/dialmaker/campaign/${campaignId}/mail/send_manually`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetMailStatisticsDialmakerCampaignMailStatisticsPost
     * @summary Get Mail Statistics
     * @request POST:/dialmaker/campaign/mail/statistics
     * @secure
     */
    getMailStatisticsDialmakerCampaignMailStatisticsPost: (
      data: BodyGetMailStatisticsDialmakerCampaignMailStatisticsPost,
      params: RequestParams = {},
    ) =>
      this.request<CampaignMailStats[], HTTPValidationError>({
        path: `/dialmaker/campaign/mail/statistics`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name DryRunMailTemplateDialmakerCampaignCampaignIdMailTemplateSlugDryRunPost
     * @summary Dry Run Mail Template
     * @request POST:/dialmaker/campaign/{campaign_id}/mail/template/{slug}/dry-run
     * @secure
     */
    dryRunMailTemplateDialmakerCampaignCampaignIdMailTemplateSlugDryRunPost: (
      campaignId: string,
      slug: string,
      params: RequestParams = {},
    ) =>
      this.request<TemplateDryRunResult, HTTPValidationError>({
        path: `/dialmaker/campaign/${campaignId}/mail/template/${slug}/dry-run`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name SendTestMailDialmakerCampaignMailSendTestPost
     * @summary Send Test Mail
     * @request POST:/dialmaker/campaign/mail/send_test
     * @secure
     */
    sendTestMailDialmakerCampaignMailSendTestPost: (data: TestTemplatePayload, params: RequestParams = {}) =>
      this.request<SentTemplateResponse[], HTTPValidationError>({
        path: `/dialmaker/campaign/mail/send_test`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetMailLeadsOverviewDialmakerCampaignCampaignIdMailLeadsGet
     * @summary Get Mail Leads Overview
     * @request GET:/dialmaker/campaign/{campaign_id}/mail/leads
     * @secure
     */
    getMailLeadsOverviewDialmakerCampaignCampaignIdMailLeadsGet: (campaignId: string, params: RequestParams = {}) =>
      this.request<CampaignMailOverview, HTTPValidationError>({
        path: `/dialmaker/campaign/${campaignId}/mail/leads`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetUserCampaignsDialmakerCampaignsGet
     * @summary Get User Campaigns
     * @request GET:/dialmaker/campaigns
     * @deprecated
     * @secure
     */
    getUserCampaignsDialmakerCampaignsGet: (params: RequestParams = {}) =>
      this.request<UserCampaignsExt | null, any>({
        path: `/dialmaker/campaigns`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name AvailableCampaignsDialmakerCampaignsAvailablePost
     * @summary Available Campaigns
     * @request POST:/dialmaker/campaigns/available
     * @secure
     */
    availableCampaignsDialmakerCampaignsAvailablePost: (params: RequestParams = {}) =>
      this.request<Available, any>({
        path: `/dialmaker/campaigns/available`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name JoinCampaignDialmakerCampaignsJoinPost
     * @summary Join Campaign
     * @request POST:/dialmaker/campaigns/join
     * @secure
     */
    joinCampaignDialmakerCampaignsJoinPost: (
      data: BodyJoinCampaignDialmakerCampaignsJoinPost,
      params: RequestParams = {},
    ) =>
      this.request<Available, HTTPValidationError>({
        path: `/dialmaker/campaigns/join`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name LeaveCampaignDialmakerCampaignsLeavePost
     * @summary Leave Campaign
     * @request POST:/dialmaker/campaigns/leave
     * @secure
     */
    leaveCampaignDialmakerCampaignsLeavePost: (
      data: BodyLeaveCampaignDialmakerCampaignsLeavePost,
      params: RequestParams = {},
    ) =>
      this.request<Available, HTTPValidationError>({
        path: `/dialmaker/campaigns/leave`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name PlayCampaignDialmakerCampaignsPlayPost
     * @summary Play Campaign
     * @request POST:/dialmaker/campaigns/play
     * @secure
     */
    playCampaignDialmakerCampaignsPlayPost: (
      data: BodyPlayCampaignDialmakerCampaignsPlayPost,
      params: RequestParams = {},
    ) =>
      this.request<Available, HTTPValidationError>({
        path: `/dialmaker/campaigns/play`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name PauseCampaignDialmakerCampaignsPausePost
     * @summary Pause Campaign
     * @request POST:/dialmaker/campaigns/pause
     * @secure
     */
    pauseCampaignDialmakerCampaignsPausePost: (
      data: BodyPauseCampaignDialmakerCampaignsPausePost,
      params: RequestParams = {},
    ) =>
      this.request<Available, HTTPValidationError>({
        path: `/dialmaker/campaigns/pause`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetCardDialmakerCardNextPost
     * @summary Get Card
     * @request POST:/dialmaker/card/next
     * @secure
     */
    getCardDialmakerCardNextPost: (params: RequestParams = {}) =>
      this.request<DMLeadExt | null, any>({
        path: `/dialmaker/card/next`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name ViewDialmakerCardViewPost
     * @summary View
     * @request POST:/dialmaker/card/view
     * @secure
     */
    viewDialmakerCardViewPost: (data: BodyViewDialmakerCardViewPost, params: RequestParams = {}) =>
      this.request<DMLeadExt, HTTPValidationError>({
        path: `/dialmaker/card/view`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name UpdateDialmakerCardUpdatePost
     * @summary Update
     * @request POST:/dialmaker/card/update
     * @secure
     */
    updateDialmakerCardUpdatePost: (data: BodyUpdateDialmakerCardUpdatePost, params: RequestParams = {}) =>
      this.request<DMLead, HTTPValidationError>({
        path: `/dialmaker/card/update`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name HangupDialmakerCardHangupPost
     * @summary Hangup
     * @request POST:/dialmaker/card/hangup
     * @secure
     */
    hangupDialmakerCardHangupPost: (params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, any>({
        path: `/dialmaker/card/hangup`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name CallSuccessDialmakerCardOutcomeSuccessPost
     * @summary Call Success
     * @request POST:/dialmaker/card/outcome/success
     * @secure
     */
    callSuccessDialmakerCardOutcomeSuccessPost: (
      data: BodyCallSuccessDialmakerCardOutcomeSuccessPost,
      params: RequestParams = {},
    ) =>
      this.request<DMLeadExt | null, HTTPValidationError>({
        path: `/dialmaker/card/outcome/success`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name CallBackDialmakerCardOutcomeCallBackPost
     * @summary Call Back
     * @request POST:/dialmaker/card/outcome/call_back
     * @secure
     */
    callBackDialmakerCardOutcomeCallBackPost: (
      data: BodyCallBackDialmakerCardOutcomeCallBackPost,
      params: RequestParams = {},
    ) =>
      this.request<DMLeadExt | null, HTTPValidationError>({
        path: `/dialmaker/card/outcome/call_back`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name CallBackDialmakerCardOutcomeLaterPost
     * @summary Call Back
     * @request POST:/dialmaker/card/outcome/later
     * @secure
     */
    callBackDialmakerCardOutcomeLaterPost: (
      data: BodyCallBackDialmakerCardOutcomeLaterPost,
      params: RequestParams = {},
    ) =>
      this.request<DMLeadExt | null, HTTPValidationError>({
        path: `/dialmaker/card/outcome/later`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name CallNoContactDialmakerCardOutcomeNoContactPost
     * @summary Call No Contact
     * @request POST:/dialmaker/card/outcome/no_contact
     * @secure
     */
    callNoContactDialmakerCardOutcomeNoContactPost: (
      data: BodyCallNoContactDialmakerCardOutcomeNoContactPost,
      params: RequestParams = {},
    ) =>
      this.request<DMLeadExt | null, HTTPValidationError>({
        path: `/dialmaker/card/outcome/no_contact`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name CallDiscardsDialmakerCardOutcomeDiscardPost
     * @summary Call Discards
     * @request POST:/dialmaker/card/outcome/discard
     * @secure
     */
    callDiscardsDialmakerCardOutcomeDiscardPost: (
      data: BodyCallDiscardsDialmakerCardOutcomeDiscardPost,
      params: RequestParams = {},
    ) =>
      this.request<DMLeadExt | null, HTTPValidationError>({
        path: `/dialmaker/card/outcome/discard`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name CallFailureDialmakerCardOutcomeFailurePost
     * @summary Call Failure
     * @request POST:/dialmaker/card/outcome/failure
     * @secure
     */
    callFailureDialmakerCardOutcomeFailurePost: (
      data: BodyCallFailureDialmakerCardOutcomeFailurePost,
      params: RequestParams = {},
    ) =>
      this.request<DMLeadExt | null, HTTPValidationError>({
        path: `/dialmaker/card/outcome/failure`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetOutcomesDialmakerCardOutcomesPost
     * @summary Get Outcomes
     * @request POST:/dialmaker/card/outcomes
     * @secure
     */
    getOutcomesDialmakerCardOutcomesPost: (
      data: BodyGetOutcomesDialmakerCardOutcomesPost,
      params: RequestParams = {},
    ) =>
      this.request<Outcomes, HTTPValidationError>({
        path: `/dialmaker/card/outcomes`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name SearchDialmakerCardSearchPost
     * @summary Search
     * @request POST:/dialmaker/card/search
     * @secure
     */
    searchDialmakerCardSearchPost: (data: BodySearchDialmakerCardSearchPost, params: RequestParams = {}) =>
      this.request<DMLeadExt[], HTTPValidationError>({
        path: `/dialmaker/card/search`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name LockDialmakerCardLockPost
     * @summary Lock
     * @request POST:/dialmaker/card/lock
     * @secure
     */
    lockDialmakerCardLockPost: (data: BodyLockDialmakerCardLockPost, params: RequestParams = {}) =>
      this.request<DMLead, HTTPValidationError>({
        path: `/dialmaker/card/lock`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name PersonalCallbacksDialmakerCardPersonalCallbacksPost
     * @summary Personal Callbacks
     * @request POST:/dialmaker/card/personal_callbacks
     * @secure
     */
    personalCallbacksDialmakerCardPersonalCallbacksPost: (params: RequestParams = {}) =>
      this.request<DMLeadExt[], any>({
        path: `/dialmaker/card/personal_callbacks`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetSlotsDialmakerCardSlotsGet
     * @summary Get Slots
     * @request GET:/dialmaker/card/slots
     * @secure
     */
    getSlotsDialmakerCardSlotsGet: (query: { date: string }, params: RequestParams = {}) =>
      this.request<BookableSlot[], HTTPValidationError>({
        path: `/dialmaker/card/slots`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetPreviousAppsDialmakerCardPreviousAppsPost
     * @summary Get Previous Apps
     * @request POST:/dialmaker/card/previous_apps
     * @secure
     */
    getPreviousAppsDialmakerCardPreviousAppsPost: (
      query: { customer_id: string; country: Country; pni?: string | null },
      params: RequestParams = {},
    ) =>
      this.request<PreviousApplicationsList, HTTPValidationError>({
        path: `/dialmaker/card/previous_apps`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get all insurances for a customer, sorted by creation date.
     *
     * @tags Dial Maker 2
     * @name GetCustomerInsurancesDialmakerCardCustomerInsurancesPost
     * @summary Get Customer Insurances
     * @request POST:/dialmaker/card/customer_insurances
     * @secure
     */
    getCustomerInsurancesDialmakerCardCustomerInsurancesPost: (
      query: { customer_id: string },
      params: RequestParams = {},
    ) =>
      this.request<Insurance[], HTTPValidationError>({
        path: `/dialmaker/card/customer_insurances`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name MineDialmakerCardOutcomesMineTodayPost
     * @summary Mine
     * @request POST:/dialmaker/card/outcomes/mine_today
     * @secure
     */
    mineDialmakerCardOutcomesMineTodayPost: (
      query: { start_date: string; end_date: string },
      params: RequestParams = {},
    ) =>
      this.request<OutcomeCount, HTTPValidationError>({
        path: `/dialmaker/card/outcomes/mine_today`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Upload file and return a preview of sheet, together with the file id needed to import leads. Uploaded file whether it's csv or xlsx should have headers in the first row to be able to map the columns to the lead data model.
     *
     * @tags Dial Maker 2
     * @name UploadDialmakerSheetCampaignIdUploadWithPreviewPost
     * @summary Upload
     * @request POST:/dialmaker/sheet/{campaign_id}/upload_with_preview
     * @secure
     */
    uploadDialmakerSheetCampaignIdUploadWithPreviewPost: (
      campaignId: string,
      data: BodyUploadDialmakerSheetCampaignIdUploadWithPreviewPost,
      params: RequestParams = {},
    ) =>
      this.request<SheetPreview, HTTPValidationError>({
        path: `/dialmaker/sheet/${campaignId}/upload_with_preview`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description This endpoint is used to import leads from a file to a global leads collection with campaign_id and file_id metadata
     *
     * @tags Dial Maker 2
     * @name ImportFromFileDialmakerSheetCampaignIdImportFromFileIdPost
     * @summary Import From File
     * @request POST:/dialmaker/sheet/{campaign_id}/import_from/{file_id}
     * @secure
     */
    importFromFileDialmakerSheetCampaignIdImportFromFileIdPost: (
      campaignId: string,
      fileId: string,
      data: Record<string, string>,
      query?: { synchronous?: boolean },
      params: RequestParams = {},
    ) =>
      this.request<ModelsDialMaker2CampaignAggregateCampaign | string, HTTPValidationError>({
        path: `/dialmaker/sheet/${campaignId}/import_from/${fileId}`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description This endpoint is used to delete a file from the database if it was not imported yet
     *
     * @tags Dial Maker 2
     * @name DeleteCampaignSheetDialmakerSheetCampaignIdDeleteFileFileIdDelete
     * @summary Delete Campaign Sheet
     * @request DELETE:/dialmaker/sheet/{campaign_id}/delete_file/{file_id}
     * @secure
     */
    deleteCampaignSheetDialmakerSheetCampaignIdDeleteFileFileIdDelete: (
      campaignId: string,
      fileId: string,
      params: RequestParams = {},
    ) =>
      this.request<ModelsDialMaker2CampaignAggregateCampaign | null, HTTPValidationError>({
        path: `/dialmaker/sheet/${campaignId}/delete_file/${fileId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This endpoint is used to delete import of a given sheet_id with all interim leads
     *
     * @tags Dial Maker 2
     * @name DeleteInterimLeadsDialmakerSheetCampaignIdImportSheetIdDelete
     * @summary Delete Interim Leads
     * @request DELETE:/dialmaker/sheet/{campaign_id}/import/{sheet_id}
     * @secure
     */
    deleteInterimLeadsDialmakerSheetCampaignIdImportSheetIdDelete: (
      campaignId: string,
      sheetId: string,
      query?: { force?: boolean },
      params: RequestParams = {},
    ) =>
      this.request<ModelsDialMaker2CampaignAggregateCampaign | null, HTTPValidationError>({
        path: `/dialmaker/sheet/${campaignId}/import/${sheetId}`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetOutcomesDialmakerMiscOutcomesGet
     * @summary Get Outcomes
     * @request GET:/dialmaker/misc/outcomes
     * @secure
     */
    getOutcomesDialmakerMiscOutcomesGet: (params: RequestParams = {}) =>
      this.request<Outcomes, any>({
        path: `/dialmaker/misc/outcomes`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name SaveOutcomesDialmakerMiscOutcomesPost
     * @summary Save Outcomes
     * @request POST:/dialmaker/misc/outcomes
     * @secure
     */
    saveOutcomesDialmakerMiscOutcomesPost: (
      data: BodySaveOutcomesDialmakerMiscOutcomesPost,
      params: RequestParams = {},
    ) =>
      this.request<Outcomes, HTTPValidationError>({
        path: `/dialmaker/misc/outcomes`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name AdminDialmakerAdminOutcomesPost
     * @summary Admin
     * @request POST:/dialmaker/admin/outcomes
     * @secure
     */
    adminDialmakerAdminOutcomesPost: (
      query: { start_date: string; end_date: string },
      data: BodyAdminDialmakerAdminOutcomesPost,
      params: RequestParams = {},
    ) =>
      this.request<OutcomeCount, HTTPValidationError>({
        path: `/dialmaker/admin/outcomes`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name BlockFieldsDialmakerAdminBlockPost
     * @summary Block Fields
     * @request POST:/dialmaker/admin/block
     * @secure
     */
    blockFieldsDialmakerAdminBlockPost: (data: BlockCustomerFieldManuallyPayload, params: RequestParams = {}) =>
      this.request<BlockCustomerFieldManuallyResponse, HTTPValidationError>({
        path: `/dialmaker/admin/block`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name StatsDialmakerAdminOutcomesUsersPost
     * @summary Stats
     * @request POST:/dialmaker/admin/outcomes/users
     * @secure
     */
    statsDialmakerAdminOutcomesUsersPost: (
      data: BodyStatsDialmakerAdminOutcomesUsersPost,
      params: RequestParams = {},
    ) =>
      this.request<OutcomeCount, HTTPValidationError>({
        path: `/dialmaker/admin/outcomes/users`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetMailTemplatesDialmakerMailTemplatesAvailableGet
     * @summary Get Mail Templates
     * @request GET:/dialmaker/mail/templates/available
     * @secure
     */
    getMailTemplatesDialmakerMailTemplatesAvailableGet: (params: RequestParams = {}) =>
      this.request<MandrillTemplate[], any>({
        path: `/dialmaker/mail/templates/available`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetVariablesTemplateVariablesDialmakerMailTemplateVariablesPost
     * @summary Get Variables Template Variables
     * @request POST:/dialmaker/mail/template/variables
     * @secure
     */
    getVariablesTemplateVariablesDialmakerMailTemplateVariablesPost: (
      data: BodyGetVariablesTemplateVariablesDialmakerMailTemplateVariablesPost,
      params: RequestParams = {},
    ) =>
      this.request<TemplateVariables, HTTPValidationError>({
        path: `/dialmaker/mail/template/variables`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetAvailableTemplateVariablesDialmakerMailTemplateAvailableVariablesGet
     * @summary Get Available Template Variables
     * @request GET:/dialmaker/mail/template/available/variables
     * @secure
     */
    getAvailableTemplateVariablesDialmakerMailTemplateAvailableVariablesGet: (
      query: { country: Country },
      params: RequestParams = {},
    ) =>
      this.request<string[], HTTPValidationError>({
        path: `/dialmaker/mail/template/available/variables`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name TemplateRenderDialmakerMailTemplateRenderPost
     * @summary Template Render
     * @request POST:/dialmaker/mail/template/render
     * @secure
     */
    templateRenderDialmakerMailTemplateRenderPost: (data: RenderTemplatePayload, params: RequestParams = {}) =>
      this.request<string, HTTPValidationError>({
        path: `/dialmaker/mail/template/render`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  urlShortener = {
    /**
     * No description
     *
     * @tags URL Shortener
     * @name CreateUrlUrlShortenerUrlPost
     * @summary Create Url
     * @request POST:/url-shortener/url
     * @secure
     */
    createUrlUrlShortenerUrlPost: (data: BodyCreateUrlUrlShortenerUrlPost, params: RequestParams = {}) =>
      this.request<URL, HTTPValidationError>({
        path: `/url-shortener/url`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags URL Shortener
     * @name GetAllUrlsUrlShortenerUrlsGet
     * @summary Get All Urls
     * @request GET:/url-shortener/urls
     * @secure
     */
    getAllUrlsUrlShortenerUrlsGet: (params: RequestParams = {}) =>
      this.request<URL[], any>({
        path: `/url-shortener/urls`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags URL Shortener
     * @name GetUrlInfoUrlShortenerUrlKeyGet
     * @summary Get Url Info
     * @request GET:/url-shortener/{url_key}
     * @secure
     */
    getUrlInfoUrlShortenerUrlKeyGet: (urlKey: string, params: RequestParams = {}) =>
      this.request<URL, HTTPValidationError>({
        path: `/url-shortener/${urlKey}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags URL Shortener
     * @name GetShortUrlUrlShortenerUrlKeyShortenedCountryGet
     * @summary Get Short Url
     * @request GET:/url-shortener/{url_key}/shortened/{country}
     * @secure
     */
    getShortUrlUrlShortenerUrlKeyShortenedCountryGet: (urlKey: string, country: Country, params: RequestParams = {}) =>
      this.request<string, HTTPValidationError>({
        path: `/url-shortener/${urlKey}/shortened/${country}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  insurance = {
    /**
     * No description
     *
     * @tags Insurance
     * @name CreateInsuranceInsuranceInsurancePost
     * @summary Create Insurance
     * @request POST:/insurance/insurance
     * @secure
     */
    createInsuranceInsuranceInsurancePost: (data: CreateInsuranceCustomerV1, params: RequestParams = {}) =>
      this.request<Insurance, HTTPValidationError>({
        path: `/insurance/insurance`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  bank = {
    /**
     * No description
     *
     * @tags Bank Login
     * @name LoginBankLoginPost
     * @summary Login
     * @request POST:/bank/login
     */
    loginBankLoginPost: (data: Login, params: RequestParams = {}) =>
      this.request<LoginResp, HTTPValidationError>({
        path: `/bank/login`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank Login
     * @name ValidateOtpCodeBankLoginValidatePost
     * @summary Validate Otp Code
     * @request POST:/bank/login/validate
     */
    validateOtpCodeBankLoginValidatePost: (data: OTPValidation, params: RequestParams = {}) =>
      this.request<OTPValidationResp, HTTPValidationError>({
        path: `/bank/login/validate`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank Login
     * @name SetNewPasswordBankSetPasswordPost
     * @summary Set New Password
     * @request POST:/bank/set-password
     */
    setNewPasswordBankSetPasswordPost: (data: SetPassword, params: RequestParams = {}) =>
      this.request<SetPasswordResp, HTTPValidationError>({
        path: `/bank/set-password`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank Login
     * @name RequestNewOtpBankNewOtpPost
     * @summary Request New Otp
     * @request POST:/bank/new-otp
     */
    requestNewOtpBankNewOtpPost: (data: RequestNewOTP, params: RequestParams = {}) =>
      this.request<SetPasswordResp, HTTPValidationError>({
        path: `/bank/new-otp`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank User Administration
     * @name CreateNewBankUserBankCreateUserPost
     * @summary Create New Bank User
     * @request POST:/bank/create-user
     * @secure
     */
    createNewBankUserBankCreateUserPost: (data: CreateUser, params: RequestParams = {}) =>
      this.request<CreateUserResp, HTTPValidationError>({
        path: `/bank/create-user`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank User Administration
     * @name GetBankUsersBankGetPost
     * @summary Get Bank Users
     * @request POST:/bank/get
     * @secure
     */
    getBankUsersBankGetPost: (data: GetUsersPayload, params: RequestParams = {}) =>
      this.request<BankUserWithBankNames[], HTTPValidationError>({
        path: `/bank/get`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank User Administration
     * @name GetBankUserBankUserIdGet
     * @summary Get Bank User
     * @request GET:/bank/{user_id}
     * @secure
     */
    getBankUserBankUserIdGet: (userId: string, params: RequestParams = {}) =>
      this.request<BankUserWithBankNames, HTTPValidationError>({
        path: `/bank/${userId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank User Administration
     * @name EditBankUserBankUserIdPut
     * @summary Edit Bank User
     * @request PUT:/bank/{user_id}
     * @secure
     */
    editBankUserBankUserIdPut: (userId: string, data: EditUser, params: RequestParams = {}) =>
      this.request<BankUserWithBankNames, HTTPValidationError>({
        path: `/bank/${userId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank User Administration
     * @name ResetBankUserPasswordBankResetPasswordPost
     * @summary Reset Bank User Password
     * @request POST:/bank/reset-password
     * @secure
     */
    resetBankUserPasswordBankResetPasswordPost: (data: ResetPasswordPayload, params: RequestParams = {}) =>
      this.request<CreateUserResp, HTTPValidationError>({
        path: `/bank/reset-password`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank User Administration
     * @name DeactivateBankUserBankDeactivatePut
     * @summary Deactivate Bank User
     * @request PUT:/bank/deactivate
     * @secure
     */
    deactivateBankUserBankDeactivatePut: (data: DeactivateUserPayload, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/bank/deactivate`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank
     * @name GetApplicationsBankApplicationsPost
     * @summary Get Applications
     * @request POST:/bank/applications
     * @secure
     */
    getApplicationsBankApplicationsPost: (data: GetApplications, params: RequestParams = {}) =>
      this.request<BankBaseApp[], HTTPValidationError>({
        path: `/bank/applications`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank
     * @name GetApplicationBankApplicationApplicationIdGet
     * @summary Get Application
     * @request GET:/bank/application/{application_id}
     * @secure
     */
    getApplicationBankApplicationApplicationIdGet: (applicationId: string, params: RequestParams = {}) =>
      this.request<BankBaseApp, HTTPValidationError>({
        path: `/bank/application/${applicationId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank
     * @name GetDocumentMetaBankDocumentMetaCustomerIdDocumentIdGet
     * @summary Get Document Meta
     * @request GET:/bank/document/meta/{customer_id}/{document_id}
     * @secure
     */
    getDocumentMetaBankDocumentMetaCustomerIdDocumentIdGet: (
      customerId: string,
      documentId: string,
      params: RequestParams = {},
    ) =>
      this.request<DocumentMeta, HTTPValidationError>({
        path: `/bank/document/meta/${customerId}/${documentId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank
     * @name GetDocumentBankGetCustomerIdDocumentIdGet
     * @summary Get Document
     * @request GET:/bank/get/{customer_id}/{document_id}
     * @secure
     */
    getDocumentBankGetCustomerIdDocumentIdGet: (customerId: string, documentId: string, params: RequestParams = {}) =>
      this.request<CustomerDocument, HTTPValidationError>({
        path: `/bank/get/${customerId}/${documentId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank
     * @name GetAllBanksBankGetBanksPost
     * @summary Get All Banks
     * @request POST:/bank/get-banks
     * @secure
     */
    getAllBanksBankGetBanksPost: (data: GetBanksPayload, params: RequestParams = {}) =>
      this.request<Bank[], HTTPValidationError>({
        path: `/bank/get-banks`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank
     * @name GetAppEventsBankGetAppEventsApplicationIdGet
     * @summary Get App Events
     * @request GET:/bank/get_app_events/{application_id}
     * @secure
     */
    getAppEventsBankGetAppEventsApplicationIdGet: (applicationId: string, params: RequestParams = {}) =>
      this.request<EventsResponse, HTTPValidationError>({
        path: `/bank/get_app_events/${applicationId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank
     * @name AddCommentBankCommentPost
     * @summary Add Comment
     * @request POST:/bank/comment
     * @secure
     */
    addCommentBankCommentPost: (data: BankComment, params: RequestParams = {}) =>
      this.request<EventsResponse, HTTPValidationError>({
        path: `/bank/comment`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank
     * @name PostOfferBankOfferPost
     * @summary Post Offer
     * @request POST:/bank/offer
     * @secure
     */
    postOfferBankOfferPost: (data: BankOfferPayload, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/bank/offer`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank, Application
     * @name RejectBankRejectPost
     * @summary Reject
     * @request POST:/bank/reject
     * @secure
     */
    rejectBankRejectPost: (data: BankRejectPayload, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/bank/reject`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank, Application
     * @name DisburseBankDisbursePost
     * @summary Disburse
     * @request POST:/bank/disburse
     * @secure
     */
    disburseBankDisbursePost: (data: BankDisbursePayload, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/bank/disburse`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank
     * @name SearchApplicationsBankSearchPost
     * @summary Search Applications
     * @request POST:/bank/search
     * @secure
     */
    searchApplicationsBankSearchPost: (
      data: RoutersBankPayloadsSearchPayload,
      query?: { page?: number; limit?: number },
      params: RequestParams = {},
    ) =>
      this.request<BankBaseApp[], HTTPValidationError>({
        path: `/bank/search`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  jotform = {
    /**
     * No description
     *
     * @tags Jotform
     * @name SubmissionJotformWebhookFormIdPost
     * @summary Submission
     * @request POST:/jotform/webhook/{form_id}
     */
    submissionJotformWebhookFormIdPost: (
      formId: string,
      data: BodySubmissionJotformWebhookFormIdPost,
      params: RequestParams = {},
    ) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/jotform/webhook/${formId}`,
        method: "POST",
        body: data,
        type: ContentType.UrlEncoded,
        format: "json",
        ...params,
      }),
  };
  campaign = {
    /**
     * No description
     *
     * @tags Affiliate
     * @name CreateCampaignInitializedEventCampaignCampaignIdGet
     * @summary Create Campaign Initialized Event
     * @request GET:/campaign/{campaign_id}
     */
    createCampaignInitializedEventCampaignCampaignIdGet: (campaignId: string, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/campaign/${campaignId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  visit = {
    /**
     * No description
     *
     * @tags Affiliate
     * @name CreateVisitEventVisitCountryZidPost
     * @summary Create Visit Event
     * @request POST:/visit/{country}/{zid}
     */
    createVisitEventVisitCountryZidPost: (
      country: Country,
      zid: string,
      data: WebsiteVisitPayload,
      params: RequestParams = {},
    ) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/visit/${country}/${zid}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  v2 = {
    /**
     * No description
     *
     * @tags Affiliate
     * @name CreateWebsiteVisitEventV2VisitCountryPost
     * @summary Create Website Visit Event
     * @request POST:/v2/visit/{country}
     */
    createWebsiteVisitEventV2VisitCountryPost: (
      country: Country,
      data: WebsiteVisitPayload,
      params: RequestParams = {},
    ) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/v2/visit/${country}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  slack = {
    /**
     * No description
     *
     * @tags Slack
     * @name SlackCallbackSlackCallbackPost
     * @summary Slack Callback
     * @request POST:/slack/callback
     */
    slackCallbackSlackCallbackPost: (data: BodySlackCallbackSlackCallbackPost, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/slack/callback`,
        method: "POST",
        body: data,
        type: ContentType.UrlEncoded,
        format: "json",
        ...params,
      }),
  };
  dialMaker2 = {
    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetImageDialMaker2ImageGet
     * @summary Get Image
     * @request GET:/dial-maker2/image
     */
    getImageDialMaker2ImageGet: (query: { file_id: string }, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/dial-maker2/image`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name DownloadLeadsXlsxDialMaker2ExportDownloadSignatureGet
     * @summary Download Leads Xlsx
     * @request GET:/dial-maker2/export/download/{signature}
     */
    downloadLeadsXlsxDialMaker2ExportDownloadSignatureGet: (signature: string, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/dial-maker2/export/download/${signature}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  wellKnown = {
    /**
     * No description
     *
     * @tags Well known
     * @name GetJwksWellKnownJwksJsonGet
     * @summary Get Jwks
     * @request GET:/.well-known/jwks.json
     */
    getJwksWellKnownJwksJsonGet: (params: RequestParams = {}) =>
      this.request<EmptyModel, any>({
        path: `/.well-known/jwks.json`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  health = {
    /**
     * No description
     *
     * @tags Health
     * @name GetHealthHealthGet
     * @summary Get Health
     * @request GET:/health
     */
    getHealthHealthGet: (params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, any>({
        path: `/health`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  validator = {
    /**
     * No description
     *
     * @tags Validator
     * @name ValidateValidatorPost
     * @summary Validate
     * @request POST:/validator/
     */
    validateValidatorPost: (
      data:
        | Email
        | PNI
        | ModelsValidatorServicePayloadPhoneNumber
        | BankAccount
        | (Email & PNI & ModelsValidatorServicePayloadPhoneNumber & BankAccount),
      params: RequestParams = {},
    ) =>
      this.request<ValidatorServiceResponseModel, HTTPValidationError>({
        path: `/validator/`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Validator
     * @name ValidateValidatorPost2
     * @summary Validate
     * @request POST:/validator
     * @originalName validateValidatorPost
     * @duplicate
     */
    validateValidatorPost2: (
      data:
        | Email
        | PNI
        | ModelsValidatorServicePayloadPhoneNumber
        | BankAccount
        | (Email & PNI & ModelsValidatorServicePayloadPhoneNumber & BankAccount),
      params: RequestParams = {},
    ) =>
      this.request<ValidatorServiceResponseModel, HTTPValidationError>({
        path: `/validator`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Validator
     * @name ValidateSafelyValidatorSafePost
     * @summary Validate Safely
     * @request POST:/validator/safe/
     */
    validateSafelyValidatorSafePost: (data: EmptyModel, params: RequestParams = {}) =>
      this.request<ValidatorServiceResponseModel, HTTPValidationError>({
        path: `/validator/safe/`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Validator
     * @name ValidateSafelyValidatorSafePost2
     * @summary Validate Safely
     * @request POST:/validator/safe
     * @originalName validateSafelyValidatorSafePost
     * @duplicate
     */
    validateSafelyValidatorSafePost2: (data: EmptyModel, params: RequestParams = {}) =>
      this.request<ValidatorServiceResponseModel, HTTPValidationError>({
        path: `/validator/safe`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  mandrill = {
    /**
     * No description
     *
     * @tags Mail Webhook
     * @name MessageOpenedMandrillWebhookMessagePost
     * @summary Message Opened
     * @request POST:/mandrill/webhook/message
     */
    messageOpenedMandrillWebhookMessagePost: (
      data: BodyMessageOpenedMandrillWebhookMessagePost,
      params: RequestParams = {},
    ) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/mandrill/webhook/message`,
        method: "POST",
        body: data,
        type: ContentType.UrlEncoded,
        format: "json",
        ...params,
      }),
  };
  mail = {
    /**
     * No description
     *
     * @tags MailMaker
     * @name UnsubscribeFromMailMarketingMailPublicUnsubscribePost
     * @summary Unsubscribe From Mail Marketing
     * @request POST:/mail/public/unsubscribe
     */
    unsubscribeFromMailMarketingMailPublicUnsubscribePost: (query: { mail_key: string }, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/mail/public/unsubscribe`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  p = {
    /**
     * No description
     *
     * @tags URL Shortener
     * @name ForwardToTargetUrlPUrlKeyPathGet
     * @summary Forward To Target Url
     * @request GET:/p/{url_key}/{path}
     */
    forwardToTargetUrlPUrlKeyPathGet: (urlKey: string, path: string, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/p/${urlKey}/${path}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  formAnalytics = {
    /**
     * No description
     *
     * @tags Online
     * @name StoreEventsFormAnalyticsStoreEventsPost
     * @summary Store Events
     * @request POST:/form-analytics/store-events
     */
    storeEventsFormAnalyticsStoreEventsPost: (
      data: BodyStoreEventsFormAnalyticsStoreEventsPost,
      params: RequestParams = {},
    ) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/form-analytics/store-events`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  infra = {
    /**
     * No description
     *
     * @tags Other
     * @name Auth0EventsProxyInfraAuth0EventsPost
     * @summary Auth0 Events Proxy
     * @request POST:/infra/auth0/events
     * @secure
     */
    auth0EventsProxyInfraAuth0EventsPost: (data: object[], params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/infra/auth0/events`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  callmaker = {
    /**
     * No description
     *
     * @tags Callmaker Callbacks
     * @name GetCallmakerDataCallmakerCallbackCallPost
     * @summary Get Callmaker Data
     * @request POST:/callmaker/callback/call
     * @secure
     */
    getCallmakerDataCallmakerCallbackCallPost: (data: EmptyModel, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/callmaker/callback/call`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Callmaker Callbacks
     * @name GetProcessedDataCallmakerCallbackProcessPost
     * @summary Get Processed Data
     * @request POST:/callmaker/callback/process
     * @secure
     */
    getProcessedDataCallmakerCallbackProcessPost: (data: EmptyModel, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/callmaker/callback/process`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  v1 = {
    /**
     * No description
     *
     * @tags V1 Affiliate API
     * @name CreateBlancoApplicationV1AffiliateApplicationsBlancoPost
     * @summary Create Blanco Application
     * @request POST:/v1/affiliate/applications/blanco
     * @secure
     */
    createBlancoApplicationV1AffiliateApplicationsBlancoPost: (
      data: AffiliateCreateBlancoApplication,
      params: RequestParams = {},
    ) =>
      this.request<AffiliateResponse, HTTPValidationError>({
        path: `/v1/affiliate/applications/blanco`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags V1 Affiliate API
     * @name GetBookingsBlancoV1AffiliateBookingsBlancoGet
     * @summary Get Bookings Blanco
     * @request GET:/v1/affiliate/bookings/blanco
     * @secure
     */
    getBookingsBlancoV1AffiliateBookingsBlancoGet: (
      query: { start_date: string; end_date: string; country: Country },
      params: RequestParams = {},
    ) =>
      this.request<AvailableBookings, HTTPValidationError>({
        path: `/v1/affiliate/bookings/blanco`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags V1 Affiliate API
     * @name CreateMortgageLeadV1AffiliateLeadsMortgagePost
     * @summary Create Mortgage Lead
     * @request POST:/v1/affiliate/leads/mortgage
     * @secure
     */
    createMortgageLeadV1AffiliateLeadsMortgagePost: (data: AffiliateLeadCreationPayload, params: RequestParams = {}) =>
      this.request<AffiliateResponse, HTTPValidationError>({
        path: `/v1/affiliate/leads/mortgage`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags V1 Affiliate API
     * @name GetBookingsMortgageV1AffiliateBookingsMortgageGet
     * @summary Get Bookings Mortgage
     * @request GET:/v1/affiliate/bookings/mortgage
     * @secure
     */
    getBookingsMortgageV1AffiliateBookingsMortgageGet: (
      query: { start_date: string; end_date: string; country: Country },
      params: RequestParams = {},
    ) =>
      this.request<AvailableBookings, HTTPValidationError>({
        path: `/v1/affiliate/bookings/mortgage`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns all allowed enum values
     *
     * @tags V1 Affiliate API
     * @name GetOptionsV1AffiliateOptionsCountryProductGet
     * @summary Get Options
     * @request GET:/v1/affiliate/options/{country}/{product}
     * @secure
     */
    getOptionsV1AffiliateOptionsCountryProductGet: (country: Country, product: Product, params: RequestParams = {}) =>
      this.request<AffiliateOptionsResponse, HTTPValidationError>({
        path: `/v1/affiliate/options/${country}/${product}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags V1 Affiliate API
     * @name GetBlancoStatusV1AffiliateApplicationsBlancoSubmissionIdGet
     * @summary Get Blanco Status
     * @request GET:/v1/affiliate/applications/blanco/{submission_id}
     * @secure
     */
    getBlancoStatusV1AffiliateApplicationsBlancoSubmissionIdGet: (submissionId: string, params: RequestParams = {}) =>
      this.request<AffiliateCallback, HTTPValidationError>({
        path: `/v1/affiliate/applications/blanco/${submissionId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags V1 Affiliate API
     * @name GetMortgageStatusV1AffiliateLeadsMortgageSubmissionIdGet
     * @summary Get Mortgage Status
     * @request GET:/v1/affiliate/leads/mortgage/{submission_id}
     * @secure
     */
    getMortgageStatusV1AffiliateLeadsMortgageSubmissionIdGet: (submissionId: string, params: RequestParams = {}) =>
      this.request<AffiliateCallback, HTTPValidationError>({
        path: `/v1/affiliate/leads/mortgage/${submissionId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
