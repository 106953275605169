/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AcceptTerms {
  encryptedInsuranceId: string;
  http_request_metadata: CustomerHTTPRequestMetadata;
  success_url: string;
  fail_url: string;

  /** The amount the customer picked while confirming */
  insured_amount?: number | null;

  /** The premium amount the customer picked while confirming */
  premium?: number | null;
}

export interface AcceptTermsResponse {
  /** The URL to the Payment Service Provider iframe */
  psp_iframe?: string | null;

  /** The URL to the Payment Service Provider external page */
  psp_external_url?: string | null;
}

export interface Account {
  account_number: string;
  clearing_number?: string | null;
  bank_id?: number | null;
  bank_name?: string | null;
}

export interface Address {
  BasicAreaName?: string | null;
  BasicAreaNumber?: string | null;
  ConcatenatedAddress?: string | null;
  FullAddress?: string | null;
  Municipality?: string | null;
  Postcode?: string | null;
  PostcodeArea?: string | null;
  Rest?: string | null;
  StreetLetter?: string | null;
  StreetName?: string | null;
  StreetNumber?: string | null;
}

export interface ApplicantDocument {
  document_id: string;
  document_type: string | DocumentType;
}

export interface ApplicantNO {
  country?: "NO";
  pni: string;
  first_name: string;
  last_name: string;
  email?: string | null;
  phone_number?: string | null;
  additional_phone_numbers?: string[] | null;
  monthly_property_cost?: number | null;
  marital_status?: MaritalStatus | null;

  /** Official marital status from ID check; should not be modified; marital_status has higher priority */
  registered_marital_status?: MaritalStatus | null;
  post_code?: string | null;
  city?: string | null;
  incomes?: Income[] | null;
  net_monthly_income?: number | null;
  account?: Account | null;
  street_address?: string | null;

  /** Number of children to pay child support for */
  paying_child_support?: number | null;

  /** Number of children to receive child support for */
  receiving_child_support?: number | null;
  consents?: Consents | null;
  consents_popup_dismissed_timestamp?: string | null;
  partner_pni?: string | null;
  partner_name?: string | null;
  municipality_code?: string | null;
  municipality?: string | null;
  is_pep?: boolean | null;
  age?: number | null;
  other_phones?: PhoneNumber[] | null;
  sverker_id?: number | null;
  children?: number | null;
  citizen?: number | null;
  education?: EducationType | null;
  monthly_partner_income?: number | null;
  immigrated_at?: string | null;
  fullname?: string | null;
  documents?: ApplicantDocument[] | null;
  verified_credits?: boolean | null;
  housing_since?: string | null;
  deceased?: boolean | null;
  emigrated?: boolean | null;
  is_sanction?: boolean | null;
  is_rca?: boolean | null;
  date_created?: string | null;
  date_updated?: string | null;
  last_credit_check?: string | null;
  last_externally_verified_credits?: string | null;
  last_property_valuation?: string | null;
  pep_quality?: boolean | null;

  /** A cluster is a group of customers displayed with a color */
  cluster?: string | null;
  housing_type?: HousingTypeNO | null;
  employer?: EmployerNO | null;
  ev_estate?: EvEstate[] | null;
  ev_estates?: EvProperty[] | null;
  citizenship_iso_codes?: string[] | null;
  externally_verified_credits?: ExternallyVerifiedCredit[] | null;

  /** @format uuid */
  customer_id: string;
  credit_check?: CreditCheckNO | null;
  external_block_check?: ExternalBlockCheckNO | null;
  block_list?: BlockList | null;
  gender?: Gender | null;
  gr_consent?: boolean;
  last_externally_verified_credits_consent?: string | null;
}

export interface ApplicantSE {
  country?: "SE";
  pni: string;
  first_name: string;
  last_name: string;
  email?: string | null;
  phone_number?: string | null;
  additional_phone_numbers?: string[] | null;
  monthly_property_cost?: number | null;
  marital_status?: MaritalStatus | null;

  /** Official marital status from ID check; should not be modified; marital_status has higher priority */
  registered_marital_status?: MaritalStatus | null;
  post_code?: string | null;
  city?: string | null;
  incomes?: Income[] | null;
  net_monthly_income?: number | null;
  account?: Account | null;
  street_address?: string | null;

  /** Number of children to pay child support for */
  paying_child_support?: number | null;

  /** Number of children to receive child support for */
  receiving_child_support?: number | null;
  consents?: Consents | null;
  consents_popup_dismissed_timestamp?: string | null;
  partner_pni?: string | null;
  partner_name?: string | null;
  municipality_code?: string | null;
  municipality?: string | null;
  is_pep?: boolean | null;
  age?: number | null;
  other_phones?: PhoneNumber[] | null;
  sverker_id?: number | null;
  children?: number | null;
  citizen?: number | null;
  education?: EducationType | null;
  monthly_partner_income?: number | null;
  immigrated_at?: string | null;
  fullname?: string | null;
  documents?: ApplicantDocument[] | null;
  verified_credits?: boolean | null;
  housing_since?: string | null;
  deceased?: boolean | null;
  emigrated?: boolean | null;
  is_sanction?: boolean | null;
  is_rca?: boolean | null;
  date_created?: string | null;
  date_updated?: string | null;
  last_credit_check?: string | null;
  last_externally_verified_credits?: string | null;
  last_property_valuation?: string | null;
  pep_quality?: any;

  /** A cluster is a group of customers displayed with a color */
  cluster?: string | null;
  housing_type?: HousingTypeSE | null;
  employer?: EmployerSE | null;
  census_registration_year?: string | null;
  credit_regulation_information?: CreditRegulationInformation[] | null;
  credit_regulations?: CreditRegulation[] | null;
  case_collection?: CaseCollectionItem[] | null;
  current_section_of_case?: CurrentSectionOfCaseItem[] | null;
  current_frame?: CurrentFrameItem[] | null;
  html?: string | null;
  last_queries?: LastQuery[] | null;
  notes?: Note[] | null;
  tax?: TaxItem[] | null;
  tax_thousands?: TaxThousand[] | null;
  fraudster?: boolean | null;
  investigation_barrier?: boolean | null;
  uc_interall?: string | null;
  date_of_birth?: string | null;
  prenup_text?: string | null;
  uc_internal_2?: any | null;
  parish?: string | null;
  parish_code?: string | null;
  blocked?: boolean | null;
  protected_address?: boolean | null;
  protected_identity?: boolean | null;
  marital_status_date?: string | null;
  minor?: string | null;
  lost_id_documents?: boolean | null;
  county?: string | null;
  county_code?: string | null;

  /** @format uuid */
  customer_id: string;
  credit_check?: CreditCheckSE | null;
  block_list?: BlockList | null;
  gender?: Gender | null;
}

export interface BlockList {
  pni?: boolean | null;
  phone_number?: boolean | null;
  email?: boolean | null;
}

export interface BodyCancelInsuranceApiCancelInsurancePut {
  insurance_id: string;
  cause: CustomerCancellationRequestReason;
}

export interface BodyGetActiveInsurancesForCustomerIdsApiCustomersInsurancesPost {
  customer_ids: string[];
}

export interface BodyRefundLastChargeForCanceledInsuranceApiRefundPost {
  insurance_id: string;
  description: string;
}

export interface BodyRequestNewMandateEmailApiRequestNewMandateEmailPost {
  insurance_id: string;
}

export interface BodyResendInvitationEmailApiResendInvitationEmailPost {
  insurance_id: string;
}

export enum CTATags {
  MISSING2PAYMENTS = "MISSING_2_PAYMENTS",
  MISSING3PAYMENTS = "MISSING_3_PAYMENTS",
  AUTOMATICALLY_CANCELED = "AUTOMATICALLY_CANCELED",
  INACTIVE_MANDATE = "INACTIVE_MANDATE",
}

export interface Cadastre {
  BNr?: number | null;
  FNr?: number | null;
  GNr?: number | null;
  KNr?: number | null;
  SNr?: number | null;
}

export interface CanHaveInsurance {
  ssn: string[];
}

export interface CanSignUp {
  id?: string | null;
  active: boolean;
  canSignup: boolean;

  /** Not stored as pni in db but as a hash */
  pni?: string | null;
  registeredCustomer: boolean;
  timestamp?: string | null;
}

export enum CancellationTrigger {
  CUSTOMER_REQUEST = "CUSTOMER_REQUEST",
  MANDATE_CANCELLED = "MANDATE_CANCELLED",
  BY_AXA = "BY_AXA",
  END_OF_INSURANCE_LIFETIME = "END_OF_INSURANCE_LIFETIME",
  BECAUSE_OF_CUSTOMER_AGE = "BECAUSE_OF_CUSTOMER_AGE",
  FAILED_PAYMENTS = "FAILED_PAYMENTS",
  DISEASED_CUSTOMER = "DISEASED_CUSTOMER",
  OTHER = "OTHER",
}

export interface CaseCollectionItem {
  uc_type_template_descition?: string | null;
  year?: string | null;
}

export interface Consents {
  email_marketing: boolean;
  sms_marketing: boolean;
  customer_club_marketing: boolean;
}

export enum Country {
  SE = "SE",
  NO = "NO",
}

export interface CreateInsuranceCustomerV2 {
  insurance_type: InsuranceType;
  insured_amount: number;
  premium: number;

  /** @format uuid4 */
  customer_id: string;
}

export interface CreditCheckNO {
  earnedIncome: number;
  mostRecentNoteDate?: string | null;
  creditNotes?: number;
  expired?: boolean;
  expiration_date?: string | null;
  debt_equity_ratio?: number | null;
  debt_equtiy_year?: number | null;
  remarks?: Debt | null;
  cdg2_risk?: number | null;
  cdg2_score?: number | null;
}

export interface CreditCheckSE {
  earnedIncome: number;
  mostRecentNoteDate?: string | null;
  creditNotes?: number;
  expired?: boolean;
  expiration_date?: string | null;
  riskAssessment?: number | null;
  kfmDebt?: number;
  kfmDebtPublic?: number | null;
  kfmDebtPrivate?: number | null;
  numPrevious?: number;
  score?: number | null;
  inquiries?: number | null;
  issuers?: string[] | null;
}

export interface CreditRegulation {
  accounting_controlls_summary?: string | null;
  allow_casuation_storage?: string | null;
  cause_table?: string | null;
  risk?: string | null;
  risk_applicant?: string | null;
  risk_applicant_2?: string | null;
  template_description?: string | null;
  uc_internal_information?: string | null;
  year?: string | null;
}

export interface CreditRegulationInformation {
  account_number?: string | null;
  decision_template?: string | null;
  no_decimals?: string | null;
  order_template?: string | null;
  template_name?: string | null;
  template_response?: string | null;
  year?: string | null;
}

export enum Currency {
  EUR = "EUR",
  SEK = "SEK",
  NOK = "NOK",
}

export interface CurrentFrameItem {
  frame_information?: string | null;
  uc_divisions?: string | null;
  uc_post_type_current?: string | null;
  uc_quick_choice?: any | null;
  year?: string | null;
}

export interface CurrentSectionOfCaseItem {
  additional_order?: string | null;
  editor_program?: string | null;
  order_date?: string | null;
  person_name_stored?: string | null;
  report_language?: string | null;
  subscriber_name?: string | null;
  uc_archive?: string | null;
  uc_divisions_1?: string | null;
  uc_divisions_2?: string | null;
  uc_divisions_3?: string | null;
  uc_divisions_4?: string | null;
  uc_divisions_5?: string | null;
  uc_divisions_6?: string | null;
  uc_divisions_7?: string | null;
  uc_divisions_8?: string | null;
  uc_more_info?: string | null;
  uc_role?: string | null;
  uc_type?: string | null;
  uc_type_internal?: string | null;
  uc_type_text?: string | null;
  year?: string | null;
}

export enum CustomerCancellationRequestReason {
  NO_LONGER_QUALIFIED = "NO_LONGER_QUALIFIED",
  SIGNED_UP_FOR_ANOTHER_INSURANCE = "SIGNED_UP_FOR_ANOTHER_INSURANCE",
  INSURANCE_TO_EXPENSIVE = "INSURANCE_TO_EXPENSIVE",
  DID_NOT_TAKE_THE_LOAN = "DID_NOT_TAKE_THE_LOAN",
  NO_MORE_LOANS = "NO_MORE_LOANS",
  OTHER = "OTHER",
}

export interface CustomerHTTPRequestMetadata {
  ip?: string | null;
  url?: string | null;
  origin?: string | null;
  user_agent?: string | null;
  source?: MetadataSource;
  bankid?: object | null;
}

export interface Debt {
  debt?: DebtItem[] | null;
}

export interface DebtItem {
  date?: string | null;
  type?: string | null;
  source?: string | null;
  status?: string | null;
  saksnr?: string | null;
  amount?: number | null;
  oppgjort?: string | null;
  creditor?: string | null;
  art?: string | null;
  amount_percentage?: number | null;
  currency?: string | null;
  code?: string | null;
}

export enum DocumentType {
  AMORTIZATION_PLAN = "AMORTIZATION_PLAN",
  PETITION_FOR_DIVORCE = "PETITION_FOR_DIVORCE",
  CERTIFICATE_OF_EMPLOYMENT = "CERTIFICATE_OF_EMPLOYMENT",
  DIVISION_OF_PROPERTY_AGREEMENT = "DIVISION_OF_PROPERTY_AGREEMENT",
  VERIFY_ESCROW_AGREEMENT = "VERIFY_ESCROW_AGREEMENT",
  FINANCIAL_FORECASTING_FOR_TENANT_OWNERSHIP_ASSOCIATION = "FINANCIAL_FORECASTING_FOR_TENANT_OWNERSHIP_ASSOCIATION",
  POWER_OF_ATTORNEY = "POWER_OF_ATTORNEY",
  PHYSICAL_MORTGAGE_DEEDS = "PHYSICAL_MORTGAGE_DEEDS",
  INTERMEDIATION_AGREEMENT_INCLUDING_STARTING_PRICE = "INTERMEDIATION_AGREEMENT_INCLUDING_STARTING_PRICE",
  DEED_OF_GIFT = "DEED_OF_GIFT",
  PURCHASE_AGREEMENT = "PURCHASE_AGREEMENT",
  CONTRACTORS_AGREEMENT = "CONTRACTORS_AGREEMENT",
  APARTMENT_CERTIFICATE = "APARTMENT_CERTIFICATE",
  COLLATERAL_OBJECT_DESCRIPTION = "COLLATERAL_OBJECT_DESCRIPTION",
  PENSION_PROJECTION = "PENSION_PROJECTION",
  PENSION_SPECIFICATION = "PENSION_SPECIFICATION",
  AUDITORS_CERTIFICATE = "AUDITORS_CERTIFICATE",
  LATESTAPPROVEDINCOMESTATEMENTANDNE3APPENDIX = "LATEST_APPROVED_INCOME_STATEMENT_AND_NE3_APPENDIX",
  LATEST_APPROVED_INCOME_STATEMENT_AND_NE_APPENDIX = "LATEST_APPROVED_INCOME_STATEMENT_AND_NE_APPENDIX",
  FINAL_INVOICE = "FINAL_INVOICE",
  SALES_AGREEMENT = "SALES_AGREEMENT",
  INFORMATION_TO_CONTACT_PERSON = "INFORMATION_TO_CONTACT_PERSON",
  TENURE_AGREEMENT = "TENURE_AGREEMENT",
  VALUATION = "VALUATION",
  VALUATION_OF_COMPLETED_PROPERTY = "VALUATION_OF_COMPLETED_PROPERTY",
  VALUATION_FROM_INDEPENDENT_BROKER = "VALUATION_FROM_INDEPENDENT_BROKER",
  TRANSFER_AGREEMENT = "TRANSFER_AGREEMENT",
  VALUATION_CERTIFICATE = "VALUATION_CERTIFICATE",
  CASH_DEPOSIT_DOCUMENTATION = "CASH_DEPOSIT_DOCUMENTATION",
  PRELIMINARY_SETTLEMENT_NOTE = "PRELIMINARY_SETTLEMENT_NOTE",
  ORIGINAL_BILL_OF_PURCHASE = "ORIGINAL_BILL_OF_PURCHASE",
  TITLE_DEED = "TITLE_DEED",
  FINAL_SERVEYING_PROTOCOL = "FINAL_SERVEYING_PROTOCOL",
  FINAL_CLEARANCE = "FINAL_CLEARANCE",
  INVOICES = "INVOICES",
  STATEMENT_OF_ACCOUNT = "STATEMENT_OF_ACCOUNT",
  PAYSLIP = "PAYSLIP",
  ANNUAL_REPORT_HOUSING_SOCIETY = "ANNUAL_REPORT_HOUSING_SOCIETY",
  ANNUAL_REPORT_SELF_EMPLOYED = "ANNUAL_REPORT_SELF_EMPLOYED",
  SPOUSE_CONSENT = "SPOUSE_CONSENT",
  OTHER_INCOME_CERTIFICATES = "OTHER_INCOME_CERTIFICATES",
  ESTATE_INVENTORY = "ESTATE_INVENTORY",
  LOAN_TERMS_OPTIONS = "LOAN_TERMS_OPTIONS",
  INSTRUMENT_OF_DEBT = "INSTRUMENT_OF_DEBT",
  KNOW_YOUR_CUSTOMER_QUESTIONS = "KNOW_YOUR_CUSTOMER_QUESTIONS",
  COPY_OF_IDENTIFICATION_CARD = "COPY_OF_IDENTIFICATION_CARD",
  INCOME_TAX_RETURN = "INCOME_TAX_RETURN",
  ENERGY_DECLARATION = "ENERGY_DECLARATION",
  OTHER = "OTHER",
}

export enum EducationType {
  ElementarySchool = "elementarySchool",
  HighSchool = "highSchool",
  Type1YearUniversity = "1yearUniversity",
  Type2YearsUniversity = "2yearsUniversity",
  Type3YearsUniversity = "3yearsUniversity",
  MoreThan3YearsUniversity = "moreThan3yearsUniversity",
}

export interface EmployerNO {
  name: string;
  type: EmploymentTypeNO;

  /** @format date */
  start_date: string;
  end_date?: string | null;
  phone?: string | null;
  retirement_age?: number | null;
  organization_number?: string | null;
  profession?: string | null;
}

export interface EmployerSE {
  name: string;
  type: EmploymentTypeSE;

  /** @format date */
  start_date: string;
  end_date?: string | null;
  phone?: string | null;
  retirement_age?: number | null;
  organization_number?: string | null;
  profession?: string | null;
}

export enum EmploymentTypeNO {
  Full = "full",
  SelfEmployed = "selfEmployed",
  Retired = "retired",
  TemporarilyDischarged = "temporarilyDischarged",
  Other = "other",
  Disabled = "disabled",
  Trial = "trial",
  Hourly = "hourly",
  Student = "student",
  NoWork = "noWork",
  StandIn = "standIn",
  Project = "project",
  NorwegianSickLeave = "norwegianSickLeave",
}

export enum EmploymentTypeSE {
  Full = "full",
  Trial = "trial",
  Retired = "retired",
  Hourly = "hourly",
  EarlyRetired = "earlyRetired",
  Project = "project",
  SelfEmployed = "selfEmployed",
  CompanyOwner = "companyOwner",
  NoWork = "noWork",
}

export interface EstateTypeType {
  Description?: string | null;
  Key?: string | null;
}

export interface EvEstate {
  municipality?: string | null;
  municipality_number?: number | null;
  cadastralUnitNumber?: number | null;
  unitNumber?: number | null;
  leaseholdUnitNumber?: number | null;
  sectionNumber?: number | null;
  apartmentReference?: string | null;
  organizationNumber?: string | null;
  estimatedValue?: number | null;
  commonDebt?: number | null;
  estateType?: string | null;
  address?: EvEstateAddress | null;
}

export interface EvEstateAddress {
  streetName?: string | null;
  streetNumber?: number | null;
  streetLetter?: string | null;
  postCodeArea?: string | null;
  postCode?: string | null;
}

export interface EvProperty {
  info: Info;
  mortgages?: Mortgage[] | null;
  value?: Value | null;
}

export interface Event {
  eventId: string;
  event: InsuranceEventType;
  data?: EventData;

  /** @format date-time */
  date: string;
  orderId?: string | null;
  userId?: string | null;

  /** The type of the event e.g charges, mandate, etc */
  type: string;

  /** If the customer responded for a winbackable insurance */
  response?: boolean | null;

  /** The comment for the winbackable insurance */
  comment?: string | null;
}

export interface EventData {
  trigger?: CancellationTrigger | null;
  mailId?: string | null;
  rejectReason?: string | null;
  successful?: boolean | null;
  signupUrl?: string | null;
  amount?: number | null;

  /** If the insurance has been winbacked */
  winbacked?: boolean | null;

  /** If the customer responded for a winbackable insurance */
  response?: boolean | null;

  /** The comment for the winbackable insurance */
  comment?: string | null;
  newMandateUrl?: string | null;
  handled?: boolean | null;
  [key: string]: any;
}

export interface ExternalBlockCheckNO {
  email?: boolean | null;
  phone?: boolean | null;
  humanitarian?: boolean | null;
}

export interface ExternallyVerifiedCredit {
  balance?: number | null;
  creditType?: string | null;
  creditLimit?: number | null;
  currency?: Currency | null;
  dataOld?: boolean | null;
  financialInstitutionId?: string | null;
  financialInstitutionName?: string | null;
  gjeldsregisteret_type?: string | null;
  hasCoBorrower?: boolean | null;
  installmentChargePeriod?: string | null;
  installmentCharges?: number | null;
  nonInterestBearingBalance?: number | null;
  interestBearingBalance?: number | null;
  isCoBorrower?: boolean | null;
  nominalInterestRate?: number | null;
  originalBalance?: number | null;
  processedTime?: string | null;
  receivedTime?: string | null;
  terms?: number | null;
}

export enum Gender {
  Male = "male",
  Female = "female",
  Other = "other",
}

export interface HTTPValidationError {
  detail?: ValidationError[];
}

export interface HousingCooperatativeShareIdentificationType {
  OrganizationNumber?: string | null;
  ShareNumber?: string | null;
}

export enum HousingTypeNO {
  RENTED = "RENTED",
  OWN_HOUSE = "OWN_HOUSE",
  PARENTS = "PARENTS",
  CONDOMINIUM = "CONDOMINIUM",
  OWN_APARTMENT = "OWN_APARTMENT",
}

export enum HousingTypeSE {
  RENTED = "RENTED",
  CONDOMINIUM = "CONDOMINIUM",
  LIVE_IN = "LIVE_IN",
  OWN_HOUSE = "OWN_HOUSE",
}

export interface IdentityTypeType {
  Description?: string | null;
  Key?: string | null;
}

export interface Income {
  /**
   * Monthly amount
   * @min 0
   */
  amount?: number;

  /**
   * Yearly amount
   * @min 0
   */
  yearly_amount?: number;
  currency?: Currency | null;
  type: IncomeType;
  name: string;
}

export enum IncomeType {
  Rent = "rent",
  Other = "other",
  Employment = "employment",
  Partner = "partner",
}

export interface Info {
  Address: Address;
  Cadastre: Cadastre;
  EV_AddressID?: string | null;
  EV_EstateID?: string | null;
  HousingCooperatativeShareIdentification?: HousingCooperatativeShareIdentificationType | null;
}

export interface Insurance {
  insuranceId: string;
  encryptedInsuranceId?: string | null;
  status: InsuranceStatus;
  substatus: InsuranceSubstatus;
  insuranceType: InsuranceType;
  insuranceGroup?: InsuranceGroup | null;
  mandate?: MandateStatus;
  premiumAmount: number;
  insuredAmount: number;

  /** @format uuid */
  customerId: string;
  sellerId?: string | null;
  seller_name?: string | null;

  /** @format date-time */
  dateCreated: string;
  lastCharge?: LastCharge;
  events?: Event[];
  signupDate?: string | null;
  totalCharged?: number;
  targetInitalChargeDate?: string | null;
  goCardlessSubscriptionId?: string | null;
  failedConsecutiveMonthlyPayments?: number;
  cancellationDate?: string | null;
  currency: Currency;
  cta_tags?: CTATags[];

  /** If the insurance has been handled uin the winbackable state */
  handled?: boolean;
  last_comment?: string | null;
  customer?: (ApplicantSE | ApplicantNO) | null;
}

export enum InsuranceEventType {
  INSURANCE_CREATED = "INSURANCE_CREATED",
  INSURANCE_SIGNUP_URL = "INSURANCE_SIGNUP_URL",
  INSURANCE_UPDATED = "INSURANCE_UPDATED",
  INSURANCE_CANCELLED = "INSURANCE_CANCELLED",
  INSURANCE_PENDING_CANCELLATION = "INSURANCE_PENDING_CANCELLATION",
  CUSTOMER_ACCEPTED_TERMS = "CUSTOMER_ACCEPTED_TERMS",
  CUSTOMER_REQUESTED_SIGNUP_DATA = "CUSTOMER_REQUESTED_SIGNUP_DATA",
  GO_CARDLESS_MANDATE_CREATED = "GO_CARDLESS_MANDATE_CREATED",
  GO_CARDLESS_MANDATE_SUBMITTED = "GO_CARDLESS_MANDATE_SUBMITTED",
  GO_CARDLESS_ACCOUNT_INFO = "GO_CARDLESS_ACCOUNT_INFO",
  GO_CARDLESS_MANDATE_ACQUIRED = "GO_CARDLESS_MANDATE_ACQUIRED",
  GO_CARDLESS_MANDATE_CANCELLED = "GO_CARDLESS_MANDATE_CANCELLED",
  GO_CARDLESS_SUBSCRIPTION_CREATED = "GO_CARDLESS_SUBSCRIPTION_CREATED",
  GO_CARDLESS_SUBSCRIPTION_SUBMITTED = "GO_CARDLESS_SUBSCRIPTION_SUBMITTED",
  GO_CARDLESS_SUBSCRIPTION_CANCELLED = "GO_CARDLESS_SUBSCRIPTION_CANCELLED",
  GO_CARDLESS_PAYMENT_CREATED = "GO_CARDLESS_PAYMENT_CREATED",
  GO_CARDLESS_PAYMENT_CONFIRMED = "GO_CARDLESS_PAYMENT_CONFIRMED",
  GO_CARDLESS_PAYMENT_FAILED = "GO_CARDLESS_PAYMENT_FAILED",
  GO_CARDLESS_PAYMENT_CANCELLED = "GO_CARDLESS_PAYMENT_CANCELLED",
  GO_CARDLESS_PAYMENT_RESUBMISSION_REQUESTED = "GO_CARDLESS_PAYMENT_RESUBMISSION_REQUESTED",
  GO_CARDLESS_REFUND_CREATED = "GO_CARDLESS_REFUND_CREATED",
  GO_CARDLESS_REFUND_PAID = "GO_CARDLESS_REFUND_PAID",
  TRUSTLY_INITIATED = "TRUSTLY_INITIATED",
  TRUSTLY_ACCOUNT_INFO = "TRUSTLY_ACCOUNT_INFO",
  TRUSTLY_MANDATE_ACQUIRED = "TRUSTLY_MANDATE_ACQUIRED",
  TRUSTLY_MANDATE_CANCELLED = "TRUSTLY_MANDATE_CANCELLED",
  GO_CARDLESS_MANDATE_FAILED = "GO_CARDLESS_MANDATE_FAILED",
  TRUSTLY_MANDATE_SIGNUP_CANCELLED = "TRUSTLY_MANDATE_SIGNUP_CANCELLED",
  TRUSTLY_UNKNOWN_EVENT = "TRUSTLY_UNKNOWN_EVENT",
  SCRIVE_SIGNATURE = "SCRIVE_SIGNATURE",
  TRUSTLY_CHARGE_INITIATED = "TRUSTLY_CHARGE_INITIATED",
  TRUSTLY_PENDING_CHARGE = "TRUSTLY_PENDING_CHARGE",
  TRUSTLY_ACCOUNT_CHARGED = "TRUSTLY_ACCOUNT_CHARGED",
  TRUSTLY_CHARGE_CANCELLED = "TRUSTLY_CHARGE_CANCELLED",
  TRUSTLY_CHARGE_REFUNDED = "TRUSTLY_CHARGE_REFUNDED",
  TRUSTLY_CHARGE_REFUND_FAILED = "TRUSTLY_CHARGE_REFUND_FAILED",
  TRUSTLY_REFUND_INITIATED = "TRUSTLY_REFUND_INITIATED",
  TRUSTLY_REFUND_INITIATION_FAILED = "TRUSTLY_REFUND_INITIATION_FAILED",
  TRUSTLY_CANCEL_ORDER_INITIATED = "TRUSTLY_CANCEL_ORDER_INITIATED",
  TRUSTLY_CANCEL_ORDER_FAILED = "TRUSTLY_CANCEL_ORDER_FAILED",
  INVITATION_EMAIL_SENT = "INVITATION_EMAIL_SENT",
  INVITATION_EMAIL_SENT_AGAIN = "INVITATION_EMAIL_SENT_AGAIN",
  INVITATION_EMAIL_REMINDER_SENT = "INVITATION_EMAIL_REMINDER_SENT",
  SIGNUP_CONFIRMATION_EMAIL_SENT = "SIGNUP_CONFIRMATION_EMAIL_SENT",
  SIGNUP_CONFIRMATION_EMAIL_SENT_AGAIN = "SIGNUP_CONFIRMATION_EMAIL_SENT_AGAIN",
  FAILED_TO_CHARGE_EMAIL_SENT = "FAILED_TO_CHARGE_EMAIL_SENT",
  FAILED_TO_CHARGE_EMAIL_SENT_AGAIN = "FAILED_TO_CHARGE_EMAIL_SENT_AGAIN",
  MANDATE_CANCELLED_EMAIL_SENT = "MANDATE_CANCELLED_EMAIL_SENT",
  MANDATE_CANCELLED_EMAIL_SENT_AGAIN = "MANDATE_CANCELLED_EMAIL_SENT_AGAIN",
  NEW_MANDATE_ACCOUNT_INFO_RECEIVED_EMAIL_SENT = "NEW_MANDATE_ACCOUNT_INFO_RECEIVED_EMAIL_SENT",
  NEW_MANDATE_ACCOUNT_INFO_RECEIVED_EMAIL_SENT_AGAIN = "NEW_MANDATE_ACCOUNT_INFO_RECEIVED_EMAIL_SENT_AGAIN",
  CANCEL_EMAIL_UNPAID_PREMIUM_SENT = "CANCEL_EMAIL_UNPAID_PREMIUM_SENT",
  CANCEL_EMAIL_UNPAID_PREMIUM_SENT_AGAIN = "CANCEL_EMAIL_UNPAID_PREMIUM_SENT_AGAIN",
  CANCEL_EMAIL_CUSTOMER_AGE_SENT = "CANCEL_EMAIL_CUSTOMER_AGE_SENT",
  CANCEL_EMAIL_CUSTOMER_AGE_SENT_AGAIN = "CANCEL_EMAIL_CUSTOMER_AGE_SENT_AGAIN",
  CANCEL_EMAIL_MANDATE_CANCELLED_SENT = "CANCEL_EMAIL_MANDATE_CANCELLED_SENT",
  CANCEL_EMAIL_MANDATE_CANCELLED_SENT_AGAIN = "CANCEL_EMAIL_MANDATE_CANCELLED_SENT_AGAIN",
  CANCEL_EMAIL_GENERIC_SENT = "CANCEL_EMAIL_GENERIC_SENT",
  CANCEL_EMAIL_GENERIC_SENT_AGAIN = "CANCEL_EMAIL_GENERIC_SENT_AGAIN",
  NEW_MANDATE_ACQUIRED_EMAIL_SENT = "NEW_MANDATE_ACQUIRED_EMAIL_SENT",
  NEW_MANDATE_ACQUIRED_EMAIL_SENT_AGAIN = "NEW_MANDATE_ACQUIRED_EMAIL_SENT_AGAIN",
  REQUEST_NEW_MANDATE_EMAIL_SENT = "REQUEST_NEW_MANDATE_EMAIL_SENT",
  REQUEST_NEW_MANDATE_LINK_CREATED = "REQUEST_NEW_MANDATE_LINK_CREATED",
  WINBACK_FEEDBACK = "WINBACK_FEEDBACK",
  VIPPS_INITIATED = "VIPPS_INITIATED",
  VIPPS_MANDATE_ACQUIRED = "VIPPS_MANDATE_ACQUIRED",
  VIPPS_MANDATE_EXPIRED = "VIPPS_MANDATE_EXPIRED",
  VIPPS_MANDATE_FAILED = "VIPPS_MANDATE_FAILED",
  VIPPS_MANDATE_CANCELLED = "VIPPS_MANDATE_CANCELLED",
  VIPPS_CHARGE_INITIATED = "VIPPS_CHARGE_INITIATED",
  VIPPS_CHARGE_PENDING = "VIPPS_CHARGE_PENDING",
  VIPPS_CHARGE_CANCELLED = "VIPPS_CHARGE_CANCELLED",
  VIPPS_ACCOUNT_CHARGED = "VIPPS_ACCOUNT_CHARGED",
  VIPPS_CHARGE_FAILED = "VIPPS_CHARGE_FAILED",
  VIPPS_CHARGE_REFUNDED = "VIPPS_CHARGE_REFUNDED",
}

export enum InsuranceGroup {
  SAFETY_INSURANCE = "SAFETY_INSURANCE",
  ACCIDENT_INSURANCE = "ACCIDENT_INSURANCE",
  LIFE_INSURANCE = "LIFE_INSURANCE",
}

export interface InsurancePage {
  insurances: Insurance[];
  total: number;
}

export enum InsuranceStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum InsuranceSubstatus {
  WAITING_FOR_CUSTOMER_ACCEPTANCE = "WAITING_FOR_CUSTOMER_ACCEPTANCE",
  WAITING_FOR_BANK = "WAITING_FOR_BANK",
  INACTIVE_MANDATE = "INACTIVE_MANDATE",
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
  PENDING_CANCELLATION = "PENDING_CANCELLATION",
  DISCARDED = "DISCARDED",
}

export interface InsuranceTerms {
  /** The age at which the insurance ceases to be valid */
  active_max_age: number;
  can_create_new: boolean;
  can_request_new_mandate: boolean;
  can_update_insurance: boolean;
  cancellation_withdrawal_period_days: number;
  countries_of_residence: Country[];
  country: Country;
  currency: Currency;
  customer_can_self_sign_up: boolean;
  employment_types: string[];
  insurance_type: InsuranceType;
  insurance_group: InsuranceGroup;
  insurer_code: string;
  max_age: number;

  /** The maximum amount that can be insured */
  max_amount?: number | null;
  max_insured_rate_vs_monthly_gross_income: number;
  min_age: number;

  /** The minimum amount that can be insured */
  min_amount?: number | null;
  min_months_full_time_employment_time?: number | null;
  min_months_employment_time: number;
  min_hours_of_work_per_week: number;
  min_months_employment_time_for_self_employed?: number | null;
  min_hours_of_work_per_week_for_self_employed?: number | null;
  payment_provider: string;

  /** The rate of the premium insured amount * premium rate = premium amount */
  premium_rate?: number | null;
  usps?: string[];
  terms?: string[];

  /** The premium amount for a static premium insurance e.g accident insurance */
  static_premium?: number | null;

  /** The amount that is insured for insurances with a fixed premium */
  insured_amount?: number | null;

  /** The name of the insurance product */
  product_name: string;
}

export enum InsuranceType {
  AXA_ZS_SE = "AXA_ZS_SE",
  AXA_ZS_NO = "AXA_ZS_NO",
  Z8 = "Z8",
  Z6 = "Z6",
  Z1 = "Z1",
  AXAO1SE = "AXA_O1_SE",
}

export interface LastCharge {
  amount?: number | null;
  date?: string | null;
  orderId?: string | null;
}

export interface LastQuery {
  issuer_business_type?: string | null;
  issuer_name?: string | null;
  query_date?: string | null;
  uc_internal_information?: string | null;
  year?: string | null;
}

export interface LegalDocumentTypeType {
  Description?: string | null;
  Key?: string | null;
}

export interface LienTypeType {
  Description?: string | null;
  Key?: string | null;
}

export interface MandateStatus {
  acquired?: boolean;
  accountId?: string | null;
  canceled?: boolean;
  bank?: string | null;
  lastBankAccountDigits?: string | null;
}

export enum MaritalStatus {
  Unmarried = "unmarried",
  Married = "married",
  Divorced = "divorced",
  WidowWidower = "widow/widower",
  Deceased = "deceased",
  ChildUnder18Years = "child under 18 years",
  PreviouslyMarried = "previously married",
  RegisteredPartnership = "registered partnership",
  Partner = "partner",
}

export enum MetadataSource {
  GR_CONSENT = "GR_CONSENT",
  ONLINE_SUBMISSION_CONSENTS = "ONLINE_SUBMISSION_CONSENTS",
  MY_PAGES_CONSENTS = "MY_PAGES_CONSENTS",
  MY_PAGES_INSURANCE_TERMS_ACCEPTED = "MY_PAGES_INSURANCE_TERMS_ACCEPTED",
  OTHER = "OTHER",
}

export interface Mortgage {
  Document?: string | null;
  DocumentNumber?: string | null;
  DocumentYear?: string | null;
  IdentityName?: string | null;
  IdentityNumber?: string | null;
  IdentityType?: IdentityTypeType | null;
  IsRegisteredElectronically?: boolean | null;
  IsUncertain?: boolean | null;
  LegalDocumentNumber?: string | null;
  LegalDocumentType?: LegalDocumentTypeType | null;
  LienType?: LienTypeType | null;
  MortgageInfoAsText?: string | null;
  NumEstatesMortgageApplyTo?: string | null;
  OfficeNumber?: string | null;
  Priority?: string | null;
  RegisteredDate?: string | null;
  Share?: number | null;
  UncertainReason?: UncertainReasonType | null;
  Value?: number | null;
}

export interface Note {
  cause_description?: string | null;
  year?: string | null;
}

export interface PhoneNumber {
  number: string;
  type: PhoneNumberType;
}

export enum PhoneNumberType {
  Home = "home",
  Day = "day",
  Work = "work",
  Other = "other",
}

export interface ResendEmail {
  insurance_id: string;
  event_id: string;
}

export interface SearchResult {
  hits: Insurance[];
  paginated: boolean;
  total: number;
}

export interface StatusType {
  Description?: string | null;
  Key?: string | null;
}

export interface StatusesAndSubstatuses {
  statuses: InsuranceStatus[];
  substatuses: InsuranceSubstatus[];
}

export enum SupportedFormats {
  JSON = "JSON",
  CSV = "CSV",
}

export interface TaxItem {
  assessed_income?: string | null;
  assessed_wages?: string | null;
  capital_gains?: string | null;
  final_tax?: string | null;
  income_year?: string | null;
  remaining_tax?: string | null;
  total_income?: string | null;
  year?: string | null;
}

export interface TaxThousand {
  assessed_income_thousand?: string | null;
  assessed_wages_thousand?: string | null;
  capital_gains_thousand?: string | null;
  final_tax_thousand?: string | null;
  income_year?: string | null;
  remaining_tax_thousand?: string | null;
  taxed_income?: string | null;
  year?: string | null;
}

export interface UncertainReasonType {
  Description?: string | null;
  Key?: string | null;
}

export interface ValidationError {
  loc: (string | number)[];
  msg: string;
  type: string;
}

export interface Value {
  AccuracyLevel?: string | null;
  AreaMarketScore?: number | null;
  AreaVacationHomeMarketScore?: string | null;
  CustomerApprovedValue?: string | null;
  CustomerMaxValue?: string | null;
  EstateType?: EstateTypeType | null;
  Estimate?: number | null;
  EstimateHaircut?: string | null;
  GrossValueEstimate?: string | null;
  HaircutPercentage?: string | null;
  LastKnownCommondebt?: number | null;
  LastKnownCommondebtDate?: string | null;
  MunicipalityScore?: number | null;
  NetValueEstimate?: number | null;
  Status?: StatusType | null;
  StoredEstimateDocumentationId?: string | null;
}

export interface VippsFetchChargeResponse {
  id: string;
  status: string;

  /** @format date-time */
  due: string;
  amount: number;
  amount_refunded: number;
  transaction_id: string | null;
  description: string;
  type: string;
  failure_reason: string | null;
  failure_description: string | null;
}

export interface WinbackFeedback {
  insurance_id: string;
  handled: boolean;
  winbacked?: boolean | null;
  comment?: string | null;
}

export namespace Api1 {
  /**
   * No description
   * @name GetInsuranceStatusesAndSubstatusesApiInsuranceStatusesGet
   * @summary Get Insurance Statuses And Substatuses
   * @request GET:/api/insurance-statuses
   * @secure
   */
  export namespace GetInsuranceStatusesAndSubstatusesApiInsuranceStatusesGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = StatusesAndSubstatuses;
  }
  /**
   * No description
   * @name RulesApiRulesGet
   * @summary Rules
   * @request GET:/api/rules
   * @secure
   */
  export namespace RulesApiRulesGet {
    export type RequestParams = {};
    export type RequestQuery = { insurance_type?: InsuranceType | null };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = InsuranceTerms[];
  }
  /**
   * No description
   * @name GetInsuranceTypesApiInsuranceTypesGet
   * @summary Get Insurance Types
   * @request GET:/api/insurance-types
   * @secure
   */
  export namespace GetInsuranceTypesApiInsuranceTypesGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = InsuranceType[];
  }
  /**
   * No description
   * @name SearchForInsurancesApiInsurancesGet
   * @summary Search For Insurances
   * @request GET:/api/insurances
   * @secure
   */
  export namespace SearchForInsurancesApiInsurancesGet {
    export type RequestParams = {};
    export type RequestQuery = { q: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SearchResult;
  }
  /**
   * No description
   * @name GetCtaInsurancesApiWinbackableGet
   * @summary Get Cta Insurances
   * @request GET:/api/winbackable
   * @secure
   */
  export namespace GetCtaInsurancesApiWinbackableGet {
    export type RequestParams = {};
    export type RequestQuery = {
      page: number;
      country: Country;
      limit?: number;
      include_handled?: boolean;
      include_events?: boolean;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = InsurancePage;
  }
  /**
   * No description
   * @name WinbackFeedbackApiWinbackFeedbackPost
   * @summary Winback Feedback
   * @request POST:/api/winback_feedback
   * @secure
   */
  export namespace WinbackFeedbackApiWinbackFeedbackPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = WinbackFeedback;
    export type RequestHeaders = {};
    export type ResponseBody = any;
  }
  /**
   * No description
   * @name ResendEmailApiResendEmailPost
   * @summary Resend Email
   * @request POST:/api/resend-email
   * @secure
   */
  export namespace ResendEmailApiResendEmailPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ResendEmail;
    export type RequestHeaders = {};
    export type ResponseBody = Insurance;
  }
  /**
   * No description
   * @name ResendInvitationEmailApiResendInvitationEmailPost
   * @summary Resend Invitation Email
   * @request POST:/api/resend-invitation-email
   * @secure
   */
  export namespace ResendInvitationEmailApiResendInvitationEmailPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyResendInvitationEmailApiResendInvitationEmailPost;
    export type RequestHeaders = {};
    export type ResponseBody = Insurance;
  }
  /**
   * No description
   * @name RequestNewMandateEmailApiRequestNewMandateEmailPost
   * @summary Request New Mandate Email
   * @request POST:/api/request-new-mandate-email
   * @secure
   */
  export namespace RequestNewMandateEmailApiRequestNewMandateEmailPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyRequestNewMandateEmailApiRequestNewMandateEmailPost;
    export type RequestHeaders = {};
    export type ResponseBody = any;
  }
  /**
   * No description
   * @name CreateInsuranceApiCreateInsurancePost
   * @summary Create Insurance
   * @request POST:/api/create-insurance
   * @secure
   */
  export namespace CreateInsuranceApiCreateInsurancePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateInsuranceCustomerV2;
    export type RequestHeaders = {};
    export type ResponseBody = Insurance;
  }
  /**
   * No description
   * @name GetMyInsurancesSlimApiMyInsurancesSlimGet
   * @summary Get My Insurances Slim
   * @request GET:/api/my-insurances/slim
   * @secure
   */
  export namespace GetMyInsurancesSlimApiMyInsurancesSlimGet {
    export type RequestParams = {};
    export type RequestQuery = { page?: number; per_page?: number; substatus?: InsuranceSubstatus | null };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Insurance[];
  }
  /**
   * No description
   * @name GetActiveInsurancesForCustomerIdsApiCustomersInsurancesPost
   * @summary Get Active Insurances For Customer Ids
   * @request POST:/api/customers_insurances
   * @secure
   */
  export namespace GetActiveInsurancesForCustomerIdsApiCustomersInsurancesPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyGetActiveInsurancesForCustomerIdsApiCustomersInsurancesPost;
    export type RequestHeaders = {};
    export type ResponseBody = Insurance[];
  }
  /**
   * No description
   * @name NewMandateLinkApiMandateLinkGet
   * @summary New Mandate Link
   * @request GET:/api/mandate-link
   * @secure
   */
  export namespace NewMandateLinkApiMandateLinkGet {
    export type RequestParams = {};
    export type RequestQuery = { insurance_id: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @name InsuranceStatusWithoutCustomerDataApiInsuranceSlimInsuranceIdGet
   * @summary Insurance Status Without Customer Data
   * @request GET:/api/insurance/slim/{insurance_id}
   * @secure
   */
  export namespace InsuranceStatusWithoutCustomerDataApiInsuranceSlimInsuranceIdGet {
    export type RequestParams = { insuranceId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Insurance;
  }
  /**
   * No description
   * @name GetInsuranceByEncryptedIdApiInsuranceEncryptedIdGet
   * @summary Get Insurance By Encrypted Id
   * @request GET:/api/insurance/encrypted_id
   * @secure
   */
  export namespace GetInsuranceByEncryptedIdApiInsuranceEncryptedIdGet {
    export type RequestParams = {};
    export type RequestQuery = { encrypted_insurance_id: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Insurance;
  }
  /**
   * No description
   * @name GetCancelInsuranceCausesApiCancelInsuranceCausesGet
   * @summary Get Cancel Insurance Causes
   * @request GET:/api/cancel-insurance-causes
   * @secure
   */
  export namespace GetCancelInsuranceCausesApiCancelInsuranceCausesGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CustomerCancellationRequestReason[];
  }
  /**
   * No description
   * @name CancelInsuranceApiCancelInsurancePut
   * @summary Cancel Insurance
   * @request PUT:/api/cancel-insurance
   * @secure
   */
  export namespace CancelInsuranceApiCancelInsurancePut {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyCancelInsuranceApiCancelInsurancePut;
    export type RequestHeaders = {};
    export type ResponseBody = Insurance;
  }
  /**
   * No description
   * @name RefundLastChargeForCanceledInsuranceApiRefundPost
   * @summary Refund Last Charge For Canceled Insurance
   * @request POST:/api/refund
   * @secure
   */
  export namespace RefundLastChargeForCanceledInsuranceApiRefundPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyRefundLastChargeForCanceledInsuranceApiRefundPost;
    export type RequestHeaders = {};
    export type ResponseBody = boolean;
  }
  /**
   * No description
   * @name GetLastChargeOriginalVippsValueApiAdminVippsLastChargeInsuranceIdGet
   * @summary Get Last Charge Original Vipps Value
   * @request GET:/api/admin/vipps/last-charge/{insurance_id}
   * @secure
   */
  export namespace GetLastChargeOriginalVippsValueApiAdminVippsLastChargeInsuranceIdGet {
    export type RequestParams = { insuranceId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = VippsFetchChargeResponse;
  }
  /**
   * No description
   * @name CancelVippsMandateApiAdminVippsCancelMandateInsuranceIdAgreementIdPut
   * @summary Cancel Vipps Mandate
   * @request PUT:/api/admin/vipps/cancel/mandate/{insurance_id}/{agreement_id}
   * @secure
   */
  export namespace CancelVippsMandateApiAdminVippsCancelMandateInsuranceIdAgreementIdPut {
    export type RequestParams = { insuranceId: string; agreementId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = any;
  }
  /**
   * No description
   * @name CanHaveInsuranceApiCanSignupPost
   * @summary Can Have Insurance
   * @request POST:/api/can-signup
   * @secure
   */
  export namespace CanHaveInsuranceApiCanSignupPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CanHaveInsurance;
    export type RequestHeaders = {};
    export type ResponseBody = Record<string, CanSignUp>;
  }
  /**
   * No description
   * @name GetAllInsurancesCustomerIdApiGetInsurancesByCustomerIdGet
   * @summary Get All Insurances Customer Id
   * @request GET:/api/get_insurances_by_customer_id
   * @secure
   */
  export namespace GetAllInsurancesCustomerIdApiGetInsurancesByCustomerIdGet {
    export type RequestParams = {};
    export type RequestQuery = { customer_id: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Insurance[];
  }
  /**
   * No description
   * @name AcceptInsuranceTermsApiAcceptTermsPost
   * @summary Accept Insurance Terms
   * @request POST:/api/accept-terms
   * @secure
   */
  export namespace AcceptInsuranceTermsApiAcceptTermsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = AcceptTerms;
    export type RequestHeaders = {};
    export type ResponseBody = AcceptTermsResponse;
  }
  /**
   * No description
   * @name GetMySalesReportApiAdminSalesReportGet
   * @summary Get My Sales Report
   * @request GET:/api/admin/sales-report
   * @secure
   */
  export namespace GetMySalesReportApiAdminSalesReportGet {
    export type RequestParams = {};
    export type RequestQuery = { from_date: string; to_date: string; format?: SupportedFormats };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = any;
  }
  /**
   * No description
   * @name HealthApiHealthGet
   * @summary Health
   * @request GET:/api/health
   */
  export namespace HealthApiHealthGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = any;
  }
  /**
   * No description
   * @name IncomingGoCardlessNotificationsApiGoCardlessNotificationPost
   * @summary Incoming Go Cardless Notifications
   * @request POST:/api/go-cardless-notification/
   */
  export namespace IncomingGoCardlessNotificationsApiGoCardlessNotificationPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = { "Webhook-Signature": string };
    export type ResponseBody = any;
  }
  /**
   * No description
   * @name GetTrustlyUrlForNewMandateApiRequestNewMandateGet
   * @summary Get Trustly Url For New Mandate
   * @request GET:/api/request-new-mandate
   */
  export namespace GetTrustlyUrlForNewMandateApiRequestNewMandateGet {
    export type RequestParams = {};
    export type RequestQuery = { id: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = any;
  }
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends globalThis.Response {
  data: D;
  error: E;
  requestId: string;
}

export interface HttpErrorResponse<E> extends globalThis.Response {
  error: E;
  requestId: string;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "include",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  private generateUUID() {
    // Public Domain/MIT
    var d = new Date().getTime(); //Timestamp
    var d2 = (typeof performance !== "undefined" && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = Math.random() * 16; //random number between 0 and 16
      if (d > 0) {
        //Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        //Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
  }

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    // We need to generate new RequestID for every request
    let requestId = requestParams.headers["X-Request-ID"];
    if (!requestId) {
      requestId = this.generateUUID();
      requestParams.headers["X-Request-ID"] = requestId;
    }

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;
      r.requestId = requestId;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
          .then((data) => {
            if (r.ok) {
              r.data = data;
            } else {
              r.error = data;
            }
            return r;
          })
          .catch((e) => {
            r.error = e;
            return r;
          });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data as HttpErrorResponse<E>;
      return data;
    });
  };
}

/**
 * @title Zäker
 * @version 0.1.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @name GetInsuranceStatusesAndSubstatusesApiInsuranceStatusesGet
     * @summary Get Insurance Statuses And Substatuses
     * @request GET:/api/insurance-statuses
     * @secure
     */
    getInsuranceStatusesAndSubstatusesApiInsuranceStatusesGet: (params: RequestParams = {}) =>
      this.request<StatusesAndSubstatuses, any>({
        path: `/api/insurance-statuses`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RulesApiRulesGet
     * @summary Rules
     * @request GET:/api/rules
     * @secure
     */
    rulesApiRulesGet: (query?: { insurance_type?: InsuranceType | null }, params: RequestParams = {}) =>
      this.request<InsuranceTerms[], HTTPValidationError>({
        path: `/api/rules`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetInsuranceTypesApiInsuranceTypesGet
     * @summary Get Insurance Types
     * @request GET:/api/insurance-types
     * @secure
     */
    getInsuranceTypesApiInsuranceTypesGet: (params: RequestParams = {}) =>
      this.request<InsuranceType[], any>({
        path: `/api/insurance-types`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SearchForInsurancesApiInsurancesGet
     * @summary Search For Insurances
     * @request GET:/api/insurances
     * @secure
     */
    searchForInsurancesApiInsurancesGet: (query: { q: string }, params: RequestParams = {}) =>
      this.request<SearchResult, HTTPValidationError>({
        path: `/api/insurances`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetCtaInsurancesApiWinbackableGet
     * @summary Get Cta Insurances
     * @request GET:/api/winbackable
     * @secure
     */
    getCtaInsurancesApiWinbackableGet: (
      query: { page: number; country: Country; limit?: number; include_handled?: boolean; include_events?: boolean },
      params: RequestParams = {},
    ) =>
      this.request<InsurancePage, HTTPValidationError>({
        path: `/api/winbackable`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name WinbackFeedbackApiWinbackFeedbackPost
     * @summary Winback Feedback
     * @request POST:/api/winback_feedback
     * @secure
     */
    winbackFeedbackApiWinbackFeedbackPost: (data: WinbackFeedback, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/winback_feedback`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ResendEmailApiResendEmailPost
     * @summary Resend Email
     * @request POST:/api/resend-email
     * @secure
     */
    resendEmailApiResendEmailPost: (data: ResendEmail, params: RequestParams = {}) =>
      this.request<Insurance, HTTPValidationError>({
        path: `/api/resend-email`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ResendInvitationEmailApiResendInvitationEmailPost
     * @summary Resend Invitation Email
     * @request POST:/api/resend-invitation-email
     * @secure
     */
    resendInvitationEmailApiResendInvitationEmailPost: (
      data: BodyResendInvitationEmailApiResendInvitationEmailPost,
      params: RequestParams = {},
    ) =>
      this.request<Insurance, HTTPValidationError>({
        path: `/api/resend-invitation-email`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RequestNewMandateEmailApiRequestNewMandateEmailPost
     * @summary Request New Mandate Email
     * @request POST:/api/request-new-mandate-email
     * @secure
     */
    requestNewMandateEmailApiRequestNewMandateEmailPost: (
      data: BodyRequestNewMandateEmailApiRequestNewMandateEmailPost,
      params: RequestParams = {},
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/api/request-new-mandate-email`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateInsuranceApiCreateInsurancePost
     * @summary Create Insurance
     * @request POST:/api/create-insurance
     * @secure
     */
    createInsuranceApiCreateInsurancePost: (data: CreateInsuranceCustomerV2, params: RequestParams = {}) =>
      this.request<Insurance, HTTPValidationError>({
        path: `/api/create-insurance`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetMyInsurancesSlimApiMyInsurancesSlimGet
     * @summary Get My Insurances Slim
     * @request GET:/api/my-insurances/slim
     * @secure
     */
    getMyInsurancesSlimApiMyInsurancesSlimGet: (
      query?: { page?: number; per_page?: number; substatus?: InsuranceSubstatus | null },
      params: RequestParams = {},
    ) =>
      this.request<Insurance[], HTTPValidationError>({
        path: `/api/my-insurances/slim`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetActiveInsurancesForCustomerIdsApiCustomersInsurancesPost
     * @summary Get Active Insurances For Customer Ids
     * @request POST:/api/customers_insurances
     * @secure
     */
    getActiveInsurancesForCustomerIdsApiCustomersInsurancesPost: (
      data: BodyGetActiveInsurancesForCustomerIdsApiCustomersInsurancesPost,
      params: RequestParams = {},
    ) =>
      this.request<Insurance[], HTTPValidationError>({
        path: `/api/customers_insurances`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name NewMandateLinkApiMandateLinkGet
     * @summary New Mandate Link
     * @request GET:/api/mandate-link
     * @secure
     */
    newMandateLinkApiMandateLinkGet: (query: { insurance_id: string }, params: RequestParams = {}) =>
      this.request<string, HTTPValidationError>({
        path: `/api/mandate-link`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name InsuranceStatusWithoutCustomerDataApiInsuranceSlimInsuranceIdGet
     * @summary Insurance Status Without Customer Data
     * @request GET:/api/insurance/slim/{insurance_id}
     * @secure
     */
    insuranceStatusWithoutCustomerDataApiInsuranceSlimInsuranceIdGet: (
      insuranceId: string,
      params: RequestParams = {},
    ) =>
      this.request<Insurance, HTTPValidationError>({
        path: `/api/insurance/slim/${insuranceId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetInsuranceByEncryptedIdApiInsuranceEncryptedIdGet
     * @summary Get Insurance By Encrypted Id
     * @request GET:/api/insurance/encrypted_id
     * @secure
     */
    getInsuranceByEncryptedIdApiInsuranceEncryptedIdGet: (
      query: { encrypted_insurance_id: string },
      params: RequestParams = {},
    ) =>
      this.request<Insurance, HTTPValidationError>({
        path: `/api/insurance/encrypted_id`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetCancelInsuranceCausesApiCancelInsuranceCausesGet
     * @summary Get Cancel Insurance Causes
     * @request GET:/api/cancel-insurance-causes
     * @secure
     */
    getCancelInsuranceCausesApiCancelInsuranceCausesGet: (params: RequestParams = {}) =>
      this.request<CustomerCancellationRequestReason[], any>({
        path: `/api/cancel-insurance-causes`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CancelInsuranceApiCancelInsurancePut
     * @summary Cancel Insurance
     * @request PUT:/api/cancel-insurance
     * @secure
     */
    cancelInsuranceApiCancelInsurancePut: (
      data: BodyCancelInsuranceApiCancelInsurancePut,
      params: RequestParams = {},
    ) =>
      this.request<Insurance, HTTPValidationError>({
        path: `/api/cancel-insurance`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RefundLastChargeForCanceledInsuranceApiRefundPost
     * @summary Refund Last Charge For Canceled Insurance
     * @request POST:/api/refund
     * @secure
     */
    refundLastChargeForCanceledInsuranceApiRefundPost: (
      data: BodyRefundLastChargeForCanceledInsuranceApiRefundPost,
      params: RequestParams = {},
    ) =>
      this.request<boolean, HTTPValidationError>({
        path: `/api/refund`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetLastChargeOriginalVippsValueApiAdminVippsLastChargeInsuranceIdGet
     * @summary Get Last Charge Original Vipps Value
     * @request GET:/api/admin/vipps/last-charge/{insurance_id}
     * @secure
     */
    getLastChargeOriginalVippsValueApiAdminVippsLastChargeInsuranceIdGet: (
      insuranceId: string,
      params: RequestParams = {},
    ) =>
      this.request<VippsFetchChargeResponse, HTTPValidationError>({
        path: `/api/admin/vipps/last-charge/${insuranceId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CancelVippsMandateApiAdminVippsCancelMandateInsuranceIdAgreementIdPut
     * @summary Cancel Vipps Mandate
     * @request PUT:/api/admin/vipps/cancel/mandate/{insurance_id}/{agreement_id}
     * @secure
     */
    cancelVippsMandateApiAdminVippsCancelMandateInsuranceIdAgreementIdPut: (
      insuranceId: string,
      agreementId: string,
      params: RequestParams = {},
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/api/admin/vipps/cancel/mandate/${insuranceId}/${agreementId}`,
        method: "PUT",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CanHaveInsuranceApiCanSignupPost
     * @summary Can Have Insurance
     * @request POST:/api/can-signup
     * @secure
     */
    canHaveInsuranceApiCanSignupPost: (data: CanHaveInsurance, params: RequestParams = {}) =>
      this.request<Record<string, CanSignUp>, HTTPValidationError>({
        path: `/api/can-signup`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetAllInsurancesCustomerIdApiGetInsurancesByCustomerIdGet
     * @summary Get All Insurances Customer Id
     * @request GET:/api/get_insurances_by_customer_id
     * @secure
     */
    getAllInsurancesCustomerIdApiGetInsurancesByCustomerIdGet: (
      query: { customer_id: string },
      params: RequestParams = {},
    ) =>
      this.request<Insurance[], HTTPValidationError>({
        path: `/api/get_insurances_by_customer_id`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name AcceptInsuranceTermsApiAcceptTermsPost
     * @summary Accept Insurance Terms
     * @request POST:/api/accept-terms
     * @secure
     */
    acceptInsuranceTermsApiAcceptTermsPost: (data: AcceptTerms, params: RequestParams = {}) =>
      this.request<AcceptTermsResponse, HTTPValidationError>({
        path: `/api/accept-terms`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetMySalesReportApiAdminSalesReportGet
     * @summary Get My Sales Report
     * @request GET:/api/admin/sales-report
     * @secure
     */
    getMySalesReportApiAdminSalesReportGet: (
      query: { from_date: string; to_date: string; format?: SupportedFormats },
      params: RequestParams = {},
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/api/admin/sales-report`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name HealthApiHealthGet
     * @summary Health
     * @request GET:/api/health
     */
    healthApiHealthGet: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/api/health`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name IncomingGoCardlessNotificationsApiGoCardlessNotificationPost
     * @summary Incoming Go Cardless Notifications
     * @request POST:/api/go-cardless-notification/
     */
    incomingGoCardlessNotificationsApiGoCardlessNotificationPost: (params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/go-cardless-notification/`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetTrustlyUrlForNewMandateApiRequestNewMandateGet
     * @summary Get Trustly Url For New Mandate
     * @request GET:/api/request-new-mandate
     */
    getTrustlyUrlForNewMandateApiRequestNewMandateGet: (query: { id: string }, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/api/request-new-mandate`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
}
